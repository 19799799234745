import React from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Divider,
  // Text,
  Center,
} from "@chakra-ui/react";
// import CallMultistepForm from "./CallMultistepForm/CallMultistepForm";
import ChatMultistepForm from "./ChatMultistepForm/ChatMultistepForm";
const ChatFlowModal = () => {
  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>Start Consultation</Center>
        </ModalHeader>
        <ModalCloseButton colorScheme={"orange"} />
        <Divider alignSelf={"center"} width={"90%"} color={"orange.300"} />
        <ModalBody p={0}>
          <ChatMultistepForm />
        </ModalBody>
      </ModalContent>
    </>
  );
};

export default ChatFlowModal;
