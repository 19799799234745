import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  // Heading,
  // Icon,
  // Image,
  Img,
  Skeleton,
  Text,
  // WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import "./FreeLive.css";
// import { GiBeveledStar } from "react-icons/gi";
// import Astro_1 from "../../../assets/Astro_1.webp";
// import Star from "../../../assets/Star.png";
// import { Avatar } from "@chakra-ui/react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { G_ALL_LIVE_ASTROLOGERS } from "../../../apiLinks";
import axios from "axios";
// import headerStar from "../../../assets/headerStar.png";
import headerSun from "../../../assets/headerSun.png";
import SkeletonCard from "../AstroCard/SkeletonCard";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase";

const FreeLive = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay of 2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  const [astrologerLive, setAstrologerLive] = useState(false);
  const [liveGurus, setLiveGurus] = useState();


  const getLiveAstrologers = async () => {
    try {
      const response = await axios.get(G_ALL_LIVE_ASTROLOGERS);
      //
      if (response.data.guru.length !== 0) {
        setLiveGurus(response.data.guru.filter((guru) => guru.gid !== 6));
        setAstrologerLive(true);
        // console.log(response.data.guru);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLiveAstrologers();
  }, []);

  const options = useMemo(
    () => ({
      nav: true,
      dots: false,
      //   margin: 4,
      // center: true,
      // autoplay: true,
      // autoplayTimeout: 3000,
      // autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2.1,
          margin: 8,
          nav: false,
          loop: false,
        },
        600: {
          items: 2.8,
          margin: 8,
          loop: true,
          nav: false,
        },
        1000: {
          items: 3,
        },
      },
    }),
    []
  );

  return (
    <>
      {astrologerLive ? (
        <Container
          maxW={"6xl"}
          id="free-live"
          my={{ base: "30px" }}
          className={"section-heading"}
        >
          {/* Heading */}
          <Box
            as="div"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mb={{ base: "1px", md: "1px" }}
          >
            <Img
              src={headerSun}
              alt="header-sun"
              w={"140px"}
              h={"auto"}
              display={"none"}
            />
            {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
            <Text
              fontSize={{ base: "18px", md: "32px" }}
              fontWeight={"700"}
              color={"#D97122"}
              mx={{ base: "12px", md: "19px" }}
            >
              Live Astrologers
            </Text>
            {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          </Box>
          <Box
            as="div"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mb={{ md: "30px", base: "16px" }}
            className={"section-heading"}
          >
            {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
            <Text
              fontSize={{ base: "12px", md: "20px" }}
              fontWeight={"500"}
              color={"#D97122"}
              mx={{ base: "12px", md: "19px" }}
              textAlign={"center"}
            >
              Unlock the expertise of renowned astrologers in their specialized
              fields for premium insights
            </Text>

            {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          </Box>

          {astrologerLive ? (
            <Flex>
              {isLoading ? (
                <Container maxW={"6xl"} mt={{ base: "18px", md: "24px" }}>

                  <Flex

                  >
                    {liveGurus.map((curr, index) => {
                      return (
                        <Skeleton
                          w={{ lg: "295px", md: "330px", base: "170px" }}
                          h={{ md: "334px", base: "307px" }}
                          borderRadius={"12px"}
                          mx={"8px"}
                          key={index}
                        />
                      );
                    })}

                    {/* <SkeletonCard/>
       <SkeletonCard/>
       <SkeletonCard/> */}
                  </Flex>
                </Container>
              ) : (
                <OwlCarousel className="owl-theme" {...options}>
                  {liveGurus.map((curr, index) => {
                    return (
                      <Box
                        //   id="astro-card"
                        borderRadius={"7px"}
                        w={{ lg: "295px", md: "330px", base: "170px" }}
                        h={{ md: "210px", base: "307px" }}
                        key={index}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-end"}
                        _hover={{
                          backgroundColor: "orange.200",
                        }}
                        sx={{
                          backgroundImage: `url(${curr?.user?.avatar?.url})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: { base: "210px", md: "334px" },
                        }}
                        onClick={() => {
                          window.location.href = `/live/${curr?.user?.firstName}${curr?.user?.lastName}?id=${curr?.user?.guru}`;
                          logEvent(analytics, "live_enter");
                        }}
                        cursor={"pointer"}
                      >
                        <Box p={{ md: "19px", base: "14px" }} pb={0}>
                          <Text
                            fontSize={{ md: "16px", base: "12px" }}
                            fontWeight={"700"}
                            color={"white"}
                          >
                            Venus and Art Venus and ArtVenus and Art
                          </Text>
                          <HStack
                            spacing={{ md: "16px", base: "8px" }}
                            mt={{ md: "14px", base: "8px" }}
                          >
                            {/* <Button colorScheme="orange" borderRadius={"0 0 7px 7px"} w={"100%"}>
                        {curr.name}
                      </Button> */}
                          </HStack>
                        </Box>
                        <Button
                          colorScheme="orange"
                          borderRadius={"0 0 7px 7px"}
                          w={"100%"}
                          fontSize={{ md: "md", base: "sm" }}
                        // onClick={() =>
                        //   (window.location.href = `/live/${curr?.user?.firstName}${curr?.user?.lastName}?id=${curr?.user?.guru}`)
                        // }
                        >
                          {curr?.user?.firstName} {curr?.user?.lastName}
                        </Button>
                      </Box>
                    );
                  })}
                </OwlCarousel>
              )}
            </Flex>
          ) : (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ lg: "332px", md: "332px" }}
              bg={"#FFF7EF"}
              borderRadius={"12px"}
            >
              <Text
                fontSize={{ md: "45px", base: "18px" }}
                textAlign={"center"}
                color={"orange.500"}
                fontWeight={"500"}
                py={{ base: "10px" }}
              >
                Live Stream Start Soon
              </Text>
            </Flex>
          )}
        </Container>
      ) : null}
    </>
  );
};

export default FreeLive;
