import { Box, Container, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import Logo from "../../assets/sign.svg";
import Girl from "../../assets/girl.png";

function UserSayCarousel(props) {
  //
  //
  //
  return (
    <Box
      m={"auto"}
      mt={{ md: "70px" }}
      width={{ md: "55%", sm: "80%", base: "90%" }}
    >
      <Flex m={"auto"} gap="46px">
        <Image
          src={Logo}
          w="94px !important"
          height={{ md: "87px" }}
          mt="10px"
          display={{
            md: "inline !important",
            sm: "none !important",
            base: "none !important",
          }}
        />
        <Flex
          flexDirection={{
            md: "row",
            sm: "column-reverse",
            base: "column-reverse",
          }}
          mt={{ md: "0px", sm: "20px", base: "20px" }}
          gap="20px"
        >
          <Box
            m={"auto"}
            width={{ md: "100%", sm: "100%", base: "90%" }}
            textAlign={{ sm: "center", md: "start" }}
          >
            <Heading
              as="h1"
              fontSize={{ md: "36px", sm: "20px", base: "20px" }}
              fontFamily="Arial"
              width={{ md: "300px", sm: "80%", base: "90%" }}
              letterSpacing={"0.0083em"}
              mt={{ base: "20px", sm: "20px", md: "0px" }}
              // ml={{ sm: "120px", base: "50px", md: "0px" }}
              m={{ md: 0, sm: "auto", base: "auto" }}
            >
              <Text
                color="#FCFCFC"
                fontWeight="700"
                w={{ md: "350px", sm: "150px" }}
              >
                {props?.data?.name}{" "}
                {/* <Text
                  as="span"
                  fontSize={"18px"}
                  color="#E6E6E6"
                  position={"relative"}
                  bottom={{ md: "6px", sm: "0", base: "-20px" }}
                  left={{ md: "0", sm: "0", base: "-100px" }}
                  fontWeight="400"
                  m={"auto"}
                >
                  (Doctor){" "}
                </Text> */}
              </Text>{" "}
            </Heading>

            <Text
              mt="20px"
              fontSize={{ sm: "15px", base: "15px", md: "16px" }}
              color={{ md: "#F2F2F2", sm: "#EBEBEB", base: "#EBEBEB" }}
              fontFamily={"Arial"}
            >
              {/* Explore love compatibility, zodiac traits, relationship dynamics,
              astrological timing, and self-love's transformative power. Gain i
              Explore love compatibility, zodiac traits, relationship dynamics,
              astrological timing, and self-love's transformative power. Gain i */}
              {props?.data?.review.slice(0, 300)} {" ...."}
            </Text>
          </Box>
          <Image
            borderRadius={"50%"}
            src={props?.data?.img}
            w="219px !important"
            height={{ md: "207px", sm: "180px", base: "180px" }}
            m="auto"
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default UserSayCarousel;
