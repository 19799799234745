// import React from 'react'
// import {
//     Container,
//     Text,
//     Box,
//     AccordionButton,
//     Accordion,
//     AccordionItem,
//     AccordionIcon,
//     AccordionPanel,
//     Image,
//     Icon,
//     Flex

// } from '@chakra-ui/react'
// import icon1 from './Arrow.svg'


// const QuestionDrop = () => {

//     return (
//         <>
           
//             <Container  maxW={"10xl"} m={"auto"} bg="#FFFFFF" display={{md:"flex", base:"none"}} justifyContent="center" alignItems="center">

//                 <Flex gap={"12px"} my={"20px"} boxShadow='0px 2px 2px rgba(0, 0, 0, 0.15)' flexDirection="row" >
//                     <Text as="h4" >What is astrology ?</Text>
//                    <Image src={icon1} />
//                 </Flex>

//             </Container >


//         </>
//     )
// }

// export default QuestionDrop

import React from 'react'
import {
    Container,
    Text,
    Box,
    AccordionButton,
    Accordion,
    AccordionItem,
    AccordionIcon,
    AccordionPanel,
    Image,
    Icon,
    Flex

} from '@chakra-ui/react'
import icon1 from './Arrow.svg'


const QuestionDrop = () => {

    return (
        <>
            {/* <Container maxW={"6xl"} m={"auto"} bg="#FFFFFF" border="2px solid red">
                <Accordion>
                    <AccordionItem>
                            <AccordionButton _expanded={{ bg: 'tomato', color: 'white' }}>
                                <Box boxShadow='0px 2px 2px rgba(0, 0, 0, 0.15)'>
                                    <Text as="h4" >What is astrology ?</Text>
                                </Box>

                                <AccordionIcon />
                            </AccordionButton>
                        <AccordionPanel>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo consequat.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Container > */}

            <Container
                bg="#FFFFFF"
                maxW={"full"}
                px={0}
                display={{ md: "flex", base: "none" }}
                justifyContent="center"
                boxShadow="0 0 5px gray"
                // border={"2px solid red"}
                my={"20px"}
                py={3} mx={0}
                alignItems="center">

                <Flex gap={"12px"}  marginBottom={"20px"} boxShadow='0px 2px 2px rgba(0, 0, 0, 0.15)' flexDirection="row" >
                    <Text as="h4" >What is astrology ?</Text>
                    <Image src={icon1} />
                </Flex>

            </Container >


        </>
    )
}

export default QuestionDrop