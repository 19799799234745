import React from 'react';
import { Box, Heading, Text } from "@chakra-ui/react";

export default function Viewmoretext({data}) {
 
 
  return (
    <>
     <Box bg={""} style={{ width: "100%",margin:"auto" }}>
            <Box mb={"15px"} mt={"15px"}>
              <Heading textAlign={"center"} fontSize={"20px"}>
                Health
              </Heading>
              <Text textAlign={"center"} color={"#545454"}>
                {data.content.health}{" "}
              </Text>
            </Box>
            <Box mb={"15px"}>
              <Heading textAlign={"center"} fontSize={"20px"}>
                Emotions
              </Heading>
              <Text textAlign={"center"} color={"#545454"}>
                {data.content.emotion}{" "}
              </Text>
            </Box>
            <Box mb={"15px"}>
              <Heading textAlign={"center"} fontSize={"20px"}>
                Profession
              </Heading>
              <Text textAlign={"center"} color={"#545454"}>
                {data.content.emotion}{" "}
              </Text>
            </Box>
            <Box mb={"15px"}>
              <Heading textAlign={"center"} fontSize={"20px"}>
                Luck
              </Heading>
              <Text textAlign={"center"} color={"#545454"}>
                {data.content.luck}{" "}
              </Text>
            </Box>
            <Box mb={"15px"}>
              <Heading textAlign={"center"} fontSize={"20px"}>
                Personal Life
              </Heading>
              <Text textAlign={"center"} color={"#545454"}>
                {data.content.personalLife}{" "}
              </Text>
            </Box>
            <Box mb={"15px"}>
              <Heading textAlign={"center"} fontSize={"20px"}>
                Travel
              </Heading>
              <Text textAlign={"center"} color={"#545454"}>
                {data.content.travel}{" "}
              </Text>
            </Box>
          </Box>
    </>
  )
}
