// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const host = localStorage.getItem("host");

const firebaseConfig =
  host === "gurucool"
    ? {
        apiKey: "AIzaSyCB3ZdFVT29yEX-m6rV0_oFwg5yQYScfsM",
        authDomain: "gurucool-832fa.firebaseapp.com",
        projectId: "gurucool-832fa",
        storageBucket: "gurucool-832fa.appspot.com",
        messagingSenderId: "602494412471",
        appId: "1:602494412471:web:50bf762ce2b47b6d39cf32",
        measurementId: "G-48PN8FVX3M",
      }
    : {
      apiKey: "AIzaSyB1xbtXOpG_W1HVdofUsehaADnsL2KkB7Q",
      authDomain: "my-guruji-d97a1.firebaseapp.com",
      projectId: "my-guruji-d97a1",
      storageBucket: "my-guruji-d97a1.appspot.com",
      messagingSenderId: "166398489997",
      appId: "1:166398489997:web:03f3b7a3b3c25a3d4fb9c4",
      measurementId: "G-6K7YR51C20"
      };

// const firebaseConfig = {
//   apiKey: "AIzaSyCB3ZdFVT29yEX-m6rV0_oFwg5yQYScfsM",
//   authDomain: "gurucool-832fa.firebaseapp.com",
//   projectId: "gurucool-832fa",
//   storageBucket: "gurucool-832fa.appspot.com",
//   messagingSenderId: "602494412471",
//   appId: "1:602494412471:web:50bf762ce2b47b6d39cf32",
//   measurementId: "G-48PN8FVX3M"
// };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export { firebaseApp, analytics, logEvent };

// export default firebase;
