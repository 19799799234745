import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { G_GET_ALL_ASTROLOGERS } from "../apiLinks";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};
export const fetchHomepageAstrologers = createAsyncThunk(
  "data/fetchHomepageAstrologers",
  async () => {
    const { data } = await axios.get(G_GET_ALL_ASTROLOGERS, {
      params: {
        homePageAstrologer: true,
      },
    });
    return data.guru.docs;
  }
);


const homepageAstrologers = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomepageAstrologers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHomepageAstrologers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchHomepageAstrologers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
// export homepageAstrologers.actions;


export default homepageAstrologers.reducer;
