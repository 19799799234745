import { configureStore } from "@reduxjs/toolkit";
import LoggedInUserDetails from "./LoggedInUserDetails";
import loginSystemSlice from "./LoginSystemSlice";
import filterSlice from "./filterSlice";
import clickHandlersSlice from "./clickHandlersSlice";
import startCallBirthDetails from "./startCallBirthDetails";
import callConsultationEndedDetailsSlice from "./callConsultationEndedDetailsSlice";
import TarotSlice from "./TarotSlice";
import selectedAstrologerSlice from "./selectedAstrologerSlice";
import selectedAstrologerDetailsSlice from "./selectedAstrologerDetailsSlice";
import getAllGurus from "./getAllGurus";
import getAllUsers from "./getAllUsers";
import getAllRecharges from "./getAllRecharges";
import getConsultationSlice from "./getConsultationSlice";
import astrologerProfileSlice from "./astrologerProfileSlice";
import getAllStripeRecharges from "./getAllStripeRecharges";
import homepageAstrologers from "./homepageAstrologers";
import consultAstrologerSlice from "./consultAstrologerSlice";
import birthDetailsIdSlice from "./birthDetailsIdSlice";
export default configureStore({
  reducer: {
    logIn: loginSystemSlice,
    loggedInUserDetails: LoggedInUserDetails,
    filter: filterSlice,
    clickHandlers: clickHandlersSlice,
    startCallBirthDetails: startCallBirthDetails,
    callConsultationEndedDetails: callConsultationEndedDetailsSlice,
    data: TarotSlice,
    homepageAstrologers: homepageAstrologers,
    allGurusData: getAllGurus,
    allUsersData: getAllUsers,
    allRazorpayPaymentData: getAllRecharges,
    allStripePaymentData: getAllStripeRecharges,
    selectedAstrologer: selectedAstrologerSlice,
    selectedAstrologerDetails: selectedAstrologerDetailsSlice,
    allConsultationsData: getConsultationSlice,
    astrologerProfile: astrologerProfileSlice,
    consultAstrologers: consultAstrologerSlice,
    birthDetails: birthDetailsIdSlice,
  },
});
