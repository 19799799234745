import { createSlice } from "@reduxjs/toolkit";


const loginSystemSlice = createSlice({
  name: "loginSystem",
  initialState: {
    // todo to check if cookie exist to chheck the user is logged in or not
    userLoggedIn:false,
    mobileNumber:"",
    mobileNumberWithoutCC:"",
    countryCode:"",
    isNewUser:"",
    loginToken:"",
  },
  reducers: {
    setUserLoggedIn(state, action) {
      state.userLoggedIn = action.payload;
    },
    setMobileNumberWithoutCC(state, action) {
      state.mobileNumberWithoutCC = action.payload;
    },
    setCountryCode(state, action) {
      state.countryCode = action.payload;
    },
    setMobileNumber(state, action) {
      state.mobileNumber = action.payload;
    },
    setNewUser(state, action) {
      state.isNewUser = action.payload;
    },
    setLoginToken(state, action) {
      state.loginToken = action.payload;
    },
  },
});

export const loginSystemAction = loginSystemSlice.actions;
export default loginSystemSlice.reducer;
