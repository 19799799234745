import React, { useState } from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Divider,
  // Text,
  Center,
  Text,
} from "@chakra-ui/react";
import CallMultistepForm from "./CallMultistepForm/CallMultistepForm";
import { useSelector } from "react-redux";
const CallFlowModal = () => {
  const host = localStorage.getItem("host");
  const loggedInUserDetails = useSelector((state) => state.loggedInUserDetails);
  const [step, setStep] = useState(
    loggedInUserDetails?.data?.userDetails?.permissions?.consultPopup ? 0 : 1
  );
  return (
    <>
      <ModalOverlay />
      <ModalContent
        border={"1px solid orange"}
        borderRadius={"6px"}
        bgColor={"#F6F6F6"}
        // p="10px"
      >
        <ModalHeader>
          <Center>
            {host === "myguruji" ? (
              <Text margin={"0px"}>
                {step === 0 ? "Important" : "Consultation Details"}
              </Text>
            ) : (
              <Text margin={"0px"}>Birth Details</Text>
            )}
          </Center>
        </ModalHeader>
        <ModalCloseButton colorScheme={"orange"} />
        <Divider
          alignSelf={"center"}
          width={"90%"}
          color={"orange.300"}
          m="0px"
        />
        <ModalBody p={0}>
          <CallMultistepForm step={step} setStep={setStep} />
        </ModalBody>
      </ModalContent>
    </>
  );
};

export default CallFlowModal;
