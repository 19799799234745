import {
  Heading,
  Text,
  VStack,
  Container,
  Box,
  Image,
  Button,
  Flex,
  HStack,
  WrapItem,
  Avatar,
  Divider,
  Stack,
  Card,
  CardBody,
} from "@chakra-ui/react";
import React from "react";
import ImagePlaceholder from "../Asset/Imageplaceholder.svg";
import ImgPlace from "../Asset/ImgPlace.svg";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { useMatch } from "react-router-dom";

const BlogSinglePage = () => {
  // const blogId = localStorage.getItem("blogId");
  // console.log(blogId);
  const [users, setUser] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [content, setContent] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  console.log(blogs);

  const match = useMatch("/blog/:blogId");
  const blogId = match.params.blogId;
  console.log(blogId);

  async function getSingleBlog() {
    try {
      const apiUrl = "https://data.gurucool.life/api/v1/blogs/getBlogs";
      const response = await axios.get(apiUrl, {
        params: {
          blogId: blogId,
        },
      });
      // console.log(response.data);
      setBlogs(response?.data?.blogs);
      // setBlogs(response?.blog?.content);
      // The API response data will be available in response.data
      // return response;
    } catch (error) {
      // Handle error if the API call fails
      console.error("Error fetching blog:", error);
      return null;
    }
  }

  useEffect(() => {
    getSingleBlog();
  }, []);

  function convertIst(date) {
    const utcDate = new Date(date);
    const istDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDate.toLocaleString();
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://data.gurucool.life/api/v1/blogs/getAllBlogs"
        );
        console.log(response);
        const updateObj = response?.data?.blogs?.docs.slice(0, 7);
        console.log(updateObj);
        const mainB = response?.data?.blogs?.docs.filter(
          (obj) => obj.mainBlog === true
        );
        setRelatedBlogs(updateObj);
      } catch (error) {
        setErr(true);
        console.error("Error fetching data:", error.message);
      }
    }
    fetchData();
  }, []);

  console.log(relatedBlogs);

  const filteredData = relatedBlogs.filter((item) => item._id !== blogId);
  console.log(filteredData);

  return (
    <>
      <Container maxW={"6xl"} mt={{ md: "140px", base: "60px" }}>
        <HStack mb={{ base: "50px" }}>
          <Button leftIcon={<ArrowBackIcon />} color="#2B2B2B" variant="link">
            Back
          </Button>
        </HStack>
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          w={{ md: "1000px" }}
          mx={"auto"}
        >
          <Heading
            mb={{ md: "30px", base: "30px" }}
            fontSize={{ base: "30px" }}
          >
            {blogs?.title}
          </Heading>
          <HStack mb={{ md: "60px", base: "60px" }}>
            <Box>
              <WrapItem>
                <Avatar
                  //   size=""
                  name="Dan Abrahmov"
                  src={blogs?.author?.avatar?.url}
                  boxSize={{ md: "50px", base: "50px" }}
                />
              </WrapItem>
            </Box>
            <Box>
              <Text
                fontSize={{ md: "18px", base: "18px" }}
                fontWeight={"400"}
                lineHeight={"28px"}
                mb={{ md: "5px", base: "2px" }}
              >
                {blogs?.author?.firstName} {blogs?.author?.lastName}
                <Text as={"span"} mb={0}>
                  | {blogs.category}
                </Text>
              </Text>

              <Text
                fontSize={{ md: "18px", base: "18px" }}
                fontWeight={"400"}
                mb={0}
                lineHeight={"28px"}
              >
                {convertIst(blogs?.date)}
              </Text>
            </Box>
          </HStack>
          <Box>
            <Image
              src={ImagePlaceholder}
              alt="Image"
              boxSize={"100%"}
              h={{ base: "213px", md: "540px" }}
              w={"100%"}
              borderRadius={"17px"}
            />
          </Box>
          <Text
            fontSize={{ md: "18px", base: "18px" }}
            pt={{ md: "50px", base: "40px" }}
            lineHeight={"28px"}
          >
            How to add a Lottie animation to a prototype in Sketch, Adobe XD or
            Figma
          </Text>

          {/* {blogs?.tags?.map((curr, index) => {
            return (
              <Button
                borderColor={"2px solid #FF4C00"}
                color={"#FF4C00"}
                mr={"4%"}
                variant={"outline"}
                h={"32px"}
                w={"auto"}
                borderRadius={"20px"}
                textAlign={"center"}
              >
                {curr}
              </Button>
            );
          })} */}
        </Flex>
        <HStack
          mb={{ md: "60px" }}
          w={{ md: "1000px" }}
          mx={"auto"}
          pt={{ md: "50px", base: "" }}
        >
          <Box>
            {/* {content.length > 0 ? (
        content.map((curr, index) => (
          key={index} */}
            <Heading
              fontSize={{ md: "24px", base: "24px" }}
              fontWeight={500}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              pb={{ md: "30px", base: "20px" }}
              pt={{ base: "40px", md: "50px" }}
            >
              {blogs?.content?.map((curr) => {
                console.log(curr);
                return curr.heading;
              })}
              {/* aksjkssaksk */}
            </Heading>
            <Text
              as={"p"}
              color="#2B2B2B"
              fontSize={{ md: "18px", base: "18px" }}
              fontWeight={400}
              lineHeight={"28px"}
            >
              {blogs?.content?.map((curr) => {
                console.log(curr);
                return curr.body;
              })}
            </Text>
          </Box>
        </HStack>
        <Box h={{ md: "540px" }} mx={"auto"}>
          <Image src={ImagePlaceholder} alt="Image" boxSize={"100%"} />
        </Box>
        <HStack w={{ md: "1000px" }} mx={"auto"}>
          <Box>
            <Heading
              fontSize={{ md: "24px" }}
              fontWeight={500}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              pt={{ md: "50px", base: "30px" }}
              pb={{ md: "30px", base: "30px" }}
            >
              {blogs?.content?.map((curr) => {
                console.log(curr);
                return curr.heading;
              })}
            </Heading>
            <Text
              as="p"
              color="#2B2B2B"
              fontSize={{ md: "18px", base: "18px" }}
              fontWeight={400}
              lineHeight={"28px"}
            >
              {blogs?.content?.map((curr) => {
                console.log(curr);
                return curr.body;
              })}
            </Text>
          </Box>
        </HStack>
        <Divider
          w={{ md: "1000px", base: "" }}
          my={{ md: "60px" }}
          mx={"auto"}
          color={"#D8D8D8"}
        />
        <Box>
          <Heading
            fontSize={{ md: "24px", base: "" }}
            fontWeight={500}
            textAlign={"center"}
            marginTop={{ md: "100px", base: "" }}
            pb={{ md: "30px", base: "" }}
          >
            Related Posts
          </Heading>
          {filteredData?.slice(0, 2).map((curr, index) => (
            <React.Fragment key={index}>
              <Box
                mt={"20px"}
                mb={"20px"}
                display={"flex"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                rowGap={"2.65rem"}
              >
                <Card w={{ base: "97%", sm: "97%", md: "48.5%" }}>
                  <CardBody>
                    <Image
                      src={curr?.titleImage?.url}
                      alt="ImgPlace"
                      borderRadius="lg"
                    />
                    <Stack mt="6" spacing="3">
                      <Box>
                        <Button
                          borderColor={"2px solid #FF4C00"}
                          color={"#FF4C00"}
                          mr={"4%"}
                          variant={"outline"}
                          h={"32px"}
                          w={"auto"}
                          borderRadius={"20px"}
                          textAlign={"center"}
                        >
                          {curr?.tags}
                        </Button>
                      </Box>
                      <Text
                        fontSize={{ md: "18px", base: "16px" }}
                        fontWeight={"400"}
                        opacity={"1"}
                        color={"#6B6B6B"}
                      >
                        {curr?.author?.firstName} | {curr?.author?.lastName}
                      </Text>
                      <Text
                        fontSize={"18px"}
                        fontWeight={"400"}
                        opacity={"1"}
                        color={"#6B6B6B"}
                      >
                        {convertIst(curr?.date)}
                      </Text>
                      <Text fontSize={"24px"} fontWeight={"500"}>
                      {curr?.title}
                      </Text>
                      <Text
                        fontSize={"16px"}
                        fontWeight={"400"}
                        opacity={"1"}
                        color={"#6B6B6B"}
                      >
                         {/* {curr?.content} */}
                      </Text>
                    </Stack>
                  </CardBody>
                </Card>
              </Box>
            </React.Fragment>
          ))}

          {/* </VStack> */}
          {/* </Flex> */}
        </Box>
      </Container>
    </>
  );
};

export default BlogSinglePage;
