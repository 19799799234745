import React, { useState } from "react";
import Zodiacsignuser from "./Components/Zodiacsignuser";
import Horosscope from "./Components/Horosscope";
import Horoscopedescription from "./Components/Horoscopedescription";
import Hero from "../HomePage/Hero/Hero";
import { useSelector } from "react-redux";
import Formpersonalized from "./Components/Formpersonalized";
import Clickviewmore from "./Clickviewmore";
import Formpersonalized2 from "./Components/Formpersonalized2";
import axios from "axios";
import { Box } from "@chakra-ui/react";

export default function Horoscopewithlogin() {
const loggedIn = useSelector((state) => state.logIn.userLoggedIn);
const [birthdetail,setBirthdetail]=useState(true);
const [zodiacsign,setZodiacsign]=useState("");
const token =localStorage.getItem("loginToken");
const config = {
      headers: {
         'Authorization': `Bearer ${token}`
  }};

const birthdetails=()=>{
    axios.get("https://data.gurucool.life/api/v1/user/getBirthDetails",config)
     .then(response => {
      
       setZodiacsign(response.data.birthDetails.zodiacSign);
       setBirthdetail(true);

     })
     .catch(error => {
       console.error('Error:', error);
       setBirthdetail(false);
});
}
birthdetails();
return (
    <>
      <Hero />
      {loggedIn?(birthdetail?<Clickviewmore name={zodiacsign}/>:<><Box textAlign={"center"} color={"red.500"} fontSize={"25px"}>Please Update Your Birth Details</Box></>):""}
      {/* {loggedIn && birthdetail&& <Clickviewmore name={zodiacsign} />} */}
      <Horosscope />
      {loggedIn? <Formpersonalized2/> :<Formpersonalized />}
      <Horoscopedescription />
      <Hero />
    </>
  );
}
