import {
  Flex,
  Card,
  Heading,
  Text,
  Box,
  // HStack,
  VStack,
  Stack,
  Container,
} from "@chakra-ui/react";
import React from "react";

const Earning = ({ astrologer }) => {
  const earning = [
    {
      text: "Daily Earning",
      money: astrologer.guruEarnings.dailyEarnings,
    },
    {
      text: "Monthly Earning",
      money: astrologer.guruEarnings.monthlyEarnings,
    },
    {
      text: "Yearly Earning",
      money: astrologer.guruEarnings.yearlyEarnings,
    },
  ];
  return (
    <Container maxW={"6xl"}>
      <VStack
        bg="#FEF1E6"
        h={{ md: "206px", base: "auto" }}
        borderRadius="12px"
        mt="32px"
        aspectRatio={"initial"}
      >
        <Box mt={{ base: "4", md: "8" }}>
          <Heading size={"md"} textAlign={"center"}>
            Earnings
          </Heading>
        </Box>

        <Flex justify={"space-evenly"} gap={{ base: "8px", md: "16px" }}>
          {earning &&
            earning.map((earning) => (
              <Flex
                justifyContent="center"
                alignItems="center"
                mt={{ base: "3", md: "15" }}
                pb={"10px"}
                minW={{ md: "196px", base: "104px" }}
              >
                <Card
                  bg="white"
                  p={{ base: "10px", md: "20px" }}
                  borderRadius="12px"
                  gap={{ base: "2.5px", md: "5px" }}
                  minW={{ md: "196px", base: "104px" }}
                >
                  <Text
                    mb="0"
                    fontSize={{ base: "10px", md: "14px" }}
                    textAlign={"center"}
                  >
                    {earning.text}
                  </Text>
                  <Heading
                    fontSize={{ base: "12px", md: "20px" }}
                    color="#7E3A04"
                    textAlign={"center"}
                    mb={0}
                  >
                    ₹ {Math.round(earning.money)}
                  </Heading>
                </Card>
              </Flex>
            ))}
        </Flex>
      </VStack>
    </Container>
  );
};

export default Earning;
