import { Box } from '@chakra-ui/react'
import React from 'react'
import Sidebar from '../Sidebar/Sidebar'

const Help = () => {
  return (
    <div>
        <Sidebar />
        <Box fontSize={"18px"}>Help</Box>
    </div>
  )
}

export default Help