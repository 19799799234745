import React, {
  // useState,
  useEffect,
  useState,
  // useRef
} from "react";
import "../../App.css";
import {
  // Heading,
  // Avatar,
  // Box,
  Container,
  // Text,
  Stack,
  // Button,
  // Link,
  //   IconButton,
  // Image,
  Flex,
  // useDisclosure,
  // Drawer,
  // DrawerOverlay,
  // DrawerContent,
  // DrawerCloseButton,
  // DrawerHeader,
  // DrawerBody,
  Input,
  // DrawerFooter,
  InputRightElement,
  InputGroup,
  Divider,
  HStack,
  Text,
  //   useColorModeValue,
  //   useBreakpointValue,
} from "@chakra-ui/react";

import { useLocation } from "react-router-dom";

import PremiumAstrologer from "./CallWithPremiumAstrologer/PremiumAstrologer";
import LoveProblemAstrologer from "./CallWithLoveProblemAstrologer/LoveProblemAstrologer";
import MarriageProblemAstrologer from "./CallWithMarriageProblemAstrologer/MarriageProblemAstrologer";
import AffordableAstrologer from "./CallWithAffordableAstrologer/AffordableAstrologer";
import AllAstrologer from "./CallWithAllAstrologer/AllAstrologer";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../store/TarotSlice";
// import { useDispatch } from "react-redux";
import { GiBigDiamondRing } from "react-icons/gi";
import { FaHeart, FaHeartbeat } from "react-icons/fa";
import { RiHandHeartLine } from "react-icons/ri";
import { MdBusinessCenter, MdBusiness } from "react-icons/md";
import { GiCardRandom } from "react-icons/gi";
import CallWithFilter from "./CallWithFilter/CallWithFilter";

const CallWithAstrologerPage = () => {
  const data = useSelector((state) => state.data.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  // const [texts, setTexts] = useState([
  //   "love",
  //   "marriage",
  //   "career",
  //   "business",
  //   "life",
  //   "health",
  //   "tarot",
  // ]);
  const [selectedText, setSelectedText] = useState("");
  const [allTextColor, setAllTextColor] = useState("black");

  const [texts, setTexts] = useState([
    {
      text: "Love",
      icon: (
        <FaHeart
          boxSize={{ md: "22px" }}
          color={selectedText === "Love" ? "#FF1E1E" : "gray.500"}
        />
      ),
    },
    {
      text: "Marriage",
      icon: (
        <GiBigDiamondRing
          boxSize={{ md: "22px" }}
          color={selectedText === "Marriage" ? "#08856A" : "gray.500"}
        />
      ),
    },
    {
      text: "Career",
      icon: (
        <MdBusinessCenter
          boxSize={{ md: "22px" }}
          color={selectedText === "Career" ? "#008BAA" : "gray.500"}
        />
      ),
    },
    {
      text: "Business",
      icon: (
        <MdBusiness
          boxSize={{ md: "22px" }}
          color={selectedText === "Business" ? "#008BAA" : "gray.500"}
        />
      ),
    },
    {
      text: "Life",
      icon: (
        <RiHandHeartLine
          boxSize={{ md: "22px" }}
          color={selectedText === "Life" ? "#BC148D" : "gray.500"}
        />
      ),
    },
    {
      text: "Health",
      icon: (
        <FaHeartbeat
          boxSize={{ md: "22px" }}
          color={selectedText === "Health" ? "#D08622" : "gray.500"}
        />
      ),
    },
    {
      text: "Tarot",
      icon: (
        <GiCardRandom
          boxSize={{ md: "22px" }}
          color={selectedText === "Tarot" ? "#8D8800" : "gray.500"}
        />
      ),
    },
  ]);

  const handleAllTextClick = () => {
    setAllTextColor(allTextColor === "black" ? "red" : "black");
    setSelectedText("");
  };

  const handleClick = (text) => {
    setSelectedText(text);
    setAllTextColor("black");
  };

  // const handleTextOrder = (text) => {
  //  
  //   const newTexts = texts.filter((t) => t !== text);
  //   setTexts([text, ...newTexts]);
  // };
  const getTextIcon = (text) => {
    switch (text) {
      case "Love":
        return (
          <FaHeart
            boxSize={{ md: "22px" }}
            color={selectedText === "Love" ? "#FF1E1E" : "gray.500"}
          />
        );
      case "Marriage":
        return (
          <GiBigDiamondRing
            boxSize={{ md: "22px" }}
            color={selectedText === "Marriage" ? "#08856A" : "gray.500"}
          />
        );
      case "Career":
        return (
          <MdBusinessCenter
            boxSize={{ md: "22px" }}
            color={selectedText === "Career" ? "#008BAA" : "gray.500"}
          />
        );
      case "Business":
        return (
          <MdBusiness
            boxSize={{ md: "22px" }}
            color={selectedText === "Business" ? "#008BAA" : "gray.500"}
          />
        );
      case "Life":
        return (
          <RiHandHeartLine
            boxSize={{ md: "22px" }}
            color={selectedText === "Life" ? "#BC148D" : "gray.500"}
          />
        );
      case "Health":
        return (
          <FaHeartbeat
            boxSize={{ md: "22px" }}
            color={selectedText === "Health" ? "#D08622" : "gray.500"}
          />
        );
      case "Tarot":
        return (
          <GiCardRandom
            boxSize={{ md: "22px" }}
            color={selectedText === "Tarot" ? "#8D8800" : "gray.500"}
          />
        );
      default:
        return null; // Return null or a default icon JSX if no match found
    }
  };  

  const handleTextOrder = (text) => {
   
    const newTexts = texts.filter((item) => item.text !== text);
    setTexts([{ text, icon: getTextIcon(text) }, ...newTexts]);
  };

  const getColorForText = (text) => {
    switch (text) {
      case "Love":
        return "#FF1E1E"; // Red color for "Love"
      case "Marriage":
        return "#08856A"; // Green color for "Marriage"
      case "Career":
        return "#008BAA"; // Green color for "Marriage"
      case "Business":
        return "#665AED"; // Green color for "Marriage"
      case "Life":
        return "#BC148D"; // Green color for "Marriage"
      case "Health":
        return "#D08622"; // Green color for "Marriage"
      case "Tarot":
        return "#Tarot"; // Green color for "Marriage"

      default:
        return "blue.500"; // Default color if no match found
    }
  };

  return (
    <>
      <Container maxW={"6xl"} mb={{ base: 4, md: 8 }}>
        {/* <Flex direction={"row"}>
          <Flex
            id="langFilter"
            gap={3}
            align="center"
            mt={{ md: "16px" }}
            wrap={"wrap"}
            maxW={{ md: "300px" }}
          >
            {language.map((currElem, index) => {
              return (
                <Button
                  className={`${bg ? "bg" : ""}`}
                  colorScheme="teal"
                  variant="outline"
                  onClick={setBackground}
                >
                  {currElem}
                </Button>
              );
            })}
          </Flex>
        </Flex> */}
        <Flex
          direction={"row"}
          justify={"space-between"}
          gap={2}
          spacing={2}
          mt={{ base: "8px", md: "12px" }}
        >
          <Stack spacing={4} w={{ base: "100%", md: "60%" }}>
            {/* <InputGroup>
              <Input
                placeholder="Search"
                _focus={{
                  border: "1px solid orange",
                  outline: "none",
                  boxShadow: "none",
                }}
              />
              <InputRightElement
                children={<Search2Icon color="orange.500" />}
                onClick={click}
                cursor={"pointer"}
              />
            </InputGroup> */}
            {/* <HStack align="flex-start">
              <Text
                cursor="pointer"
                color={allTextColor}
                fontWeight={allTextColor === "red" ? "bold" : "normal"}
                onClick={handleAllTextClick}
                mr={{md: "56px"}}
              >
                All
              </Text>
              <HStack spacing={{ md: "20px" }}>
                {texts.map((text, index) => (
                  <HStack>
                    {text.icon}
                    {getTextIcon(text.text)}
                    <Text
                      key={index}
                      color={
                        selectedText === text.text
                          ? getColorForText(text.text)
                          : "gray.500"
                      }
                      fontWeight={
                        selectedText === text.text ? "bold" : "normal"
                      }
                      onClick={() => {
                        handleClick(text.text);
                        handleTextOrder(text.text);
                      }}
                      cursor="pointer"
                    >
                      {text.text}
                    </Text>
                  </HStack>
                ))}
              </HStack>
            </HStack> */}
          </Stack>
          {/* <CallWithFilter /> */}
        </Flex>
        <PremiumAstrologer data={data} />
        {/* <Divider my="6" w={"80%"} mx={"auto"} color="orange.600" />
        <LoveProblemAstrologer />
        <Divider my="6" w={"80%"} mx={"auto"} color="orange.600" />
        <MarriageProblemAstrologer />
        <Divider my="6" w={"80%"} mx={"auto"} color="orange.600" />
        <AffordableAstrologer />
        <Divider my="6" w={"80%"} mx={"auto"} color="orange.600" /> */}
      </Container>
    </>
  );
};

export default CallWithAstrologerPage;

// import React, {
//   // useState,
//    useEffect,
//     // useRef
//    } from "react";
// import "../../App.css";
// import {
//   // Heading,
//   // Avatar,
//   // Box,
//   Container,
//   // Text,
//   Stack,
//   // Button,
//   // Link,
//   //   IconButton,
//   // Image,
//   Flex,
//   // useDisclosure,
//   // Drawer,
//   // DrawerOverlay,
//   // DrawerContent,
//   // DrawerCloseButton,
//   // DrawerHeader,
//   // DrawerBody,
//   Input,
//   // DrawerFooter,
//   InputRightElement,
//   InputGroup,
//   Divider,
//   //   useColorModeValue,
//   //   useBreakpointValue,
// } from "@chakra-ui/react";

// import { useLocation } from "react-router-dom";

// import PremiumAstrologer from "./CallWithPremiumAstrologer/PremiumAstrologer";
// import LoveProblemAstrologer from "./CallWithLoveProblemAstrologer/LoveProblemAstrologer";
// import MarriageProblemAstrologer from "./CallWithMarriageProblemAstrologer/MarriageProblemAstrologer";
// import AffordableAstrologer from "./CallWithAffordableAstrologer/AffordableAstrologer";
// import AllAstrologer from "./CallWithAllAstrologer/AllAstrologer";
// import CallWithFilter from "./CallWithFilter/CallWithFilter";
// import { Search2Icon } from "@chakra-ui/icons";
// import { fetchData } from "../../store/TarotSlice";
// import { useDispatch } from "react-redux";

// const CallWithAstrologerPage = () => {
//   // const language = ["English", "Marathi", "Hindi", "Kannada", "Telugu"];
//   // const [bg, setBg] = useState(true);

//   // const setBackground = () => {
//   //   setBg(true);
//   // };

//   // const myStyle = {
//   //   color: "white",
//   //   backgroundColor: "#DD7323",
//   // };

//   const { pathname } = useLocation();
//   // const componentRef = useRef(null);
//   // useEffect(() => {
//   //   componentRef.current.scrollIntoView({ behavior: 'smooth' });
//   // }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     // window.location.reload();
//   }, [pathname]);

//   const click = () => {
//    
//   };

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(fetchData());
//   }, [dispatch]);

//   return (

//   );
// };

// export default CallWithAstrologerPage;
