import {
  Stack,
  Box,
  Image,
  VStack,
  // HStack,
  Heading,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Hero_Bg from "../../../assets/Hero_Bg.webp";
import Hero_Bg_Mobile from "../../../assets/Hero_Bg_Mobile.webp";
import customer_support from "../../../assets/New_hero/customer_support.png";
import wallet from "../../../assets/New_hero/wallet.png";
import Private_Network from "../../../assets/New_hero/Private_Network.png";
import agreement from "../../../assets/New_hero/agreement.png";
import secure_payments from "../../../assets/New_hero/secure_payments.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Carousel } from "react-bootstrap";
import "../NewHero/Newhero.css";
import { useTypewriter, Cursor, Typewriter } from "react-simple-typewriter";

const NewHero = () => {
  const headingText = [
    {
      id: 1,

      heading: `"India's Premium Astrologer"`,
      text: "Consult with India's Premium Astrologer which are handpick for you ",
      text2: "100% Refund if you are not Satisfied 🙂",
    },
    {
      id: 2,

      heading: "GuruCool: Help You Discover Your potential ",
      text: "Connect with Indian Top Premium astrologers expert.",
      text2: "Your First Consultation Is free 🆓😃",
    },
    {
      id: 3,

      heading: "Astrologer Expert of India 💍💖💼🏢",
      text: "Get your First Consultation with our Expert Astrologers for Free.",
      text2: "Take control of your destiny with our Experts",
    },
    {
      id: 4,

      heading: "Astrologer Expert of India: Get the Guidance You Need",
      text: "Avoid making costly mistakes with the expert advice of our astrologers.",
      text2: "Get your first consultation for free!",
    },
  ];

  const { text } = useTypewriter({
    words: ["Heading 1", "Heading 2", "Heading 3"],
    loop: {},
  });

  const trust = [
    {
      id: 1,
      img: customer_support,
      text: "24 X 7 Customer  Support",
    },
    {
      id: 2,
      img: wallet,
      text: "100% Refund if Unsatisfied",
    },
    {
      id: 3,
      img: Private_Network,
      text: "Private & Confidential",
    },
    {
      id: 4,
      img: agreement,
      text: "Verfied Astrologer",
    },
    {
      id: 5,
      img: secure_payments,
      text: "Secure Payment",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <Stack
        position="relative"
        height={{ lg: "580px", md: "480px", base: "299x" }}
        justify={"center"}
        // mb={{ md: "11px", base: "9px" }}
        align={"center"}
        spacing={{ md: "70px", base: "38px" }}
      >
        <Box position={"absolute"} w="100%" zIndex="-1" >
          <Image
            // objectFit={"cover"}
            src={Hero_Bg}
            // srcSet="Hero_Bg 768w, Hero_Bg_Mobile 480w"
            alt="hero-banner"
            w="100%"
            height={{ lg: "580px", md: "480px", base: "299px" }}
            display={{ base: "none", md: "block" }}
          />
          <Image
            // objectFit={"cover"}
            src={Hero_Bg_Mobile}
            // srcSet="Hero_Bg 768w, Hero_Bg_Mobile 480w"
            alt="hero-banner"
            w="100%"
            height={{ base: "299px" }}
            display={{ base: "block", md: "none" }}
          />
        </Box>
        {/* <VStack>
          <Carousel className="hide-carousel-buttons"
          >
            {headingText.map((curr) => (
              <Carousel.Item>
                  
                    <Stack>
          <Heading
            fontSize={{ md: "58px", base: "27px" }}
            fontWeight={"700"}
            color="white"
            mb={{ md: "14px", base: "4px" }}
          >
            
            {curr.heading}
            
           
          </Heading>
          <Text
            fontSize={{ md: "16px", base: "12px" }}
            fontWeight={"400"}
            color={"white"}
            pb={{ md: "40px", base: "20px" }}
            textAlign="center"
            mx={"15px "}
          >
           
            {curr.text}
            
             
           
          </Text>
          </Stack>
          </Carousel.Item>
            ))}
          </Carousel>
          <Button
            variant="solid"
            colorScheme="orange"
            borderRadius="full"
            py={{ base: "4px", md: "16px" }}
            px={{ base: "14px", md: "56px" }}
            onClick={() => (window.location.href = "/astrologers")}
          >
            Consult Now
          </Button>
          
        </VStack> */}

        <Carousel
        style={{minHeight:'120px'}}
          className="hide-carousel-buttons"
          interval={3000}
          // style={{ marginTop: "18px !important" }}
        >
          {headingText.map((curr) => (
            <Carousel.Item 
            
            // style={{ marginTop: "18px !important" }}
            >
              <VStack>
                <Heading
                  fontSize={{ md: "50px", base: "24px" }}
                  fontWeight={"700"}
                  color="white"
                  style={{ textAlign: "center" }}
                  // mb={{ md: "14px", base: "4px" }}
                >
                  {curr.heading}
                </Heading>
                <Text
                
                  fontSize={{ md: "25px", base: "12px" }}
                  fontWeight={"400"}
                  color={"white"}
                  // pb={{ md: "40px", base: "20px" }}
                  textAlign="center"
                  mx={"15px "}
                >
                  {curr.text}
                  <br />
                  {curr.text2}
                </Text>
              </VStack>
            </Carousel.Item>
          ))}
        </Carousel>
        <Button
          mt={"18px !important"}
          variant="solid"
          colorScheme="orange"
          borderRadius="full"
          py={{ base: "4px", md: "16px" }}
          px={{ base: "14px", md: "56px" }}
          onClick={() => (window.location.href = "/astrologers")}
        >
           Consult Now
        </Button>
        {/* Style will be inherited from the parent element */}

        {/* <Stack className="hide-carousel-buttons">
          <VStack>
            <Heading
              fontSize={{ md: "58px", base: "27px" }}
              fontWeight={"700"}
              color="white"
              mb={{ md: "14px", base: "4px" }}
            >"India's Premium Astrologer"
               */}
        {/* <Typewriter
            words={['Eat', 'Sleep', 'Code', 'Repeat!']}
            loop={false}
            // cursor
            // cursorStyle='_'
            typeSpeed={80}
            deleteSpeed={50}
            delaySpeed={500}
            // onLoopDone={handleDone}
            // onType={handleType}
          /> */}
        {/* <Cursor/> */}
        {/* </Heading> */}
        {/* <Text
              fontSize={{ md: "16px", base: "12px" }}
              fontWeight={"400"}
              color={"white"}
              pb={{ md: "40px", base: "20px" }}
              textAlign="center"
              mx={"15px "}
            >
               Consult with India's Premium Astrologer which are handpick for you 100% Refund if you are not Satisfied 🙂
            </Text> */}

        {/* <Button
              variant="solid"
              colorScheme="orange"
              borderRadius="full"
              py={{ base: "4px", md: "16px" }}
              px={{ base: "14px", md: "56px" }}
              onClick={() => (window.location.href = "/astrologers")}
            >
              Free Consult Now
            </Button> */}
        {/* </VStack> */}
        {/* </Stack> */}

        <Flex
          gap={{ lg: "48px" }}
          // mt={{ lg: "60px", md: "40px", base: "auto" }}
          // mx={{base: "48px", md:'24px'}}
        >
          {trust.map((curr, index) => {
            return (
              <Flex
                direction={{ lg: "row", md: "column", base: "column" }}
                key={curr.id}
                align={"center"}
                justify={"center"}
              >
                <Box
                  maxWidth="100%"
                  height="auto"
                  mr={{ lg: "16px", md: "0", base: "0" }}
                >
                  <Image
                    maxWidth={{ md: "100%", base: "60%" }}
                    src={curr.img}
                    alt="customer_support"
                    mx="auto"
                  />
                </Box>
                <Text
                  fontSize={{ md: "15px", base: "8px" }}
                  fontWeight={"500"}
                  color="white"
                  textAlign="center"
                  marginBottom={"0"}
                  w={{ base: "70%", md: "70%", lg: "100%" }}
                >
                  {curr.text}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Stack>
    </>
  );
};

export default NewHero;
