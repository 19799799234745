import React, { useState, useEffect } from "react";
import "../../../App.css";
import {
  // Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  // GridItem,
  FormLabel,
  Input,
  Select,
  // SimpleGrid,
  // InputLeftAddon,
  InputGroup,
  // Textarea,
  // FormHelperText,
  // InputRightElement,
  Image,
  Text,
  Divider,
  // HStack,
  VStack,
  Stack,
  Avatar,
  Center,
  // FormErrorMessage,
  Spinner,
  Checkbox,
} from "@chakra-ui/react";
// import {  } from "@chakra-ui/react";
// import { TimeIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

// import _user from "../../../assets/_user.png";
import Insufficient_balance from "../../../assets/Insufficient_balance.png";

import { useToast } from "@chakra-ui/react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { startCallBirthDetailsAction } from "../../../store/startCallBirthDetails";
import {
  G_GET_SINGLE_ASTROLOGER_BY_TOKEN,
  G_GET_USER_PROFILE,
  P_STRIPE_CHECKOUT,
  P_STRIPE_CHECKOUT_INTERNATIONAL,
} from "../../../apiLinks";
import { fetchHomepageAstrologers } from "../../../store/homepageAstrologers";

import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";
import { fetchLoggedInUserDetails } from "../../../store/LoggedInUserDetails";

const Form1 = () => {
  const isInternational = localStorage.getItem("isInternational") === "true";
  const token = localStorage.getItem("guruToken");
  const dispatch = useDispatch();
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const astroDetails = useSelector(
    (state) => state.selectedAstrologerDetails.astroDetails
  );
  // console.log("......................",astroDetails);
  const [statusQueue, setStatusQueue] = useState(false);
  // axios.get(G_GET_SINGLE_ASTROLOGER_BY_TOKEN(localStorage.getItem("guruToken"))).then((response) => {
  //   console.log("response",response);
  //   // setAstrodetails(response.data.guru);
  // });
  // console.log(statusQueue);
  useEffect(() => {
    const fetchData = async () => {
      try {
        axios
          .get(
            G_GET_SINGLE_ASTROLOGER_BY_TOKEN(localStorage.getItem("guruToken"))
          )
          .then((response) => {
            // console.log("response",response);
            if (response?.data?.guru?.callQueue.length > 0) {
              setStatusQueue(true);
            }
          });
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    const interval = setInterval(fetchData, 1000);

    // Call fetchData immediately upon mounting
    fetchData();

    return () => clearInterval(interval);
  }, []);

  const userDetails = useSelector((state) => state.loggedInUserDetails.data);

  const userWalletBalance =
    userDetails &&
    (isInternational
      ? userDetails?.userDetails?.internationalWallet
      : userDetails?.userDetails?.wallet);

  // const fee = isInternational
  //   ? astroDetails.internationalFee
  //   : astroDetails.fee;
  const fee = getPrice(isInternational, userDetails, astroDetails);
  useEffect(() => {
    if (
      host === "myguruji" &&
      userDetails?.userDetails?.consultationCount === 0
    ) {
      if (fee > 0) {
        if (
          userWalletBalance >= fee * 5 ||
          userDetails?.userDetails?.normalUserJourney2 === false
        ) {
          setInsufficientBalance(false);
        } else {
          setInsufficientBalance(true);
        }
      } else {
        setInsufficientBalance(true);
      }
    } else {
      if (fee > 0) {
        if (
          userWalletBalance >=
          fee * 5
          // || !userDetails?.userDetails?.normalUserJourney2
        ) {
          setInsufficientBalance(false);
        } else {
          setInsufficientBalance(true);
        }
      } else {
        setInsufficientBalance(true);
      }
    }
  }, [astroDetails, userDetails]);

  const minCallDuration = Math.floor(userWalletBalance / fee);
  dispatch(startCallBirthDetailsAction.setMinCallDuration(minCallDuration));

  // const [show, setShow] = React.useState(false);
  const host = localStorage.getItem("host");
  console.log("userDetails?.userDetails.NewUser", userDetails);

  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${token}`
        )
        .then((response) => {
          //
          dispatch(
            selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
          );
        });
    }
    // setIsLoading(false);
  }, [token]);

  return (
    <>
      {astroDetails && !insufficientBalance ? (
        <Flex direction={"column"}>
          <Box mb={{ base: "8px", md: "16px" }}>
            <Center>
              <Avatar
                mx={"auto"}
                src={astroDetails.user.avatar.url}
                alt="user"
                width="100px"
                height="100px"
                // maxW={{ base: "25%", md: "100%" }}
              />
            </Center>
            <Text
              margin="0px"
              fontSize={{ base: "16px", md: "22px" }}
              fontWeight={"500"}
              textAlign={"center"}
            >
              {astroDetails.user.firstName} {astroDetails.user.lastName}
            </Text>
          </Box>
          <Divider
            m="0"
            alignSelf={"center"}
            width={"90%"}
            color={"gray.300"}
          />

          <Flex
            justifyContent={"space-between"}
            my={{ base: "8px", md: "12px" }}
          >
            <VStack borderRight="1px" w={"50%"}>
              <Text
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight={500}
                color={"#7B7B7B"}
                m="0"
              >
                Consultation Charges
              </Text>
              <Flex
                fontSize={{ base: "14px", md: "25px" }}
                fontWeight={500}
                color={"#252525"}
              >
                {/* <Text
                  m="0"
                  display={
                    userDetails?.userDetails?.consultationCount === 0
                      ? "flex"
                      : "none"
                  }
                  color={"green"}
                >
                  FREE &nbsp;
                </Text> */}
                <Text
                  fontSize={{ base: "14px", md: "25px" }}
                  fontWeight={500}
                  color={"#252525"}
                  margin={"0px"}
                  // textDecoration={
                  //   userDetails?.userDetails?.consultationCount === 0
                  //     ? "line-through"
                  //     : "none"
                  // }
                >
                  {userDetails?.userDetails?.website[0] === "gurucool.co" &&
                  host === "myguruji" &&
                  userDetails?.userDetails?.consultationCount === 0 &&
                  !userDetails?.userDetails?.normalUserJourney2 ? (
                    <Text margin={"0px"} color={"#15AD00"}>
                      Free
                    </Text>
                  ) : (
                    <>
                      {astroDetails &&
                        (isInternational ? "$" + fee : "₹" + fee)}
                      {"/min"}
                    </>
                  )}
                </Text>
              </Flex>
            </VStack>
            {/* {userDetails?.userDetails?.consultationCount === 0 ? (
              <VStack w={"50%"}>
                <Text
                  m="0"
                  fontSize={{ base: "14px", md: "18px" }}
                  fontWeight={500}
                  color={"#7B7B7B"}
                >
                  Duration
                </Text>
                <Text
                  m="0"
                  fontSize={{ base: "14px", md: "25px" }}
                  fontWeight={500}
                  color={"#252525"}
                >
                  4 mins
                </Text>
              </VStack>
            ) : ( */}
            <VStack w={"50%"}>
              {host === "myguruji" &&
              userDetails?.userDetails?.website[0] === "gurucool.co" &&
              (userDetails?.userDetails?.consultationCount === 0 ||
                userDetails?.userDetails?.normalUserJourney2) ? (
                <Text
                  m="0"
                  fontSize={{ base: "14px", md: "18px" }}
                  fontWeight={500}
                  color={"#7B7B7B"}
                >
                  Duration
                </Text>
              ) : (
                <Text
                  m="0"
                  fontSize={{ base: "14px", md: "18px" }}
                  fontWeight={500}
                  color={"#7B7B7B"}
                >
                  Your Wallet Balance
                </Text>
              )}
              {host === "myguruji" &&
              userDetails?.userDetails?.website[0] === "gurucool.co" &&
              (userDetails?.userDetails?.consultationCount === 0 ||
                userDetails?.userDetails?.normalUserJourney2) ? (
                <Text
                  m="0"
                  fontSize={{ base: "14px", md: "25px" }}
                  fontWeight={500}
                  color={"#252525"}
                >
                  {`${userDetails?.userDetails?.timeWallet} Min`}
                </Text>
              ) : (
                <Text
                  m="0"
                  fontSize={{ base: "14px", md: "25px" }}
                  fontWeight={500}
                  color={"#252525"}
                >
                  {userDetails.userDetails &&
                    (isInternational
                      ? "$ " +
                        parseFloat(
                          userDetails.userDetails.internationalWallet
                        ).toFixed(2)
                      : "₹ " + Math.round(userDetails.userDetails.wallet))}
                  {/* ₹ {userWalletBalance} */}
                </Text>
              )}
              {host === "myguruji" ? null : (
                <Text
                  m="0"
                  fontSize={{ base: "10px", md: "14px" }}
                  fontWeight={400}
                  color={"gray.500"}
                >
                  Max call duration: {minCallDuration} min
                </Text>
              )}
            </VStack>
            {/* )} */}
          </Flex>
          <Divider
            m="0"
            alignSelf={"center"}
            width={"90%"}
            color={"gray.300"}
          />
          {/* {userDetails?.userDetails?.consultationCount === 1 &&
          userDetails?.userDetails?.paymentsDetails.length === 0 ? (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width={"100%"}
            >
              <Text
                m="0"
                fontSize={{ base: "12px", md: "16px" }}
                textAlign="center"
                width={{ base: "100%", lg: "70%" }}
              >
                Limited time offer Recharge with just{" "}
                <span style={{ color: "#FF4C00" }}>
                  1{isInternational ? "$" : "Rs"}/-{" "}
                </span>
                and enjoy a{" "}
                <span style={{ color: "#FF4C00" }}>FREE consultation!</span>
              </Text>
            </Flex>
          ) : ( */}
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={"100%"}
          >
            {userDetails?.userDetails?.website[0] === "gurucool.co" &&
            userDetails?.userDetails?.consultationCount === 0 &&
            (userDetails?.userDetails?.paymentsDetails?.length === 0 ||
              userDetails?.userDetails?.stripeInvoice?.length === 0) ? (
              <>
                <Text color="#FF4C00" textDecoration="underline" mb="10px">
                  ONE TIME OFFER
                </Text>

                <Text color="#828282" fontSize={"14px"}>
                  <Text as="span" textDecoration="underline" fontWeight={"400"}>
                    Recharge of 1₹
                  </Text>{" "}
                  & get additional{" "}
                  <Text as="span" textDecoration="underline" fontWeight={"700"}>
                    2 Minutes more
                  </Text>
                </Text>
              </>
            ) : (
              <>
                <Text
                  m="0"
                  fontSize={{ base: "12px", md: "16px" }}
                  textAlign="center"
                  width={{ base: "100%", lg: "80%" }}
                >
                  Please provide your detail for consultation: First and last
                  name, Gender, Date and time of birth, Town/city of birth.
                </Text>
                <Text
                  fontSize={{ base: "12px", md: "16px" }}
                  textAlign="center"
                  width={"70%"}
                >
                  Happy Consulting.{" "}
                  <Text as={"span"} color={"red"}>
                    {statusQueue && " Join Queue"}
                  </Text>
                </Text>
              </>
            )}
          </Flex>
          {/* )} */}
        </Flex>
      ) : (
        <Flex direction={"column"}>
          <Heading
            fontSize={{ base: "14px", md: "20px" }}
            fontWeight={500}
            textAlign={"center"}
          >
            {/* Birth Details */}
          </Heading>
          <Box mb={{ base: "8px", md: "16px" }}>
            <Image
              mx={"auto"}
              src={Insufficient_balance}
              alt="no-Balance"
              maxW={{ base: "25%", md: "100%" }}
            />
            <Text
              fontSize={{ base: "16px", md: "22px" }}
              fontWeight={"500"}
              textAlign={"center"}
            >
              Insufficient Balance !!..
            </Text>
            {/* {userDetails?.userDetails?.consultationCount === 1 &&
            userDetails?.userDetails?.StripeInvoice.length === 0 ? (
              <Text
                fontSize={{ base: "12px", md: "14px" }}
                fontWeight={"400"}
                textAlign={"center"}
                color={"#FF6644"}
                mt={"26px"}
              >
                Limited time offer Recharge with just{" "}
                <span style={{ color: "#FF4C00" }}>
                  1{isInternational ? "$" : "Rs"}/-{" "}
                </span>
                and enjoy a{" "}
                <span style={{ color: "#FF4C00" }}>FREE consultation!</span>
              </Text>
            ) : (
            )} */}
            <Text
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight={"400"}
              textAlign={"center"}
              color={"#FF6644"}
              mt={"26px"}
            >
              Minimum wallet balance required to talk is{" "}
              <strong>
                {astroDetails &&
                  (isInternational ? "$ " + fee * 5 : "₹ " + fee * 5)}
                {/* ₹{astroDetails.fee*5} */}
              </strong>{" "}
              please recharge your wallet
            </Text>
          </Box>
          <Divider
            m="0"
            alignSelf={"center"}
            width={"90%"}
            color={"gray.300"}
          />
          <Flex
            justifyContent={"space-between"}
            my={{ base: "8px", md: "12px" }}
          >
            <VStack borderRight="1px" w={"50%"}>
              <Text
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight={500}
                color={"#7B7B7B"}
              >
                Consultation Charges
              </Text>
              <Text
                fontSize={{ base: "14px", md: "25px" }}
                fontWeight={500}
                color={"#252525"}
              >
                {astroDetails && (isInternational ? "$ " + fee : "₹ " + fee)}
                {/* ₹ {astroDetails.fee}/min */}
              </Text>
            </VStack>
            <VStack w={"50%"}>
              <Text
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight={500}
                color={"#7B7B7B"}
              >
                Your Balance
              </Text>
              <Text
                fontSize={{ base: "14px", md: "25px" }}
                fontWeight={500}
                color={"#252525"}
              >
                {userDetails.userDetails &&
                  (isInternational
                    ? "$ " +
                      parseFloat(
                        userDetails.userDetails.internationalWallet
                      ).toFixed(2)
                    : "₹ " + Math.round(userDetails.userDetails.wallet))}
              </Text>
            </VStack>
          </Flex>
          <Divider
            m="0"
            alignSelf={"center"}
            width={"90%"}
            color={"gray.300"}
          />
        </Flex>
      )}
    </>
  );
};

// let isDontShowChecked;

// const Form2 = () => {
//   const [isChecked, setIsChecked] = useState(false);

//   const handleChange = () => {
//     setIsChecked(!isChecked);
//   };

//   useEffect(() => {
//     isDontShowChecked = isChecked;
//     console.log("isChecked", isChecked);
//   }, [isChecked]);
//   return (
//     <>
//       <Box>
//         <Box
//           bgColor="#FF570FCC"
//           color="#FFF"
//           px="20px"
//           py="10px"
//           borderTopLeftRadius={"20px"}
//           borderTopRightRadius={"20px"}
//         >
//           <Heading as="h1" fontSize="20px" fontWeight={"600"}>
//             Important
//           </Heading>
//           <Text as="p" fontSize="13px" fontWeight={"500"} m="0px">
//             During A Call Consultation
//           </Text>
//         </Box>
//         <Box p="15px" fontSize={"16px"} bgColor={"#FFF"}>
//           <ul p="0px">
//             <li>
//               <Text pl="10px">
//                 If you can't hear the astrologer, end the call within 60 seconds
//                 to avoid charges
//               </Text>
//             </li>
//             <li>
//               <Text pl="10px">
//                 Remember, you'll only be charged after 60 seconds on the call
//               </Text>
//             </li>
//             <li>
//               {" "}
//               <Text pl="10px">
//                 If you face any issues during the call, contact{" "}
//                 <Text as="span" color="#FF4D00">
//                   support
//                 </Text>{" "}
//                 for assistance. They're here to help!
//               </Text>
//             </li>
//             <li>
//               <Text pl="10px">
//                 Your Call will automatically be disconnected based on your
//                 wallet balance.
//               </Text>
//             </li>
//           </ul>
//         </Box>

//         <Checkbox
//           display="flex"
//           justifyContent={"center"}
//           isChecked={isChecked}
//           gap="12px"
//           color="#969696"
//           onChange={handleChange}
//         >
//           Don't Show this again.
//         </Checkbox>

//         <Button
//                     // w="7rem"
//                     // isDisabled={step === 3}
//                     onClick={() => {
//                       setStep(step + 1);
//                       if (step === 0) {
//                         const apiUrl =
//                           "https://data.gurucool.life/api/v1/user/setPermissions";
//                         const requestData = { consultPopup: false };

//                         axios
//                           .put(apiUrl, null, {
//                             headers: {
//                               Authorization: `Bearer ${loginToken}`,
//                             },
//                             params: requestData, // Send data as URL parameters
//                           })
//                           .then((response) => {
//                             console.log(response.data);
//                             // Handle the response data if needed
//                           })
//                           .catch((error) => {
//                             console.error("Error:", error.message);
//                           });
//                       } else if (step === 3) {
//                         setProgress(100);
//                       } else {
//                         setProgress(progress + 50);
//                       }
//                     }}
//                     // colorScheme="green"
//                     bgColor={host === "myguruji" ? "#FF4C00" : "#FF4C00"}
//                     color={host === "myguruji" ? "#FBFBFB" : "#FBFBFB"}
//                     // colorScheme="orange"
//                     variant="solid"
//                     w={"100%"}
//                     h={isChecked ? "40px" : "30px"}
//                     _hover={{}}
//                   >
//                     Next
//                   </Button>
//       </Box>
//     </>
//   );
// };

export default function CallMultistepForm({ step, setStep }) {
  // const toast = useToast()
  const host = localStorage.getItem("host");
  const isInternational = localStorage.getItem("isInternational") === "true";
  const astroDetails = useSelector(
    (state) => state.selectedAstrologerDetails.astroDetails
  );
  // console.log("Astro Details :",astroDetails);
  const startCallUserDetaills = useSelector(
    (state) => state.startCallBirthDetails
  );

  // import { useSelector } from 'react-redux';
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  // useEffect(() => {
  //   // isDontShowChecked = isChecked;
  //   console.log("isChecked", isChecked);
  // }, [isChecked]);

  // Inside your component
  const selectedBirthDetailId = useSelector(
    (state) => state.birthDetails.selectedBirthDetailId
  );

  const loggedInUserDetails = useSelector((state) => state.loggedInUserDetails);
  const userDetails = useSelector((state) => state.loggedInUserDetails.data);
  const toast = useToast();
  // const [step, setStep] = useState(
  //   loggedInUserDetails?.data?.userDetails?.permissions?.consultPopup ? 0 : 1
  // );
  // console.log("loggedInUserDetails?.data?.userDetails?.permissions", loggedInUserDetails?.data?.userDetails)

  const [progress, setProgress] = useState(50);
  // const [noBalance, setNoBalance] = useState(false);
  const navigate = useNavigate();
  const fee = getPrice(isInternational, userDetails, astroDetails);
  // const fee = isInternational
  //   ? astroDetails.internationalFee
  //   : astroDetails.fee;
  const userWalletBalance =
    userDetails &&
    (isInternational
      ? userDetails?.userDetails?.internationalWallet
      : userDetails?.userDetails?.wallet);
  const minCallDuration = Math.floor(userWalletBalance / fee);
  const loginToken = localStorage.getItem("loginToken");
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [birthPlace, setBirthPlace] = useState();
  const [birthDate, setBirthDate] = useState();
  const [birthTime, setBirthTime] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [insufficientBalance, setInsufficientBalance] = useState(false);

  const [startDate, setStartDate] = useState();
  const [time, setTime] = useState("");
  console.log("loggedInUserDetails ", loggedInUserDetails);
  const handleTimeChange = (event) => {
    setTime(event.target.value);
    dispatch(startCallBirthDetailsAction.setBirthTime(event.target.value));
  };

  const [showComponent, setShowComponent] = useState(false);
  const [birthData, setBirthdata] = useState();
  const [isBirthDetailsId, setIsBirthDetailsId] = useState(false);
  const [birthID, setBirthID] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 300);
  });

  useEffect(() => {
    if (fee > 0) {
      if (userWalletBalance >= fee * 5) {
        setInsufficientBalance(false);
      } else {
        setInsufficientBalance(true);
      }
    } else {
      setInsufficientBalance(true);
    }
  }, [astroDetails]);

  useEffect(() => {
    if (startDate) {
      const day = startDate.getDate();
      const month = startDate.getMonth() + 1;
      const year = startDate.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      setBirthDate(formattedDate);
      dispatch(startCallBirthDetailsAction.setBirthDate(formattedDate));
    }
  }, []);

  // Fetch birth details
  useEffect(() => {
    const fetchBirthDetails = async () => {
      try {
        const response = await axios.get(
          "https://data.gurucool.life/api/v1/user/getBirthDetails",
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        );
        // console.log("Response");
        console.log(response);
        // Save the response data in the state
        let text = response.data.birthDetails.birthDate;
        let myarray = text.split("/");
        let birtDateformat = myarray[2] + "-" + myarray[1] + "-" + myarray[0];
        setBirthdata(response);
        setIsBirthDetailsId(response.data.success);
        setBirthID(response.data.birthDetails._id);
        setName(response.data.birthDetails.fullName);
        setGender(response.data.birthDetails.gender);
        setBirthPlace(response.data.birthDetails.birthPlace);
        setBirthDate(birtDateformat);
        setTime(response.data.birthDetails.birthTime);
        setIsFormComplete(true);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchBirthDetails();
  }, []);

  const startConsultationHandler = (e) => {
    e.preventDefault();

    const startConsultationUrlV1 =
      loggedInUserDetails?.data?.userDetails?.website[0] === "gurucool.co"
        ? "https://data.gurucool.life/api/v2/consultations/initiateCall"
        : "https://data.gurucool.life/api/v1/consultations/initiateCall";

    const startConsultationUrlV2 =
      "https://data.gurucool.life/api/v1/consultations/initiateCall";
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };

    let requestData;
    console.log("Data...........");
    console.log(birthID);
    console.log(isFormComplete);
    if (birthID || isFormComplete || ischecked) {
      if (!birthID || ischecked) {
        requestData = {
          astrologerID: astroDetails.user._id,
          minutes: minCallDuration,
          // birthID: birthID,
          // birthDetails: birthDetails,
        };
      } else {
        // let birthDetails = {
        //   fullName: name,
        //   gender: gender,
        //   birthTime: time,
        //   birthDate: birthDate,
        //   birthPlace: birthPlace,
        // };
        requestData = {
          astrologerID: astroDetails.user._id,
          minutes: minCallDuration,
          birthID: birthID,
          // birthDetails: birthDetails,
        };
      }
    } else {
      requestData = {
        astrologerID: astroDetails.user._id,
        minutes: minCallDuration,
        fullName: startCallUserDetaills.name,
        gender: startCallUserDetaills.gender,
        birthTime: startCallUserDetaills.birthTime,
        birthDate: startCallUserDetaills.birthDate,
        birthPlace: startCallUserDetaills.birthPlace,
      };
    }
    console.log("post request data", requestData);
    const a = astroDetails.callQueue
      ? astroDetails.callQueue.length === 0
        ? false
        : true
      : false;
    if (a) {
      callQueuepost(requestData);
      return;
    }

    axios
      .post(
        startConsultationUrlV1,
        // {
        //   astrologerID: astroDetails.user._id,
        //   minutes: minCallDuration,
        //   fullName: startCallUserDetaills.name,
        //   gender: startCallUserDetaills.gender,
        //   birthTime: startCallUserDetaills.birthTime,
        //   birthDate: startCallUserDetaills.birthDate,
        //   birthPlace: startCallUserDetaills.birthPlace,
        // },
        requestData,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("purchaseId", response.data.purchaseId);
          window.location.href = "/callconsultationstarted";
        }
      })
      .catch((error) => {
        setErrorMsg(error.response.data.message);
      });
  };
  const [callqueue, setCallqueue] = useState(false);

  const callQueuepost = async (data) => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      };
      const response = await axios.post(
        `https://data.gurucool.life/api/v1/consultations/joinQueue`,
        data,
        config
      );
      console.log("Response Data", response.data);
      // toast.success('Successfully joined the queue!');
      toast({
        title: response.data.message,
        description: "Please Wait For a Min",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      let array = response.data.Queue;
      console.log(array[array.length - 1]);
      localStorage.setItem("purchaseId", array[array.length - 1]);
      window.location.href = "/callconsultationstarted";
    } catch (error) {
      console.error("Error:", error);
      // toast.error('An error occurred. Please try again.');
    }
  };

  const handleSkip = () => {
    // console.log(isFormComplete);
    setIsFormComplete(!isFormComplete); // Skip the form, mark it as complete
    console.log(ischecked);
    setIschecked(!ischecked);
  };

  let requestData = {
    astrologer: astroDetails?.user?._id,
    minutes: minCallDuration,
    birthID: birthID,
    // birthDetails: birthDetails,
  };

  //
  useEffect(() => {
    if (
      birthID ||
      (name && time && birthDate && birthPlace && gender) ||
      (startCallUserDetaills.name &&
        startCallUserDetaills.birthTime &&
        startCallUserDetaills.birthDate &&
        startCallUserDetaills.birthPlace &&
        startCallUserDetaills.gender)
    ) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [birthID, name, time, birthDate, birthPlace, gender]);

  // const goToConsultationStarted = () => {
  //   navigate("/callconsultationdetails")
  // };

  // const goToCallConsultation = () => {
  //   navigate("/callconsultationstarted")
  // }

  function differenceAmount() {
    const userWallet = isInternational
      ? loggedInUserDetails?.userDetails?.internationalWallet
      : loggedInUserDetails?.userDetails?.wallet;

    const rechargeAmount = isInternational ? 3 : 100;

    if (userWallet !== undefined && userWallet < rechargeAmount) {
      return (rechargeAmount - userWallet).toFixed(2);
    }

    return 0;
  }

  const [isLoading, setIsLoading] = useState(false);

  const stripePaymentHandlerSecondConsultation = async () => {
    setIsLoading(true);
    const gst = 0;
    const customerId = loggedInUserDetails.data.userDetails.stripe_customer_id;
    const userId = loggedInUserDetails.data.userDetails.user;

    try {
      const response = await axios.post(
        isInternational
          ? P_STRIPE_CHECKOUT_INTERNATIONAL(
              differenceAmount(),
              "usd",
              customerId,
              userId,
              1
            )
          : P_STRIPE_CHECKOUT(
              differenceAmount(),
              "inr",
              customerId,
              userId,
              gst,
              1
            )
      );
    } catch (error) {}
    setIsLoading(false);
  };
  // const loginToken = localStorage.getItem("loginToken");
  // Get Birth Details Function when modal is render.

  //

  // const validate = () => {
  //   return (
  //     (name || startCallUserDetaills.name) &&
  //     (gender || startCallUserDetaills.gender) &&
  //     (birthPlace || startCallUserDetaills.birthPlace) &&
  //     (birthDate || startCallUserDetaills.birthDate) &&
  //     (time || startCallUserDetaills.birthTime)
  //   );
  // };
  const [isInQueue, setIsinQueue] = useState(false);
  const [callQueue1, setCallqueue1] = useState([]);
  // For Call Queue
  useEffect(() => {
    const fetchData = async () => {
      try {
        axios
          .get(G_GET_USER_PROFILE, {
            headers: { Authorization: `Bearer ${loginToken}` },
          })
          .then((response) => {
            // console.log("response...",response?.data?.userDetails?.isInQueue);
            setIsinQueue(response?.data?.userDetails?.isInQueue);
          });
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    const fetchDataforAstrologer = async () => {
      try {
        axios
          .get(
            G_GET_SINGLE_ASTROLOGER_BY_TOKEN(localStorage.getItem("guruToken"))
          )
          .then((response) => {
            // console.log("response of astrologer",response);
            setCallqueue1(response?.data?.guru?.callQueue);
          });
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    const interval = setInterval(() => {
      fetchData();
      fetchDataforAstrologer();
    }, 5000);

    // Call fetchData immediately upon mounting
    fetchData();
    fetchDataforAstrologer();
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {showComponent ? (
        <Box
          // borderWidth="1px"
          rounded="lg"
          // shadow="1px 1px 3px rgba(0,0,0,0.3)"
          maxWidth={800}
          px={3}
          m="10px auto"
          as="form"
        >
          {/* {insufficientBalance ? null : (
            <Progress
              hasStripe
              value={progress}
              mb="5%"
              mx="5%"
              isAnimated
              colorScheme={"orange"}
            ></Progress>
          )} */}

          {step === 0 ? (
            <>
              <Box>
                <Box
                  bgColor="#FF570FCC"
                  color="#FFF"
                  px="20px"
                  py="10px"
                  borderTopLeftRadius={"20px"}
                  borderTopRightRadius={"20px"}
                >
                  {/* <Heading as="h1" fontSize="20px" fontWeight={"600"}>
                    Important
                  </Heading> */}
                  <Text as="p" fontSize="20px" fontWeight={"500"} m="0px">
                    During A Call Consultation
                  </Text>
                </Box>
                <Box p="15px" fontSize={"16px"} bgColor={"#FFF"}>
                  <ul p="0px">
                    <li>
                      <Text pl="10px">
                        Hang up within 60s if you can't hear the astrologer (no
                        charges applied).
                      </Text>
                    </li>
                    <li>
                      <Text pl="10px">
                        Charges start after 60s on the call.
                      </Text>
                    </li>
                    {/* <li> */}
                    {/* {" "} */}
                    {/* <Text pl="10px">
                        If you face any issues during the call, contact{" "}
                        <Text as="span" color="#FF4D00">
                          support
                        </Text>{" "}
                        for assistance. They're here to help!
                      </Text> */}
                    {/* </li> */}
                    <li>
                      <Text pl="10px">
                        Keep your wallet balance in check to prevent automatic
                        disconnection.
                      </Text>
                    </li>
                  </ul>
                </Box>
                <Box bgColor={"#FFFFFF"} pb="20px" px="20px">
                  <Checkbox
                    display="flex"
                    justifyContent={"center"}
                    isChecked={isChecked}
                    gap="12px"
                    color="#969696"
                    onChange={handleChange}
                  >
                    Don't Show this again.
                  </Checkbox>

                  <Button
                    // w="7rem"
                    // isDisabled={step === 3}
                    onClick={() => {
                      setStep(step + 1);
                      if (step === 0) {
                        const apiUrl =
                          "https://data.gurucool.life/api/v1/user/setPermissions";
                        const requestData = { consultPopup: false };

                        axios
                          .put(apiUrl, null, {
                            headers: {
                              Authorization: `Bearer ${loginToken}`,
                            },
                            params: requestData, // Send data as URL parameters
                          })
                          .then((response) => {
                            console.log(response.data);
                            // Handle the response data if needed
                          })
                          .catch((error) => {
                            console.error("Error:", error.message);
                          });
                      } else if (step === 3) {
                        setProgress(100);
                      } else {
                        setProgress(progress + 50);
                      }
                      dispatch(fetchLoggedInUserDetails(loginToken));
                    }}
                    // colorScheme="green"
                    mt="20px"
                    bgColor={host === "myguruji" ? "#FF4C00" : "#FF4C00"}
                    color={host === "myguruji" ? "#FBFBFB" : "#FBFBFB"}
                    // colorScheme="orange"
                    variant="solid"
                    w={"100%"}
                    h={isChecked ? "40px" : "37px"}
                    _hover={{}}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </>
          ) : step === 1 ? (
            <Form1 />
          ) : (
            <form>
              <Center>
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {errorMsg}
                </div>
              </Center>
              <FormControl isRequired>
                <FormLabel
                  htmlFor="full-name"
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight={"normal"}
                  color={"#E1843E"}
                >
                  Name
                </FormLabel>
                <Input
                  value={name}
                  onChange={(e) =>
                    dispatch(
                      startCallBirthDetailsAction.setName(e.target.value)
                    )
                  }
                  id="last-name"
                  placeholder="Name"
                  borderRadius={"2px"}
                  borderColor={"#ffa5004d"}
                  _focus={{
                    border: "1px solid orange",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  required
                />
              </FormControl>
              <Flex
                justifyContent={"space-between"}
                gap={2}
                mt={{ base: "12px", md: "16px" }}
              >
                <Box w={"50%"}>
                  <FormControl
                    mt={{ md: "16px" }}
                    isRequired
                    // isInvalid={!validate()}
                  >
                    <FormLabel
                      htmlFor="last-name"
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight={"normal"}
                      color={"#E1843E"}
                    >
                      Date of birth
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="callflowbirthdate"
                        type="date"
                        value={birthDate}
                        onChange={(e) => {
                          const selectedDate = e.target.value;
                          const [year, month, day] = selectedDate.split("-");
                          const updatedDate = `${day}/${month}/${year}`;
                          setBirthDate(e.target.value);
                          setStartDate(updatedDate);
                          dispatch(
                            startCallBirthDetailsAction.setBirthDate(
                              updatedDate
                            )
                          );
                        }}
                        pl={"6px"}
                        borderRadius={"2px"}
                        borderColor={"#ffa5004d"}
                        _focus={{
                          border: "1px solid orange",
                          outline: "none",
                          boxShadow: "none",
                        }}
                        required
                      />
                    </InputGroup>

                    {/* <Select
                      value={gender}
                      onChange={(e) =>
                        dispatch(
                          startCallBirthDetailsAction.setGender(e.target.value)
                        )
                      }
                      placeholder="Select Gender"
                      colorScheme="orange"
                      _focus={{
                        border: "1px solid orange",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      required
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Select> */}
                  </FormControl>
                </Box>
                <Box w={"50%"}>
                  <FormControl isRequired>
                    <FormLabel
                      htmlFor="birth-place"
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight={"normal"}
                      color={"#E1843E"}
                      mt={{ md: "16px" }}
                    >
                      Birth Place
                    </FormLabel>
                    <Input
                      value={birthPlace}
                      onChange={(e) =>
                        dispatch(
                          startCallBirthDetailsAction.setBirthPlace(
                            e.target.value
                          )
                        )
                      }
                      id="birth-place"
                      placeholder="Your Birth Place!!"
                      borderRadius={"2px"}
                      borderColor={"#ffa5004d"}
                      _focus={{
                        border: "1px solid orange",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      required
                    />
                  </FormControl>
                </Box>
              </Flex>

              <Flex
                justifyContent={"space-between"}
                gap={2}
                mt={{ base: "12px", md: "16px" }}
              >
                <Box w={"50%"}>
                  <FormControl isRequired>
                    <FormLabel
                      htmlFor="birth-place"
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight={"normal"}
                      color={"#E1843E"}
                    >
                      Gender
                    </FormLabel>

                    <Select
                      value={gender}
                      onChange={(e) =>
                        dispatch(
                          startCallBirthDetailsAction.setGender(e.target.value)
                        )
                      }
                      placeholder="Select Gender"
                      colorScheme="orange"
                      borderRadius={"2px"}
                      borderColor={"#ffa5004d"}
                      _focus={{
                        border: "1px solid orange",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      required
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Select>
                  </FormControl>
                </Box>
                <Box w={"50%"}>
                  <FormControl isRequired>
                    <FormLabel
                      htmlFor="birth-place"
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight={"normal"}
                      color={"#E1843E"}
                      // mt={{ md: "16px" }}
                    >
                      Birth Time
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type="time"
                        value={time}
                        onChange={handleTimeChange}
                        placeholder="Select a time"
                        pl={"6px"}
                        borderRadius={"2px"}
                        borderColor={"#ffa5004d"}
                        _focus={{
                          border: "1px solid orange",
                          outline: "none",
                          boxShadow: "none",
                        }}
                        required
                        id="callflowbirthtime"
                      />
                    </InputGroup>
                  </FormControl>
                </Box>
              </Flex>
              <Stack
                direction={"column"}
                w={"100%"}
                spacing={4}
                justifyContent={"center"}
              >
                {/* <Link to="/callconsultationstarted"> */}
                {/* <Button variant={"link"} onClick={handleSkip}>
                  Skip
                </Button> */}
                {/* {isFormComplete || birthID ? ( */}
                <Checkbox
                  colorScheme="red"
                  onChange={handleSkip}
                  mt={{ base: 6, md: 8 }}
                  mx={"auto"}
                  display={birthID ? "inline-flex" : "inline-flex"}
                >
                  <Text
                    mb={0}
                    ml={2}
                    fontSize={{ base: "14px", md: "16px" }}
                    textDecoration={"underline"}
                  >
                    Skip Birth Chart Details
                  </Text>
                </Checkbox>
                {isInQueue === false ? (
                  <Button
                    // w="7rem"
                    type="submit"
                    // isDisabled={(skip || !validate()) ? false : true}
                    isDisabled={
                      isFormComplete || birthID || callQueue1?.length < 5
                        ? false
                        : true
                    }
                    onClick={(e) => {
                      // setStep(step + 1);
                      startConsultationHandler(e);
                      // goToConsultationStarted();
                      // if (step === 3) {
                      //   setProgress(100);
                      // } else {
                      //   setProgress(progress + 33.33);
                      // }
                    }}
                    colorScheme="orange"
                    variant="solid"
                    w={"100%"}
                    // mt="5%"
                    // mt={{ base: 6, md: 8 }}
                  >
                    {callQueue1
                      ? callQueue1.length === 0
                        ? "Call"
                        : callQueue1.length >= 5
                        ? "Busy"
                        : "Let's Start To Join Queue"
                      : "Call"}
                  </Button>
                ) : (
                  <Button
                    // w="7rem"
                    type="submit"
                    // isDisabled={(skip || !validate()) ? false : true}
                    isDisabled={true}
                    colorScheme="orange"
                    variant="solid"
                    w={"100%"}
                    mt="5%"
                  >
                    You're Already in Queue
                  </Button>
                )}

                {/* // ) : ( //{" "}
                <Button mt={4} colorScheme="teal" onClick={handleSkip}>
                  // Skip //{" "}
                </Button>
                // )} */}
                {/* </Link> */}
              </Stack>
            </form>
          )}

          <ButtonGroup mt="3%" w="100%">
            <Flex w="100%" justifyContent="space-between">
              {step === 1 &&
              insufficientBalance &&
              (userDetails?.userDetails?.normalUserJourney2 ||
                userDetails?.userDetails?.consultationCount > 0) ? (
                <Stack
                  direction={"row"}
                  w={"100%"}
                  spacing={4}
                  justifyContent={"center"}
                >
                  <Button
                    colorScheme="green"
                    w={"100%"}
                    variant="solid"
                    onClick={() => {
                      navigate("/wallet");
                    }}
                  >
                    Recharge
                    {/* {isLoading
                      ? "Loading..."
                      : `Recharge for ${
                          isInternational ? "1$" : "₹1"
                        } and get ${differenceAmount()}`} */}
                  </Button>
                </Stack>
              ) : step === 2 ? null : (
                <Stack
                  direction={"row"}
                  w={"100%"}
                  spacing={4}
                  justifyContent={"space-between"}
                  m={host === "myguruji" ? "0px" : "10px"}
                >
                  <Flex
                    flexDirection={"column"}
                    gap={"5px"}
                    alignItems={"center"}
                  >
                    <Button
                      // colorScheme={"gray"}
                      variant="solid"
                      bgColor={host === "myguruji" ? "#15AD00" : "#15AD00"}
                      color={host === "myguruji" ? "#FBFBFB" : "#FBFBFB"}
                      w={{ md: "200px", sm: "130px", base: "130px" }}
                      onClick={() => {
                        navigate("/wallet");
                      }}
                      _hover={{}}
                      display={step === 0 ? "none" : "block"}
                    >
                      Recharge
                    </Button>
                    {userDetails?.userDetails?.website[0] === "gurucool.co" &&
                    userDetails?.userDetails?.paymentsDetails?.length === 0 ? (
                      <Text
                        fontWeight={400}
                        fontSize={"14px"}
                        margin={"0px"}
                        display={
                          loggedInUserDetails?.data?.userDetails?.permissions
                            ?.consultPopup
                            ? "none"
                            : "block"
                        }
                      >
                        Get 2 Minutes extra
                      </Text>
                    ) : null}
                  </Flex>
                  <Button
                    // w="7rem"
                    isDisabled={step === 3}
                    onClick={() => {
                      setStep(step + 1);
                      if (step === 0) {
                        const apiUrl =
                          "https://data.gurucool.life/api/v1/user/setPermissions";
                        const requestData = { consultPopup: false };

                        axios
                          .put(apiUrl, null, {
                            headers: {
                              Authorization: `Bearer ${loginToken}`,
                            },
                            params: requestData, // Send data as URL parameters
                          })
                          .then((response) => {
                            console.log(response.data);
                            // Handle the response data if needed
                          })
                          .catch((error) => {
                            console.error("Error:", error.message);
                          });
                      } else if (step === 3) {
                        setProgress(100);
                      } else {
                        setProgress(progress + 50);
                      }
                    }}
                    // colorScheme="green"
                    bgColor={host === "myguruji" ? "#FF4C00" : "#FF4C00"}
                    color={host === "myguruji" ? "#FBFBFB" : "#FBFBFB"}
                    // colorScheme="orange"
                    variant="solid"
                    w={"50%"}
                    display={step === 0 ? "none" : "block"}
                    _hover={{}}
                  >
                    Next
                  </Button>
                </Stack>
              )}
            </Flex>
          </ButtonGroup>
        </Box>
      ) : (
        <Center>
          <Spinner size="xl" />
        </Center>
      )}
    </>
  );
}

function getPrice(isInternationalUser, userDetails, astroDetails) {
  //
  if (isInternationalUser) {
    if (userDetails?.userDetails?.consultationCount === 0) {
      // todo return first price
      return astroDetails?.firstOfferPrice?.international?.fee;
    } else {
      return astroDetails?.internationalFee;
    }
  } else {
    if (userDetails?.userDetails?.consultationCount === 0) {
      // todo return first price
      return astroDetails?.firstOfferPrice?.national?.fee;
    } else {
      return astroDetails?.fee;
    }
  }
}
