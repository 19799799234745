import React from "react";
import { useState, useEffect,useRef } from 'react';
import { useNavigate  } from 'react-router-dom';
import { Container, Box, Heading, Image, Text } from "@chakra-ui/react";
import userPic from "../../../assets/_user.png";

const ChatConsultationStarted = () => {

    const [time, setTime] = useState(7);
    const intervalRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(intervalRef.current);
    }, []);
  
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        if (time === 0) {
          clearInterval(intervalRef.current);
          navigate("/chatconsultationdetails"); // redirect to home page
        }
      }, [time, navigate]);

  return (
    <>
      <Container
        bg="#EDEDED"
        maxW="6xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box
          bg="#FCFCFC"
          width={{ md: "500px", base: "265px" }}
          height={{ md: "414px", base: "auto" }}
          borderRadius={{ md: "12px", base: "9px" }}
          mx="auto"
        >
          <Heading
            fontSize={{ md: "20px", base: "16px" }}
            fontWeight={500}
            textAlign="center"
            mt={{ md: "26px", base: "20px" }}
            mb={{ md: "22px", base: "18px" }}
          >
            Consultation Started
          </Heading>

          <Image
            src={userPic}
            borderRadius="full"
            width={{ md: "142px", base: "100px" }}
            mx="auto"
            mb={{ md: "28px", base: "22px" }}
            alt="userPic"
          ></Image>

          <Heading
            fontSize={{ md: "20px", base: "16px" }}
            fontWeight={500}
            textAlign="center"
            mb={{ md: "4px", base: "3px" }}
            color="#7B7B7B"
          >
            Your consultation ends in
          </Heading>

          <Text
            fontSize={{ md: "32px", base: "26px" }}
            fontWeight={600}
            textAlign="center"
            mb={{ md: "15px", base: "11px" }}
            color="#15AD00"
          >
            {formatTime(time)}
          </Text>

          <Box width={{ md: "418px", base: "200px" }} mx="auto" >
            <Text
              fontSize={{ md: "12px", base: "9px" }}
              fontWeight={400}
              mb={{ md: "15px", base: "11px" }}
              color="#FF6644"
              textAlign="center"
            >
              Connecting to astrologer wait for a few seconds. In case of any
              internet issue, the first 30 second wouldn't be charged
            </Text>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ChatConsultationStarted;
