import React, { useState } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import Zodiacsign from '../../Zodaicsign';
import Horosscope from '../Horosscope';
import axios from 'axios';

export default function Aquarius() {
const loggedIn = useSelector((state) => state.logIn.userLoggedIn);

const [daily,setDaily]=useState(null);
const [monthly,setMonthly]=useState(null);
const [yearly,setYearly]=useState(null);

const date = new Date(); 
let day= date.getDate();
let month=date.getMonth()+1;
let year=date.getFullYear();
const fetchZodiacDataDaily = async () => {
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign: "Aquarius",
        type: "daily",
        day: day,
        month: month,
        year: year,
      },
    });
    //
    setDaily(response.data.zodiac.mainContent);
  } catch (error) {
    // Handle the error
   
    console.error(error);
  }
};

const fetchZodiacDataMonthly= async ()=>{
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign: "Aquarius",
        type: "monthly",
        month: month,
        year: year,
      },
    });
   
    setMonthly(response.data.zodiac.mainContent);
  } catch (error) {
    // Handle the error
   
    console.error(error);
  }

};
const fetchZodiacDataYearly = async ()=>{
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign: "Aquarius",
        type: "yearly",
        year: year,
      },
    });
   
    setYearly(response.data.zodiac.mainContent);
  } catch (error) {
    // Handle the error
    console.error(error);
  }

}
 fetchZodiacDataDaily();
 fetchZodiacDataMonthly();
 fetchZodiacDataYearly();

  return (
    <>
     <ChakraProvider>
        <Zodiacsign name={"Aquarius"} daily={daily} monthly={monthly} yearly={yearly} />
        <Horosscope/>
    </ChakraProvider>
    </>
  )
}
