import { Box, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';

const AdminProfile = () => {
  return (
    <Flex  justifyContent={"flex-end"}>
      <Sidebar/>
      <Box w={"80%"} fontSize={"18px"} px={3}>Profile</Box>
    </Flex>
  )
}

export default AdminProfile