import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  // Button,
  Input,
  Center,
  Spinner,
  Text,
  // Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { fetchData } from "../../../store/TarotSlice";
import { Navigate, useNavigate } from "react-router";
// import { fetchAllGurus } from "../../../store/getAllGurus";
import Pagination from "react-js-pagination";
// import { fetchAllUsers } from "../../../store/getAllUsers";
import { fetchAllRazorpayRecharges } from "../../../store/getAllRecharges";
import { fetchAllStripeRecharges } from "../../../store/getAllStripeRecharges";

const ShowStripeRecharges = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  function convertIst(date) {
    const utcDate = new Date(date);
    const istDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );

    const day = istDate.getDate();
    const month = istDate.toLocaleString("default", { month: "long" });
    const year = istDate.getFullYear();
    const hours = istDate.getHours();
    const minutes = istDate.getMinutes();
    const seconds = istDate.getSeconds();

    return `${day} ${month} ${year}, ${hours}:${minutes}:${seconds}`;
  }

  //
  useEffect(() => {
    dispatch(fetchAllRazorpayRecharges(activePage));
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchAllStripeRecharges(activePage));
  }, [dispatch]);

  // const data = useSelector((state) => state.allGurusData.data);
  const data = useSelector((state) => state.allRazorpayPaymentData.data);
  const dataa = useSelector((state) => state.allStripePaymentData.data);
  const status = useSelector((state) => state.allRazorpayPaymentData.status);
  const error = useSelector((state) => state.allRazorpayPaymentData.error);
 
  const ITEMS_PER_PAGE = 10;
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  if (status === "loading") {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  } else if (status === "succeeded") {
    return (
      <>
        <Flex justifyContent={"flex-end"}>
          <Sidebar />
          <Box w={"80%"} fontSize={"18px"} p={5}>
            <Input
              w={"sm"}
              placeholder="Search users"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <Table>
              <Thead>
                <Tr>
                  <Th>UID</Th>
                  <Th>Stripe Id</Th>
                  <Th>Customer Id</Th>
                  <Th>Status</Th>
                  <Th>Recharge</Th>
                  <Th>GST</Th>
                  <Th>Total Amount</Th>
                  <Th>Time</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataa.map((currElem) => (
                  <Tr key={currElem._id}>
                    <Td>{currElem.uid ? currElem.uid : "NA"}</Td>
                    {currElem.payment_intent ? (
                      <Td>{currElem.payment_intent}</Td>
                    ) : (
                      <Td color={"red"}>Failed</Td>
                    )}
                    <Td>{currElem.customer}</Td>
                    {/* {currElem.status === "created" && ( */}
                    <Td color={"green"}>{currElem.status}</Td>
                    {/* )} */}
                    {currElem.status === "authorized" && (
                      <Td color={"yellow"}>Authorized</Td>
                    )}
                    {currElem.status === "captured" && (
                      <Td color={"green"}>Completed</Td>
                    )}

                    <Td>
                      {" "}
                      {currElem.currency === "inr" ? "₹" : "$"}
                      {currElem.userAmount
                        ? Math.floor(currElem.userAmount)
                        : "--"}
                    </Td>
                    <Td> {currElem.currency === "inr" ? "₹" : "$"}{currElem.gst ? Math.floor(currElem.gst) : "--"}</Td>
                    <Td>
                      {" "}
                      {currElem.currency === "inr" ? "₹" : "$"}
                      {currElem.offerPrice}
                    </Td>
                    <Td>{convertIst(currElem.createdAt)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Flex>
        <Flex justifyContent={"center"} alignItems={"center"} m="70px">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={ITEMS_PER_PAGE}
            totalItemsCount={data.length}
            pageRangeDisplayed={50}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="Previous"
            nextPageText="Next"
          />
        </Flex>
      </>
    );
  } else if (status === "failed") {
    return (
      <Center>
        <Text color="red">{error}</Text>
      </Center>
    );
  }

  return null;
};

export default ShowStripeRecharges;
