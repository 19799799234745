import { useState, useEffect } from "react";
import useManagementToken from "./useManagementToken";
import axios from "axios";
const loginToken = localStorage.getItem("loginToken");

// The custom hook takes the room ID as a parameter and the condition to fetch the session
function useSession(roomId) {
  useEffect(() => {
    const fetchSession = async () => {
      const managementToken = fetchManagementToken();
      const url = `https://api.100ms.live/v2/sessions?room_id=${roomId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${managementToken}`,
        },
      });
     
      if (response.status === 200 && data.data[0]?.active) {
        const sessionId = data.data[0].id;
        return sessionId;
      }
    };
    fetchSession();
  }, [roomId]);
}

export default useSession;

const fetchManagementToken = async () => {
  try {
    const response = await axios.get(
      "https://data.gurucool.life/api/v1/live/getManagementToken",
      {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data.token;
    }
  } catch (err) {
   
  }
};
