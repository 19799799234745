/* The above code is a React component that displays a table of astrologers with their details such as
UID, GID, profile photo, user name, earnings, online status, fee, international fee, status, and
date of joining. It also allows the admin to search for astrologers by UID, edit astrologer details,
change astrologer status (online/offline, active/blocked), and navigate through the pages using
pagination. The component fetches data from an API using Redux and Axios. */
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Input,
  Center,
  Spinner,
  Text,
  Avatar,
  NumberInputField,
  NumberInput,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../store/TarotSlice";
import { Navigate, useNavigate } from "react-router";
import { fetchAllGurus } from "../../../store/getAllGurus";
import Pagination from "react-js-pagination";
import { P_EDIT_ASTROLOGERS_DETAILS } from "../../../apiLinks";

const ShowAstrologer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const ITEMS_PER_PAGE = 20;
  const [activePage, setActivePage] = useState(1);

  /* This is a React hook called `useEffect` that is used to perform side effects in functional
  components. In this code, it is used to fetch data from an API using Redux and Axios when the
  component mounts or when the `activePage` state changes. The `dispatch` function is called with an
  action creator `fetchAllGurus` that takes an object with `pageNumber`, `perPage`, and `uid`
  properties as arguments. These properties are used to fetch the appropriate data from the API. The
  `activePage` state is passed as a dependency to the `useEffect` hook, which means that the effect
  will be re-run whenever the `activePage` state changes. */
  useEffect(() => {
    dispatch(
      fetchAllGurus({
        pageNumber: activePage,
        perPage: `${ITEMS_PER_PAGE}`,
        uid: searchTerm,
      })
    );
  }, [activePage]);

  const data = useSelector((state) => state.allGurusData.data);
  const total = useSelector((state) => state.allGurusData.total);
  const status = useSelector((state) => state.allGurusData.status);
  const error = useSelector((state) => state.allGurusData.error);
 
  /**
   * The function updates the active page number.
   */
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  /**
   * The function handles the editing of an astrologer's information by navigating to the admin edit page
   * and setting the user ID in local storage.
   */
  const handleEdit = (userId) => {
    navigate("/admin/editAstrologer");
    localStorage.setItem("guruToken", userId);
  };

  /**
   * This function updates the status and call availability of an astrologer in the database using an
   * API call.
   */
  const handleAstrologerStatus = async (astrologerId, statusData) => {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      await axios.post(
        P_EDIT_ASTROLOGERS_DETAILS(astrologerId),
        {
          status: statusData.status,
          callAvailability: statusData.callAvailability,
        },
        config
      );
      window.location.reload();
    } catch (error) {
      //
    }
  };

  async function handleAstrologerLiveStatus(astrologerId, res) {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      const response = await axios.post(
        P_EDIT_ASTROLOGERS_DETAILS(astrologerId),
        {
          liveStatus: res,
        },
        config
      );
     
      if (response.status === 200) {
        dispatch(
          fetchAllGurus({
            pageNumber: activePage,
            perPage: `${ITEMS_PER_PAGE}`,
            uid: searchTerm,
          })
        );
      }
    } catch (error) {
      //
    }
  }

  async function handleAstrologerHomepage(astrologerId, res) {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      const response = await axios.post(
        P_EDIT_ASTROLOGERS_DETAILS(astrologerId),
        {
          homePageAstrologer: res,
        },
        config
      );
      if (response.status === 200) {
        dispatch(
          fetchAllGurus({
            pageNumber: activePage,
            perPage: `${ITEMS_PER_PAGE}`,
            uid: searchTerm,
          })
        );
      }
    } catch (error) {}
  }

  async function handleAstrologerMostChoice(astrologerId, res) {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      const response = await axios.post(
        P_EDIT_ASTROLOGERS_DETAILS(astrologerId),
        {
          mostTrusted: res,
        },
        config
      );
     
      if (response.status === 200) {
        dispatch(
          fetchAllGurus({
            pageNumber: activePage,
            perPage: `${ITEMS_PER_PAGE}`,
            uid: searchTerm,
          })
        );
      }
    } catch (error) {}
  }

  const onSearch = (e) => {
    setSearchTerm(e);
    setActivePage(1);
    dispatch(
      fetchAllGurus({ pageNumber: 1, perPage: `${ITEMS_PER_PAGE}`, uid: e })
    );
  };

  if (status === "failed") {
    return (
      <Center>
        <Text color="red">{error}</Text>
      </Center>
    );
  }

  return (
    <>
      <Flex justifyContent={"flex-end"}>
        <Sidebar />
        <Box w={"80%"} fontSize={"18px"} p={5}>
          <NumberInput w={"sm"} value={searchTerm} onChange={onSearch}>
            <NumberInputField placeholder="Enter UID to search astrologer" />
          </NumberInput>
          {status === "loading" && (
            <Center>
              <Spinner size="xl" />
            </Center>
          )}
          <AstrologerTable
            data={data}
            handleEdit={handleEdit}
            handleAstrologerStatus={handleAstrologerStatus}
            handleAstrologerLiveStatus={handleAstrologerLiveStatus}
            handleAstrologerHomepage={handleAstrologerHomepage}
            handleAstrologerMostChoice={handleAstrologerMostChoice}
          />
        </Box>
      </Flex>
      <Flex justifyContent={"center"} alignItems={"center"} m="70px">
        <CustomPagination
          activePage={activePage}
          itemsCountPerPage={ITEMS_PER_PAGE}
          totalItemsCount={total}
          onChange={handlePageChange}
        />
      </Flex>
    </>
  );
};
const AstrologerTable = ({
  data,
  handleEdit,
  handleAstrologerStatus,
  handleAstrologerLiveStatus,
  handleAstrologerHomepage,
  handleAstrologerMostChoice,
}) => (
  <Table>
    <Thead>
      <Tr>
        <Th>UID</Th>
        <Th>GID</Th>
        <Th>Profile Photo</Th>
        <Th>User Name</Th>
        <Th>Mobile</Th>
        <Th>Earning in ₹</Th>
        <Th>Earning in $</Th>
        <Th>Online</Th>
        <Th>Fee</Th>
        <Th>International Fee</Th>
        <Th>Status</Th>
        <Th>Date of Joining</Th>
        <Th>Action</Th>
        <Th>Live Status</Th>
        <Th>Most Choice</Th>
        <Th>Homepage</Th>
      </Tr>
    </Thead>
    <Tbody>
      {data?.map((currElem) => (
        <Tr key={currElem._id}>
          <Td>{currElem.user.uid}</Td>
          <Td>{currElem.gid}</Td>
          <Td>
            <Avatar
              src={currElem.user.avatar.url}
              alt={`Profile of ${currElem.user.firstName}`}
            />
          </Td>
          <Td>
            {currElem.user.firstName} {currElem.user.lastName}
          </Td>
          <Td>{currElem?.user?.phone}</Td>
          <Td>₹{parseFloat(currElem.earnings || 0).toFixed(2)}</Td>
          <Td>${parseFloat(currElem.earningsInternational || 0).toFixed(2)}</Td>
          <Td>{currElem.callAvailability}</Td>
          <Td>₹{currElem.fee}</Td>
          <Td>${currElem.internationalFee}</Td>
          <Td>{currElem.status}</Td>
          <Td>{currElem.user.createdAt.slice(0, 10)}</Td>
          <Td>
            <Button
              size="sm"
              m={2}
              onClick={() => handleEdit(currElem.user.guru)}
            >
              Edit
            </Button>
            {currElem.callAvailability === "online" ? (
              <>
                <Button
                  m={2}
                  size="sm"
                  colorScheme="gray"
                  onClick={() =>
                    handleAstrologerStatus(currElem.user.guru, {
                      callAvailability: "busy",
                    })
                  }
                >
                  Go Busy
                </Button>
                <Button
                  m={2}
                  size="sm"
                  colorScheme="red"
                  onClick={() =>
                    handleAstrologerStatus(currElem.user.guru, {
                      callAvailability: "offline",
                    })
                  }
                >
                  Go Offline
                </Button>
              </>
            ) : (
              <Button
                size="sm"
                colorScheme="green"
                onClick={() =>
                  handleAstrologerStatus(currElem.user.guru, {
                    callAvailability: "online",
                  })
                }
              >
                Go Online
              </Button>
            )}
            {currElem.status === "blocked" ? (
              <Button
                m={2}
                size="sm"
                colorScheme="green"
                onClick={() =>
                  handleAstrologerStatus(currElem.user.guru, {
                    status: "active",
                  })
                }
              >
                Enable
              </Button>
            ) : (
              <Button
                m={2}
                size="sm"
                colorScheme="red"
                onClick={() =>
                  handleAstrologerStatus(currElem.user.guru, {
                    status: "blocked",
                  })
                }
              >
                Disable
              </Button>
            )}
          </Td>
          <Td>
            {currElem.liveStatus ? (
              <Button
                m={2}
                size="sm"
                colorScheme="red"
                onClick={() => {
                  handleAstrologerLiveStatus(currElem?.user?.guru, false);
                }}
              >
                Go Offline
              </Button>
            ) : (
              <Button
                m={2}
                size="sm"
                colorScheme="green"
                onClick={() =>
                  handleAstrologerLiveStatus(currElem?.user?.guru, true)
                }
              >
                Go Online
              </Button>
            )}
          </Td>
          <Td>
            {currElem.mostTrusted ? (
              <Button
                m={2}
                size="sm"
                colorScheme="red"
                onClick={() => {
                  handleAstrologerMostChoice(currElem?.user?.guru, false);
                }}
              >
                Disable
              </Button>
            ) : (
              <Button
                m={2}
                size="sm"
                colorScheme="green"
                onClick={() =>
                  handleAstrologerMostChoice(currElem?.user?.guru, true)
                }
              >
                Enable
              </Button>
            )}
          </Td>
          <Td>
            {currElem.homePageAstrologer ? (
              <Button
                m={2}
                size="sm"
                colorScheme="red"
                onClick={() => {
                  handleAstrologerHomepage(currElem?.user?.guru, false);
                }}
              >
                Disable
              </Button>
            ) : (
              <Button
                m={2}
                size="sm"
                colorScheme="green"
                onClick={() =>
                  handleAstrologerHomepage(currElem?.user?.guru, true)
                }
              >
                Enable
              </Button>
            )}
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
const CustomPagination = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  onChange,
}) => (
  <Pagination
    activePage={activePage}
    itemsCountPerPage={itemsCountPerPage}
    totalItemsCount={totalItemsCount}
    onChange={onChange}
    itemClass="page-item"
    linkClass="page-link"
    prevPageText="Previous"
    nextPageText="Next"
  />
);

export default ShowAstrologer;
