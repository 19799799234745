import React, { useState, useEffect } from "react";
import "../../../App.css";
import {
  Heading,
  Box,
  Container,
  Text,
  Stack,
  useDisclosure,
  Modal,
  Flex,
} from "@chakra-ui/react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ChatFlowModal from "../../ChatFlow/ChatFlowModal";
import CallFlowModal from "../../CallFlow/CallFlowModal";
import Login from "../../Login&Signup/Login";

import { useDispatch, useSelector } from "react-redux";
import { Center, Spinner } from "@chakra-ui/react";
import axios from "axios";
import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";
import { useMemo } from "react";
import AstroProfileCard from "../../HomePage/AstroCard/AstroProfileCard";

export default function PremiumAstrologer(props) {
  const dispatch = useDispatch();
  const data = props.data;
  const token = localStorage.getItem("guruToken");

  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${token}`
        )
        .then((response) => {
          //
          dispatch(
            selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
          );
        });
    }
  }, [token]);

  const filteredData = data.filter((item) =>
    item.specialization.includes("Love")
  );
  const shuffledArray = useMemo(() => {
    const array = [...filteredData];
    for (let i = array.length - 1, j; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }, [data]);

  const options = {
    nav: true,
    dots: true,
    responsive: {
      0: {
        items: 1.3,
        margin: 8,
        nav: false,
        loop: true,
      },
      600: {
        items: 2.2,
        loop: true,
      },
      1000: {
        items: 3,
      },
    },
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [chatBtnClicked, setChatBtnClicked] = useState(false);
  const [callBtnClicked, setCallBtnClicked] = useState(false);

  const callClicked = () => {
    setCallBtnClicked(true);
    onOpen();
  };

  const loginToken = localStorage.getItem("loginToken");

  return (
    <>
      <Container maxW={"6xl"} mt={{ base: "24px", md: "32px" }}>
        {!loginToken ? (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "md" }}
          >
            <Login />
          </Modal>
        ) : (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "md" }}
          >
            {chatBtnClicked && <ChatFlowModal />}
            {callBtnClicked && <CallFlowModal />}
          </Modal>
        )}
        <Box
          as="div"
          maxW={"6xl"}
          display={"flex"}
          justifyContent={{ base: "space-between", md: "center" }}
          alignItems={"baseline"}
          // mb={{ base: "8px" }}
          mb={{ base: "12px", md: "24px" }}
          bg={"#FFECDD"}
          w={"fit-content"}
          mx={"auto"}
          borderRadius={"12px"}
        >
          <Heading
            fontSize={{ base: "20px", md: "30px" }}
            fontWeight={{ base: 500 }}
            textAlign={{ base: "left", md: "center" }}
            // mb={{ base: "12px", md: "24px" }}
            color={"#BC5D14"}
            px={"15px"}
            py={"10px"}
          >
            Premium Astrologer
          </Heading>
          {/* <Text
              display={{ base: "flex", md: "none" }}
              fontSize={{ base: "14px", md: "18px" }}
              textAlign={"right"}
              float={"right"}
            >
              <Link color="#C56C28" href="/all-astrologers">
                See All
              </Link>
            </Text> */}
        </Box>

        <Stack
          id="topAstrologer"
          spacing={2}
          direction="column"
          py={0}
          mb={{ md: "18px" }}
        >
          {/* <OwlCarousel className="owl-theme" {...options}> */}
          <Flex
            gap={{ lg: 0, md: "24px", base: 0 }}
            flexWrap={{ md: "wrap", base: "nowrap" }}
            justifyContent={{ md: "center", base: "flex-start" }}
            borderRadius={"7px"}
            overflowX={{ md: "unset", base: "auto" }}
            pb={"6px"}
          >
            {filteredData.map((currElem, index) => {
              return (
                <AstroProfileCard
                  currElem={currElem}
                  length={shuffledArray.length}
                  index={index}
                  callClicked={callClicked}
                />
              );
            })}
          </Flex>
          {/* </OwlCarousel> */}

          {/* <Text
              display={{ base: "none", md: "block" }}
              fontSize={{ base: "14px", md: "18px" }}
              textAlign={"right"}
              float={"right"}
            >
              <Link color="#C56C28" href="/all-astrologers">
                See All
              </Link>
            </Text> */}
        </Stack>
      </Container>
    </>
  );
}
