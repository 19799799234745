import {
  Container,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import UsersConsultationCard from "./UsersConsultationCard";
import PrimaryLoading from "../../Loaders/PrimaryLoading";
import { Spinner } from "react-bootstrap";

function ShowMoreConsultation() {
  const location = useLocation();
  const { uid, gid } = location.state;
  const [consultation, setConsultation] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loginToken = localStorage.getItem("loginToken");
        const response = await axios.get(
          `https://data.gurucool.life/api/v1/guru/getUsersConsultation?gid=${gid}&uid=${uid}`,
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        );

        // Save the response data in the state or perform any necessary actions
        setConsultation(response?.data?.consultation?.docs);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [uid, gid]);

  // 

  //
  //
  //
  //
  //
  //publicCallData
  //

  const consultationCallData = consultation?.filter(
    (item) => item.consultationType === "Call"
  );

  //
  const publicCallData = consultation?.filter(
    (item) => item.consultationType === "public_live_Call"
  );

  //
  //

  // const consultationData = data?.filter((item) => item.name);
  //

  const [currentDetailsPage, setCurrentDetailsPage] = useState(0);
  const [currentCallDetailsPage, setCurrentCallDetailsPage] = useState(0);
  const [currentPublicCallDetailsPage, setCurrentPublicCallDetailsPage] =
    useState(0);
  const itemsPerPage = 10;

  const handlePageChange = ({ selected }) => {
    setCurrentDetailsPage(selected);
  };
  const handleCallPageChange = ({ selected }) => {
    setCurrentCallDetailsPage(selected);
  };
  const handlePublicCallPageChange = ({ selected }) => {
    setCurrentPublicCallDetailsPage(selected);
  };

  const indexOfLastDetailsItem = (currentDetailsPage + 1) * itemsPerPage;
  const indexOfFirstDetailsItem = indexOfLastDetailsItem - itemsPerPage;

  const currentConsultation = consultation?.slice(
    indexOfFirstDetailsItem,
    indexOfLastDetailsItem
  );

  const indexOfLastCallDetailsItem =
    (currentCallDetailsPage + 1) * itemsPerPage;
  const indexOfFirstCallDetailsItem = indexOfLastCallDetailsItem - itemsPerPage;

  const currentCallConsultation = consultationCallData?.slice(
    indexOfFirstCallDetailsItem,
    indexOfLastCallDetailsItem
  );

  const indexOfLastPublicCallDetailsItem =
    (currentPublicCallDetailsPage + 1) * itemsPerPage;
  const indexOfFirstPublicCallDetailsItem =
    indexOfLastPublicCallDetailsItem - itemsPerPage;

  const currentPublicCallConsultation = publicCallData?.slice(
    indexOfFirstPublicCallDetailsItem,
    indexOfLastPublicCallDetailsItem
  );
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  return (
    <>
      <Container
        mt={"40px"}
        maxW="1148px"
        bg="#FAFAFA"
        border="2px solid orange"
        borderBottom="none"
        borderTopLeftRadius="20px"
        borderTopRightRadius="20px"
      >
        <Heading
          pt="32px"
          
          as="h1"
          color="#000"
          textAlign="center"
          fontSize="24px"
          fontStyle="normal"
          fontWeight="500"
          lineWeight="normal"
        >
          Consultation History
        </Heading>
        <Text
          display={consultation?.[0]?.user?.firstName ? "block" : "none"}
          textAlign={"center"}
          color="#FF5E1A"
          mt="10px"
          fontWeight={"500"}
          fontSize={"20px"}
          pb="32px"
        >{`${consultation?.[0]?.user?.firstName} ${consultation?.[0]?.user?.lastName}`}</Text>
        {/* <Button onClick={fetchBirthDetails}>fetchBirth</Button> */}
        <Tabs variant="unstyled" align="center">
          <TabList>
            <Tab
              border="1px solid transparent"
              bg="#FFE1CA"
              color="#767676"
              borderTopLeftRadius="5px"
              borderBottomLeftRadius="5px"
              _selected={{ color: "white", bg: "#FF5E1A" }}
              w={{ md: "132px", base: "80px", sm: "80px" }}
              fontSize={{ md: "18px", base: "12px", sm: "12px" }}
              fontWeight={"500"}
            >
              All
            </Tab>
            <Tab
              border="1px solid transparent"
              bg="#FFE1CA"
              color="#767676"
              _selected={{ color: "white", bg: "#FF5E1A" }}
              w={{ md: "132px", base: "80px", sm: "80px" }}
              fontSize={{ md: "18px", base: "12px", sm: "12px" }}
              fontWeight={"500"}
            >
              Call
            </Tab>
            <Tab
              border="1px solid transparent"
              bg="#FFE1CA"
              color={"#767676"}
              borderTopRightRadius="5px"
              borderBottomRightRadius="5px"
              _selected={{ color: "white", bg: "#FF5E1A" }}
              w={{ md: "132px", base: "100px", sm: "100px" }}
              fontSize={{ md: "18px", base: "12px", sm: "12px" }}
              fontWeight={"500"}
            >
              Public Live
            </Tab>
          </TabList>
          <TabPanels pt="32px">
            <TabPanel
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {!consultation ? (
                <Spinner />
              ) : (
                currentConsultation &&
                currentConsultation.map((item) => (
                  <UsersConsultationCard
                    key={item._id}
                    consultationData={item}
                  />
                ))
              )}
              {currentConsultation && (
                <ReactPaginate
                  previousLabel="<< Prev"
                  nextLabel="Next >>"
                  pageCount={Math.ceil(consultation.length / itemsPerPage)}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                  // style={paginationStyles}
                />
              )}
            </TabPanel>
            <TabPanel>
              {!consultationCallData ? (
                <Spinner />
              ) : (
                currentCallConsultation &&
                currentCallConsultation.map((item) => (
                  <UsersConsultationCard
                    key={item._id}
                    consultationData={item}
                  />
                ))
              )}

              {consultationCallData && (
                <ReactPaginate
                  previousLabel="<< Prev"
                  nextLabel="Next >>"
                  pageCount={Math.ceil(
                    consultationCallData.length / itemsPerPage
                  )}
                  onPageChange={handleCallPageChange}
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                  // style={paginationStyles}
                />
              )}
            </TabPanel>
            <TabPanel>
              {!publicCallData ? (
                <Spinner />
              ) : (
                currentPublicCallConsultation &&
                currentPublicCallConsultation.map((item) => (
                  <UsersConsultationCard
                    key={item._id}
                    consultationData={item}
                  />
                ))
              )}

              {publicCallData && (
                <ReactPaginate
                  previousLabel="<< Prev"
                  nextLabel="Next >>"
                  pageCount={Math.ceil(publicCallData.length / itemsPerPage)}
                  onPageChange={handlePublicCallPageChange}
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                  // style={paginationStyles}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </>
  );
}

export default ShowMoreConsultation;
