import { Button } from "@chakra-ui/button";
import { Card } from "@chakra-ui/card";
import { Icon } from "@chakra-ui/icon";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useParams } from "react-router";

const initialWeekDays = [
  { day: "Monday", date: "16 Aug", timeSlots: [] },
  { day: "Tuesday", date: "16 Aug", timeSlots: [] },
  { day: "Wednesday", date: "16 Aug", timeSlots: [] },
  { day: "Thursday", date: "16 Aug", timeSlots: [] },
  { day: "Friday", date: "16 Aug", timeSlots: [] },
  { day: "Saturday", date: "16 Aug", timeSlots: [] },
  { day: "Sunday", date: "16 Aug", timeSlots: [] },
];

const Edit = () => {
  const [timeInputs, setTimeInputs] = useState([]);
  const [details, setDetails] = useState([]);
  const [weekDays, setWeekDays] = useState(initialWeekDays);

  // Monday
  const [monMorningStartTime, setMonMorningStartTime] = useState("Time not updated");
  const [monMorningEndTime, setMonMorningEndTime] = useState("Time not updated");
  const [monEveningStartTime, setMonEveningStartTime] = useState("Time not updated");
  const [monEveningEndTime, setMonEveningEndTime] = useState("Time not updated");
  const handlemondayMorningStartTime = (event) =>
    setMonMorningStartTime(event.target.value);
  const handlemondayMorningEndTime = (event) =>
    setMonMorningEndTime(event.target.value);
  const handlemondayEveningStartTime = (event) =>
    setMonEveningStartTime(event.target.value);
  const handlemondayEveningEndTime = (event) =>
    setMonEveningEndTime(event.target.value);
  const mondayEveningTimings = monEveningStartTime + " " + monEveningEndTime;
  const mondayMoriningTimings = monMorningStartTime + " " + monMorningEndTime;
  //Tuesday
  const [tueMorningStartTime, setTueMorningStartTime] = useState("Time not updated");
  const [tueMorningEndTime, setTueMorningEndTime] = useState("Time not updated");
  const [tueEveningEndTime, setTueEveningEndTime] = useState("Time not updated");
  const [tueEveningStartTime, setTueEveningStartTime] = useState("Time not updated");
  const handletuesdayMorningStartTime = (event) =>
    setTueMorningStartTime(event.target.value);
  const handletuesdayMorningEndTime = (event) =>
    setTueMorningEndTime(event.target.value);
  const handletuesdayEveningStartTime = (event) =>
    setTueEveningStartTime(event.target.value);
  const handletuesdayEveningEndTime = (event) =>
    setTueEveningEndTime(event.target.value);
  const tuesdayMoriningTimings = tueMorningStartTime + " " + tueMorningEndTime;
  const tuesdayEveningTimings = tueEveningStartTime + " " + tueEveningEndTime;
  //Wednesday
  const [wedMorningStartTime, setWedMorningStartTime] = useState("Time not updated");
  const [wedMorningEndTime, setWedMorningEndTime] = useState("Time not updated");
  const [wedEveningStartTime, setWedEveningStartTime] = useState("Time not updated");
  const [wedEveningEndTime, setWedEveningEndTime] = useState("Time not updated");
  const handlewednesdayMorningStartTime = (event) =>
    setWedMorningStartTime(event.target.value);
  const handlewednesdayMorningEndTime = (event) =>
    setWedMorningEndTime(event.target.value);
  const handlewednesdayEveningStartTime = (event) => {
    setWedEveningStartTime(event.target.value);
  };
  const handlewednesdayEveningEndTime = (event) =>
    setWedEveningEndTime(event.target.value);
  const wednesdayMoriningTimings =
    wedMorningStartTime + " " + wedMorningEndTime;
  const wednesdayEveningTimings = wedEveningStartTime + " " + wedEveningEndTime;
  //Thursday
  const [thuMorningStartTime, setThuMorningStartTime] = useState("Time not updated");
  const [thuMorningEndTime, setThuMorningEndTime] = useState("Time not updated");
  const [thuEveningStartTime, setThuEveningStartTime] = useState("Time not updated");
  const [thuEveningEndTime, setThuEveningEndTime] = useState("Time not updated");
  const handlethursdayMorningStartTime = (event) =>
    setThuMorningStartTime(event.target.value);
  const handlethursdayMorningEndTime = (event) =>
    setThuMorningEndTime(event.target.value);
  const handlethursdayEveningStartTime = (event) => {
    setThuEveningStartTime(event.target.value);
  };
  const handlethursdayEveningEndTime = (event) =>
    setThuEveningEndTime(event.target.value);
  const thursdaysMoriningTimings =
    thuMorningStartTime + " " + thuMorningEndTime;
  const thursdaysEveningTimings = thuEveningStartTime + " " + thuEveningEndTime;
  //Friday
  const [friMorningStartTime, setFriMorningStartTime] = useState("Time not updated");
  const [friMorningEndTime, setFriMorningEndTime] = useState("Time not updated");
  const [friEveningStartTime, setFriEveningStartTime] = useState("Time not updated");
  const [friEveningEndTime, setFriEveningEndTime] = useState("Time not updated");
  const handlefridayMorningStartTime = (event) =>
    setFriMorningStartTime(event.target.value);
  const handlefridayMorningEndTime = (event) =>
    setFriMorningEndTime(event.target.value);
  const handlefridayEveningStartTime = (event) =>
    setFriEveningStartTime(event.target.value);
  const handlefridayEveningEndTime = (event) =>
    setFriEveningEndTime(event.target.value);
  const fridaysMoriningTimings = friMorningStartTime + " " + friMorningEndTime;
  const firdaysEveningTimings = friEveningStartTime + " " + friEveningEndTime;
  //Saturday
  const [satMorningStartTime, setSatMorningStartTime] = useState("Time not updated");
  const [satMorningEndTime, setSatMorningEndTime] = useState("Time not updated");
  const [satEveningStartTime, setSatEveningStartTime] = useState("Time not updated");
  const [satEveningEndTime, setSatEveningEndTime] = useState("Time not updated");
  const handlesaturdayMorningStartTime = (event) =>
    setSatMorningStartTime(event.target.value);
  const handlesaturdayMorningEndTime = (event) =>
    setSatMorningEndTime(event.target.value);
  const handlesaturdayEveningStartTime = (event) => {
    setSatEveningStartTime(event.target.value);
  };
  const handlesaturdayEveningEndTime = (event) => {
    setSatEveningEndTime(event.target.value);
  };
  const saturdaysMoriningTimings =
    satMorningStartTime + " " + satMorningEndTime;
  const saturdaysEveningTimings = satEveningStartTime + " " + satEveningEndTime;
  //Sunday
  const [sunMorningStartTime, setSunMorningStartTime] = useState("Time not updated");
  const [sunMorningEndTime, setSunMorningEndTime] = useState("Time not updated");
  const [sunEveningStartTime, setSunEveningStartTime] = useState("Time not updated");
  const [sunEveningEndTime, setSunEveningEndTime] = useState("Time not updated");
  const handlesundayMorningStartTime = (event) =>
    setSunMorningStartTime(event.target.value);
  const handlesundayMorningEndTime = (event) =>
    setSunMorningEndTime(event.target.value);
  const handlesundayEveningStartTime = (event) => {
    setSunEveningStartTime(event.target.value);
  };
  const handlesundayEveningEndTime = (event) =>
    setSunEveningEndTime(event.target.value);
  const sunsMoriningTimings = sunMorningStartTime + " " + sunMorningEndTime;
  const sunsEveningTimings = sunEveningStartTime + " " + sunEveningEndTime;
  const { id } = useParams();
  const handleSave = async () => {
    try {
      const apiUrl =
        "https://data.gurucool.life/api/v1/guru/updateTimeSchedule";
      const requestBody = {
        timeSchedule: {
          sun: {
            morning: sunsMoriningTimings,
            evening: sunsEveningTimings,
          },
          mon: {
            morning: mondayMoriningTimings,
            evening: mondayEveningTimings,
          },
          tue: {
            morning: tuesdayMoriningTimings,
            evening: tuesdayEveningTimings,
          },
          wed: {
            morning: wednesdayMoriningTimings,
            evening: wednesdayEveningTimings,
          },
          thu: {
            morning: thursdaysMoriningTimings,
            evening: thursdaysEveningTimings,
          },
          fri: {
            morning: fridaysMoriningTimings,
            evening: firdaysEveningTimings,
          },
          sat: {
            morning: saturdaysMoriningTimings,
            evening: saturdaysEveningTimings,
          },
        },
      };

      const params = {
        astrologer: id,
      };

      const response = await axios.put(apiUrl, requestBody, { params });
    } catch (error) {
      console.error("Error updating data:", error.response.data);
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("timeInputs");
    if (savedData) {
      setTimeInputs(JSON.parse(savedData));
    }
  }, []);

  const handleRemoveTimeInput = (dayIndex, timeIndex) => {
    if (weekDays[dayIndex] && weekDays[dayIndex].timeSlots) {
      const updatedTimeSlots = [...weekDays[dayIndex].timeSlots];
      updatedTimeSlots.splice(timeIndex, 1);

      const updatedWeekDays = [...weekDays];
      updatedWeekDays[dayIndex].timeSlots = updatedTimeSlots;

      setWeekDays(updatedWeekDays);
    }
  };

  return (
    <Container
      bg={{ md: "#EAEAEA", base: "white" }}
      maxW={"initial"}
      py={"0 !important"}
    >
      <Flex
        display={{ base: "none", md: "flex" }}
        flexDirection={"row"}
        pt={{ md: "61px" }}
        pb={{ md: "3px" }}
        mb={{ md: "3px" }}
        bg={"#FFCDA8"}
      >
        {" "}
        <Text
          color={"#929292"}
          fontWeight={"500"}
          fontSize={{ md: "18px" }}
          mb={"0 !important"}
        >
          Dashboard/
        </Text>
        <Text
          fontSize={{ md: "18px" }}
          fontWeight={"500"}
          color={"#A95210"}
          mb={"0 !important"}
        >
          Set up Online Timing
        </Text>{" "}
      </Flex>
      <Flex
        display={{ base: "flex", md: "none" }}
        flexDirection={"row"}
        gap={"20px"}
        pt={"12px"}
        pb={"10px"}
      >
        <Icon as={FiArrowLeft} mt={{ base: "4px" }} />
        <Heading fontWeight={"600"} fontSize={{ base: "18px" }}>
          Set up online Timing
        </Heading>
      </Flex>
      <Text
        display={{ base: "none", md: "block" }}
        border={"1px dashed rgba(135, 66, 12, 0.4)"}
      ></Text>
      <Text
        display={{ base: "block", md: "none" }}
        border={"1px solid #EDEDED"}
      ></Text>
      <Box
        pt={{ base: "10px" }}
        bg={{ md: "#EAEAEA", base: "white" }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        pb={{ base: "41px" }}
      >
        {/* SUNDAY */}
        <Card
          maxW={{ md: "6xl" }}
          bg={"white"}
          width={{
            base: "-webkit-fill-available !important",
            md: "100% !important",
          }}
          h={"auto !important"}
          borderRadius={{ md: "4px !important", base: "0" }}
          boxShadow={{ base: "none" }}
          px={{ md: "43px !important", base: "17px" }}
          py={{ md: "22px !important" }}
          pt={{ base: "35px", md: "22px" }}
          pb={{ base: "8px", md: "22px" }}
          mt={{ md: "8px !important" }}

          // mb={{ md: "8px " }}
        >
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Flex
              alignItems={"flex-start"}
              w={{ md: "7%" }}
              flexDirection={"column"}
            >
              <Text
                fontWeight={"500"}
                fontSize={{ md: "20px", base: "14px" }}
                color={"#4B4B4B"}
                marginBottom={"0 !important"}
              >
                Sunday
                {/* {weekday.day} */}
              </Text>
              <Text
                fontWeight={"500"}
                fontSize={{ md: "14px", base: "12px" }}
                color={"#8F8F8F"}
              >
                14 June
                {/* {weekday.date} */}
              </Text>
            </Flex>

            <Flex flexDirection={"column"}>
              {/* Add new time slot */}
              <Flex
                alignItems={"center"}
                flex
                className="timing"
                // key={index}
                gap={{ md: "25px" }}
                flexDirection={"column"}
              >
                <HStack mb={{ base: "8px", md: "16px" }}>
                  <Input
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    //   htmlSize={{md:'9', base:'6'}}
                    size={{ base: "sm", sm: "md" }}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlesundayMorningStartTime(e)}
                    // value={weekday.startTime}
                    //   onChange={(e) => handleStartTimeChange(e, index, weekday.day)
                    // }
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlesundayMorningEndTime(e)}
                    // value={weekday.endTime}
                    // onChange={(e) => handleEndTimeChange(e, index)}
                  />
                </HStack>
                <HStack>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlesundayEveningStartTime(e)}
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlesundayEveningEndTime(e)}
                  />
                </HStack>
              </Flex>
              {/* {weekday.timeSlots &&
                  weekday.timeSlots.map((timeSlot, slotIndex) => ( */}

              {/* ))} */}
            </Flex>

            {/* <VStack>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={weekday.iconColor}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => [...prevInputs, {}]);
                  }}
                >
                  <Icon color={"white"} as={AddIcon} />
                </Card>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={"blue"}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => prevInputs.slice(0, -1));
                  }}
                >
                  <Icon color={"white"} as={MinusIcon} />
                </Card>
              </VStack> */}
          </Flex>
        </Card>
        {/* MONDAY */}
        <Card
          maxW={{ md: "6xl" }}
          bg={"white"}
          width={{
            base: "-webkit-fill-available !important",
            md: "100% !important",
          }}
          h={"auto !important"}
          borderRadius={{ md: "4px !important", base: "0" }}
          boxShadow={{ base: "none" }}
          px={{ md: "43px !important", base: "17px" }}
          py={{ md: "22px !important" }}
          pt={{ base: "35px", md: "22px" }}
          pb={{ base: "8px", md: "22px" }}
          mt={{ md: "8px !important" }}
        >
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Flex
              alignItems={"flex-start"}
              w={{ md: "7%" }}
              flexDirection={"column"}
            >
              <Text
                fontWeight={"500"}
                fontSize={{ md: "20px", base: "14px" }}
                color={"#4B4B4B"}
                marginBottom={"0 !important"}
              >
                {/* {weekday.day} */}
                Monday
              </Text>
              <Text
                fontWeight={"500"}
                fontSize={{ md: "14px", base: "12px" }}
                color={"#8F8F8F"}
              >
                15 June
                {/* {weekday.date} */}
              </Text>
            </Flex>
            <Flex flexDirection={"column"}>
              <Flex
                alignItems={"center"}
                flex
                className="timing"
                gap={{ md: "25px" }}
                flexDirection={"column"}
              >
                <HStack mb={{ base: "8px", md: "16px" }}>
                  <Input
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    size={{ base: "sm", sm: "md" }}
                    bg={"#F9F9F9"}
                    type="time"
                    // value={weekday.startTime}
                    onChange={(e) => handlemondayMorningStartTime(e)}
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    // value={weekday.endTime}
                    onChange={(e) => handlemondayMorningEndTime(e)}
                  />
                </HStack>
                <HStack>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlemondayEveningStartTime(e)}
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlemondayEveningEndTime(e)}
                  />
                </HStack>
              </Flex>
            </Flex>
          </Flex>
        </Card>
        {/* TUESDAY */}
        <Card
          maxW={{ md: "6xl" }}
          bg={"white"}
          width={{
            base: "-webkit-fill-available !important",
            md: "100% !important",
          }}
          h={"auto !important"}
          borderRadius={{ md: "4px !important", base: "0" }}
          boxShadow={{ base: "none" }}
          px={{ md: "43px !important", base: "17px" }}
          py={{ md: "22px !important" }}
          pt={{ base: "35px", md: "22px" }}
          pb={{ base: "8px", md: "22px" }}
          mt={{ md: "8px !important" }}

          // mb={{ md: "8px " }}
        >
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Flex
              alignItems={"flex-start"}
              w={{ md: "7%" }}
              flexDirection={"column"}
            >
              <Text
                fontWeight={"500"}
                fontSize={{ md: "20px", base: "14px" }}
                color={"#4B4B4B"}
                marginBottom={"0 !important"}
              >
                Tuesday
                {/* {weekday.day} */}
              </Text>
              <Text
                fontWeight={"500"}
                fontSize={{ md: "14px", base: "12px" }}
                color={"#8F8F8F"}
              >
                16 June
                {/* {weekday.date} */}
              </Text>
            </Flex>

            <Flex flexDirection={"column"}>
              {/* Add new time slot */}
              <Flex
                alignItems={"center"}
                flex
                className="timing"
                // key={index}
                gap={{ md: "25px" }}
                flexDirection={"column"}
              >
                <HStack mb={{ base: "8px", md: "16px" }}>
                  <Input
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    //   htmlSize={{md:'9', base:'6'}}
                    size={{ base: "sm", sm: "md" }}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handletuesdayMorningStartTime(e)}
                    // value={weekday.startTime}
                    //   onChange={(e) => handleStartTimeChange(e, index, weekday.day)
                    // }
                  />
                  <Text
                
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handletuesdayMorningEndTime(e)}
                    // value={weekday.endTime}
                    // onChange={(e) => handleEndTimeChange(e, index)}
                  />
                </HStack>
                <HStack>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handletuesdayEveningStartTime(e)}
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handletuesdayEveningEndTime(e)}
                  />
                </HStack>
              </Flex>
              {/* {weekday.timeSlots &&
                  weekday.timeSlots.map((timeSlot, slotIndex) => ( */}

              {/* ))} */}
            </Flex>

            {/* <VStack>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={weekday.iconColor}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => [...prevInputs, {}]);
                  }}
                >
                  <Icon color={"white"} as={AddIcon} />
                </Card>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={"blue"}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => prevInputs.slice(0, -1));
                  }}
                >
                  <Icon color={"white"} as={MinusIcon} />
                </Card>
              </VStack> */}
          </Flex>
        </Card>
        {/* WEDNESDAY */}
        <Card
          maxW={{ md: "6xl" }}
          bg={"white"}
          width={{
            base: "-webkit-fill-available !important",
            md: "100% !important",
          }}
          h={"auto !important"}
          borderRadius={{ md: "4px !important", base: "0" }}
          boxShadow={{ base: "none" }}
          px={{ md: "43px !important", base: "17px" }}
          py={{ md: "22px !important" }}
          pt={{ base: "35px", md: "22px" }}
          pb={{ base: "8px", md: "22px" }}
          mt={{ md: "8px !important" }}

          // mb={{ md: "8px " }}
        >
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Flex
              alignItems={"flex-start"}
              w={{ md: "7%" }}
              flexDirection={"column"}
            >
              <Text
                fontWeight={"500"}
                fontSize={{ md: "20px", base: "14px" }}
                color={"#4B4B4B"}
                marginBottom={"0 !important"}
              >
                Wednesday
                {/* {weekday.day} */}
              </Text>
              <Text
                fontWeight={"500"}
                fontSize={{ md: "14px", base: "12px" }}
                color={"#8F8F8F"}
              >
                17 June
                {/* {weekday.date} */}
              </Text>
            </Flex>

            <Flex flexDirection={"column"}>
              {/* Add new time slot */}
              <Flex
                alignItems={"center"}
                flex
                className="timing"
                // key={index}
                gap={{ md: "25px" }}
                flexDirection={"column"}
              >
                <HStack mb={{ base: "8px", md: "16px" }}>
                  <Input
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    //   htmlSize={{md:'9', base:'6'}}
                    size={{ base: "sm", sm: "md" }}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlewednesdayMorningStartTime(e)}
                    // value={weekday.startTime}
                    //   onChange={(e) => handleStartTimeChange(e, index, weekday.day)
                    // }
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlewednesdayMorningEndTime(e)}
                    // value={weekday.endTime}
                    // onChange={(e) => handleEndTimeChange(e, index)}
                  />
                </HStack>
                <HStack>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlewednesdayEveningStartTime(e)}
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlewednesdayEveningEndTime(e)}
                  />
                </HStack>
              </Flex>
              {/* {weekday.timeSlots &&
                  weekday.timeSlots.map((timeSlot, slotIndex) => ( */}

              {/* ))} */}
            </Flex>

            {/* <VStack>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={weekday.iconColor}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => [...prevInputs, {}]);
                  }}
                >
                  <Icon color={"white"} as={AddIcon} />
                </Card>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={"blue"}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => prevInputs.slice(0, -1));
                  }}
                >
                  <Icon color={"white"} as={MinusIcon} />
                </Card>
              </VStack> */}
          </Flex>
        </Card>
        {/* THURSDAY */}
        <Card
          maxW={{ md: "6xl" }}
          bg={"white"}
          width={{
            base: "-webkit-fill-available !important",
            md: "100% !important",
          }}
          h={"auto !important"}
          borderRadius={{ md: "4px !important", base: "0" }}
          boxShadow={{ base: "none" }}
          px={{ md: "43px !important", base: "17px" }}
          py={{ md: "22px !important" }}
          pt={{ base: "35px", md: "22px" }}
          pb={{ base: "8px", md: "22px" }}
          mt={{ md: "8px !important" }}

          // mb={{ md: "8px " }}
        >
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Flex
              alignItems={"flex-start"}
              w={{ md: "7%" }}
              flexDirection={"column"}
            >
              <Text
                fontWeight={"500"}
                fontSize={{ md: "20px", base: "14px" }}
                color={"#4B4B4B"}
                marginBottom={"0 !important"}
              >
                Thursday
                {/* {weekday.day} */}
              </Text>
              <Text
                fontWeight={"500"}
                fontSize={{ md: "14px", base: "12px" }}
                color={"#8F8F8F"}
              >
                17 June
                {/* {weekday.date} */}
              </Text>
            </Flex>

            <Flex flexDirection={"column"}>
              {/* Add new time slot */}
              <Flex
                alignItems={"center"}
                flex
                className="timing"
                // key={index}
                gap={{ md: "25px" }}
                flexDirection={"column"}
              >
                <HStack mb={{ base: "8px", md: "16px" }}>
                  <Input
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    //   htmlSize={{md:'9', base:'6'}}
                    size={{ base: "sm", sm: "md" }}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlethursdayMorningStartTime(e)}
                    // value={weekday.startTime}
                    //   onChange={(e) => handleStartTimeChange(e, index, weekday.day)
                    // }
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlethursdayMorningEndTime(e)}
                    // value={weekday.endTime}
                    // onChange={(e) => handleEndTimeChange(e, index)}
                  />
                </HStack>
                <HStack>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlethursdayEveningStartTime(e)}
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlethursdayEveningEndTime(e)}
                  />
                </HStack>
              </Flex>
              {/* {weekday.timeSlots &&
                  weekday.timeSlots.map((timeSlot, slotIndex) => ( */}

              {/* ))} */}
            </Flex>

            {/* <VStack>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={weekday.iconColor}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => [...prevInputs, {}]);
                  }}
                >
                  <Icon color={"white"} as={AddIcon} />
                </Card>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={"blue"}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => prevInputs.slice(0, -1));
                  }}
                >
                  <Icon color={"white"} as={MinusIcon} />
                </Card>
              </VStack> */}
          </Flex>
        </Card>
        {/* FRIDAY */}
        <Card
          maxW={{ md: "6xl" }}
          bg={"white"}
          width={{
            base: "-webkit-fill-available !important",
            md: "100% !important",
          }}
          h={"auto !important"}
          borderRadius={{ md: "4px !important", base: "0" }}
          boxShadow={{ base: "none" }}
          px={{ md: "43px !important", base: "17px" }}
          py={{ md: "22px !important" }}
          pt={{ base: "35px", md: "22px" }}
          pb={{ base: "8px", md: "22px" }}
          mt={{ md: "8px !important" }}

          // mb={{ md: "8px " }}
        >
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Flex
              alignItems={"flex-start"}
              w={{ md: "7%" }}
              flexDirection={"column"}
            >
              <Text
                fontWeight={"500"}
                fontSize={{ md: "20px", base: "14px" }}
                color={"#4B4B4B"}
                marginBottom={"0 !important"}
              >
                Friday
                {/* {weekday.day} */}
              </Text>
              <Text
                fontWeight={"500"}
                fontSize={{ md: "14px", base: "12px" }}
                color={"#8F8F8F"}
              >
                19 June
                {/* {weekday.date} */}
              </Text>
            </Flex>

            <Flex flexDirection={"column"}>
              {/* Add new time slot */}
              <Flex
                alignItems={"center"}
                flex
                className="timing"
                // key={index}
                gap={{ md: "25px" }}
                flexDirection={"column"}
              >
                <HStack mb={{ base: "8px", md: "16px" }}>
                  <Input
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    //   htmlSize={{md:'9', base:'6'}}
                    size={{ base: "sm", sm: "md" }}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlefridayMorningStartTime(e)}
                    // value={weekday.startTime}
                    //   onChange={(e) => handleStartTimeChange(e, index, weekday.day)
                    // }
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlefridayMorningEndTime(e)}
                    // value={weekday.endTime}
                    // onChange={(e) => handleEndTimeChange(e, index)}
                  />
                </HStack>
                <HStack>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlefridayEveningStartTime(e)}
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlefridayEveningEndTime(e)}
                  />
                </HStack>
              </Flex>
              {/* {weekday.timeSlots &&
                  weekday.timeSlots.map((timeSlot, slotIndex) => ( */}

              {/* ))} */}
            </Flex>

            {/* <VStack>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={weekday.iconColor}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => [...prevInputs, {}]);
                  }}
                >
                  <Icon color={"white"} as={AddIcon} />
                </Card>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={"blue"}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => prevInputs.slice(0, -1));
                  }}
                >
                  <Icon color={"white"} as={MinusIcon} />
                </Card>
              </VStack> */}
          </Flex>
        </Card>
        {/* SATURDAY */}
        <Card
          maxW={{ md: "6xl" }}
          bg={"white"}
          width={{
            base: "-webkit-fill-available !important",
            md: "100% !important",
          }}
          h={"auto !important"}
          borderRadius={{ md: "4px !important", base: "0" }}
          boxShadow={{ base: "none" }}
          px={{ md: "43px !important", base: "17px" }}
          py={{ md: "22px !important" }}
          pt={{ base: "35px", md: "22px" }}
          pb={{ base: "8px", md: "22px" }}
          mt={{ md: "8px !important" }}

          // mb={{ md: "8px " }}
        >
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Flex
              alignItems={"flex-start"}
              w={{ md: "7%" }}
              flexDirection={"column"}
            >
              <Text
                fontWeight={"500"}
                fontSize={{ md: "20px", base: "14px" }}
                color={"#4B4B4B"}
                marginBottom={"0 !important"}
              >
                Saturday
                {/* {weekday.day} */}
              </Text>
              <Text
                fontWeight={"500"}
                fontSize={{ md: "14px", base: "12px" }}
                color={"#8F8F8F"}
              >
                20 June
                {/* {weekday.date} */}
              </Text>
            </Flex>

            <Flex flexDirection={"column"}>
              {/* Add new time slot */}
              <Flex
                alignItems={"center"}
                flex
                className="timing"
                // key={index}
                gap={{ md: "25px" }}
                flexDirection={"column"}
              >
                <HStack mb={{ base: "8px", md: "16px" }}>
                  <Input
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    //   htmlSize={{md:'9', base:'6'}}
                    size={{ base: "sm", sm: "md" }}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlesaturdayMorningStartTime(e)}
                    // value={weekday.startTime}
                    //   onChange={(e) => handleStartTimeChange(e, index, weekday.day)
                    // }
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlesaturdayMorningEndTime(e)}
                    // value={weekday.endTime}
                    // onChange={(e) => handleEndTimeChange(e, index)}
                  />
                </HStack>
                <HStack>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlesaturdayEveningStartTime(e)}
                  />
                  <Text
                    fontWeight={500}
                    color={"#686868"}
                    mb={"0 !important"}
                    mx={15}
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    to
                  </Text>
                  <Input
                    size={{ base: "sm", sm: "md" }}
                    color={"#747474"}
                    borderRadius={"4px"}
                    border={"0.5px solid #CFCFCF"}
                    htmlSize={9}
                    bg={"#F9F9F9"}
                    type="time"
                    onChange={(e) => handlesaturdayEveningEndTime(e)}
                  />
                </HStack>
              </Flex>
              {/* {weekday.timeSlots &&
                  weekday.timeSlots.map((timeSlot, slotIndex) => ( */}

              {/* ))} */}
            </Flex>

            {/* <VStack>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={weekday.iconColor}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => [...prevInputs, {}]);
                  }}
                >
                  <Icon color={"white"} as={AddIcon} />
                </Card>
                <Card
                  height={"33px"}
                  width={"33px"}
                  bg={"blue"}
                  borderRadius={"50%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    setTimeInputs((prevInputs) => prevInputs.slice(0, -1));
                  }}
                >
                  <Icon color={"white"} as={MinusIcon} />
                </Card>
              </VStack> */}
          </Flex>
        </Card>
        <Flex
          mt={{ base: "41px" }}
          gap={"3px"}
          pt={{ md: "61px" }}
          justifyContent={"flex-end"}
        >
          <Button
            color={"#A95210"}
            bg={"transparent"}
            _hover={{
              border: "1px solid #A95210",
            }}
            onClick={handleRemoveTimeInput}
          >
            Clear All
          </Button>
          <Button
            color={"white"}
            bg={"#A95210"}
            _hover={{
              bg: "#A95210",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};

export default Edit;
