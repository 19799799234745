import "./App.css";
import {
  ChakraProvider,
  HStack,
  Box,
  Image,
  Text,
  Button,
  Flex,
  Stack,
  Modal,
  useDisclosure,
} from "@chakra-ui/react";
import Gurucool from "./Gurucool";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "./store/TarotSlice";
import axios from "axios";
import logo_gif from "./assets/loading.gif";
import { fetchLoggedInUserDetails } from "./store/LoggedInUserDetails";
import { useAddToHomeScreen } from "./customHooks/useAddToHomeScreen";
import { CloseIcon } from "@chakra-ui/icons";
import popup_logo from "./assets/gurucoolLogo/60x60.png";
import ScrollTracker from "./components/ScrollTracker";
import { fetchHomepageAstrologers } from "./store/homepageAstrologers";
import { bg } from "date-fns/locale";
import TagManager from "react-gtm-module";
import NewUserJourneyPopup from "./components/Popups/newUserJourneyPopup/newUserJourneyPopup";
import NewUserJourneyDelayPopup from "./components/Popups/newUserJourneyPopup/newUserJourneyDelayPopup";
import io from "socket.io-client";

const tagManagerArgs = {
  gtmId: "GTM-T5G3HM52",
};

TagManager.initialize(tagManagerArgs);

const useInternationalization = (loggedIn, userLoggedIn, userDetails) => {
  useEffect(() => {
    if (!loggedIn && !userLoggedIn) {
      setInternationalStatusFromGeoLocation();
    } else {
      setInternationalStatusFromUserDetails(userDetails);
    }
  }, [loggedIn, userLoggedIn, userDetails]);
};

const setInternationalStatusFromGeoLocation = async () => {
  try {
    const response = await axios.get(
      "https://geolocation-db.com/json/e5e47150-bc2c-11ed-9b49-492949f4ff3d/49.36.239.168"
    );

    const isInternational = response.data.country_name !== "India";
    localStorage.setItem("isInternational", isInternational);
  } catch (error) {
    console.error("Error fetching geolocation data:", error);
    localStorage.setItem("isInternational", true);
  }
};

const setInternationalStatusFromUserDetails = (userDetails) => {
  if (userDetails.data.userDetails) {
    localStorage.setItem(
      "isInternational",
      userDetails.data.userDetails.isInternational
    );
  }
};

function App() {
  // const host = "gurucool";
  const host = "myguruji";
  localStorage.setItem("host", host);
  // localStorage.setItem("host", "myguruji");
  const dispatch = useDispatch();
  const loggedIn = localStorage.getItem("loginToken");
  const userDetails = useSelector((state) => state.loggedInUserDetails);
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );
  const userLoggedIn = useSelector((state) => state.logIn.userLoggedIn);
  const [showInstallApk, setShowInstallApk] = useState(true);

  const loginToken = localStorage.getItem("loginToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deferredPrompt, addToHomeScreen, isInstalled] = useAddToHomeScreen();
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  async function requestWakeLock() {
    try {
      if ("wakeLock" in navigator) {
        // Request a screen wake lock
        const wakeLock = await navigator.wakeLock.request("screen");

        // Handle when the wake lock is released
        wakeLock.addEventListener("release", () => {});

        // Handle when the visibility state of the document changes
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "visible") {
            navigator.wakeLock.request("screen").catch(() => {});
          }
        });
      } else {
      }
    } catch (err) {
      console.error(`Error requesting wake lock: ${err.name}, ${err.message}`);
    }
  }

  /* This `useEffect` hook is responsible for fetching user data and setting the login status of the
  user. It runs only once when the component mounts, as the dependency array is empty. */
  useEffect(() => {
    requestWakeLock();
    if (loginToken) {
      dispatch(fetchLoggedInUserDetails(loginToken));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchData());
    dispatch(fetchHomepageAstrologers());
  }, [dispatch]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//in.fw-cdn.com/30915417/487105.js";
    if (
      window.location.pathname.startsWith("/live-streaming") ||
      window.location.pathname.startsWith("/live")
    ) {
      // Condition for '/live-streaming/' or '/live'
      script.setAttribute("chat", false);
    } else {
      script.setAttribute("chat", true);
    }
    document.body.appendChild(script);
    script.onload = () => {
      if (window.fwcrm && typeof window.fwcrm.on === "function") {
        window.fwcrm.on("user:created", function () {
          window.fcWidget.user.setFirstName(
            loggedInUserDetails?.user?.firstName
          );
          window.fcWidget.user.setLastName(loggedInUserDetails?.user?.lastName);
          window.fcWidget.setUserUid(loggedInUserDetails?.user?.uid);
          window.fcWidget.user.setPhone(loggedInUserDetails?.user?.phone);
          window.fcWidget.user.setEmail(loggedInUserDetails?.user?.email);

          meta = {
            cf_user_uid: loggedInUserDetails?.user?.uid,
            cf_consultation_count: loggedInUserDetails?.userDetails
              ?.isInternational
              ? "$" + loggedInUserDetails?.userDetails?.internationalWallet
              : "Rs" + loggedInUserDetails?.userDetails?.wallet,
          };
          // window.fcWidget.setWalletBalance(
          //   loggedInUserDetails?.userDetails?.isInternational
          //     ? "$" + loggedInUserDetails?.userDetails?.internationalWallet
          //     : "Rs" + loggedInUserDetails?.userDetails?.wallet
          // );
        });
      }
    };
  }, [loggedInUserDetails, window.location.pathname]);

  // Use the custom hook
  useInternationalization(loggedIn, userLoggedIn, userDetails);

  const [showUserJourneyPopup, setShowUserJourneyPopup] = useState(false);

  useEffect(() => {
    const shouldShowPopup = true;

    if (loginToken) {
      setShowUserJourneyPopup(shouldShowPopup);

      setShowUserJourneyPopup(shouldShowPopup);
    } else {
      setShowUserJourneyPopup(true);
    }
  }, [loginToken, loggedInUserDetails]);

  const handleClosePopup = () => {
    setShowUserJourneyPopup(false);
  };

  // const [showUserJourneyDelayPopup, setShowUserJourneyDelayPopup] =
  //   useState(false);

  // useEffect(() => {
  //   const shouldShowPopup = true;
  //   // setTimeout(() => {
  //   //   setShowUserJourneyDelayPopup(shouldShowPopup);
  //   // }, 30000);
  //   if (loginToken) {
  //     setShowUserJourneyPopup(shouldShowPopup);
  //   } else {
  //     setShowUserJourneyDelayPopup(true);
  //   }
  // }, [loginToken, loggedInUserDetails]);

  // const handleCloseDelayPopup = () => {
  //   setShowUserJourneyDelayPopup(false);
  // };

  // useEffect(() => {
  //   console.log("userDetails:", userDetails);
  //   if (userDetails?.data?.userDetails?.normalUserJourney2) {
  //     localStorage.setItem("loginToken", "");
  //     window.location.href = "https://gurucool.life";
  //   } else if (
  //     userDetails?.data?.userDetails?.website[0] === "gurucool" ||
  //     userDetails?.data?.userDetails?.website.length === 0
  //   ) {
  //     // Redirect to a different website
  //     localStorage.setItem("loginToken", "");
  //     window.location.href = "https://gurucool.life";
  //   }
  // }, [userDetails]);

  // useEffect(() => {
  //   if (userDetails && userDetails?.data?.userDetails) {
  //     if (userDetails.data.userDetails.normalUserJourney2) {
  //       window.location.href = "https://gurucool.life";
  //     } else if (
  //       userDetails.data.userDetails.website[0] === "gurucool" ||
  //       userDetails.data.userDetails.website.length === 0
  //     ) {
  //       // Redirect to a different website
  //       window.location.href = "https://gurucool.life";
  //     }
  //   }
  // }, [userDetails]);

  // console.log("userdetails>>>>>>>>>>>>>>>>", userDetails);

  // const socket = io("https://data.gurucool.life");
  // const [eventName, setEventName] = useState();
  // useEffect(() => {
  //   if (loginToken && loggedInUserDetails?.user) {
  //     const uid = loggedInUserDetails.user.uid?.toString();
  //     if (uid) {
  //       const alphabet = "abcdefghijklmnopqrstuvwxyz";
  //       const encodedStr = uid
  //         .split("")
  //         .map((digit) => alphabet[parseInt(digit, 10)])
  //         .join("");
  //       setEventName(encodedStr);
  //     }
  //     socket.on("connect", () => {
  //       console.log("Connected to the server");
  //     });
  //     socket.on(eventName, (data) => {
  //       console.log(eventName);
  //       console.log("Received message:", data);
  //     });
  //     // socket.on("Queue Updated",(data)=>{
  //     //    console.log(data);
  //     //  });
  //     // Clean up the socket connection when the component unmounts
  //     return () => {
  //       socket.off('connect');
  //       socket.off('disconnect');
  //       // socket.off('Queue Updated');
  //       socket.off(`${eventName}`);
  //     };
  //   }
  // }, [loginToken, loggedInUserDetails]);

  return (
    <ChakraProvider>
      <>
        <>
          {showInstallApk && !isInstalled && deferredPrompt && (
            <HStack
              p={{ md: 4, base: 2 }}
              bg={"#763900"}
              justify={"space-between"}
              id="alert-top"
            >
              <HStack
                spacing={{ md: "34px", base: "17px" }}
                ml={{ md: "207px", base: "2px" }}
              >
                <CloseIcon
                  boxSize={6}
                  color={"#C0C0C0"}
                  onClick={() => setShowInstallApk(false)}
                />
                <Box p={{ md: 2, base: 1 }} bg="gray.300" borderRadius={"4px"}>
                  <Image src={popup_logo} />
                </Box>
                <Box>
                  <Text
                    fontSize={{ md: "18px", base: "14px" }}
                    fontWeight={"600"}
                    color={"white"}
                  >
                    Download App
                  </Text>
                  <Text
                    fontSize={{ md: "18px", base: "10px" }}
                    fontWeight={"600"}
                    color={"#C3C3C3"}
                  >
                    Get app for quick access
                  </Text>
                </Box>
              </HStack>
              <Stack>
                <Button
                  mr={{ md: "207px", base: "auto" }}
                  onClick={addToHomeScreen}
                  variant={"solid"}
                  // colorScheme={"#ff4c00"}
                  bg={"#FF4C00"}
                  color={"white"}
                  _hover={{
                    color: "#ff4c00",
                    bg: "#FF4C00",
                  }}
                >
                  Get Now
                </Button>
              </Stack>
            </HStack>
          )}
          {/* {isIOS && <button onClick={addToHomeScreen}>To add this app to your home screen, tap the Share button below, and then select "Add to Home Screen".</button>} */}
        </>
        {/* {showUserJourneyPopup && host === "myguruji" && (
          <NewUserJourneyPopup
            isOpen={showUserJourneyPopup}
            // onClose={() => handleClosePopup()}
          />
        )} */}
        {/* {showUserJourneyDelayPopup && host === "myguruji" && (
          <NewUserJourneyDelayPopup
            isOpen={showUserJourneyDelayPopup}
            onClose={() => handleCloseDelayPopup()}
          />
        )} */}
        <Gurucool />
      </>
    </ChakraProvider>
  );
}

export default App;
