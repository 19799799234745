import React, { useState } from "react";
import {
  Box,
  Image,
  Container,
  // Text,
  // Button,
  Stack,
  useDisclosure,
  Img,
  //   Icon,
  //   useColorModeValue,
} from "@chakra-ui/react";
import {
  Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
  // ModalCloseButton,
} from "@chakra-ui/react";

import {
  // Link,
  useNavigate,
} from "react-router-dom";

import banner1 from "../../../assets/newHeroBannerDesktop.jpeg";
import banner2 from "../../../assets/banners/offerbannerInd.png";
import banner3 from "../../../assets/banners/offerbannerInt.png";
import banner4 from "../../../assets/banners/recharge_banner.webp";
import banner5 from "../../../assets/banners/doller.webp";
import banner6 from "../../../assets/banners/live_new_topic.webp";
import banner7 from "../../../assets/banners/gyan.webp";
import totalSpendD from "../../../assets/banners/totalSpendDesktop.webp";
import totalSpendM from "../../../assets/banners/totalSpendMobile.webp";
import mobileBanner1 from "../../../assets/newHeroBanner.webp";
import mobileBanner2 from "../../../assets/mobileBanner/mobileBanner2.webp";
import mobileBanner3 from "../../../assets/mobileBanner/mobileBanner3.webp";
import mobileBanner4 from "../../../assets/mobileBanner/mobileBanner4.webp";
import mobileBanner5 from "../../../assets/mobileBanner/mobileBanner5.webp";
import mobileBanner6 from "../../../assets/mobileBanner/mobileBanner6.webp";
import mobileBanner7 from "../../../assets/mobileBanner/mobileBanner7.webp";
import mobileBanner8 from "../../../assets/mobileBanner/mobileBanner8.webp";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import LazyLoad from "react-lazy-load";
import Login from "../../Login&Signup/Login";

import footerLine from "../../../assets/footerLine.png";

import "../Hero/Hero.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useSelector } from "react-redux";
import { fetchLoggedInUserDetails } from "../../../store/LoggedInUserDetails";

const Hero = () => {
  const isInternational = localStorage.getItem("isInternational") === "true";
  const users = useSelector((state) => state.loggedInUserDetails.data);
  //
  const options = {
    loop: true,
    // controlsClass: 'owl-controls',
    // center: true,
    // nav: true,
    dots: true,
    // margin: 18,
    // dotsEach: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
        // margin: 8,
        // nav: false,
        dots: false,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const loginToken = localStorage.getItem("loginToken");
  const navigate = useNavigate();

  const handleClick = () => {
    if (loginToken) {
      navigate("/wallet");
    } else {
      onOpen();
    }
  };
  return (
    <>
      <Modal
        isCentered
        size={{ base: "xs", md: "4xl" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Login />
      </Modal>
      <Container
        maxW={"full"}
        id="hero-banner"
        spacing={2}
        direction="row"
        pt={{ md: "55px" }}
        pb={{ md: "25px" }}
        display={{ base: "none", md: "flex" }}
        // bg={"#F2D2BA"}
        mb={"-30px"}
      >
        <Container maxW={"6xl"} id="hero-banner">
          <OwlCarousel className="owl-theme" loop {...options}>
            <Box w={"100%"} mb={{ md: "11px", base: "9px" }}>
              <LazyLoadImage
                objectFit={"cover"}
                src={banner1}
                alt="hero-banner"
                w="100%"
                effect="blur"
              />
            </Box>

            {/* {isInternational ? (
              <Box
                w={"100%"}
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"
              >
                <LazyLoadImage
                  objectFit={"cover"}
                  src={banner3}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            ) : (
              <Box
                w={"100%"}
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"

              >
                <LazyLoadImage
                  objectFit={"cover"}
                  src={banner2}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            )} */}
            {/* {isInternational ? (
              <Box
                w={"100%"}
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"
              >
                <LazyLoadImage
                  objectFit={"cover"}
                  src={banner5}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            ) : (
              <Box
                w={"100%"}
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"
              >
                
                <LazyLoadImage
                  objectFit={"cover"}
                  src={banner4}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            )} */}

            {/* {users?.data?.userDetails?.StripeInvoice.length === 0 ||
            users?.data?.userDetails?.paymentsDetails.length === 0 ? (
              <Box>
                <Image src={free} />
              </Box>
            ) : null} */}
            {loginToken ? (
              users?.userDetails?.StripeInvoice.length !== 0 ||
              users?.userDetails?.paymentsDetails.length !==
                0 ? null : isInternational ? ( // <p>No carousel for logged-in users with StripeInvoice or paymentsDetails.</p> // Render a placeholder or message indicating no carousel
                <Box
                  w="100%"
                  mb={{ md: "11px", base: "9px" }}
                  onClick={() => handleClick()}
                  cursor="pointer"
                >
                  <LazyLoadImage
                    objectFit="cover"
                    src={banner5}
                    alt="hero-banner"
                    w="100%"
                    effect="blur"
                  />
                </Box>
              ) : (
                <Box
                  w="100%"
                  mb={{ md: "11px", base: "9px" }}
                  onClick={() => handleClick()}
                  cursor="pointer"
                >
                  <LazyLoadImage
                    objectFit="cover"
                    src={banner4}
                    alt="hero-banner"
                    w="100%"
                    effect="blur"
                  />
                </Box>
              )
            ) : isInternational ? (
              <Box
                w="100%"
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"
              >
                <LazyLoadImage
                  objectFit="cover"
                  src={banner5}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            ) : (
              <Box
                w="100%"
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"
              >
                <LazyLoadImage
                  objectFit="cover"
                  src={banner4}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            )}

            {loginToken && users?.userDetails?.totalSpending > 200 && (
              <Box onClick={() => handleClick()} w={"100%"} mb={{ md: "11px", base: "9px" }}>
                <LazyLoadImage
                  objectFit={"cover"}
                  src={totalSpendD}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            )}
            <Box w={"100%"} mb={{ md: "11px", base: "9px" }}>
              <LazyLoadImage
                objectFit={"cover"}
                src={banner6}
                alt="hero-banner"
                w="100%"
                effect="blur"
              />
            </Box>
            <Box w={"100%"} mb={{ md: "11px", base: "9px" }}>
              <LazyLoadImage
                objectFit={"cover"}
                src={banner7}
                alt="hero-banner"
                w="100%"
                effect="blur"
              />
            </Box>
          </OwlCarousel>
        </Container>
      </Container>
      <Stack
        id="hero-banner"
        spacing={2}
        direction="row"
        py={0}
        display={{ base: "flex", md: "none" }}
      >
        <OwlCarousel className="owl-theme" loop {...options}>
          <Box w={"100%"} mb={{ md: "11px", base: "9px" }}>
            <LazyLoadImage
              objectFit={"cover"}
              src={mobileBanner1}
              alt="hero-banner"
              width="100%"
              effect="blur"
            />
          </Box>
          {/* {isInternational ? (
            <Box
              w={"100%"}
              mb={{ md: "11px", base: "9px" }}
              onClick={() => handleClick()}
            >
              <LazyLoadImage
                objectFit={"cover"}
                src={mobileBanner7}
                alt="hero-banner"
                width="100%"
                effect="blur"
              />
            </Box>
          ) : (
            <Box
              w={"100%"}
              mb={{ md: "11px", base: "9px" }}
              onClick={() => handleClick()}
            >
              <LazyLoadImage
                objectFit={"cover"}
                src={mobileBanner5}
                alt="hero-banner"
                effect="blur"
                width="100%"
              />
            </Box>
          )} */}
          {loginToken ? (
            users?.userDetails?.StripeInvoice.length !== 0 ||
            users?.userDetails?.paymentsDetails.length !==
              0 ? null : isInternational ? ( // <p>No carousel for logged-in users with StripeInvoice or paymentsDetails.</p> // Render a placeholder or message indicating no carousel
              <Box
                w="100%"
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"
              >
                <LazyLoadImage
                  objectFit="cover"
                  src={mobileBanner6}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            ) : (
              <Box
                w="100%"
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"
              >
                <LazyLoadImage
                  objectFit="cover"
                  src={mobileBanner8}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            )
          ) : isInternational ? (
            <Box
              w="100%"
              mb={{ md: "11px", base: "9px" }}
              onClick={() => handleClick()}
              cursor="pointer"
            >
              <LazyLoadImage
                objectFit="cover"
                src={mobileBanner6}
                alt="hero-banner"
                w="100%"
                effect="blur"
              />
            </Box>
          ) : (
            <Box
              w="100%"
              mb={{ md: "11px", base: "9px" }}
              onClick={() => handleClick()}
              cursor="pointer"
            >
              <LazyLoadImage
                objectFit="cover"
                src={mobileBanner8}
                alt="hero-banner"
                w="100%"
                effect="blur"
              />
            </Box>
          )}

          {loginToken && users?.userDetails?.totalSpending > 200 && (
            <Box onClick={() => handleClick()} w={"100%"} mb={{ md: "11px", base: "9px" }}>
              <LazyLoadImage
                objectFit={"cover"}
                src={totalSpendM}
                alt="hero-banner"
                w="100%"
                effect="blur"
              />
            </Box>
          )}

          <Box w={"100%"} mb={{ md: "11px", base: "9px" }}>
            <LazyLoadImage
              objectFit={"cover"}
              src={mobileBanner4}
              alt="hero-banner"
              w="100%"
              effect="blur"
            />
          </Box>
          <Box w={"100%"} mb={{ md: "11px", base: "9px" }}>
            <LazyLoadImage
              objectFit={"cover"}
              src={mobileBanner3}
              alt="hero-banner"
              w="100%"
            />
          </Box>
        </OwlCarousel>
      </Stack>

      <Container maxW={"6xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 4, md: 14, lg: 16 }}
        ></Stack>
        <Img src={footerLine} alt="footer-line" />
      </Container>
    </>
  );
};

export default Hero;
