import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  // Heading,
  Input,
  Stack,
  useColorModeValue,
  // HStack,
  Avatar,
  // AvatarBadge,
  // IconButton,
  Center,
  Box,
  Spinner,
} from "@chakra-ui/react";
// import { SmallCloseIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { loginSystemAction } from "../../store/LoginSystemSlice";
import userRegisterImage from "../../assets/userProfileAvatar.jpg";
import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";

function sendPhoneNumberHandler(
  countryCode,
  phoneNumberWithoutCC,
  phoneNumberWithCC
) {
  if (countryCode === "+91") {
    return phoneNumberWithoutCC;
  } else {
    return phoneNumberWithCC;
  }
}
export default function UserProfileEdit() {
  const dispatch = useDispatch();
  const mobileNumberWithoutCC = useSelector(
    (state) => state.logIn.mobileNumberWithoutCC
  );
  const mobileNumberWithCC = useSelector((state) => state.logIn.mobileNumber);
  const countryCode = useSelector((state) => state.logIn.countryCode);
  const [errorMsg, setErrorMsg] = useState("");
  const [isInternational, setIsInternational] = useState();
  const [newUserEnteredFirstName, setNewUserEnteredFirstName] = useState("");
  const [newUserEnteredLastName, setNewUserEnteredLastName] = useState("");
  const [newUserEnteredEmail, setNewUserEnteredEmail] = useState("");
  const [newUserEnteredImage, setNewUserEnteredImage] =
    useState(userRegisterImage);
  const [newShowUserEnteredImage, setShowNewUserEnteredImage] = useState("");
  const host = localStorage.getItem("host");
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setNewUserEnteredImage(event.target.files[0]);
      setShowNewUserEnteredImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  function sendEmail(newUserEnteredEmail) {
    if (newUserEnteredEmail.trim() === "") {
      return undefined;
    } else {
      return newUserEnteredEmail;
    }
  }
  useEffect(() => {
    if (countryCode.startsWith("+91")) {
      //
      setIsInternational(false);
    } else {
      //
      setIsInternational(true);
    }
  }, [countryCode]);
  const [isLoading, setIsLoading] = useState(false);
  const registerNewUserHandler = () => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const registerNewUserUrl =
      host === "myguruji"
        ? "https://data.gurucool.life/api/v2/user/register"
        : "https://data.gurucool.life/api/v1/user/register";
    axios
      .post(
        registerNewUserUrl,
        {
          firstName: newUserEnteredFirstName,
          lastName: newUserEnteredLastName,
          email: sendEmail(newUserEnteredEmail),
          file: newUserEnteredImage,
          code: countryCode,
          phone: sendPhoneNumberHandler(
            countryCode,
            mobileNumberWithoutCC,
            mobileNumberWithCC
          ),
          isInternational,
          website: "gurucool.co",
        },
        config
      )
      .then((response) => {
        //
        if (response.data.success) {
          //
          localStorage.setItem("loginToken", response.data.token);
          dispatch(fetchLoggedInUserDetails(response.data.token));
          dispatch(loginSystemAction.setUserLoggedIn(true));
          // window.location.reload();
          // onClose();
          window.location.href = "/astrologers";
        } else {
          setErrorMsg(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMsg(error.response.data.message);
      });

    // set isLoading to true
    setIsLoading(true);

    // perform the registration logic here

    // simulate loading time
    setTimeout(() => {
      // set isLoading back to false
      setIsLoading(false);

      // handle success or error case here
    }, 2000);
  };

  return (
    <Flex
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        p={6}
        w="90%"
      >
        <Center fontSize={{ base: "sm", sm: "md" }} style={{ color: "red" }}>
          {errorMsg}
        </Center>
        <FormControl id="userName">
          <Stack direction={["column", "row"]} alignItems="center">
            <Center>
              <Avatar size="xl" src={newShowUserEnteredImage}></Avatar>
              {/* <img src={newUserEnteredImage}/> */}
            </Center>
            <Center w={{ base: "70%", md: "full" }}>
              <Button w="full">
                <input
                  type="file"
                  onChange={(e) => onImageChange(e)}
                  //   onChange={(event)=>setNewUserEnteredImage(event.target.files[0])}
                  className="filetype"
                  p={{ base: "0px 0px 0px 12px", md: "0px" }}
                  style={{ touchAction: "manipulation" }}
                />
              </Button>
            </Center>
          </Stack>
        </FormControl>
        <Flex
          align={{ base: "unset", md: "center" }}
          justify={{ base: "unset", md: "space-between" }}
          flexDirection={{ base: "column", md: "row" }}
          // px={{ base: , md: 0 }}
        >
          <FormControl id="firstName" mr={{ base: 1.5, md: 3 }} isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              onChange={(e) => setNewUserEnteredFirstName(e.target.value)}
              placeholder="First Name"
              _placeholder={{ color: "gray.500" }}
              type="text"
              style={{ touchAction: "manipulation" }}
            />
          </FormControl>
          <FormControl id="userName" isRequired>
            <FormLabel>Last name</FormLabel>
            <Input
              onChange={(e) => setNewUserEnteredLastName(e.target.value)}
              placeholder="Last Name"
              _placeholder={{ color: "gray.500" }}
              type="text"
              style={{ touchAction: "manipulation" }}
            />
          </FormControl>
        </Flex>
        <FormControl id="email" px={{ base: 0, md: 0 }} isRequired>
          <FormLabel>Email address</FormLabel>
          <Input
            onChange={(e) => setNewUserEnteredEmail(e.target.value)}
            placeholder="your-email@example.com"
            _placeholder={{ color: "gray.500" }}
            type="email"
            style={{ touchAction: "manipulation" }}
          />
        </FormControl>
        {/* </Flex> */}
        <Stack
          spacing={6}
          direction={["column", "row"]}
          // px={{ base: 12, md: 0 }}
          display="flex"
          justifyContent={"center"}
          w="100%"
        >
          <Box position="relative" w="100%">
            <Button
              onClick={() => registerNewUserHandler()}
              bg={"orange.400"}
              color={"white"}
              w="100%"
              _hover={{
                bg: "orange.500",
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.300"
                  color="white"
                  size="lg"
                  // mx={"10px"}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Flex>
  );
}
