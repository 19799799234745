import { useHMSActions, useRecordingStreaming } from "@100mslive/react-sdk";
import {
  Box,
  Flex,
  Avatar,
  Text,
  Button,
  Icon,
  HStack,
  WrapItem,
  VStack,
  Container,
  useMediaQuery,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Heading,
} from "@chakra-ui/react";
import pic from "../../assets/live-stream/pic.png";
import { IoClose, IoVolumeHighOutline } from "react-icons/io5";
import { MdVisibility, MdOutlineAdd, MdCallEnd, MdCall } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { selectPeers, useHMSStore } from "@100mslive/react-sdk";
import { useCallback, useEffect, useState } from "react";
import { P_CHANGE_CALLS_AVAILABILITY, P_LIVE_END_CALL } from "../../apiLinks";
import axios from "axios";
import Feedback from "./Feedback/Feedback";

function LiveHeader(props) {
  const hmsActions = useHMSActions();
  const peers = useHMSStore(selectPeers);
  const hasPaidViewer = peers.some((item) =>
    item.roleName.includes("paid-viewer")
  );
  const { isRTMPRunning } = useRecordingStreaming();
  const localUser = peers.filter((peer) => peer.isLocal);
  const localUserRole = localUser[0]?.roleName;
  const astroDetails = props.astroDetail;
  const [seconds, setSeconds] = useState(0);
  const [consultation, setConsultation] = useState();
  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const roomCode = astroDetails?.roomId;
  useEffect(() => {
    if (consultation) {
      setIsOpenModal1(true);
    } else {
      setIsOpenModal1(false);
    }
  }, [consultation]);
  // functions for timer during private call
  useEffect(() => {
    let interval;
    if (localUserRole === "paid-viewer" || localUserRole === "astrologer") {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [localUserRole]);

  const formataTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  /**
   * This function sends a post request to end a live call and sets a state variable to false.
   */
  async function handleLiveCallEndHandler() {
    // setCallOngoing(false);
    const loginToken = localStorage.getItem("loginToken");
    const callTicket = localStorage.getItem("callTicket");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      const response = await axios.post(
        P_LIVE_END_CALL,
        {
          callTicketId: callTicket,
        },
        config
      );

      setConsultation(response.data);
      if (response.status === 200) {
        localStorage.removeItem("callTicket");
        setIncomingPicked(false);
        setCountdown(0);
      }
    } catch (error) {}
  }

  const [isMediumScreen] = useMediaQuery("(min-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const goOfflineClickHandler = async () => {
    if (localUserRole === "astrologer") {
      try {
        const loginToken = localStorage.getItem("loginToken");
        const config = {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        };

        await axios.post(
          P_CHANGE_CALLS_AVAILABILITY,
          {
            liveStatus: false,
          },
          config
        );
        hmsActions.stopRTMPAndRecording();
        await hmsActions.endRoom(false);
        window.location.href = "/myProfile";
      } catch (error) {}
    } else {
      window.location.href = "/";
    }
  };

  const startRTMP = async () => {
    const params = {
      meetingURL: `https://tech-livestream-1440.app.100ms.live/preview/${roomCode}/beam?skip_preview=true`,
      rtmpURLs: [
        "rtmp://a.rtmp.youtube.com/live2/v0gg-x6a0-c4u1-uywg-dys1",
        "rtmps://live-api-s.facebook.com:443/rtmp/FB-209480848701586-1-AbwmCk5YmTgyCW3I",
      ],
      record: false,
    };
    try {
      await hmsActions.startRTMPOrRecording(params);
    } catch (err) {
      console.error("Failed to start RTMP/recording", err);
    }
  };

  const stopRTMP = async () => {
    try {
      await hmsActions.stopRTMPAndRecording();
    } catch (err) {
      console.error("Failed to stop RTMP/recording", err);
    }
  };

  const [countdown, setCountdown] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown + 1);
    }, 1000); // Update countdown every second

    return () => {
      clearInterval(interval); // Clean up the interval when component unmounts
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  useEffect(() => {
    setCountdown(0);
  }, [hasPaidViewer]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xs"} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign={"center"}>
              {localUserRole === "astrologer"
                ? "Are you sure You want to go offline from live?"
                : "Are you sure you want to exit from live?"}
            </Box>
          </ModalBody>
          <ModalFooter justifyContent={"center"} p={0} mt={{ md: "30px" }}>
            <Button
              onClick={onClose}
              w={"100%"}
              borderTop={"1px solid #DDDDDD"}
              borderRadius={"0 0 0 6px"}
              _hover={{
                bg: "#38A169",
                color: "white",
              }}
            >
              No
            </Button>
            <Button
              onClick={goOfflineClickHandler}
              w={"100%"}
              borderRadius={"0 0 6px 0"}
              borderTop={"1px solid #DDDDDD"}
              borderRight={"1px solid #DDDDDD"}
              _hover={{
                bg: "#E53E3E",
                color: "white",
              }}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenModal1}
        onClose={() => setIsOpenModal1(false)}
        size={"xs"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Heading fontSize={"20px"}>Consultation Details</Heading>
            <Box>
              <Text mb={0}>
                Consultation Type: : {consultation?.purchase?.consultationType}
              </Text>
              <Text mb={0}>Astro: {consultation?.purchase?.guruName}</Text>
              <Text mb={0}>Time Duration: {consultation?.minutes}</Text>
              <Text mb={0}>
                Consultation Amount:{" "}
                {Math.round(consultation?.purchase?.amount)}
              </Text>
            </Box>
            <Box mt={{ base: 2 }}>
              <Feedback
                userId={consultation?.purchase?.user}
                astroId={consultation?.purchase?.guru}
                purchaseId={consultation?.purchase?._id}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Container
        maxW={"6xl"}
        position={{ base: "absolute", md: "unset" }}
        top={0}
        zIndex={"9999"}
        display={"flex"}
        flexDirection={"column"}
      >
        {/* user live header */}
        <HStack
          w={"100%"}
          justify={"space-between"}
          pt={{ base: "8px" }}
          // px={{ base: "20px" }}
          display={{
            base: localUserRole === "astrologer" ? "none" : "flex",
            md: localUserRole === "astrologer" ? "none" : "flex",
          }}
          // spacing={{base: "unset", md: "29px"}}
        >
          {/* <Icon
            onClick={onOpen}
            as={IoClose}
            boxSize={23}
            color={{ base: "white", md: "gray" }}
            // mr={{ md: "28px", base: "unset" }}
            cursor={"pointer"}
          /> */}

          <HStack
            bg={{ base: "rgba(0, 0, 0, 0.3)", md: "transparent" }}
            p={{ base: "3px" }}
            borderRadius={{ base: "38px" }}
            mr={{ md: "48px", base: "unset" }}
          >
            <WrapItem
              cursor={"pointer"}
              onClick={() =>
                (window.location.href = `/astrologer/${astroDetails?.user?.firstName}${astroDetails?.user?.lastName}?id=${astroDetails?.user?.guru}`)
              }
            >
              <Avatar
                name="Astrologer photo"
                src={astroDetails?.user?.avatar?.url}
                size={{ base: "sm", md: "lg" }}
              />
            </WrapItem>
            <Box
              justify={"center"}
              ml={{ md: "16px", base: "unset" }}
              mr={"48px"}
              p={"5px"}
            >
              <Text
                cursor={"pointer"}
                onClick={() =>
                  (window.location.href = `/astrologer/${astroDetails?.user?.firstName}${astroDetails?.user?.lastName}?id=${astroDetails?.user?.guru}`)
                }
                fontSize={{ base: "12px", md: "22px" }}
                fontWeight={"500"}
                color={{ base: "white", md: "black" }}
                mb={0}
              >
                {astroDetails?.user?.firstName} {astroDetails?.user?.lastName}
              </Text>
              <Flex align={"center"}>
                <Icon
                  as={MdVisibility}
                  boxSize={{ base: "12px", md: "20px" }}
                  color={{ base: "white", md: "gray" }}
                />
                <Text
                  ml={{ base: "7px" }}
                  fontSize={{ base: "10px", md: "16px" }}
                  fontWeight={"600"}
                  color={{ base: "white", md: "gray" }}
                  mb={0}
                >
                  {peers?.length}
                </Text>
              </Flex>
            </Box>
            {/* <Button
              colorScheme="orange"
              borderRadius={{ base: "50%", md: "4px" }}
              p={{ base: "8px" }}
              // ml={{ md: "48px", base: "unset" }}
              display={{ md: "none", base: "inline-flex" }}
            >
              {isMediumScreen ? (
                <Text fontSize={"16px"} mb={0}>
                  Follow
                </Text>
              ) : (
                <Icon as={MdOutlineAdd} boxSize={"17px"} color={"white"} />
              )}
            </Button> */}
          </HStack>

          <Button
            background={"rgba(0, 0, 0, 0.3)"}
            variant={"outline"}
            colorScheme="red"
            py={{ base: 2 }}
            onClick={onOpen}
            color={"white"}
          >
            Exit Live
          </Button>

          {/* <HStack>
            <Button
              colorScheme="orange"
              borderRadius={{ base: "50%", md: "4px" }}
              p={{ base: "8px" }}
              mx={{ md: "48px", base: "unset" }}
              display={{ md: "inline-flex", base: "none" }}
            >
              Follow
            </Button> */}
            {/* <Icon
              as={BsThreeDotsVertical}
              boxSize={23}
              color={{ base: "white", md: "gray" }}
              ml={4}
            /> */}
          {/* </HStack> */}
        </HStack>
        {/*  */}
        {/* <ShowIncomingCallPrompt/> */}
        {/* Astrologer Live Header */}
        <HStack
          justify={"space-between"}
          align={"center"}
          pt={{ base: "8px" }}
          mb={{ base: 0, md: 2 }}
          display={localUserRole === "astrologer" ? "flex" : "none"}
        >
          <HStack
            p={"6px 10px"}
            bg={"rgba(255, 255, 255, 0.3)"}
            borderRadius={"6px"}
          >
            <Icon
              as={MdVisibility}
              boxSize={"12px"}
              color={{ base: "white", md: "black" }}
            />
            <Text
              ml={{ base: "7px" }}
              fontSize={{ base: "10px" }}
              fontWeight={"600"}
              color={{ base: "white", md: "black" }}
              mb={0}
            >
              {peers.length}
            </Text>
          </HStack>
          {/* <Icon
            as={IoClose}
            boxSize={23}
            color={{ base: "white", md: "red" }}
            // onClick={() => handleLeaveLive()}
            onClick={onOpen}
            cursor={"pointer"}
          /> */}
          <Button
            background={"rgba(0, 0, 0, 0.3)"}
            py={{ base: 2 }}
            color={"white"}
            variant={"outline"}
            colorScheme="red"
            onClick={onOpen}
          >
            End Live
          </Button>
          {/* <HStack>
            {isRTMPRunning ? (
              <Button
                variant={"solid"}
                bg={"rgba(255, 255, 255, 0.7)"}
                color={"red"}
                onClick={stopRTMP}
              >
                Stop
              </Button>
            ) : (
              <Button
                variant={"solid"}
                bg={"rgba(255, 255, 255, 0.7)"}
                color={"Green"}
                onClick={startRTMP}
              >
                Start
              </Button>
            )}
          </HStack> */}
        </HStack>
      </Container>

      {/* Stack for call timer */}
      <HStack
        p={"8px 12px"}
        bg={"rgba(255, 177, 120, 0.5)"}
        borderRadius={"12px"}
        position={"absolute"}
        top={{ base: "10%", lg: "18%" }}
        left={{ base: "5%", lg: "20%" }}
        zIndex={"1000"}
        display={localUserRole === "paid-viewer" ? "flex" : "none"}
      >
        <Icon as={IoVolumeHighOutline} boxSize={23} color={"white"} />
        <Text fontSize={"18px"} fontWeight={"500"} color={"#FFFFFF"}>
          {formatTime(countdown)}
        </Text>
        <Button
          colorScheme="whiteAlpha"
          borderRadius={"50%"}
          p={{ base: "8px" }}
          onClick={() => {
            handleLiveCallEndHandler();
          }}
        >
          <Icon as={MdCallEnd} boxSize={"17px"} color={"red"} />
        </Button>
      </HStack>
    </>
  );
}

export default LiveHeader;
