import React, { useEffect } from "react";
import {
  Container,
  Box,
  Heading,
  Image,
  Text,
  Divider,
  Flex,
} from "@chakra-ui/react";
import logo from "../../assets/roundLogo.png";
import gurujilogo from "../../assets/gurucoolLogo/512x512.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const PrivacyPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
    <Helmet>
        <title>Gurucool || Privacy Policy</title>
        <meta
            name="description"
            content="GuruCool prioritizes your privacy. Read our comprehensive privacy policy to understand how we collect, use, and protect your personal information. Your data security is our top concern."
          />
      </Helmet>
      <Container maxW={"6xl"}>
        <Box m={{ md: "70px 0 72px", base: "40px 0 55px" }} textAlign="justify">
          <Flex justifyContent={"center"}>
            <Flex>
              <Image
                src={gurujilogo}
                alt="Gurucool logo"
                width={{ md: "54px", base: "40px" }}
                height={{ md: "54px", base: "40px" }}
                borderRadius="5px"
                mr={"15px"}
              ></Image>
              <Heading
                fontSize={{ md: "36px", base: "24px" }}
                fontWeight={700}
                mb={{ md: "70px", base: "40px" }}
                textAlign="center"
              >
                Privacy Policy
              </Heading>
            </Flex>
          </Flex>
          <Divider
            border="1px"
            borderColor="gray.300"
            width={{ md: "6xl", base: "full" }}
            mb={{ md: "35px", base: "24px" }}
          />
          <Box mb={{ md: "16px", base: "14px" }}>
            <Text>
              At GuruCool, we understand the importance of your privacy and are
              committed to protecting it. This privacy policy explains how we
              collect, use, and disclose your personal information when you use
              our website, mobile application, or any other service we offer
              (collectively, the "Services").
            </Text>
            <Text>
              This Privacy Policy is published in accordance with Rule 3(1) of
              the Information Technology (Intermediaries Guidelines) Rules, 2011
              and Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules, 2011
              which requires publishing of the Privacy policy for collection,
              use, storage, and transfer of sensitive personal data or
              information.{" "}
            </Text>
          </Box>

          <Heading
            fontSize={{ md: "28px", base: "20px" }}
            fontWeight={500}
            m={{ md: "20px 0 8px 0", base: "16px 0 8px 0" }}
          >
            I. Collection of Information
          </Heading>

          <Box mb={{ md: "16px", base: "14px" }}>
            <Text>
              We may collect personal information, such as your name, email
              address, phone number, and payment information, when you use our
              Services. We may also collect non-personal information, such as
              your device information, browser type, and IP address.
            </Text>
          </Box>

          <Heading
            fontSize={{ md: "28px", base: "20px" }}
            fontWeight={500}
            m={{ md: "20px 0 8px 0", base: "16px 0 8px 0" }}
          >
            II. Use of Information
          </Heading>

          <Box mb={{ md: "16px", base: "14px" }}>
            <Text>
              We use your personal information to provide you with the Services,
              process your payments, communicate with you, and improve our
              Services. We may also use your information for research and
              analysis purposes.
            </Text>
          </Box>

          <Heading
            fontSize={{ md: "28px", base: "20px" }}
            fontWeight={500}
            m={{ md: "20px 0 8px 0", base: "16px 0 8px 0" }}
          >
            III.Disclosure of Information
          </Heading>

          <Box mb={{ md: "16px", base: "14px" }}>
            <Text>
              We do not sell or rent your personal information to third parties.
              We may disclose your personal information to our trusted service
              providers who assist us in providing the Services. We may also
              disclose your information to comply with legal obligations,
              protect our rights and property, and prevent fraud or other
              illegal activities.
            </Text>
          </Box>

          <Heading
            fontSize={{ md: "28px", base: "20px" }}
            fontWeight={500}
            m={{ md: "20px 0 8px 0", base: "16px 0 8px 0" }}
          >
            IV. Security of Information
          </Heading>

          <Box mb={{ md: "16px", base: "14px" }}>
            <Text>
              We take appropriate measures to protect your personal information
              from unauthorized access, disclosure, alteration, or destruction.
              However, no method of transmission over the internet or electronic
              storage is completely secure. Therefore, we cannot guarantee
              absolute security.
            </Text>
          </Box>

          <Heading
            fontSize={{ md: "28px", base: "20px" }}
            fontWeight={500}
            m={{ md: "20px 0 8px 0", base: "16px 0 8px 0" }}
          >
            V. Changes to Privacy Policy
          </Heading>

          <Box mb={{ md: "16px", base: "14px" }}>
            <Text>
              We may update this privacy policy from time to time. We will
              notify you of any changes by posting the new privacy policy on our
              website. You are advised to review this policy periodically for
              any changes.
            </Text>
          </Box>
          <Heading
            fontSize={{ md: "28px", base: "20px" }}
            fontWeight={500}
            m={{ md: "20px 0 8px 0", base: "16px 0 8px 0" }}
          >
            V. Contact Us
          </Heading>

          <Box mb={{ md: "16px", base: "14px" }}>
            <Text>
              If you have any questions or concerns about our privacy policy,
              please contact us at hi@gurucool.life
            </Text>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPage;
