import React, { useEffect } from "react"; // , { useState }
import "../../../App.css";
import {
  Box,
  Button,
  // Container,
  Flex,
  HStack,
  // Heading,
  Icon,
  Image,
  // SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

// import Masonry from "react-masonry-css";
// import AstroCard from "./AstroCard";
// import Astro_1 from "../../../assets/Astro_1.webp";
import Star from "../../../assets/Star.png";
import Live_Btn from "../../../assets/Live_Btn.png";
import Live_offline from "../../../assets/Live_offline.png";
import TickTrusted from "../../../assets/TickTrusted.svg";
// import Heading_star from "../../../assets/Heading_star.webp";
// import { GiBeveledStar } from "react-icons/gi";
// import { BsChatSquareDots } from "react-icons/bs";
import {
  //  IoCallOutline,
  IoCall,
} from "react-icons/io5";
import { MdPhoneDisabled } from "react-icons/md";

// import bgImg from "../../../assets/profileBg.png";
import { NavLink, useLocation } from "react-router-dom";
import {
  BsFillCameraVideoOffFill,
  // BsFillCameraVideoFill,
} from "react-icons/bs";
import card_bg from "../../../assets/card_bg.webp";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase";
import { useSelector } from "react-redux";

const AstroProfileCard = (props) => {
  // console.log("callavailability ",props.currElem);
  // console.log("Guru...",props.currElem);
 
  const guruToken = props?.currElem?.user?.guru;
  const isInternational = localStorage.getItem("isInternational") === "true";
  const loginToken = localStorage.getItem("loginToken");
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );
  // console.log(loggedInUserDetails);
  const location = useLocation();
  // const breakpointColumnsObj = {
  //   default: 3,
  //   1100: 3,
  //   700: 2,
  //   500: 1,
  // };
  // const columnWidth = 300;

  let leftIcon = null;

  let isDisabled = false;
  let image = null;

  if (props.currElem.callAvailability === "offline") {
    leftIcon = <Icon as={BsFillCameraVideoOffFill} color="gray.600" />;
    isDisabled = true;
    image = Live_offline;
  } else if (props.currElem.callAvailability === "busy") {
    leftIcon = <Icon as={BsFillCameraVideoOffFill} color="red" />;

    image = Live_Btn;
  } else if (props.currElem.callAvailability === "online") {
    leftIcon = <Icon as={BsFillCameraVideoOffFill} />;
    image = Live_Btn;
  }

  //

  // if (cardLength % 2 === 0) {
  //   setLastAstroCard(24)
  // }

  // useEffect(() => {
  //   const marginTop = () => {
  //     for (let index = 0; index < cardLength; index++) {
  //       // const element = array[index];
  //       setLastAstroCard("24px")
  //     }
  //   }

  //   marginTop();
  // }, [])

  //
  //
  const ZoomBox = motion(Box);

  // useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);

  function getPrice(isInternationalUser, userDetails) {
    if (isInternationalUser) {
      if (userDetails?.userDetails?.consultationCount === 0) {
        // todo return first price
        return props?.currElem?.firstOfferPrice?.international?.fee;
      } else {
        return props?.currElem?.internationalFee;
      }
    } else {
      if (userDetails?.userDetails?.consultationCount === 0) {
        // todo return first price
        return props?.currElem?.firstOfferPrice?.national?.fee;
      } else {
        return props?.currElem?.fee;
      }
    }
  }

  function getCuttedprice(isInternationalUser,userDetails){
    if (isInternationalUser) {
      if (userDetails?.userDetails?.consultationCount === 0) {
        // todo return first price
        return "$"+props?.currElem?.internationalFee+"/Min";
      } else {
        return "";
      }
    } else {
      if (userDetails?.userDetails?.consultationCount === 0) {
        // todo return first price
        return  "₹"+props?.currElem?.fee+"/Min";
      } else {
        return "";
      }
    }

  }
  return (
    <ZoomBox>
      {/* <Container maxW={"6xl"}> */}
      {/* <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ md: "56px", base: "16px" }}
        >
          <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" />
          <Text
            fontSize={{ base: "18px", md: "32px" }}
            fontWeight={"700"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
          >
            Premium Astrologers
          </Text>
          <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" />
        </Box> */}
      {/* <Flex
          gap={{ lg: 0, md: "24px", base: 0 }}
          flexWrap={{ md: "wrap", base: "nowrap" }}
          justifyContent={{ md: "center", base: "flex-start" }}
          borderRadius={"7px"}
          overflowX={{ md: "unset", base: "auto" }}
        > */}
      {/* {cards.map((card, index) => ( */}

      <Box
        onClick={() => {
          logEvent(analytics, "open_astrologer_card");
        }}
        // data-aos={
        //   location.pathname === "/consult-astrologers" ? undefined : "zoom-in"
        // }
        id="astro-card"
        key={props.currElem.user.guru}
        // bg={
        //   "radial-gradient(69.29% 49.88% at 102.32% 101.97%, rgba(0, 0, 0, 0.7) 12.59%, rgba(0, 0, 0, 0.372048) 45.56%, rgba(0, 0, 0, 0) 100%)"
        // }
        borderRadius={"7px"}
        w={{ lg: "346px", md: "330px", base: "240px" }}
        h={{ md: "461px", base: "307px" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        // background={"radial-gradient(circle, rgba(255,146,42,1) 25%, rgba(163,53,6,1) 69%)"}
        // transition="transform 0.3s ease-in-out"

        _hover={{
          backgroundColor: "orange.200",
          // transform: "scale(1.1)",
        }}
        sx={{
          // backgroundImage: `url(${props.currElem.user.avatar.url}),  url(${card_bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          height: { base: "307px", md: "461px" },
          transition: "all 0.3s ease-in-out",
          _hover: {
            backgroundSize: "150%",
            transition: "all 0.5s ease-in-out",
          },
        }}
        style={{
          backgroundImage: `url(${props?.currElem?.user?.avatar?.url}), url(${card_bg})`,

          // Add styles for scrolling
          scrollSnapAlign: "start",
          scrollSnapStop: "always",
        }}
        // bgSize="cover"
        // bgImage={`url(${Astro_1})`}
        // position={"relative"}
        mt={
          location.pathname === "/astrologers"
            ? 0
            : {
                lg:
                  props.index % 3 === 1 ||
                  (props.index % 3 === 1 && props.length - 1 === props.index) ||
                  (props.index % 3 === 0 && props.length - 2 === props.index) ||
                  (props.index % 3 === 0 && props.length - 1 === props.index)
                    ? "24px"
                    : 0,
                md: 0,
                base: 0,
              }
        }
        mr={
          location.pathname === "/astrologers"
            ? 0
            : { base: props.index === props.length - 1 ? 0 : "16px", md: 0 }
        }
        mx={{
          lg: props.index % 3 === 1 ? "24px" : 0,
        }}
        position={"relative"}
      >
        {/* <Box
          position={"absolute"}
          bg={
            "linear-gradient(180deg, rgba(8,1,1,0.06486344537815125) 0%, rgba(0,0,0,0.8295693277310925) 100%)"
          }
          borderRadius={"7px"}
          w={{ lg: "346px", md: "330px", base: "240px" }}
          h={{ md: "461px", base: "307px" }}
          backgroundColor={"orange"}
          zIndex={-1}
        ></Box> */}
        <HStack
          justify={"space-between"}
          align={"center"}
          p={{ md: "19px", base: "14px" }}
          w={{ md: "100%", base: "240px" }}
        >
          <Text
            fontSize={{ md: "16px", base: "12px" }}
            fontWeight={"600"}
            color={"#FFFFFF"}
            textShadow={
              "0px 0.9px 4.94286px rgba(0, 0, 0, 26.3), 0px 4.94286px 4.94286px rgba(0, 0, 0, 3.5)"
            }
            marginBottom={0}
          >
            {props.currElem.experience} Years
          </Text>
          <HStack
            p={{ md: "7px 9px", base: "5px 7px" }}
            bg="rgba(0, 0, 0, 0.5)"
            borderRadius={"6px"}
          >
            <Box
              boxSize={{ md: "10px", base: "8px" }}
              bg={
                (props.currElem.callAvailability === "offline" && "gray") ||
                (props.currElem.callAvailability === "online" && "green.400") ||
                (props.currElem.callAvailability === "busy" && "red")
              }
              borderRadius={"50%"}
            ></Box>
            <Text
              fontSize={{ md: "14px", base: "11px" }}
              fontWeight={"700"}
              ml={{ md: "10px", base: "8px" }}
              color={"#FFFFFF"}
              marginBottom={0}
            >
              {props.currElem.callAvailability === "offline"
                ? "Offline"
                : props.currElem.callAvailability === "online"
                ? "Online"
                : props.currElem.callAvailability === "busy"
                ? "Busy"
                : "Online"}
            </Text>
          </HStack>
        </HStack>
        <Flex
          justify={"space-between"}
          align={"end"}
          mt={{ md: 12, base: 6 }}
          px={{ md: "10px", base: "7px" }}
          pt={{ md: "100px", base: "55px" }}
        >
          <VStack w={"fit-content"} align={"start"} zIndex={"1000"}>
            <HStack align={"center"}>
              <Box boxSize={{ md: "15px", base: "11px" }}>
                <LazyLoadImage
                  src={Star}
                  alt="rating"
                  objectFit="cover"
                  boxSize={"100%"}
                />
              </Box>
              <Text
                fontSize={{ md: "16px", base: "12px" }}
                fontWeight={"600"}
                color={"white"}
                textShadow={
                  "0px 4.94286px 4.94286px rgba(0, 0, 0, 0.3), 0px 4.94286px 4.94286px rgba(0, 0, 0, 0.25)"
                }
                marginBottom={0}
              >
                {props.currElem.rating}{" "}
              </Text>
              <Flex
                gap="10px"
                display={props?.currElem?.mostTrusted ? "flex" : "none" }
              >
                <Text
                  as="span"
                  fontSize={{ md: "16px", base: "12px" }}
                  fontWeight={"600"}
                  color={"white"}
                  textShadow={
                    "0px 4.94286px 4.94286px rgba(0, 0, 0, 0.3), 0px 4.94286px 4.94286px rgba(0, 0, 0, 0.25)"
                  }
                  marginBottom={0}
                >
                  {" "}
                  |{" "}
                </Text>
                <Image src={TickTrusted} />{" "}
                <Text
                  as="span"
                  fontSize={{ md: "16px", base: "12px" }}
                  fontWeight={"600"}
                  color={"white"}
                  textShadow={
                    "0px 4.94286px 4.94286px rgba(0, 0, 0, 0.3), 0px 4.94286px 4.94286px rgba(0, 0, 0, 0.25)"
                  }
                  marginBottom={0}
                >
                  {" "}
                  Most Trusted
                </Text>
              </Flex>
            </HStack>
            <NavLink
              // to={`/astrologer/${props.currElem.user.firstName}${props.currElem.user.lastName}?id=${guruToken}`}
              to={`/astrologer/${props.currElem?.userName}`}
            >
              <Text
                fontSize={{ md: "22px", base: "18px" }}
                fontWeight={"600"}
                color={"white"}
                textShadow={
                  "0px 4.94286px 4.94286px rgba(0, 0, 0, 0.3), 0px 4.94286px 4.94286px rgba(0, 0, 0, 0.25)"
                }
                marginBottom={0}
              >
                {props.currElem.user.firstName} {props.currElem.user.lastName}
              </Text>
            </NavLink>
            <Box>
              <Text
                fontSize={{ md: "14px", base: "11px" }}
                fontWeight={"400"}
                color={"#FFDBC0"}
                marginBottom={0}
              >
                Specialities
              </Text>
              <Text
                fontSize={{ md: "16px", base: "13px" }}
                fontWeight={"500"}
                color={"white"}
                textShadow={
                  "0px 4.94286px 4.94286px rgba(0, 0, 0, 0.3), 0px 4.94286px 4.94286px rgba(0, 0, 0, 0.25)"
                }
                marginBottom={0}
              >
                {props.currElem.specialization
                  .slice(0, 3)
                  .map((curr, index) => {
                    const isLast =
                      index === 2 ||
                      index === props.currElem.specialization.length - 1;
                    return `${curr}${isLast ? "" : ", "}`;
                  })}
                {props.currElem.specialization.length > 3 && "..."}
              </Text>
            </Box>
            {/* <Box display={{base: "none", md: "block", lg: "block"}}>
              <Text
                fontSize={{ md: "14px", base: "11px" }}
                fontWeight={"400"}
                color={"#FFDBC0"}
                textShadow={
                  "0px 4.94286px 4.94286px rgba(0, 0, 0, 0.3), 0px 4.94286px 4.94286px rgba(0, 0, 0, 0.25)"
                }
                marginBottom={0}
              >
                Skills
              </Text>
              <Text
                fontSize={{ md: "16px", base: "13px" }}
                fontWeight={"500"}
                color={"white"}
                textShadow={
                  "0px 4.94286px 4.94286px rgba(0, 0, 0, 0.3), 0px 4.94286px 4.94286px rgba(0, 0, 0, 0.25)"
                }
                marginBottom={0}
              >
                Vedic Astrology, Kundli
              </Text>
            </Box> */}
          </VStack>
          {/* not logged in journey */}
          {loginToken ? (
            /* for logged insss */
            <VStack
              display={loginToken ? "flex" : "none"}
              w={"fit-content"}
              align={"center"}
              zIndex={1000}
            >
              <Text
                fontSize={{ md: "14px", base: "10px" }}
                fontWeight={"500"}
                color={"#5CE449"}
                marginBottom={0}
              >
                Price
              </Text>
              {/* <Text
                fontSize={{ md: "30px", base: "22px" }}
                fontWeight={"600"}
                color={"#5CE449"}
                mt={"5x !important"}
                marginBottom={0}
                lineHeight={"1 !important"}
                display={
                  loggedInUserDetails?.userDetails?.consultationCount === 0
                    ? "flex"
                    : "none"
                }
              >
                Free
              </Text> */}
              <HStack>
                <Text
                  fontSize={{ md: "20px", base: "15px" }}
                  fontWeight={"600"}
                  color={"#5CE449"}
                  mt={"0 !important"}
                  lineHeight={"1"}
                  marginBottom={0}
                  // style={{
                  //   textDecoration:
                  //     loggedInUserDetails?.userDetails?.consultationCount === 0
                  //       ? "line-through"
                  //       : "none",
                  // }}
                >
                 
                  <Text fontSize={{base:"17px",md:"20px"}}>
                  {isInternational
                    ? "$" + getPrice(true, loggedInUserDetails)
                    : "₹" + getPrice(false, loggedInUserDetails)}{" "}
                  /Min
                  </Text>
                  <br/>
                  <Text textDecoration={"line-through"} fontSize={{base:"13px",sm:"15px"}} fontWeight={"500"} color={"#B4B4B4"} textAlign={"center"}>
                  {isInternational
                    ?  getCuttedprice(true, loggedInUserDetails)
                    : getCuttedprice(false, loggedInUserDetails)}{" "}
                  </Text>
                </Text>
                {/* <Text>12/Min</Text> */}
              </HStack>
            </VStack>
          ) : (
            <VStack
              display={!loginToken ? "flex" : "none"}
              w={"fit-content"}
              align={"center"}
              zIndex={1000}
            >
              <Text
                fontSize={{ md: "14px", base: "10px" }}
                fontWeight={"500"}
                color={"#5CE449"}
                marginBottom={0}
              >
                Price
              </Text>
              <Text
                fontSize={{ md: "30px", base: "22px" }}
                fontWeight={"600"}
                color={"#5CE449"}
                mt={"5x !important"}
                marginBottom={0}
                lineHeight={"1 !important"}
              >
                Free
              </Text>
              <HStack>
                <Text
                  fontSize={{ md: "20px", base: "15px" }}
                  fontWeight={"600"}
                  color={"#5CE449"}
                  mt={"0 !important"}
                  lineHeight={"1"}
                  marginBottom={0}
                  style={{ textDecoration: "line-through" }}
                >
                  {isInternational
                    ? "$" + props?.currElem?.internationalFee
                    : "₹" + props?.currElem?.fee}
                  /Min
                </Text>
              </HStack>
            </VStack>
          )}
        </Flex>

        <Flex w={"100%"} position={"relative"} zIndex={1000}>
          {props.currElem.callAvailability === "offline" && (
            <Button
              leftIcon={leftIcon}
              // colorScheme="gray"
              bg="#d9d9d9"
              variant="solid"
              w={"100%"}
              h={{ md: "52px", base: "40px" }}
              borderRadius={"0 0 0 5px"}
              borderRight={"3px solid white"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={"500"}
              _hover={{
                cursor: "no-drop",
              }}
            >
              Offline
            </Button>
          )}
          {props.currElem.callAvailability === "busy" && (
            <Button
              leftIcon={leftIcon}
              // bg="gray.400"
              bg="#d9d9d9"
              variant="solid"
              w={"100%"}
              h={{ md: "52px", base: "40px" }}
              borderRadius={"0 0 0 5px"}
              borderRight={"3px solid white"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={"500"}
              _hover={{
                cursor: "no-drop",
              }}
            >
              Busy
            </Button>
          )}
          {props.currElem.callAvailability === "queue" && (
            <Button
            leftIcon={leftIcon}
            // colorScheme="gray"
            bg="#d9d9d9"
            variant="solid"
            w={"100%"}
            h={{ md: "52px", base: "40px" }}
            borderRadius={"0 0 0 5px"}
            borderRight={"3px solid white"}
            fontSize={{ base: "14px", md: "18px" }}
            fontWeight={"500"}
            // isDisabled
            style={{ cursor: "no-drop" }}
            opacity={0.9}
          >
            Pvt. Live
          </Button>
          )}
          {props.currElem.callAvailability === "online" && (
            <Button
              leftIcon={leftIcon}
              // colorScheme="gray"
              bg="#d9d9d9"
              variant="solid"
              w={"100%"}
              h={{ md: "52px", base: "40px" }}
              borderRadius={"0 0 0 5px"}
              borderRight={"3px solid white"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={"500"}
              // isDisabled
              style={{ cursor: "no-drop" }}
              opacity={0.9}
            >
              Pvt. Live
            </Button>
          )}
          {props.currElem.callAvailability === "offline" && (
            <Button
              leftIcon={<MdPhoneDisabled />}
              // colorScheme="gray"
              bg="#d9d9d9"
              variant="solid"
              w={"100%"}
              h={{ md: "52px", base: "40px" }}
              borderRadius={"0 0 5px 0"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={"500"}
              _hover={{
                cursor: "no-drop",
              }}
            >
              Offline
            </Button>
          )}
          {props.currElem.callAvailability === "busy" && (
            <Button
              // leftIcon={<MdPhoneDisabled color="red" />}
              // bg="gray.400"
              bg="#d9d9d9"
              // colorScheme="green"
              variant="solid"
              w={"100%"}
              h={{ md: "52px", base: "40px" }}
              borderRadius={"0 0 5px 0"}
              // border={"1px solid red"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={"500"}
              _hover={{
                cursor: "no-drop",
              }}
              // onClick={() => {
              //   props.callClicked();
              //   localStorage.setItem("guruToken", props.currElem.user.guru);
              // }}
            >
              Busy
            </Button>
          )}
          {props.currElem.callAvailability === "queue" &&(loggedInUserDetails?.userDetails?.isInQueue?<Button
              // leftIcon={leftIcon}
              // bg="gray.400"
              // isDisabled={loggedInUserDetails?.userDetails?.isInQueue}
              bg={"#FF4C00"}
              color={"white"}
              _hover={{
                bg: "#f7692d",
              }}
              variant="solid"
              w={"100%"}
              h={{ md: "52px", base: "40px" }}
              borderRadius={"0 0 5px 0"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={"500"}
              // _hover={{
              //   cursor: "no-drop",
              // }}
              onClick={() => {
                window.location.href = "/callconsultationstarted";
              }}
            >
              Queue
            </Button>: (
           props?.currElem?.callQueue.length>=5? <Button
           // leftIcon={leftIcon}
           // bg="gray.400"
           isDisabled={true}
           bg={"#FF4C00"}
           color={"white"}
           _hover={{
             bg: "#f7692d",
           }}
           variant="solid"
           w={"100%"}
           h={{ md: "52px", base: "40px" }}
           borderRadius={"0 0 5px 0"}
           fontSize={{ base: "14px", md: "18px" }}
           fontWeight={"500"}
           // _hover={{
           //   cursor: "no-drop",
           // }}
         
         >
           Busy
         </Button> :<Button
           // leftIcon={leftIcon}
           // bg="gray.400"
           // isDisabled={loggedInUserDetails?.userDetails?.isInQueue}
           bg={"#FF4C00"}
           color={"white"}
           _hover={{
             bg: "#f7692d",
           }}
           variant="solid"
           w={"100%"}
           h={{ md: "52px", base: "40px" }}
           borderRadius={"0 0 5px 0"}
           fontSize={{ base: "14px", md: "18px" }}
           fontWeight={"500"}
           // _hover={{
           //   cursor: "no-drop",
           // }}
           onClick={() => {
             props.callClicked();
             localStorage.setItem("guruToken", props.currElem.user.guru);
           }}
         >
           Join Queue
         </Button>
          ))}
          {props.currElem.callAvailability === "online" && (
            <Button
              leftIcon={<IoCall />}
              // colorScheme="orange"
              bg={"#FF4C00"}
              color={"white"}
              _hover={{
                bg: "#f7692d",
              }}
              variant="solid"
              w={"100%"}
              h={{ md: "52px", base: "40px" }}
              borderRadius={"0 0 5px 0"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={"500"}
              onClick={() => {
                props.callClicked();
                localStorage.setItem("guruToken", props.currElem.user.guru);
              }}
            >
              Call
            </Button>
          )}
          {props.currElem.liveStatus ? (
            <>
              <Box
                boxSize={{ md: "80px", base: "60px" }}
                position={"absolute"}
                top={"-35%"}
                left={"50%"}
                transform={"translate(-50%, -25%)"}
                onClick={() => {
                  window.location.href = `/live/${props.currElem.user.firstName}${props.currElem.user.lastName}?id=${props.currElem.user.guru}`;
                  logEvent(analytics, "live_enter");
                }}
                cursor={"pointer"}
                // isDisabled
                zIndex={1}
              >
                <Image
                  src={Live_Btn}
                  boxSize={"100%"}
                  objectFit={"cover"}
                  alt="live-btn"
                />
              </Box>
              <Box
                position={"absolute"}
                top={"21%"}
                left={"50%"}
                transform={"translate(-50%, -25%)"}
                width={{ md: "76px", base: "60px" }}
                height={{ md: "38px", base: "30px" }}
                bg="gray"
                border={"3px solid white"}
                borderTop={0}
                style={{
                  borderBottomLeftRadius: "40px",
                  borderBottomRightRadius: "40px",
                }}
              />
            </>
          ) : (
            <>
              <Box
                boxSize={{ md: "80px", base: "60px" }}
                position={"absolute"}
                top={"-35%"}
                left={"50%"}
                transform={"translate(-50%, -25%)"}
                // onClick={() => {
                //   window.location.href = `/live/${props.currElem.user.firstName}${props.currElem.user.lastName}?id=${guruToken}`;
                // }}
                style={{ cursor: "no-drop" }}
                isDisabled
                zIndex={1}
              >
                <Image
                  src={Live_offline}
                  boxSize={"100%"}
                  objectFit={"cover"}
                  alt="live-offline-btn"
                />
              </Box>
              <Box
                position={"absolute"}
                top={"21%"}
                left={"50%"}
                transform={"translate(-50%, -25%)"}
                width={{ md: "76px", base: "60px" }}
                height={{ md: "38px", base: "30px" }}
                bg="gray"
                border={"3px solid white"}
                borderTop={0}
                style={{
                  borderBottomLeftRadius: "40px",
                  borderBottomRightRadius: "40px",
                }}
              />
            </>
          )}
        </Flex>

        <NavLink
          to={`/astrologer/${props.currElem?.userName}`}
          style={{ position: "absolute" }}
          aria-label="View Astrologer Profile"
        >
          <Box
            // position={"absolute"}
            bg={
              " linear-gradient(180deg, rgba(2,0,36,0) 56%, rgba(0,0,0,0.7849264705882353) 78%, rgba(0,0,0,0.8941701680672269) 100%)"
            }
            borderRadius={"7px"}
            w={{ lg: "346px", md: "330px", base: "240px" }}
            h={{ md: "461px", base: "307px" }}
          ></Box>
        </NavLink>
      </Box>

      {/* ))} */}
      {/* <Box
          position={"absolute"}
          w={{ md: "346px" }}
          h={{ md: "461px" }}
          borderRadius={"7px"}
          zIndex={-1}
        >
          <Image
            borderRadius={"7px"}
            w="100%"
            h={"100%"}
            src={Astro_1}
            alt="Astro"
            objectFit="cover"
          />
        </Box> */}

      {/* 2 */}
      {/* </Flex> */}
      {/* </Container> */}
    </ZoomBox>
  );
};

export default AstroProfileCard;
