import {
  Container,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ConsultationCard from "./ConsultationCard";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import axios from "axios";
import PrimaryLoading from "../../Loaders/PrimaryLoading";
import { Spinner } from "react-bootstrap";

function ConsultationHistory() {
  const [data, setData] = useState();
  const consultationDetails = useSelector(
    (state) => state.loggedInUserDetails?.data?.guru?.consultations
  );
  const consultationCallData = consultationDetails?.filter(
    (item) => item.consultationType === "Call"
  );

  //
  const publicCallData = consultationDetails?.filter(
    (item) => item.consultationType === "public_live_Call"
  );
  // const loginToken = localStorage.getItem("loginToken");

  //   const [birthChartData, setBirthChartData] = useState();
  //   // useEffect(() => {
  //     const fetchBirthDetails = async () => {
  //       try {
  //         const response = await axios.get(
  //           https://data.gurucool.life/api/v1/user/getBirthDetails",
  //           {
  //             headers: {
  //               Authorization: `Bearer ${loginToken}`,
  //             },
  //           }
  //         );
  //         setBirthChartData(response);
  //
  // Save the response data in the state
  // setBirthdata(response);
  // setIsBirthDetailsId(response.data.success);
  // setBirthID(response.data.birthDetails._id);
  // setName(response.data.birthDetails.fullName);
  // setGender(response.data.birthDetails.gender);
  // setBirthPlace(response.data.birthDetails.birthPlace);
  // setBirthDate(response.data.birthDetails.birthDate);
  // setTime(response.data.birthDetails.birthTime);
  // setIsFormComplete(true);
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //   }
  // };

  //   fetchBirthDetails();
  // },

  const userDetails = useSelector((state) => state.loggedInUserDetails.data);
  console.log("userDetails", userDetails);

  useEffect(() => {
    if (userDetails?.user?.role === "guru") {
      const fetchData = async () => {
        try {
          const loginToken = localStorage.getItem("loginToken");

          const response = await axios.get(
            `https://data.gurucool.life/api/v1/guru/getThreeConsultation?guruId=${userDetails?.user?.guru}`,
            {
              headers: {
                Authorization: `Bearer ${loginToken}`,
              },
            }
          );

          setData(response?.data?.consultations?.docs);
          // Handle the response data
        } catch (error) {
          // Handle the error
          console.error(error);
        }
      };

      fetchData();
    }
  }, [userDetails]);

  //

  const consultationData = data?.filter((item) => item?.name);
  //

  const [currentDetailsPage, setCurrentDetailsPage] = useState(0);
  const [currentCallDetailsPage, setCurrentCallDetailsPage] = useState(0);
  const [currentPublicCallDetailsPage, setCurrentPublicCallDetailsPage] =
    useState(0);
  const itemsPerPage = 6;

  const handlePageChange = ({ selected }) => {
    setCurrentDetailsPage(selected);
  };
  const handleCallPageChange = ({ selected }) => {
    setCurrentCallDetailsPage(selected);
  };
  const handlePublicCallPageChange = ({ selected }) => {
    setCurrentPublicCallDetailsPage(selected);
  };

  const indexOfLastDetailsItem = (currentDetailsPage + 1) * itemsPerPage;
  const indexOfFirstDetailsItem = indexOfLastDetailsItem - itemsPerPage;

  const currentConsultationDetails = consultationDetails?.slice(
    indexOfFirstDetailsItem,
    indexOfLastDetailsItem
  );

  const indexOfLastCallDetailsItem =
    (currentCallDetailsPage + 1) * itemsPerPage;
  const indexOfFirstCallDetailsItem = indexOfLastCallDetailsItem - itemsPerPage;

  const currentCallConsultationDetails = consultationCallData?.slice(
    indexOfFirstCallDetailsItem,
    indexOfLastCallDetailsItem
  );

  const indexOfLastPublicCallDetailsItem =
    (currentPublicCallDetailsPage + 1) * itemsPerPage;
  const indexOfFirstPublicCallDetailsItem =
    indexOfLastPublicCallDetailsItem - itemsPerPage;

  const currentPublicCallConsultationDetails = publicCallData?.slice(
    indexOfFirstPublicCallDetailsItem,
    indexOfLastPublicCallDetailsItem
  );
  console.log("consultationData", consultationData);
  return (
    <Container
      maxW="1148px"
      bg="#FAFAFA"
      // border="2px solid orange"
      // borderBottom="none"
      // borderTopLeftRadius="20px"
      // borderTopRightRadius="20px"
    >
      {/* <Heading
        pt="32px"
        pb="32px"
        as="h1"
        color="#000"
        textAlign="center"
        fontSize="24px"
        fontFamily="poppins"
        fontStyle="normal"
        fontWeight="500"
        lineWeight="normal"
      >
        Consultation History
      </Heading> */}
      {/* <Button onClick={fetchBirthDetails}>fetchBirth</Button> */}
      {/* <Tabs variant="unstyled" align="center">
        <TabList>
          <Tab
            border="1px solid transparent"
            bg="#FFE1CA"
            color="#767676"
            borderTopLeftRadius="5px"
            borderBottomLeftRadius="5px"
            _selected={{ color: "white", bg: "#FF5E1A" }}
            w={{ md: "132px", base: "80px", sm: "80px" }}
            fontSize={{ md: "18px", base: "12px", sm: "12px" }}
            fontFamily={"poppins"}
            fontWeight={"500"}
          >
            All
          </Tab>
          <Tab
            border="1px solid transparent"
            bg="#FFE1CA"
            color="#767676"
            _selected={{ color: "white", bg: "#FF5E1A" }}
            w={{ md: "132px", base: "80px", sm: "80px" }}
            fontSize={{ md: "18px", base: "12px", sm: "12px" }}
            fontFamily={"poppins"}
            fontWeight={"500"}
          >
            Call
          </Tab>
          <Tab
            border="1px solid transparent"
            bg="#FFE1CA"
            color={"#767676"}
            borderTopRightRadius="5px"
            borderBottomRightRadius="5px"
            _selected={{ color: "white", bg: "#FF5E1A" }}
            w={{ md: "132px", base: "100px", sm: "100px" }}
            fontSize={{ md: "18px", base: "12px", sm: "12px" }}
            fontFamily={"poppins"}
            fontWeight={"500"}
          >
            Public Live
          </Tab>
        </TabList>
        <TabPanels pt="32px">
          <TabPanel>
            {consultationData &&
              consultationData.map((item) => (
                <ConsultationCard key={item._id} consultationData={item} />
              ))}
            {consultationData && (
              <ReactPaginate
                previousLabel="<< Prev"
                nextLabel="Next >>"
                pageCount={Math.ceil(consultationData.length / itemsPerPage)}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
                // style={paginationStyles}
              />
            )}
          </TabPanel>
          <TabPanel> */}
      {/* {consultationCallData &&
              consultationCallData.map((item) => (
                <ConsultationCard key={item._id} consultationData={item} />
              ))} */}

      {/* {currentCallConsultationDetails &&
              currentCallConsultationDetails.map((item) => (
                <ConsultationCard key={item._id} consultationData={item} />
              ))}
            {consultationCallData && (
              <ReactPaginate
                previousLabel="<< Prev"
                nextLabel="Next >>"
                pageCount={Math.ceil(
                  consultationCallData.length / itemsPerPage
                )}
                onPageChange={handleCallPageChange}
                containerClassName="pagination"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
                // style={paginationStyles}
              />
            )} */}
      {/* </TabPanel>
          <TabPanel> */}
      {/* {publicCallData &&
              publicCallData.map((item) => (
                <ConsultationCard key={item._id} consultationData={item} />
              ))} */}

      {/* {currentPublicCallConsultationDetails &&
              currentPublicCallConsultationDetails.map((item) => (
                <ConsultationCard key={item._id} consultationData={item} />
              ))}
            {publicCallData && (
              <ReactPaginate
                previousLabel="<< Prev"
                nextLabel="Next >>"
                pageCount={Math.ceil(publicCallData.length / itemsPerPage)}
                onPageChange={handlePublicCallPageChange}
                containerClassName="pagination"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
                // style={paginationStyles}
              />
            )} */}
      {/* </TabPanel>
        </TabPanels>
      </Tabs> */}
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        {!consultationData && userDetails.user?.role === "guru" ? (
          <Spinner m="auto" />
        ) : (
          <>
            {consultationData &&
              consultationData.map((item) => (
                <ConsultationCard key={item._id} consultationData={item} />
              ))}
            {/* {consultationData && (
            <ReactPaginate
              previousLabel="<< Prev"
              nextLabel="Next >>"
              pageCount={Math.ceil(consultationData.length / itemsPerPage)}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              previousLinkClassName="pagination__link"
              nextLinkClassName="pagination__link"
              disabledClassName="pagination__link--disabled"
              activeClassName="pagination__link--active"
            />
          )} */}
          </>
        )}
      </Flex>
    </Container>
  );
}

export default ConsultationHistory;
