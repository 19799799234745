import {
  Alert,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Image
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
// Import Toastify for Notify the user.
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Userdetails from "../../Popups/AstrologerCallPopup/Userdetails";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { RiDeleteBinFill } from "react-icons/ri";
import { FaEdit, FaPen } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBirthDetailId } from "../../../store/birthDetailsIdSlice";
import { fetchLoggedInUserDetails } from "../../../store/LoggedInUserDetails";
import Deletepopup from "./Deletepopup";
import editicon from "../Icons/Vector.svg"

const BirthChart = () => {
  let userDetails = useSelector((state) => state.loggedInUserDetails.data);
  //
  const [update, setUpdate] = useState(false);
  //
  const fullNames = userDetails?.userDetails?.birthDetails?.flatMap(
    (details) => details.fullName
  );
  //
  const [birtdetailsshow, setBirthdetailsshow] = useState(false);

  const [birthDetails, setBirthDetails] = useState([]);
  const [fullName, setFullName] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [birthDate, setBirthDate] = useState("");
  // Fuction For the Select the Date.
  const handleDateChange = (e) => {
    setBirthDate(e.target.value);
  };
  const [birthTime, setBirthTime] = useState("");
  const [gender, setGender] = useState("");
  // const [selectedBirthDetail, setSelectedBirthDetail] = useState(0);
  const [birthId, setBirthId] = useState("");
  const [birthIdforupdate,setBirthidforupdate]=useState("");

  const loginToken = localStorage.getItem("loginToken");

  // setBirthDetails(userDetails?.userDetails?.birthDetails)
  //

  const submitBirthDetails = () => {
    setBirthdetailsshow(!birtdetailsshow);
    const url = "https://data.gurucool.life/api/v1/user/createBirthDetails";
    let text = birthDate;
    const myArray = text.split("-");
    const updateBirthdate = myArray[2] + "/" + myArray[1] + "/" + myArray[0];
    const data = {
      fullName: fullName,
      birthPlace: birthPlace,
      birthDate: updateBirthdate,
      birthTime: birthTime,
      gender: gender,
    };
    const headers = {
      Authorization: `Bearer ${loginToken}`,
    };

    axios
      .post(url, data, { headers })
      .then((response) => {
        // Handle the response from the API
        //
        console.log(response);
        toast.success("Data successfully posted!", { autoClose: 3000 });
        dispatch(fetchLoggedInUserDetails(localStorage.getItem("loginToken")));
        // Clear input fields after successful post
        setFullName("");
        setBirthPlace("");
        setBirthDate("");
        setBirthTime("");
        setGender("");
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
        toast.error("An error occurred!", { autoClose: 3000 });
      });
  };

  const fetchBirthDetails = async (birthId) => {
    //
    //
    setUpdate(true);
    window.scrollTo({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
    setBirthdetailsshow(true);
    try {
      // const respons = await axios.get(
      //   https://data.gurucool.life/api/v1/user/getBirthDetails",
      //   {
      //     headers: {
      //       Authorization: `Bearer ${loginToken}`,
      //     },
      //   }
      // );
      //
      const respons = userDetails?.userDetails?.birthDetails.filter((obj) => {
        // 
        return obj._id === birthId;
      });
      //
      const response = respons[0];

      // Save the response data in the state
      // setBirthdata(response);
      let text = response.birthDate;
      const myArray = text.split("/");
      const updateBirthdate = myArray[2] + "-" + myArray[1] + "-" + myArray[0];
      //
      setFullName(response.fullName);
      setBirthPlace(response.birthPlace);
      setBirthDate(updateBirthdate);
      setBirthTime(response.birthTime);
      setGender(response.gender);
      // setBirthId(response._id);
      setBirthidforupdate(response._id);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleDeleteBirthDetails = async (birthId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      };
      await axios.delete(
        `https://data.gurucool.life/api/v1/user/deleteBirthDetails?birthId=${birthId}`,
        config
      );
      //
      dispatch(fetchLoggedInUserDetails(localStorage.getItem("loginToken")));
      
    } catch (error) {
      console.error("Error deleting birth details:", error);
      toast.error("An error occurred!", { autoClose: 3000 });
    }
    toast.success("Birth details deleted successfully!", { autoClose: 3000 });
  };

  const handleRadioChange = (value) => {
    if (value === selectedBirthDetail) {
      // Deselect the currently selected birthDetail
      setSelectedBirthDetail(null);
    } else {
      const selectedDetail = birthDetails.find(
        (detail) => detail._id === value
      );
      const isSimilarSelected = birthDetails.some(
        (detail) =>
          detail.fullName === selectedDetail.fullName && detail._id !== value
      );

      if (isSimilarSelected) {
        // Deselect any similar birthDetail that is already selected
        setSelectedBirthDetail(null);
      } else {
        setSelectedBirthDetail(value);
      }
    }
  };

  const handleUpdate = () => {
    setBirthdetailsshow(!birtdetailsshow);
    let text = birthDate;
    const myArray = text.split("-");
    const updateBirthdate = myArray[2] + "/" + myArray[1] + "/" + myArray[0];
    let token = localStorage.getItem("loginToken"); // Replace 'your_auth_token' with your actual token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `https://data.gurucool.life/api/v1/user/editBirthDetails?birthId=${birthIdforupdate}`,
        {
          fullName: fullName,
          birthPlace: birthPlace,
          birthDate: updateBirthdate,
          birthTime: birthTime,
          gender: gender,
        },
        config
      )
      .then((response) => {
        //
        dispatch(fetchLoggedInUserDetails(localStorage.getItem("loginToken")));
        toast("Birthdetails Update Successfully");
      })
      .catch((error) => {
        //
        toast.error("Update not Succesfully");
      });
  };

  const handleSubmit = () => {
    setUpdate(false);
    setFullName("");
    setBirthDate("");
    setBirthPlace("");
    setBirthTime("");
    setBirthId("");
    setBirthdetailsshow(!birtdetailsshow);
  };

  // const [selectedBirthDetail, setSelectedBirthDetail] = useState(null);

  // useEffect(() => {
  //   handleDeleteBirthDetails();
  // }, [birthId]);

  const dispatch = useDispatch();

  // const handleRadioChange = (event) => {
  //   const selectedId = event.target.value;
  //   setSelectedBirthDetail(selectedId);
  //   dispatch(setSelectedBirthDetailId(selectedId));
  //  
  // };
  const fetchBirtchart = (id) => {
    let token = localStorage.getItem("loginToken"); // Replace 'your_auth_token' with your actual token
    let config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
   
    axios
      .put(
        `https://data.gurucool.life/api/v1/user/setDefaultBirthDetail?birthId=${id}`,
        {},
        config
      )
      .then((response) => {
        // console.log(response);
        toast("Birthdetails Update Successfully");
      })
      .catch((error) => {
       
        toast.error("Update not Succesfully");
      });
  };
  useEffect(() => {
    //
    //
    let array = userDetails?.userDetails?.birthDetails;
    const obj = array.filter((obj) => obj.defaultBirthChart === true);
    //
    //
    setBirthId(obj[0]?._id);
  }, []);

  return (
    <>
      <Box
        h={{ md: "auto", base: "auto" }}
        w={"100%"}
        borderRadius={{md:"23px", sm:"8px", base:"8px"}}
        // border={"2px solid #FFC293"}
        p={{ md: "34px", base: "8px" }}
      >
        {/* <Heading
        as="h3"
          textAlign={"center"}
          fontSize={{ md: "24px" }}
          fontWeight={"500"}
        >
          Birth Chart
        </Heading> */}
        <Text
          fontSize={{ md: "14px" }}
          color={"#656565"}
          textAlign={"center"}
          mb={0}
        >
          Save your birth details to get personalized horoscope and many more
          free feature.
        </Text>

        {(birtdetailsshow ||
          userDetails?.userDetails?.birthDetails?.length === 0) && (
          <>
            {" "}
            <Grid
              templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
              gap={{ md: "20px", base: "12px" }}
              w="100%"
              mt={{ md: "26px", base: "20px" }}
            >
              <Box h="52px">
                <Input
                  type="text"
                  placeholder="Name"
                  w={"100%"}
                  h={"100%"}
                  bg="gray.200"
                  focusBorderColor="orange.500"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Box>
              <Box h="52px">
                <HStack spacing={{ md: "20px", base: "16px" }} height={"100%"}>
                  <Input
                    type="date"
                    placeholder="Select Your DOB"
                    w={"100%"}
                    h={"100%"}
                    bg="gray.200"
                    focusBorderColor="orange.500"
                    value={birthDate}
                    onChange={handleDateChange}
                  />
                  <Input
                    type="time"
                    placeholder="Select Time"
                    w={"100%"}
                    h={"100%"}
                    bg="gray.200"
                    focusBorderColor="orange.500"
                    value={birthTime}
                    onChange={(e) => setBirthTime(e.target.value)}
                  />
                </HStack>
              </Box>
              {/* <Box h="52px" bg="gray.200"> */}
              <Select
                placeholder="Gender"
                w={"100%"}
                h={"52px"}
                style={{ height: "52px !important" }}
                focusBorderColor="orange.500"
                bg="gray.200"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
              {/* </Box> */}
              <Box h="52px">
                <Input
                  type="text"
                  placeholder="Birth Place"
                  w={"100%"}
                  h={"100%"}
                  bg="gray.200"
                  focusBorderColor="orange.500"
                  value={birthPlace}
                  onChange={(e) => setBirthPlace(e.target.value)}
                />
              </Box>
            </Grid>
            <Flex justifyContent="flex-end" mt={{ md: "40px", base: "28px" }}>
               <Button colorScheme="orange" mr={"10px"} onClick={()=>{setBirthdetailsshow(!birtdetailsshow)}} >
                  Cancel
                </Button>
              {update ? (
                <Button colorScheme="orange" onClick={handleUpdate}>
                  Update
                </Button>
              ) : (
                <Button colorScheme="orange" onClick={submitBirthDetails}>
                  Save
                </Button>
              )}
            </Flex>
          </>
        )}
        <Box>
          {/* {console.log(userDetails?.userDetails?.birthDetails)} */}
          <Stack direction="column" mt={"5px"}>
        <Box height={"50px"} pl={"10px"} pr={"10px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
         <Heading color={"blackAlpha.700"} fontSize={"16px"} fontWeight={"500"} display={"flex"} alignItems={"center"} width={"159.9px"}>
             Name
        </Heading>
       <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} border={""} width={"50%"}>
        <Heading fontSize={"16px"} color={"blackAlpha.700"} fontWeight={"500"}>Edit</Heading>
        <Heading fontSize={"16px"} color={"blackAlpha.700"} fontWeight={"500"}>Delete</Heading>
        </Box>
       </Box>
       </Stack>

          {userDetails?.userDetails?.birthDetails.map((detail, index) => (
            <RadioGroup
              onChange={(event) => {
                const selectedId = event;
                dispatch(setSelectedBirthDetailId(selectedId));
                setBirthId(selectedId);
                fetchBirtchart(selectedId);
              }}
              value={birthId}
              colorScheme="orange"
            >
              <Stack direction="column" mt={"5px"}>
                <Box height={"50px"} pl={"10px"} pr={"10px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} bg={detail._id===birthId?"#FFF5F0":""}>
                  <Box display={"flex"} alignItems={"center"} border={""} width={"159.9px"}>
                  <label for={detail._id} >{detail.fullName.split(" ")[0].slice(0,16)}{detail.fullName.split(" ")[0].length>15&&"..."}{" "}{detail._id===birthId&&"(Default)"}</label>
                  <Radio  border={"2px solid #FF4C00"} value={detail._id} id={detail._id} float={""} mr={"10px"}></Radio>
                  {/* <label for={detail._id} >{detail.fullName.split(" ")[0].slice(0,16)}{detail.fullName.split(" ")[0].length>15&&"..."}{" "}{detail._id===birthId&&"(Default)"}</label> */}
                  </Box>
                  <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} border={""} width={"50%"}>
                  <Image
                   src={editicon}
                    // display={{ base: "block", md: "none" }}
                    alignSelf={"center"}
                    // siz  e={"xs"}
                    bg={"none"}
                    color={"#008BD4"}
                    // as={FaPen}
                    size={"20px"}
                    h={"22px"}
                    w={"22px"}
                    onClick={() => fetchBirthDetails(detail?._id)}
                  />
                   <Deletepopup handleDeleteBirthDetails={()=>{handleDeleteBirthDetails(detail?._id)}} birthId={detail?._id} userDetails={userDetails} />
                  </Box>
                </Box>
              </Stack>
            </RadioGroup>
          ))}

          {/* {userDetails?.userDetails?.birthDetails.map((detail, index) => (
            <RadioGroup
              // onChange={(e)=> handleRadioChange(e)}
              onChange={(event) => {
                const selectedId = event;
                dispatch(setSelectedBirthDetailId(selectedId));
                setSelectedBirthDetail(index);
                //
                //
              }}
              // onChange={setValue} value={value}
              value={selectedBirthDetail}
            >
              <Flex
                justifyContent={"space-between"}
                mt={{ md: "40px", base: "24px" }}
              >
                <Text mb={0}>{detail.fullName}</Text>
                <Radio
                  key={index}
                  // value={detail._id}
                  value={detail._id}
                  display="flex"
                  alignItems="center"
                  // mb={1}
                  colorScheme="orange"
                ></Radio>
              </Flex>
            </RadioGroup>
          ))} */}

          {fullNames.length === 0 ? (
            ""
          ) : (
            <>
              <Divider color={"gray.300"} />
              <Flex
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Flex gap={"32px"}>
                  <Button
                    // display={{ base: "none", md: "block" }}
                    variant={"outline"}
                    color={"white"}
                    height={{base:"42px",sm:"46px"}}
                    width={{base:"110px",sm:"160px"}}
                    borderRadius={"6px"}
                    border={"2px solid #FF4C00"}
                    leftIcon={<AiOutlinePlus color="white" size={"20px"} />}
                    onClick={handleSubmit}
                    bg={"rgb(255, 76, 0)"}
                  >
                    Add New
                  </Button>
                  {/* <Button
                    display={{ base: "none", md: "block" }}
                    color={"#008BD4"}
                    bg={"inherit"}
                    leftIcon={<FaPen color="#008BD4" />}
                    onClick={() => fetchBirthDetails(birthId)}
                  >
                    Edit
                  </Button> */}
                  {/* <Button
                    display={{ base: "none", md: "block" }}
                    color={"#FF4C00"}
                    bg={"inherit"}
                    leftIcon={<RiDeleteBinFill color={"#FF4C00"} />}
                    onClick={handleDeleteBirthDetails}
                  >
                    Delete
                    
                  </Button> */}
                  {/* <IconButton
                    display={{ base: "block", md: "none" }}
                    bg={"#FF4C00"}
                    color={"white"}
                    as={AiOutlinePlus}
                    onClick={handleSubmit}
                  ></IconButton> */}
                  {/* <IconButton
                    display={{ base: "block", md: "none" }}
                    alignSelf={"center"}
                    size={"xs"}
                    bg={"none"}
                    color={"#008BD4"}
                    as={FaPen}
                    onClick={() => fetchBirthDetails(birthId)}
                  ></IconButton> */}
                  {/* <Deletepopup handleDeleteBirthDetails={handleDeleteBirthDetails} birthId={birthId} userDetails={userDetails} /> */}
                </Flex>
              </Flex>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BirthChart;
