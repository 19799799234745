import { createSlice } from '@reduxjs/toolkit';

const clickHandlersSlice = createSlice({
  name: 'clickHandlers',
  initialState: {
    activeLink: "",
  },
  reducers: {
    setActiveLink: (state, action) => {
      state.activeLink = action.payload;
    },
    
  },
});

export const clickHandlersAction = clickHandlersSlice.actions;
export default clickHandlersSlice.reducer;