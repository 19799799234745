import {
  selectPeers,
  useAutoplayError,
  useHMSActions,
  useHMSStore,
  useRecordingStreaming,
} from "@100mslive/react-sdk";
import React, { useEffect, useState } from "react";
import Peer from "../Peer/Peer";
import LiveFooter from "../LiveFooter";
import LiveHeader from "../LiveHeader";
import { Box, Text } from "@chakra-ui/layout";
import ChatNdParticipants from "../LiveChat/ChatNdParticipants";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchAstrologerProfileData } from "../../../store/astrologerProfileSlice";
import { Spinner } from "@chakra-ui/spinner";
import {
  //  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
// import { Img } from "@chakra-ui/react";
// import fallback from "../../../assets/live-stream/fallback.png";
import ShowGifts from "../GIfts/ShowGifts";
// import { useCallback } from "react";
// import axios from "axios";
// import { P_LIVE_GET_LIVE_TRIGGER } from "../../../apiLinks";
import taphere from "../../../assets/live-stream/tap_to_unmute.png";
import liveFallback from "../../../assets/live-stream/live_fallback.png";

import "./Conference.css";
import axios from "axios";
import { P_LIVE_GET_LIVE_TRIGGER } from "../../../apiLinks";
import ShowIncomingCallPrompt from "../ShowIncomingCallPrompt";
function AstrologyConference() {
  const dispatch = useDispatch();
  const { error, resetError, unblockAudio } = useAutoplayError();
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );

  const [searchParams] = useSearchParams();
  const guruToken = searchParams.get("id");
  useEffect(() => {
    dispatch(fetchAstrologerProfileData({userName: guruToken, isToken: true}));
  }, [guruToken, dispatch]);
  const astroDetails = useSelector((state) => state.astrologerProfile.data);
  const status = useSelector((state) => state.astrologerProfile.status);
  const hmsActions = useHMSActions();
  const peers = useHMSStore(selectPeers);
  const localUser = peers.filter((peer) => peer.isLocal);
  const localUserRole = localUser[0]?.roleName;
  const astrologerPeer = peers.find((peer) => peer.roleName === "astrologer");
  const [callData, setCallData] = useState();
  const [showIncomingCall, setShowIncomingCall] = useState(false);

  useEffect(() => {
    window.onunload = () => {
      hmsActions.leave();
    };
  }, [hmsActions]);

  const handleSubmit = async () => {
    if (loggedInUserDetails?.guru) {
      const firstName = loggedInUserDetails?.user?.firstName;
      const lastName = loggedInUserDetails?.user?.lastName;
      const userName = firstName + " " + lastName;
      const roomCode = astroDetails?.liveGatewayIDs?.astrologerLiveId;

      const params = {
        meetingURL: `https://tech-livestream-1440.app.100ms.live/preview/${roomCode}?skip_preview=true
        `,
        rtmpURLs: [
          "rtmp://a.rtmp.youtube.com/live2/v0gg-x6a0-c4u1-uywg-dys1",
          // "rtmps://live-api-s.facebook.com:443/rtmp/FB-143480922069806-0-AbyhUYbsDgDb2emf",
        ],
        record: false,
      };

      if (roomCode) {
        try {
          // Use room code to fetch auth token
          const authToken = await hmsActions.getAuthTokenByRoomCode({
            roomCode,
          });

          if (authToken) {
            await hmsActions.join({ userName, authToken });
          }
        } catch (e) {
          console.error("Error joining:", e);
        }
      }
    }
  };

  // Run handleSubmit on component mount (refresh)
  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line
  }, [loggedInUserDetails, astroDetails]);
  const [isMediumScreen] = useMediaQuery("(min-width: 768px)");

  return (
    <>
      {error && (
        <Box
          className="conference-section"
          width={"100%"}
          height={"100vh"}
          position={"relative"}
          bg={"#884227"}
          onClick={() => {
            unblockAudio();
            resetError();
          }}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img alt="taphere img" src={taphere}></img>
          <Text fontSize={"29.2835px"} color={"white"}>
            Tap Here
          </Text>
          <Text fontSize={"17px"} color={"white"}>
            Tap to start live session with astrologer
          </Text>
        </Box>
      )}

      <Box
        className="conference-section"
        width={"100%"}
        height={"100svh"}
        position={"relative"}
        backgroundImage={liveFallback}
        // bgGradient="linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(0, 0, 0, 0.00) 40.10%, rgba(0, 0, 0, 0.00) 52.08%, rgba(0, 0, 0, 0.59) 100%)"
      >
        {/* <Box
          className="contains-Gradient"
          width={"100%"}
          height={"100svh"}
          position={"absolute"}
          overflow={"hidden"}
          bgGradient={{
            base: "linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(0, 0, 0, 0.00) 40.10%, rgba(0, 0, 0, 0.00) 52.08%, rgba(0, 0, 0, 0.59) 100%);",
            md: "none",
          }}
        ></Box> */}
        {!error && status === "succeeded" ? (
          <>
            {isMediumScreen ? null : (
              <LiveHeader
                showIncomingCall={showIncomingCall}
                callData={callData}
                localUserRole={localUserRole}
                astroDetail={astroDetails}
              />
            )}
            <ShowIncomingCallPrompt />
            {isMediumScreen ? null : (
              <ChatNdParticipants
                astroDetail={astroDetails}
                localUserRole={localUserRole}
              />
            )}
            <div className="peers-container">
              {astrologerPeer ? (
                <Peer
                  key={astrologerPeer.id}
                  peer={astrologerPeer}
                  localUserRole={localUserRole}
                />
              ) : null}
            </div>
            {isMediumScreen ? null : (
              <LiveFooter
                localUserRole={localUserRole}
                astroDetail={astroDetails}
                localUser={localUser}
              />
            )}
          </>
        ) : null}
      </Box>
    </>
  );
}

export default AstrologyConference;
