import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Box,
  Flex,
  IconButton,
  Text,
  Image,
  Button,
  Modal,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import call from "../../assets/calll.svg";
import callOutline from "../../assets/calll (2).svg";

import profile from "../../assets/profile.svg";
import profileOutline from "../../assets/profileOuline.svg";

import chat from "../../assets/messagetext1.svg";
import chatOutline from "../../assets/messagetext1 (2).svg";

import home from "../../assets/home2.svg";
import homeOutline from "../../assets/home2 (2).svg";

import live from "../../assets/liveActiveIcon.svg";
import liveOutline from "../../assets/liveNonActive.svg";
import {
  BsCameraVideo,
  BsCameraVideoFill,
  BsCameraVideoOff,
} from "react-icons/bs";

import Login from "../Login&Signup/Login";
import { useState } from "react";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../firebaseEvents/firebase";

const FloatingButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentURL = location.pathname;
  console.log("CURRENT URL", currentURL);

  // const [activeButton, setActiveButton] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);

  const handleClick = (path) => {
    navigate(path);
    // setActiveButton(button);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isHome = location.pathname === "/";
  const isChat = location.pathname === "/streamWithAstrologer";
  const isCall = location.pathname === "/astrologers";
  const isHistory = location.pathname === "/myProfile";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showModal, setShowModal] = useState(false);

  const loginToken = localStorage.getItem("loginToken");

  const profileClicked = () => {
    if (!loginToken) {
      setShowModal(true);
      onOpen();
    } else {
      navigate("/myProfile");
    }
  };

  const walletClicked = () => {
    if (!loginToken) {
      setShowModal(true);
      onOpen();
    } else {
      navigate("/wallet");
    }
  };

  const userDetails = useSelector((state) => state.loggedInUserDetails.data);
  //

  return (
    <>
      {userDetails.user && userDetails.user.role === "astrologer" ? (
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bg="#BC5D14"
          shadow="sm"
          zIndex={100000}
          display={{ md: "none" }}
          pb={0}
          mb={0}
          id="bottom-nav"
          cursor={"pointer"}
          onClick={() => handleClick("/myProfile")}
        >
          <Flex justify="space-evenly" align="center" mx={-5} pt={2} pb={2}>
            <Text fontWeight={"700"} py={"13px"}>
              Dashboard
            </Text>
          </Flex>

          {showModal ? (
            <Modal
              isCentered
              isOpen={isOpen}
              onClose={onClose}
              size={{ base: "xs", md: "4xl" }}
            >
              <Login />
            </Modal>
          ) : null}
        </Box>
      ) : (
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={"0"}
          bg="white"
          shadow="sm"
          zIndex={100000}
          display={{ md: "none" }}
          pb={0}
          mb={0}
          id="bottom-nav"
        >
          <Flex
            display={showModal ? "none" : "flex"}
            justify="space-evenly"
            align="center"
            mx={-5}
            pt={2}
            pb={2}
          >
            <IconButton
              height={"fit-content"}
              aria-label="Home"
              icon={
                <>
                  <Flex direction="column" align="center">
                    {isHome ? (
                      <Image
                        src={home}
                        width={{ base: "30px" }}
                        height={{ base: "30px" }}
                        alt="home"
                      />
                    ) : (
                      <Image
                        src={homeOutline}
                        width={{ base: "30px" }}
                        height={{ base: "30px" }}
                        alt="homeOutline"
                      />
                    )}
                    <Text color={isHome ? "#BF6928" : "#E2AF88"}>Home</Text>
                  </Flex>
                </>
              }
              variant="unstyled"
              onClick={() => handleClick("/", "home")}
            />
            {/* <IconButton
              height={"fit-content"}
              aria-label="Chat"
              icon={
                <>
                  <Flex direction="column" align="center">
                    {isChat ? (
                      <Image
                        src={live}
                        width={{ base: "30px" }}
                        height={{ base: "30px" }}
                        alt="chat"
                      />
                    ) : (
                      // <Icon as={BsCameraVideoFill} boxSize={{base: "30px"}} color={"#BF6928"} />
                      <Image
                        src={liveOutline}
                        width={{ base: "30px" }}
                        height={{ base: "30px" }}
                        alt="chatOutline"
                      />
                      // <Icon as={BsCameraVideo} boxSize={{base: "30px"}} color={"#E2AF88"} />
                      // <Icon as={HiOutlineVideoCamera} w={{base:'30px'}} h={{base:'30px'}}/>
                    )}
                    <Text color={isChat ? "#BF6928" : "#E2AF88"}>Live</Text>
                  </Flex>
                </>
              }
              variant="unstyled"
              onClick={() => handleClick("/streamWithAstrologer")}
            /> */}
            <IconButton
              height={"fit-content"}
              aria-label="Call"
              icon={
                <>
                  <Flex direction="column" align="center">
                    {isCall ? (
                      <Image
                        src={call}
                        width={{ base: "30px" }}
                        height={{ base: "30px" }}
                        alt="call"
                      />
                    ) : (
                      <Image
                        src={callOutline}
                        width={{ base: "30px" }}
                        height={{ base: "30px" }}
                        alt="callOutline"
                      />
                    )}
                    <Text color={isCall ? "#BF6928" : "#E2AF88"}>Call</Text>
                  </Flex>
                </>
              }
              variant="unstyled"
              onClick={() => {
                handleClick("/doctors");
                logEvent(analytics, "ConsultAstrologerPage_enter");
              }}
            />
            <IconButton
              height={"fit-content"}
              aria-label="Profile"
              icon={
                <>
                  <Flex direction="column" align="center">
                    {isHistory ? (
                      <Image
                        src={profile}
                        width={{ base: "30px" }}
                        height={{ base: "30px" }}
                        alt="profile"
                      />
                    ) : (
                      <Image
                        src={profileOutline}
                        width={{ base: "30px" }}
                        height={{ base: "30px" }}
                        alt="profileOutline"
                      />
                    )}
                    <Text color={isHistory ? "#BF6928" : "#E2AF88"}>
                      Profile
                    </Text>
                  </Flex>
                </>
              }
              variant="unstyled"
              onClick={() => {
                profileClicked();
                localStorage.setItem("guruToken", data.user.guru);
              }}
              // onClick={() => handleClick("/myProfile")}
            />
          </Flex>

          {showModal ? (
            <Modal
              isCentered
              isOpen={isOpen}
              onClose={onClose}
              size={{ base: "xs", md: "md" }}
            >
              <Login />
            </Modal>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default FloatingButton;
