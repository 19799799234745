import { createSlice } from "@reduxjs/toolkit";


const startCallBirthDetails = createSlice({
  name: "startCallBirthDetails",
  initialState: {
    // todo to check if cookie exist to chheck the user is logged in or not
    name:"",
    gender:"",
    birthTime:"",
    birthDate:"",
    birthPlace:"",
    minCallDuration:"",
    callPurchasedId:"",
  },
  reducers: {
    setName(state, action) {
      state.name = action.payload;
    },
    setGender(state, action) {
      state.gender = action.payload;
    },
    setBirthTime(state, action) {
      state.birthTime = action.payload;
    },
    setBirthDate(state, action) {
      state.birthDate = action.payload;
    },
    setBirthPlace(state, action) {
      state.birthPlace = action.payload;
    },
    setMinCallDuration(state, action) {
      state.minCallDuration = action.payload;
    },
    setCallPurchasedId(state, action) {
      state.callPurchasedId = action.payload;
    },
  },
});

export const startCallBirthDetailsAction = startCallBirthDetails.actions;
export default startCallBirthDetails.reducer;
