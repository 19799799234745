import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { 
  // G_GET_ALL_GURUS, 
  G_GET_ALL_USERS } from "../apiLinks";

const initialState = {
  data: [],
  total: 0,
  status: "idle",
  error: null,
};

export const fetchAllUsers = createAsyncThunk(
  "allUsersData/fetchAllUsers",
  async ({pageNumber, perPage, uid = ""}) => {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    const response = await axios.get(G_GET_ALL_USERS(pageNumber, perPage, uid), config);
    //
    return response.data.user;
  }
);

const getAllUsersSlice = createSlice({
  name: "allGurusData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.docs;
        state.total = action.payload.total;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getAllUsersSlice.reducer;
