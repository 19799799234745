import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  // Input,
  Center,
  Spinner,
  Text,
  Avatar,
  useDisclosure,
  Select,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { fetchData } from "../../../store/TarotSlice";
import { Navigate, useNavigate } from "react-router";
// import { fetchAllGurus } from "../../../store/getAllGurus";
import Pagination from "react-js-pagination";
import { fetchAllUsers } from "../../../store/getAllUsers";
import { IconButton } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
// import { Link } from "react-router-dom";
import { P_PUT_MAKE_USER_ADMIN, G_GET_USER_PROFILE } from "../../../apiLinks";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import AddBalance from "../AddBalance/AddBalance";

const ShowUsers = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInternational, setIsInternational] = useState();
 
  const [userIdToUpdateWalletBalance, setUserIdToUpdateWalletBalance] =
    useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const ITEMS_PER_PAGE = 20;
  const [activePage, setActivePage] = useState(1);
  const [role, setRole] = useState("");
  //
  useEffect(() => {
    dispatch(
      fetchAllUsers({
        pageNumber: activePage,
        perPage: `${ITEMS_PER_PAGE}`,
        uid: searchTerm,
      })
    );
    // eslint-disable-next-line
  }, [activePage]);

  function convertIst(date) {
    const utcDate = new Date(date);
    const istDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDate.toLocaleString();
  }

  // const data = useSelector((state) => state.allGurusData.data);
  const data = useSelector((state) => state.allUsersData.data);
  const total = useSelector((state) => state.allUsersData.total);
  const status = useSelector((state) => state.allUsersData.status);
  const error = useSelector((state) => state.allUsersData.error);
  //

  //

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const loginToken = localStorage.getItem("loginToken");
  useEffect(() => {
    if (loginToken) {
      axios
        .get(G_GET_USER_PROFILE, {
          headers: { Authorization: `Bearer ${loginToken}` },
        })
        .then((response) => {
          if (response.data.user.role) {
            setRole(response.data.user.role);
          }
        });
    }
  }, [loginToken]);

  const onChangeRole = (id, admin) => {
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        P_PUT_MAKE_USER_ADMIN,
        {
          id,
          admin,
        },
        config
      )
      .then((x) =>
        dispatch(
          fetchAllUsers({
            pageNumber: activePage,
            perPage: `${ITEMS_PER_PAGE}`,
            uid: searchTerm,
          })
        )
      );
  };

  const onSearch = (e) => {
    setSearchTerm(e);
    setActivePage(1);
    dispatch(
      fetchAllUsers({ pageNumber: 1, perPage: `${ITEMS_PER_PAGE}`, uid: e })
    );
  };

  if (status === "failed") {
    return (
      <Center>
        <Text color="red">{error}</Text>
      </Center>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg={"gray.700"}>
          <ModalCloseButton />
          <ModalBody>
            <AddBalance
              international={isInternational}
              idForUpdateBalance={userIdToUpdateWalletBalance}
              onClose={onClose}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex justifyContent={"flex-end"}>
        <Sidebar />
        <Box w={"80%"} fontSize={"18px"} p={5}>
          <NumberInput w={"sm"} value={searchTerm} onChange={onSearch}>
            <NumberInputField placeholder="Enter UID to search users" />
          </NumberInput>
          {status === "loading" && (
            <Center>
              <Spinner size="xl" />
            </Center>
          )}
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>UID</Th>
                <Th>Profile Photo</Th>
                <Th>User Name</Th>
                <Th>Role</Th>
                <Th>Wallet</Th>
                {/* <Th>$ Wallet</Th> */}
                <Th>Phone</Th>
                <Th>Email</Th>
                {/* <Th>Country</Th> */}
                <Th>Time</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((currElem) => (
                <Tr
                  key={currElem._id}
                  bg={
                    currElem.role === "admin" || currElem.role === "super-admin"
                      ? "gray.200"
                      : undefined
                  }
                >
                  <Td>{currElem._id}</Td>
                  <Td>{currElem.uid}</Td>
                  <Td>
                    {currElem.avatar ? (
                      <Avatar
                        src={currElem.avatar.url}
                        alt={`Profile of ${currElem.firstName}`}
                      />
                    ) : (
                      <Avatar />
                    )}
                  </Td>
                  <Td>
                    {currElem.firstName} &nbsp;
                    {currElem.lastName}
                  </Td>

                  <Td>
                    {currElem.role}
                    {role === "super-admin" &&
                      currElem.role !== "super-admin" && (
                        <Select
                          style={{ width: "100px" }}
                          // defaultValue={currElem.role}
                          onChange={(e) =>
                            onChangeRole(
                              currElem._id,
                              e.target.value === "admin"
                            )
                          }
                          placeholder="Select"
                          borderRadius="5px"
                          variant="outline"
                          borderColor="gray.400"
                        >
                          <option value="admin">Admin</option>
                          <option value="user">User</option>
                        </Select>
                      )}
                  </Td>

                  {currElem.userDetails.isInternational ? (
                    <Td>
                      {currElem.userDetails.internationalWallet ? (
                        <>
                          ${" "}
                          {parseFloat(
                            currElem.userDetails.internationalWallet
                          ).toFixed(2)}
                        </>
                      ) : (
                        <>$ 0</>
                      )}
                      {role === "super-admin" && (
                        <IconButton
                          colorScheme="teal"
                          aria-label="Call Segun"
                          size="xs"
                          icon={<AddIcon />}
                          ml={"8px"}
                          onClick={() => {
                            setIsInternational(true);
                            setUserIdToUpdateWalletBalance(currElem._id);
                            onOpen();
                          }}
                        />
                      )}
                    </Td>
                  ) : (
                    <Td>
                      {currElem.userDetails.wallet ? (
                        <>
                          ₹ {parseFloat(currElem.userDetails.wallet).toFixed(2)}
                        </>
                      ) : (
                        <>₹ 0</>
                      )}

                      {role === "super-admin" && (
                        <IconButton
                          colorScheme="teal"
                          aria-label="Call Segun"
                          size="xs"
                          icon={<AddIcon />}
                          ml={"8px"}
                          onClick={() => {
                            setIsInternational(false);
                            setUserIdToUpdateWalletBalance(currElem._id);
                            onOpen();
                          }}
                        />
                      )}
                    </Td>
                  )}

                  <Td>{currElem.phone}</Td>
                  <Td>{currElem.email}</Td>
                  {/* {currElem.isInternational ? (
                      <Td>International</Td>
                    ) : (
                      <Td>India</Td>
                    )} */}
                  <Td>{convertIst(currElem.createdAt)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <Flex justifyContent={"center"} alignItems={"center"} m="70px">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={ITEMS_PER_PAGE}
          totalItemsCount={total}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          prevPageText="Previous"
          nextPageText="Next"
        />
      </Flex>
    </>
  );
};

export default ShowUsers;
