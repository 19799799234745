import React, {
  //  ReactNode,
  useEffect,
  useState,
} from "react";
// import Avatar from "react-avatar";
import "../Header/Header.css";
import { useNavigate, useLocation } from "react-router-dom";
// import { selectWallet } from '../../store/walletSlice';
import {
  Box,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  Container,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  // FormControl,
  Flex,
  Text,
  // Input,
  // ModalOverlay,
  // ModalCloseButton,
  // Divider,
  // ModalBody,
  // ModalContent,
  Modal,
  Image,
  Icon,
  Input,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  AvatarBadge,
  Heading,
  // Spacer,
} from "@chakra-ui/react";
import "../../App.css";
import Wallet from "../../assets/walletSidebar.svg";
import profileIcon from "../../assets/profileIcon.svg";
import LogOut from "../../assets/LogOut.svg";
// import { Center, Heading } from "@chakra-ui/react";
// import { PinInput, PinInputField } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import { HamburgerIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import Login from "../Login&Signup/Login";
// import LoginSystemSlice, {
//   loginSystemAction,
// } from "../../store/LoginSystemSlice";
import axios from "axios";
// import LoggedInUserDetails, {
//   logInUserDetailsAction,
// } from "../../store/LoggedInUserDetails";
import { NavLink } from "react-router-dom";
import "../../App.css";
import { keyframes } from "@emotion/react";
// import { MDBModalContent } from "mdb-react-ui-kit";
import { selectedAstrologerDetailsAction } from "../../store/selectedAstrologerDetailsSlice";
// import logo from "../../../src/assets/logo.png";
import gurucool_logo from "../../assets/gurucoolLogo/gurucoolLogo.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import avatar from "../../assets/Avatar 1.svg";
import {
  G_GET_SINGLE_GURUS,
  G_GET_USER_PROFILE,
  // , G_GET_USER_PROFILE
} from "../../apiLinks";
import { BiWalletAlt } from "react-icons/bi";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../firebaseEvents/firebase";
import { fetchAllGurus } from "../../store/getAllGurus";
import { IoNotificationsOutline } from "react-icons/io5";
import notification from "../../assets/topNavigation/notification.svg";
import notificationActive from "../../assets/topNavigation/notificationactive.svg";
import searchIcon from "../../assets/topNavigation/searchMobile.svg";
import walletIcon from "../../assets/topNavigation/wallet2.svg";
import hamburgerIcon from "../../assets/topNavigation/hamburgerMobile.svg";
import { MdNotificationsNone } from "react-icons/md";
import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";
import DefaultAvatar from "../../assets/DefaultAvatar.svg";
import NoNotification from "../../assets/no_notification.svg";
import voiceicon from "./Icons/voice.svg";
import avataricon from "./Icons/Chair.svg";
import avataricon1 from "./Icons/on.svg";
// import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";
import StopWatch from "./Stopwatch/StopWatch/StopWatch";

export default function Simple({ isOpen, onOpen, onClose }) {
  // const notify = () => toast("Log Out Successful!");
  // const size = "96px";
  // const color = "teal";
  const userDetails = useSelector((state) => state.loggedInUserDetails.data);
  // const [userObject,setUserobject]=useState(userDetails);
  // console.log("Userobject",userObject);
  // console.log("Userdetails",userDetails);

  const pulseRing = keyframes`
	0% {
    transform: scale(0.33);
  }
  40%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
	`;
  const dispatch = useDispatch();

  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();

  const loggedIn = useSelector((state) => state.logIn.userLoggedIn);
  // const [showLogin, setShowLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [userWalletBalance, setUserWalletBalance] = useState();
  const userImg = useSelector((state) => state.loggedInUserDetails.userImg);

  const loggedInStatus = useSelector(
    (state) => state.loggedInUserDetails.status
  );

  const loginToken = localStorage.getItem("loginToken");
  const isInternational = localStorage.getItem("isInternational") === "true";

  useEffect(() => {
    if (
      userDetails.user &&
      (userDetails.user.role === "admin" ||
        userDetails.user.role === "super-admin")
    ) {
      setIsAdmin(true);
    }
    //   if (loginToken) {
    //     dispatch(loginSystemAction.setUserLoggedIn(true));
    //     axios
    //       .get(G_GET_USER_PROFILE, {
    //         headers: { Authorization: `Bearer ${loginToken}` },
    //       })
    //       .then((response) => {
    //         dispatch(logInUserDetailsAction.setData(response.data));
    //         if (
    //           response.data.user.role === "admin" ||
    //           response.data.user.role === "super-admin"
    //         ) {
    //           setIsAdmin(true);
    //         }
    //         if (response.data.user.avatar) {
    //           dispatch(
    //             logInUserDetailsAction.setLoggedInUserImg(
    //               response.data.user.avatar.url
    //             )
    //           );
    //         } else {
    //           dispatch(logInUserDetailsAction.setLoggedInUserImg(avatar));
    //         }
    //         // if (response.data.userDetails.paymentsDetails != "") {
    //         //   dispatch(
    //         //     logInUserDetailsAction.setLoggedInPreviousRecharges(
    //         //       response.data.userDetails.paymentsDetails
    //         //     )
    //         //   );
    //         // }
    //         // if (response.data.userDetails.stripe_customer_id != "") {
    //         //   dispatch(
    //         //     logInUserDetailsAction.setLoggedInStripeId(
    //         //       response.data.userDetails.stripe_customer_id
    //         //     )
    //         //   );
    //         // }
    //         // if (response.data.userDetails.purchases != "") {
    //         //   dispatch(
    //         //     logInUserDetailsAction.setLoggedInPreviousConsultations(
    //         //       response.data.userDetails.purchases
    //         //     )
    //         //   );
    //         // }
    //         // dispatch(
    //         //   logInUserDetailsAction.setLoggedInUserFirstName(
    //         //     response.data.user.firstName
    //         //   )
    //         // );
    //         // dispatch(
    //         //   logInUserDetailsAction.setLoggedInUserLastName(
    //         //     response.data.user.lastName
    //         //   )
    //         // );
    //         // dispatch(
    //         //   logInUserDetailsAction.setLoggedInUserEmail(
    //         //     response.data.user.email
    //         //   )
    //         // );
    //         // dispatch(
    //         //   logInUserDetailsAction.setLoggedInUserPhone(
    //         //     response.data.user.phone
    //         //   )
    //         // );
    //         // dispatch(
    //         //   logInUserDetailsAction.setLoggedInUserRole(response.data.user.role)
    //         // );
    //         // dispatch(
    //         //   logInUserDetailsAction.setLoggedInUserWalletBalance(
    //         //     response.data.wallet_balance
    //         //   )
    //         // );
    //       });
    //   }
  }, [userDetails]);

  const logoutHandler = () => {
    localStorage.removeItem("loginToken");
    // dispatch(fetchLoggedInUserDetails(localStorage.getItem("loginToken")));
    window.location.href = "/";
    notify();
  };

  //selected astrologer
  const token = localStorage.getItem("guruToken");
  //
  useEffect(() => {
    if (token) {
      axios.get(G_GET_SINGLE_GURUS(token)).then((response) => {
        dispatch(
          selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
        );
      });
    }
    // eslint-disable-next-line
  }, [token]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isHoroscope = location.pathname === "/horoscope";

  const isHome = location.pathname === "/";
  // const isChat = location.pathname === "/chatWithAstrologers";
  const isChat = location.pathname === "/streamWithAstrologer";
  const isCall = location.pathname === "/doctors";
  // const isAstrologer = location.pathname === "/all-astrologers";
  const isWallet = location.pathname === "/wallet";
  // const wallet = useSelector(selectWallet);

  const onCloseModal2 = () => {
    if (isWallet) {
      navigate("/");
    }
    onCloseModal1();
  };

  useEffect(() => {
    //
    if (!loggedIn && isWallet) {
      onOpenModal1();
    } else {
      onCloseModal1();
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  // useEffect(() => {
  //   if(loggedIn && isWallet){
  //     setIsWalletOpen(true)
  //   }
  // }, [setIsWalletOpen])

  const handleRedirectWallet = () => {
    navigate("/wallet");
  };

  // const toolbarRef = useRef(null);
  // const showAnim = useRef(null);

  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);

  //   showAnim.current = gsap
  //     .from(toolbarRef.current, {
  //       yPercent: -100,
  //       paused: true,
  //       duration: 0.2,
  //     })
  //     .progress(1);

  //   ScrollTrigger.create({
  //     start: "top top",
  //     end: 99999,
  //     onUpdate: (self) => {
  //       self.direction === -1
  //         ? showAnim.current.play()
  //         : showAnim.current.reverse();
  //     },
  //   });

  //   return () => {
  //     showAnim.current.kill(); // kill the animation on unmount
  //     ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  //   };
  // }, []);

  const [inputVisible, setInputVisible] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const redirectToAstroProfile = filteredData?.guru?.docs?.user?.guru;
  const astroFirstName = filteredData?.guru?.docs?.user?.firstName;
  const astroLastName = filteredData?.guru?.docs?.user?.lastName;

  const handleSearchIconClick = () => {
    if (inputVisible === false) {
      setInputVisible(true);
    } else {
      setInputVisible(false);
    }
  };

  const handleSearch = () => {
    axios
      .get("https://data.gurucool.life/api/v1/guru/homePageSearchBar", {
        // https://data.gurucool.life/api/v1/guru/homePageSearchBar
        params: {
          Name: searchTerm,
        },
      })
      .then((response) => {
        setFilteredData(response.data.guru);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // console.log("Filtered Data>>>>>>>>>>>", filteredData)
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (searchTerm) {
        handleSearch();
      }
    }, 500); // Adjust the delay time according to your needs

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const {
    isOpen: isOpenNavigation,
    onOpen: onOpenNavigation,
    onClose: onCloseNavigation,
  } = useDisclosure();
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = React.useRef();

  const breakpoints = {
    sm: "40em", // 480px
    md: "48em", // 768px
    lg: "62em", // 992px
    xl: "80em", // 1280px
    "2xl": "96em", // 1536px
  };

  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const changePosition = () => {
    if (window.scrollY >= 92) {
      setIsHeaderFixed(true);
    } else {
      setIsHeaderFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changePosition);
    return () => {
      window.removeEventListener("scroll", changePosition);
    };
  }, []);
  //Call Queue Implementation
  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const [stopwatch, setStopwatch] = useState(false);
  const [callQueue, setCallQueue] = useState([]);
  useEffect(() => {
    const fetchData = async () => {   
      try {
        const response = await axios.get(G_GET_USER_PROFILE, {
          headers: { Authorization: `Bearer ${loginToken}` },
        });
        console.log("Prev Data", callQueue);
        console.log("Current Data", response?.data?.guru?.callQueue);
        setCallQueue(response?.data?.guru?.callQueue);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    // if(loginToken && userDetails?.user.role==="astrologer" ){
    // }
    const interval = setInterval(fetchData, 5000);

    if (userDetails?.user?.role !== "astrologer") {
      clearInterval(interval);
    }

    // Call fetchData immediately upon mounting
    fetchData();
    // if(userDetails?.user?.role!=="astrologer"){
    //   clearInterval(interval);
    // }

    return () => clearInterval(interval);
  }, [loginToken]);

  // window.addEventListener("scroll", changePosition);

  return (
    <>
      <Box
        bg={useColorModeValue("#FFECDD")}
        // p={{ md: 2, base: 0 }}
        position={isHeaderFixed ? "fixed" : "sticky"}
        w="100%"
        top="0"
        zIndex="sticky"
        className="main-tool-bar"
        id="top-navbar"
        py={{ md: "18px" }}
        transition="top 0.3s ease-in-out"
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={0}
          padding={{ base: "0 !important" }}
        >
          {/* {loggedIn && isWallet && wallet && <div className="wallet">{wallet}</div>} */}
          <Flex
            h={14}
            alignItems={{ md: "flex-end", base: "center" }}
            justifyContent={"space-between"}
            px={"10px"}
          >
            <Box
              px={{ base: "16px" }}
              display={"flex"}
              gap={{ base: "14px" }}
              padding={"0 !important"}
            >
              <>
                <Image
                  alt="hamburger"
                  py={{ base: "0 !important" }}
                  w={{ base: "20px", sm: "40px" }}
                  src={hamburgerIcon}
                  display={{
                    base:
                      loggedIn && loggedInStatus === "succeeded"
                        ? "flex"
                        : "none",
                    md: "none",
                  }}
                  // leftIcon={<HamburgerIcon />}
                  bg={"tr"}
                  color="#4B4B4B"
                  onClick={() => {
                    onOpenDrawer(), logEvent(analytics, "menubar_click");
                  }}
                ></Image>
                <Drawer
                  size={"xs"}
                  isOpen={isOpenDrawer}
                  placement="left"
                  initialFocusRef={firstField}
                  onClose={onCloseDrawer}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                      <Flex
                        alignItems={"center"}
                        justifyContent={"start"}
                        gap="10px"
                      >
                        {/* {userDetails?.user?.avatar ? <Image w="48px" borderRadius={"50%"} src={userDetails?.user?.avatar?.url} /> : <Image w="48px" borderRadius={"50%"} src={DefaultAvatar} />} */}
                        {userDetails?.user?.avatar ? (
                          <Image
                            w="48px"
                            borderRadius="50%"
                            src={userDetails?.user?.avatar?.url}
                          />
                        ) : (
                          <Avatar
                            name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
                            size={"md"}
                          ></Avatar>
                        )}
                        <Text m="0">
                          {userDetails?.user?.firstName}{" "}
                          {userDetails?.user?.lastName}
                          {/* {console.log(userDetails)} */}
                        </Text>
                      </Flex>
                    </DrawerHeader>

                    <DrawerBody>
                      <Stack spacing="24px">
                        <Box>
                          <Link href="/myProfile">
                            <Flex gap="10px">
                              <Image src={profileIcon} />
                              <Text mb="0px">
                                {userDetails?.user?.role === "astrologer"
                                  ? "My Dashboard"
                                  : "My Profile"}
                                {/* My Dashboard */}
                              </Text>
                            </Flex>
                          </Link>
                        </Box>

                        <Box>
                          <Link
                            href="/wallet"
                            display={
                              userDetails &&
                              userDetails?.user?.role === "astrologer"
                                ? "none"
                                : "flex"
                            }
                            // justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Image src={Wallet} mr="10px" />
                            Wallet:{" "}
                            <Text
                              ml="130px"
                              // justifyContent={"center"}
                              display={"flex"}
                              alignItems={"center"}
                              mb="0"
                            >
                              {userDetails.userDetails &&
                                (isInternational
                                  ? "$ " +
                                    parseFloat(
                                      userDetails.userDetails
                                        .internationalWallet
                                    ).toFixed(2)
                                  : "₹ " +
                                    Math.round(userDetails.userDetails.wallet))}
                            </Text>
                          </Link>
                        </Box>
                        {/* {isAdmin && (
                          <Box>
                            <Link href="/admin">Admin Panel</Link>
                          </Box>
                        )} */}
                      </Stack>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth="1px" mb={"125px"}>
                      <Flex
                        gap="190px"
                        justifyContent={"flex-start"}
                        alignItems={"start"}
                        onClick={() => logoutHandler()}
                      >
                        <Text>Log Out</Text>
                        <Image src={LogOut} />
                      </Flex>
                    </DrawerFooter>
                  </DrawerContent>
                </Drawer>
              </>
              {/* <Menu> */}
              {/* {loggedIn ? ( */}
              {/* <>
                  <ToastContainer />
                  <MenuButton
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                  >
                    <HamburgerIcon
                      display={{ base: "block", md: "none" }}
                      color={"black"}
                    /> */}
              {/* <Avatar
                        display={{ base: "none", md: "block" }}
                        size={"sm"}
                        src={userImg}
                      /> */}
              {/* </MenuButton>
                  <MenuList>
                    <MenuItem as={Link} href="/myProfile">
                      {userDetails?.user?.role === "astrologer"
                        ? "My Dashboard"
                        : "My Profile"} */}
              {/* My Dashboard */}
              {/* </MenuItem>
                    <MenuItem
                      as={Link}
                      href="/wallet"
                      display={
                        userDetails && userDetails?.user?.role === "astrologer"
                          ? "none"
                          : "flex"
                      }
                    > */}
              {/* Wallet:{" "}
                      {userDetails.userDetails &&
                        (isInternational
                          ? "$ " +
                            parseFloat(
                              userDetails.userDetails.internationalWallet
                            ).toFixed(2)
                          : "₹ " + Math.round(userDetails.userDetails.wallet))}
                    </MenuItem>
                    {isAdmin && ( */}
              {/* <>
                        <MenuDivider />
                        <MenuItem as={Link} href="/admin">
                          Admin Panel
                        </MenuItem>
                        <MenuDivider />
                      </> */}
              {/* )} */}
              {/* <MenuItem onClick={() => logoutHandler()}>Logout</MenuItem>
                  </MenuList> */}
              {/* </> */}
              {/* ) : ( */}
              {/* <MenuButton
                  display={{ md: "none", base: "none" }}
                  onClick={onOpenModal1}
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    colorScheme="orange"
                    variant="solid"
                    w={{ md: "120px" }}
                  >
                    Log In
                  </Button>
                </MenuButton> */}
              {/* )} */}
              {/* </Menu> */}
              <Stack
                display={{ base: inputVisible ? "none" : "block", md: "block" }}
              >
                <NavLink to="/">
                  <Image
                    src={gurucool_logo}
                    w={{ base: "130px", md: "215px", lg: "206px" }}
                    h={{ base: "36px", md: "45px", lg: "56px" }}
                    alt="gurucool_logo"
                  ></Image>
                </NavLink>
              </Stack>
            </Box>
            {/* <IconButton
              aria-label="Hamburger"
              size={"md"}
              icon={
                isOpen ? (
                  <CloseIcon color={"white"} pb="2px" />
                ) : (
                  <HamburgerIcon color={"white"} pb="2px" variant="outline" />
                )
              }
              // aria-label={"Open Menu"}
              bg={"#BC5D14"}
              borderRadius="6px"
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
              sx={{
                backgroundColor: isOpen ? "#BC5D14" : "#BC5D14",
                transition: "background-color 0.1s ease-in-out",
              }}
            /> */}
            {/* <Flex flexDirection={'row'} display={{md:'none', base:'block'}}> */}
            {/* <div>
      {!inputVisible && (
        <button onClick={handleSearchIconClick}>
          <SearchIcon color="#4B4B4B" />
        </button>
      )}
      {inputVisible && (
        <div>
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="search astrologer"
          />
          <button onClick={handleSearch}>
            <SearchIcon color="#4B4B4B" />
          </button>
        </div>
      )}
    </div> */}
            {/* {console.log(userDetails)} */}

            <Box
              mr={"0 !important"}
              gap={"18px"}
              flexDirection={"row-reverse"}
              paddingLeft={{ base: "85px", md: "0" }}
            >
              {/* <Box
                display={{ md: "none", base: "flex" }}
              >
                {!inputVisible && (
                  <Image
                    src={searchIcon}
                    // paddingTop={"10px"}
                    py={"0 !important"}
                    bg={"none"}
                    _hover={{ bg: "#BC5D14", color: "white" }}
                    onClick={handleSearchIconClick}
                  >

                  </Image>
                )}
                {inputVisible && (
                  <>
                    <Box
                      className="searchContainer"
                      style={{ height: "38px" }}
                      display={"flex"}
                    >
                      <Input
                        border={"1px solid #4B4B4B"}
                        borderRight={"none !important"}
                        type="text"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        borderRadius={"4px 0 0 4px"}
                        value={searchTerm}
                        bg={"white"}
                        placeholder="search astrologer"
                        focusBorderColor="orange"
                      />
                      <Button
                        borderLeft={"none !important"}
                        border={"1px solid #4B4B4B"}
                        borderRadius={"0 4px 4px 0 !important"}
                        maxW={"40px"}
                        onClick={handleSearchIconClick}
                        bg={"none"}
                        _hover={{ bg: "#BC5D14", color: "white" }}
                      >
                        <CloseIcon color={"#4B4B4B"} />
                      </Button>
                    </Box>
                    {searchTerm && filteredData.length !== 0 && (
                      <div
                        className="dataResultMobile"
                        style={{
                          borderRadius: "0 0 4px 4px",
                          display: "flex",
                          flexDirection: "column",
                          width: "211px",
                          maxHeight: "100px", // Set the maximum height to show 5 items
                          overflowY: "scroll", // Enable vertical scroll
                          scrollbarWidth: "thin", // Set the width of the scrollbar to be thin
                          scrollbarColor: "transparent transparent", // Make the scrollbar transparent
                        }}
                      >
                        {Array.isArray(filteredData) &&
                          filteredData.map((item, index) => (
                            <a
                              className="dataItem"
                              style={{ display: "block", height: "40px" }}
                              href={`/astrologer/${item?.firstName}${item?.lastName}?id=${item?.guru}`}
                              key={index}
                            >
                              <p>
                                {item?.firstName} {item?.lastName}
                              </p>
                            </a>
                          ))}
                      </div>
                    )}
                  </>
                )}
              </Box> */}
              <Box
                display={"flex"}
                // display={loggedIn ? "flex" : "none"}
                gap={"20px"}
              >
                {/* {loggedInStatus === "succeeded" && (
                  <Image
                    alt="notification"
                    display={{
                      base: inputVisible ? "none" : "flex",
                      md: "none",
                    }}
                    w={{ base: "21px" }}
                    onClick={onOpenNavigation}
                    src={notification}
                  ></Image>
                )} */}
                <Drawer
                  size={"xs"}
                  isOpen={isOpenNavigation}
                  placement="right"
                  initialFocusRef={firstField}
                  onClose={onCloseNavigation}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Notification</DrawerHeader>
                  </DrawerContent>
                </Drawer>

                {/* <IoNotificationsOutline color="black" /> */}
                {loggedInStatus === "succeeded" && (
                  <Box
                    display={{
                      base: inputVisible ? "none" : "flex",
                      md: "none",
                    
                    }}
                  >
                    <HStack
                    
                      h={"fit-content"}
                      alignSelf={"center"}
                      // w={{base:'59px', md:'auto'}}
                      // ml={{ base: "20px", md: "nones" }}
                      // p={{ md: "6px", base: "4px" }}
                      p={"1 !important"}
                      border="1.5px solid orange"
                      borderRadius={"4px"}
                      // mr={"24px"}
                      cursor="pointer"
                      display={{
                        base:
                          userDetails &&
                          userDetails?.user?.role === "astrologer"
                            ? "none"
                            : "flex",
                        md:
                          userDetails &&
                          userDetails?.user?.role === "astrologer"
                            ? "none"
                            : "none",
                      }}
                      onClick={() => {
                        handleRedirectWallet();
                        logEvent(analytics, "wallet_click");
                      }}
                    >
                      <Image
                        w={{ base: "24px", sm: "24px", md: "38px" }}
                        src={walletIcon}
                      ></Image>
                       <Text
                       color={"orange.600"}
                       fontWeight={700}
                       fontSize={12}
                              ml="130px"
                              // justifyContent={"center"}
                              display={"flex"}
                              alignItems={"center"}
                              mb="0"
                            >
                              {userDetails.userDetails &&
                                (isInternational
                                  ? "$ " +
                                    parseFloat(
                                      userDetails.userDetails
                                        .internationalWallet
                                    ).toFixed(2)
                                  : "₹ " +
                                    Math.round(userDetails.userDetails.wallet))}
                            </Text>
                    </HStack>
                  </Box>
                )}
                <Box display={{ md: "none", base: "none" }}>
                  {!inputVisible && (
                    <Image
                      alt="search"
                      src={searchIcon}
                      // paddingTop={"10px"}
                      display={{
                        base: loggedInStatus === "succeeded" ? "flex" : "flex",
                        md: "none",
                      }}
                      // ml={'47px'}
                      py={"0 !important"}
                      bg={"none"}
                      _hover={{ bg: "#BC5D14", color: "white" }}
                      onClick={handleSearchIconClick}
                    ></Image>
                  )}
                  <Box>
                    {inputVisible && (
                      <>
                        <Box
                          className="searchContainer"
                          style={{ height: "38px", marginRight: "10px" }}
                          display={"flex"}
                        >
                          <Input
                            border={"1px solid #4B4B4B"}
                            borderRight={"none !important"}
                            type="text"
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                            borderRadius={"4px 0 0 4px"}
                            value={searchTerm}
                            bg={"white"}
                            placeholder="search astrologer"
                            focusBorderColor="orange"
                            onClick={logEvent(analytics, "search_click")}
                          />
                          <Button
                            borderLeft={"none !important"}
                            border={"1px solid #4B4B4B"}
                            borderRadius={"0 4px 4px 0 !important"}
                            maxW={"40px"}
                            onClick={handleSearchIconClick}
                            bg={"none"}
                            _hover={{ bg: "#BC5D14", color: "white" }}
                          >
                            <CloseIcon color={"#4B4B4B"} />
                          </Button>
                        </Box>
                        {/* {searchTerm && filteredData.length !== 0 && (
                        <div
                          className="dataResultMobile"
                          style={{
                            borderRadius: "0 0 4px 4px",
                            display: "flex",
                            flexDirection: "column",
                            width: "215px",
                            maxHeight: "100px", // Set the maximum height to show 5 items
                            overflowY: "scroll", // Enable vertical scroll
                            scrollbarWidth: "thin", // Set the width of the scrollbar to be thin
                            scrollbarColor: "transparent transparent", // Make the scrollbar transparent
                          }}
                        >
                          {Array.isArray(filteredData) &&
                            filteredData.map((item, index) => (
                              <a
                                className="dataItem"
                                style={{ display: "block", height: "40px" }}
                                href={`/astrologer/${item?.firstName}${item?.lastName}?id=${item?.guru?._id}`}
                                key={index}
                              >
                                <p style={{height:"33px"}}>
                                  {item?.firstName} {item?.lastName}
                                </p>
                              </a>
                            ))}
                        </div>
                      )} */}

                        {searchTerm && filteredData.length !== 0 && (
                          <Box
                            borderRadius="0 0 4px 4px"
                            display="flex"
                            flexDirection="column"
                            maxWidth={{
                              md: "215px",
                              sm: "215px",
                              base: "215px",
                            }}
                            // maxHeight="100px"
                            overflowY="scroll"
                            scrollbarWidth="thin"
                            scrollbarColor="transparent transparent"
                            className="dataResultMobile"
                          >
                            {Array.isArray(filteredData) &&
                              filteredData.map((item, index) => (
                                <Text
                                  as="a"
                                  className="dataItem"
                                  style={{ display: "block", height: "40px" }}
                                  // href={`/astrologer/${item?.firstName}${item?.lastName}?id=${item?.guru?._id}`}
                                  href={`/astrologer/${item?.userName}`}
                                  key={index}
                                  maxWidth={{
                                    md: "215px",
                                    sm: "200px",
                                    base: "200px",
                                  }}
                                >
                                  <Text as="p">
                                    {item?.firstName} {item?.lastName}
                                  </Text>
                                </Text>
                              ))}
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>

              {/* </Flex> */}

              {/* <Spacer/> */}
            </Box>

            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              <NavLink
                to="/"
                // className="navbarLinks"
                onClick={() => handleClick("/", "home")}
              >
                <Text
                  // bg={isHome ? "#BC5D14" : "#RRGGBB"}
                  _hover={{
                    bg: "#EEB080",
                  }}
                  color={isHome ? "#BC5D14" : "black"}
                  fontWeight={isHome ? "bold" : "md"}
                  p={"0.4rem 0.7rem"}
                  borderRadius="10px"
                  fontSize={{ base: "inherit", md: "15px" }}
                >
                  Home
                </Text>
              </NavLink>
              <NavLink
                to="/doctors"
                // className="navbarLinks"
                onClick={() => {
                  handleClick("/doctors");
                  logEvent(analytics, "ConsultAstrologerPage_enter");
                }}
              >
                <Text
                  // bg={isCall ? "#BC5D14" : "#RRGGBB"}
                  _hover={{
                    bg: "#EEB080",
                  }}
                  color={isCall ? "#BC5D14" : "black"}
                  fontWeight={isCall ? "bold" : "md"}
                  p={"0.4rem 0.7rem"}
                  borderRadius="10px"
                  fontSize={{ base: "inherit", md: "15px" }}
                >
                  Consult Doctor
                </Text>
              </NavLink>
              {/* <NavLink
                to="/streamWithAstrologer"
                // className="navbarLinks"
                // bg={isHome ? "#BC5D14" : "#RRGGBB"}
                onClick={() => handleClick("/streamWithAstrologer")}
              >
                <Text
                  // bg={isChat ? "#BC5D14" : "#RRGGBB"}
                  _hover={{
                    bg: "#EEB080",
                  }}
                  color={isChat ? "#BC5D14" : "black"}
                  fontWeight={isChat ? "bold" : "md"}
                  p={"0.4rem 0.7rem"}
                  borderRadius="10px"
                  fontSize={{ base: "inherit", md: "15px" }}
                >
                  Live Astrologers
                </Text>
              </NavLink> */}

              {/* {loggedIn?(
              <NavLink
                to="/withlogin"
                // className="navbarLinks"
                // onClick={() => handleClick("/")}
              >
                <Text
                  // bg={isHome ? "#BC5D14" : "#RRGGBB"}
                  _hover={{
                    bg: "#EEB080",
                  }}
                  // color={isHoroscope ? "#BC5D14" : "black"}
                  // fontWeight={isHoroscope ? "bold" : "md"}
                  p={"0.4rem 0.7rem"}
                  borderRadius="10px"
                >
                  Horoscope
                </Text>
              </NavLink>):(<NavLink
                to="/withoutlogin"
                // className="navbarLinks"
                onClick={() => handleClick("/withoutlogin")}
              >
                <Text
                  // bg={isHome ? "#BC5D14" : "#RRGGBB"}
                  _hover={{
                    bg: "#EEB080",
                  }}
                  color={isHoroscope ? "#BC5D14" : "black"}
                  fontWeight={isHoroscope ? "bold" : "md"}
                  p={"0.4rem 0.7rem"}
                  borderRadius="10px"
                >
                  Horoscope
                </Text>
              </NavLink>)} */}

              {/* <NavLink
                to="/horoscope"
                // className="navbarLinks"
                onClick={() => handleClick("/horoscope")}
              >
                <Text
                  // bg={isHome ? "#BC5D14" : "#RRGGBB"}
                  _hover={{
                    bg: "#EEB080",
                  }}
                  color={isHoroscope ? "#BC5D14" : "black"}
                  fontWeight={isHoroscope ? "bold" : "md"}
                  p={"0.4rem 0.7rem"}
                  borderRadius="10px"
                  fontSize={{base:"inherit", md:"15px"}}
                >
                  Horoscope
                </Text>
              </NavLink> */}

              {/* <NavLink
                  to="/all-astrologers"
                  // className="navbarLinks"
                  onClick={() => handleClick("/all-astrologers")}
                >
                  <Text
                    // bg={isAstrologer ? "#BC5D14" : "#RRGGBB"}
                    _hover={{
                      bg: "#EEB080",
                    }}
                    color={isAstrologer ? "#BC5D14" : "black"}
                    fontWeight={isAstrologer ? "bold" : "md"}
                    p={"0.4rem 0.7rem"}
                    borderRadius="10px"
                  >
                    Astrologers
                  </Text>
                </NavLink> */}
              {/* <NavLink
                  to="/"
                  // className="navbarLinks"
                  onClick={() => handleClick("/")}
                >
                  <Text
                    // bg={isCall ? "#BC5D14" : "#RRGGBB"}
                    _hover={{
                      bg: "#EEB080",
                    }}
                    color={isCall ? "#BC5D14" : "black"}
                    fontWeight={isCall ? "bold" : "md"}
                    p={"0.4rem 0.7rem"}
                    borderRadius="10px"
                  >
                    Live Coming Soon
                  </Text>
                </NavLink> */}
            </HStack>
            {/* </HStack> */}
            <Box
              className="searchContainer"
              style={{ height: "38px" }}
              display={{ md: "block", base: "none" }}
            >
              <Input
                display={"flex"}
                alignItems={"center"}
                type="text"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                value={searchTerm}
                bg={"white"}
                placeholder="Search Doctor"
                focusBorderColor="orange"
                w={"200px"}
                onClick={logEvent(analytics, "search_click")}
              />

              {searchTerm.length !== 0 && filteredData.length !== 0 && (
                <div
                  className="dataResult"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "200px",
                    maxHeight: "100px", // Set the maximum height to show 5 items
                    overflowY: "scroll", // Enable vertical scroll
                    scrollbarWidth: "thin", // Set the width of the scrollbar to be thin
                    scrollbarColor: "transparent transparent", // Make the scrollbar transparent
                  }}
                >
                  {Array.isArray(filteredData) &&
                    filteredData.map((item, index) => (
                      <>
                        {/* {console.log(
                          item?.guru?.firstName,
                          item?.guru?.lastName
                        )} */}
                        <a
                          className="dataItem"
                          style={{ display: "block", height: "40px" }}
                          // href={`/astrologer/${item?.firstName}${item?.lastName}?id=${item?.guru?.userNmae}`}
                          href={`/astrologer/${item?.userName}`}
                          key={index}
                        >
                          {/* {name} */}
                          <p>
                            {" "}
                            {item?.firstName} {item?.lastName}
                          </p>
                        </a>
                      </>
                    ))}
                </div>
              )}
            </Box>

            {/* <Stack display={{ md: "flex", base: "none" }}>
            {/* <Stack display={{ md: "flex", base: "none" }}>
            {/* <Stack display={{ md: "flex", base: "none" }}>
              {/* {!inputVisible && (
                <Button
                  // paddingTop={"10px"}
                  bg={"none"}
                  onClick={handleSearchIconClick}
                >
                  <SearchIcon color={"#4B4B4B"} />
                </Button>
              )} */}

            {/* <Box flexDirection={"column"} gap={'0 !important'} position={'relative'}> */}
            {/* <Flex flexDirection={'row'}> */}
            {/* <Input
                display={'flex'}
                alignItems={'center'}
                  type="text"
                  onChange={handleInputChange}
                  onKeyDown={handleSearch}
                  value={searchTerm}
                  bg={"white"}
                  placeholder="search astrologer"
                  focusBorderColor="orange"
                /> */}
            {/* <Button
                    maxW={"40px"}
                    // onClick={() => handleClick(`/astrologer/${astroFirstName}${astroLastName}?id=${redirectToAstroProfile}`)}
                    bg={"none"}
                    _hover={{bg:"#BC5D14", color:'white'}}  
                  >
                     <SearchIcon  color={"#4B4B4B"} />
                  </Button> */}
            {/* </Flex> */}

            {/* {filteredData.length !== 0 && (
  <div className="dataResult" style={{display:"flex", flexDirection:"column"}}>
    {filteredNames.map((name, index) => (
      <a className="dataItem" style={{display:'block'}} href={`/astrologer/${astroFirstName}${astroLastName}?id=${redirectToAstroProfile}`} key={index}>
        <p>{name}</p>
      </a>
    ))}
  </div>
)} */}

            {/* </Box> */}
            {/* </Stack> */}
            {/* <Box
              display={{ md: "none", base: "flex" }}
              flexDirection={"column"}
              ml={'10px'}
            >
              {!inputVisible && (
                // <Button
                //   // paddingTop={"10px"}
                //   py={"0 !important"}
                //   bg={"none"}
                //   _hover={{ bg: "#BC5D14", color: "white" }}
                //   onClick={handleSearchIconClick}
                // >
                //   <SearchIcon color={"#4B4B4B"} />
                // </Button>
                <Image 
                src={searchIcon}
                  // paddingTop={"10px"}
                  py={"0 !important"}
                  bg={"none"}
                  _hover={{ bg: "#BC5D14", color: "white" }}
                  onClick={handleSearchIconClick}
                >

                </Image>
              )}
              {inputVisible && (
                <>
                  <Box
                    className="searchContainer"
                    style={{ height: "38px" }}
                    display={"flex"}
                  >
                    <Input
                      border={"1px solid #4B4B4B"}
                      borderRight={"none !important"}
                      type="text"
                      onChange={handleInputChange}
                      onKeyDown={handleSearch}
                      borderRadius={"4px 0 0 4px"}
                      value={searchTerm}
                      bg={"white"}
                      placeholder="search astrologer"
                      focusBorderColor="orange"
                    />
                    <Button
                      borderLeft={"none !important"}
                      border={"1px solid #4B4B4B"}
                      borderRadius={"0 4px 4px 0 !important"}
                      maxW={"40px"}
                      onClick={handleSearchIconClick}
                      bg={"none"}
                      _hover={{ bg: "#BC5D14", color: "white" }}
                    >
                      <CloseIcon color={"#4B4B4B"} />
                    </Button>
                  </Box>
                  {searchTerm && filteredData.length !== 0 && (
                    <div
                      className="dataResultMobile"
                      style={{
                        borderRadius: "0 0 4px 4px",
                        display: "flex",
                        flexDirection: "column",
                        width: "211px",
                        maxHeight: "100px", // Set the maximum height to show 5 items
                        overflowY: "scroll", // Enable vertical scroll
                        scrollbarWidth: "thin", // Set the width of the scrollbar to be thin
                        scrollbarColor: "transparent transparent", // Make the scrollbar transparent
                      }}
                    >
                      {Array.isArray(filteredData) &&
                        filteredData.map((item, index) => (
                          <a
                            className="dataItem"
                            style={{ display: "block", height: "40px" }}
                            href={`/astrologer/${item?.firstName}${item?.lastName}?id=${item?.guru}`}
                            key={index}
                          >
                            <p>
                              {item?.firstName} {item?.lastName}
                            </p>
                          </a>
                        ))}
                    </div>
                  )}
                </>
              )}
            </Box> */}

            <Flex alignItems={"center"}>
              <Menu>
                {loggedIn && loggedInStatus === "succeeded" ? (
                  <>
                    <ToastContainer />

                    <HStack
                      p={"4px 6px"}
                      border="1px solid orange"
                      borderRadius={"4px"}
                      mr={"24px"}
                      cursor="pointer"
                      display={{
                        base: "none",
                        md:
                          userDetails &&
                          userDetails?.user?.role === "astrologer"
                            ? "none"
                            : "flex",
                      }}
                      onClick={() => {
                        handleRedirectWallet();
                        logEvent(analytics, "wallet_click");
                      }}
                    >
                      <Icon as={BiWalletAlt} boxSize={6} color="orange.600" />
                      <Text fontWeight="bold" color="orange.600">
                        {userDetails.userDetails &&
                          (isInternational
                            ? "$ " +
                              parseFloat(
                                userDetails.userDetails.internationalWallet
                              ).toFixed(2)
                            : "₹ " +
                              Math.round(userDetails.userDetails.wallet))}
                      </Text>
                    </HStack>
                    <Box display={{ base: "none", md: "flex" }}>
                      <Image
                        alt="notification"
                        onClick={() => {
                          onOpenNavigation(),
                            logEvent(analytics, "ConsultAstrologerPage_enter");
                        }}
                        src={notification}
                        marginRight={"18px"}
                      ></Image>
                      <Drawer
                        size={"xs"}
                        isOpen={isOpenNavigation}
                        placement="right"
                        initialFocusRef={firstField}
                        onClose={onCloseNavigation}
                      >
                        <DrawerOverlay />
                        <DrawerContent>
                          <DrawerCloseButton />
                          <DrawerHeader>
                            <Text textAlign={"center"}>Notification</Text>
                            {/* <Box>
                              <hr style={{ color: "#FF4C00" }} />
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Box>
                                  <Button
                                    bg={"#FF4C00"}
                                    color={"white"}
                                    borderRadius={"20px"}
                                    mr={"5px"}
                                  >
                                    Read
                                  </Button>
                                  <Button borderRadius={"20px"}>Unread</Button>
                                </Box>
                                <Box>
                                  <Button variant="ghost" color={"#FF4C00"}>
                                    Clear All
                                  </Button>
                                </Box>
                              </Box>
                              <hr style={{ color: "#FF4C00" }} />
                            </Box> */}
                          </DrawerHeader>
                          <DrawerBody>
                            <Box>
                              <Image
                                alignContent={"center"}
                                // ml="20px"
                                m="auto"
                                boxSize={{
                                  md: "200px",
                                  sm: "150px",
                                  base: "150px",
                                }}
                                src={NoNotification}
                              />
                              <Text
                                textAlign={"center"}
                                fontWeight={"500"}
                                fontSize={{
                                  md: "20px",
                                  sm: "18px",
                                  base: "18px",
                                }}
                                mt="20px"
                              >
                                No Notifications Right Now?
                              </Text>
                              <Text
                                textAlign={"center"}
                                fontWeight={"400"}
                                fontSize={{
                                  md: "18px",
                                  sm: "14px",
                                  base: "14px",
                                }}
                              >
                                When you have notification, You’ll see them here
                              </Text>
                            </Box>
                          </DrawerBody>
                          {/* {/* <DrawerBody mt={"-30px"}>
                          <Box display={"flex"} alignItems={"start"} gap={"20px"}  border={""} bg={""} height={"120px"} m={"0"} p={"0"}>
                          <Avatar name='Dan Abrahmov' src='https://bit.ly/dan-abramov' h={"60px"} w={"60px"} >
                              <AvatarBadge boxSize='1em' bg='green.500' />
                          </Avatar>
                          <Box m={"-6px"} p={"0"}>
                            <Text m={"0"} p={"0"} fontSize={"18px"} color={"#1B1B1B"} fontWeight={"500"} >Astro Arlene</Text>
                            <Text m={"0"} p={"0"} textAlign={""} fontSize={"16px"} color={"#4F4F4F"} lineHeight={"17px"} >Provided a remedies ovided a remedies Provided a remedies</Text>
                            <Text m={"0"} p={"0"} fontSize={"14px"} color={"#A8A8A8"}>20 min ago</Text>
                          </Box>

                          </Box>
                          <Box display={"flex"} alignItems={"start"} gap={"20px"}  border={""} bg={""} height={"120px"} m={"0"} p={"0"}>
                          <Avatar name='Dan Abrahmov' src='https://bit.ly/dan-abramov' h={"60px"} w={"60px"} >
                              <AvatarBadge boxSize='1em' bg='green.500' />
                          </Avatar>
                          <Box m={"-6px"} p={"0"}>
                            <Text m={"0"} p={"0"} fontSize={"18px"} color={"#1B1B1B"} fontWeight={"500"} >Astro Arlene</Text>
                            <Text m={"0"} p={"0"} textAlign={""} fontSize={"16px"} color={"#4F4F4F"} lineHeight={"17px"} >Provided a remedies ovided a remedies Provided a remedies</Text>
                            <Text m={"0"} p={"0"} fontSize={"14px"} color={"#A8A8A8"}>20 min ago</Text>
                          </Box>

                          </Box>
                         </DrawerBody> */}
                        </DrawerContent>
                      </Drawer>
                    </Box>
                    <MenuButton
                      as={Button}
                      rounded={"full"}
                      variant={"link"}
                      cursor={"pointer"}
                      minW={0}
                    >
                      {/* <HamburgerIcon
                        display={{ base: "block", md: "none" }}
                        color={"black"}
                      /> */}
                      {/* <Avatar
                        display={{ base: "none", md: "block" }}
                        size={"sm"}
                        src={DefaultAvatar}
                      /> */}

                      {userDetails?.user?.avatar ? (
                        <Image
                          display={{ base: "none", md: "block" }}
                          w="36px"
                          h="36px"
                          borderRadius="50%"
                          src={userDetails?.user?.avatar?.url}
                        />
                      ) : (
                        <Avatar
                          display={{ base: "none", md: "block" }}
                          name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
                          size={"sm"}
                        ></Avatar>
                      )}
                    </MenuButton>
                    <MenuList>
                      <MenuItem as={Link} href="/myProfile">
                        {userDetails?.user?.role === "astrologer"
                          ? "My Dashboard"
                          : "My Profile"}
                        {/* My Dashboard */}
                      </MenuItem>
                      <MenuItem
                        as={Link}
                        href="/wallet"
                        display={
                          userDetails &&
                          userDetails?.user?.role === "astrologer"
                            ? "none"
                            : "flex"
                        }
                      >
                        Wallet:{" "}
                        {userDetails.userDetails &&
                          (isInternational
                            ? "$ " +
                              parseFloat(
                                userDetails.userDetails.internationalWallet
                              ).toFixed(2)
                            : "₹ " +
                              Math.round(userDetails.userDetails.wallet))}
                      </MenuItem>
                      {/* {isAdmin && (
                        <>
                          <MenuDivider />
                          <MenuItem as={Link} href="/admin">
                            Admin Panel
                          </MenuItem>
                          <MenuDivider />
                        </>
                      )} */}
                      <MenuItem onClick={() => logoutHandler()}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </>
                ) : (
                  <MenuButton
                    onClick={onOpenModal1}
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      colorScheme="orange"
                      variant="solid"
                      w={{ md: "120px" }}
                      pr={{ base: "16px" }}
                    >
                      Log In
                    </Button>
                  </MenuButton>
                )}
              </Menu>
            </Flex>
          </Flex>

          {/* {isOpen ? ( */}
          <Box
            marginTop={"0 !important"}
            bg={useColorModeValue("#FFECDD")}
            pb={isOpen ? 0 : 0}
            display={{ md: "none" }}
            // sx={{
            //   transition: "all 1s ease-zout",
            // }}
            height={isOpen ? "auto" : "0px"}
            overflowY={isOpen ? "visible" : "hidden"}
          >
            <Stack as={"nav"} spacing={4}>
              <NavLink
                className="navbarLinks"
                to="/"
                onClick={isOpen ? onClose : onOpen}
              >
                Home
              </NavLink>
              <NavLink
                to="/streamWithAstrologer"
                className="navbarLinks"
                onClick={isOpen ? onClose : onOpen}
              >
                Live Coming Soon
              </NavLink>
              <NavLink
                to="/doctors"
                className="navbarLinks"
                onClick={() => {
                  isOpen ? onClose : onOpen;
                  logEvent(analytics, "ConsultAstrologerPage_enter");
                }}
              >
                Consult Astrologers
              </NavLink>
              {/* <NavLink
                to="/all-astrologers"
                className="navbarLinks"
                onClick={isOpen ? onClose : onOpen}
              >
                Astrologers
              </NavLink> */}
            </Stack>
          </Box>
          {/* ) : null} */}
        </Container>
      </Box>
      <Box
        mb={"10px"}
        // style={{ position: "sticky", top: "100px",left:"25vw" }}
        display={userDetails?.guru?.enableQueue ? "block" : "none"}
      >
        <Box
          mt={"10px"}
          display={{ base: "none", sm: "none", md: "flex" }}
          justifyContent={"space-between"}
          pt={"14px"}
          pb={"14px"}
          pl={"29.2px"}
          pr={"29.2px"}
          borderRadius={"102px"}
          h={"107px"}
          bg={"rgba(227, 99, 1, 0.80)"}
          opacity={"0.80"}
          alignItems={"center"}
          width={{ base: "95%", sm: "95%", md: "883px" }}
          // m={"auto"}
          ml={"auto"}
          mr={"auto"}
          // position={"sticky !important"}
          // top={"0"}
        >
          <Box
            display={callQueue?.length > 0 ? "flex" : "none"}
            w={"40%"}
            columnGap={"23.36px"}
          >
            {userDetails?.user?.avatar ? (
              <Image
                // display={{ base: "none", md: "block" }}
                w="68px"
                h="68px"
                borderRadius="50%"
                ml={"0px"}
                src={userDetails?.user?.avatar?.url}
              />
            ) : (
              <Avatar
                display={{ base: "none", md: "block" }}
                name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
                size={"sm"}
              ></Avatar>
            )}
            <Image
              w={"73px"}
              h={"58px"}
              mt={"auto"}
              mb={"auto"}
              src={voiceicon}
            />
            {callQueue?.length > 0 &&
              (stopwatch ? (
                <StopWatch stopwatch={stopwatch} />
              ) : (
                <Text
                  mt={"auto"}
                  mb={"auto"}
                  color={"white"}
                  fontSize={"30.8px"}
                >
                  Connecting
                </Text>
              ))}

            {/* <StopWatch stopwatch={stopwatch} /> */}
          </Box>
          {callQueue?.length > 0 ? (
            <Box display={"flex"} columnGap={"23.36px"}>
              {callQueue?.map((purchaseId) => {
                return (
                  <Avatar
                    display={{ base: "none", md: "block" }}
                    // name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
                    // size={"md"}
                    height={"70px"}
                    width={"70px"}
                    src={avataricon1}
                  ></Avatar>
                );
              })}
              {/* {5-userDetails?.guru?.callQueue?.length} */}
              {[...Array(5 - (callQueue?.length ? callQueue?.length : 0))].map(
                () => (
                  <Avatar
                    display={{ base: "none", md: "block" }}
                    // name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
                    // size={"md"}
                    height={"70px"}
                    width={"70px"}
                    src={avataricon}
                  ></Avatar>
                )
              )}
            </Box>
          ) : (
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent={"space-evenly"}
            >
              {callQueue?.map((purchaseId) => {
                return (
                  <Avatar
                    display={{ base: "none", md: "block" }}
                    // name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
                    // size={"md"}
                    height={"70px"}
                    width={"70px"}
                    src={avataricon1}
                  ></Avatar>
                );
              })}
              {/* {5-userDetails?.guru?.callQueue?.length} */}
              {[...Array(5 - (callQueue?.length ? callQueue?.length : 0))].map(
                () => (
                  <Avatar
                    display={{ base: "none", md: "block" }}
                    // name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
                    // size={"md"}
                    height={"70px"}
                    width={"70px"}
                    src={avataricon}
                  ></Avatar>
                )
              )}
            </Box>
          )}
        </Box>
        <Box
          mt={"10px"}
          display={{ base: "flex", sm: "flex", md: "none" }}
          justifyContent={"space-between"}
          borderRadius={"35px"}
          h={"50px"}
          bg={"rgba(227, 99, 1, 0.80)"}
          opacity={"0.80"}
          p={"10px"}
          alignItems={"center"}
          width={{ base: "85%", sm: "95%", md: "883px" }}
          ml={"auto"}
          mr={"auto"}
          columnGap={"11px"}
        >
          <Box
            display={callQueue?.length > 0 ? "flex" : "none"}
            alignItems={"center"}
            columnGap={"8px"}
          >
            {userDetails?.user?.avatar ? (
              <Image
                // display={{ base: "none", md: "block" }}
                height={"30px"}
                width={"30px"}
                borderRadius="50%"
                ml={"0px"}
                src={userDetails?.user?.avatar?.url}
              />
            ) : (
              <Avatar
                height={"30px"}
                width={"30px"}
                name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
                // size={"sm"}
              ></Avatar>
            )}
            {/* <Avatar height={"30px"} width={"30px"} src={avataricon1}></Avatar> */}
            <Image height={"25px"} width={"20px"} src={voiceicon} />
            {callQueue?.length > 0 &&
              (stopwatch ? (
                <StopWatch stopwatch={stopwatch} />
              ) : (
                <Text color={"white"} mt={"auto"} mb={"auto"} fontSize={"10px"}>
                  Connecting
                </Text>
              ))}
          </Box>
          {callQueue?.length > 0 ? (
            <Box display={"flex"} columnGap={"8px"}>
              {callQueue?.map((purchaseId) => {
                return (
                  <Avatar
                    height={"24px"}
                    width={"24px"}
                    src={avataricon1}
                  ></Avatar>
                );
              })}
              {/* {5-userDetails?.guru?.callQueue?.length} */}
              {[...Array(5 - (callQueue?.length ? callQueue?.length : 0))].map(
                () => (
                  <Avatar
                    height={"24px"}
                    width={"24px"}
                    src={avataricon}
                  ></Avatar>
                )
              )}
            </Box>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"space-evenly"}
              width={"100%"}
            >
              {[...Array(5 - (callQueue?.length ? callQueue?.length : 0))].map(
                () => (
                  <Avatar
                    height={"24px"}
                    width={"24px"}
                    src={avataricon}
                  ></Avatar>
                )
              )}
            </Box>
          )}
        </Box>
      </Box>
      {/* {showLogin ?  */}
      <Modal
        isCentered
        size={{ base: "xs", md: "4xl" }}
        // borderRadius="16px"
        isOpen={isOpenModal1}
        onClose={onCloseModal2}
      >
        <Login />
      </Modal>

      {/* ) : null} */}
    </>
  );
}
