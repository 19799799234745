import React, { useEffect, useState } from "react";
import Hero from "./Hero/Hero";
import UserQuery from "./UserQuery/UserQuery";
import Offers from "./Offers/Offers";
import News from "./News/News";
import Trust from "./Trust/Trust";
import RecommendedAstrologer from "./RecommendedAstrologer/RecommendedAstrologer";
import UserReview from "./UserReview/UserReview";
import { homepageViewEvent } from "../../facebookEvents/pixelEvents";
import { useDispatch, useSelector } from "react-redux";
import logo_gif from "../../assets/loading.gif";
import { Flex, HStack, Image } from "@chakra-ui/react";
import UserTestimonials from "./AstroCard/UserTestimonials";
import Artigyan from "./ArtiGyan/Artigyan";
import Unification from "./Unification/Unification";
import NewHero from "./NewHero/NewHero";
import FreeLive from "./FreeLive/FreeLive";
import homepageAstrologers, {
  fetchHomepageAstrologers,
} from "../../store/homepageAstrologers";
import axios from "axios";
import { G_ALL_LIVE_ASTROLOGERS } from "../../apiLinks";
import { Helmet } from "react-helmet";
import PrimaryLoading from "../Loaders/PrimaryLoading";

import SearchBottomBanner from "./Searchbottombanner/SearchBottomBanner";
import LiveAstrologer from "./Liveastrologer/LiveAstrologer";
import LiveastrologerBottom from "./Liveastrologerbottom/LiveastrologerBottom";
import Feedback from "./Feedback/Feedback";
import Slider from "./Slide/Slider";
import Footerhome from "./Footerhome/Footerhome";
import Testimonal from "./Testimonal/Testimonal";
import Tarot from "./Tarot/TarotAstrologer"
import Career from "./Career/Career"
import Marriage from "./Marriage/MarriageAstrologer"
import SearchBottomCard from "./searchbottomcard/SearchBottomCard";
import SearchBarTop from "./SearchBarTop/SearchBarTop";

const NewHomepage = () => {
  useEffect(() => {
    homepageViewEvent();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHomepageAstrologers());
  }, []);

  const status = useSelector((state) => state.homepageAstrologers.status);
  const [astrologerLive, setAstrologerLive] = useState(false);
  const [liveGurus, setLiveGurus] = useState();

  const getLiveAstrologers = async () => {
    try {
      const response = await axios.get(G_ALL_LIVE_ASTROLOGERS);
      //
      if (response.data.guru.length !== 0) {
        setLiveGurus(response.data.guru);
        setAstrologerLive(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLiveAstrologers();
  }, []);
  return (
    <div style={{ backgroundColor: "#F1ECE9" }}>
      {status === "succeeded" ? (
        <>
          <SearchBarTop />
          <SearchBottomBanner />
          <LiveAstrologer />

          <LiveastrologerBottom />
          <Unification />
          <RecommendedAstrologer />

          <Testimonal />
          <Tarot />
          <Hero />

          <Artigyan />
          <Career />

          <Offers />
          <Marriage />
          <Feedback />
          <UserQuery />
          <Footerhome />
        </>
      ) : (
        <PrimaryLoading />
      )}
    </div>
  );
};

export default NewHomepage;