import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import { useEffect } from "react";

const selectedAstrologerDetailsSlice = createSlice({
  name: "loginSystem",
  initialState: {
    astroDetails:""
  },
  reducers: {
    setAstroDetails(state, action) {
      state.astroDetails = action.payload;
    },
    
  },
  
});

export const selectedAstrologerDetailsAction =
  selectedAstrologerDetailsSlice.actions;
export default selectedAstrologerDetailsSlice.reducer;


