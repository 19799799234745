import React
// , { useEffect, useState }
 from "react";
import {
  Container,
  Box,
  Flex,
  // Image,
  Text,
  Divider,
  VStack,
  // Stack,
  // Button,
  Avatar,
  Center,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
// import _user from "../../../assets/_user.png";
import { useSelector } from "react-redux";
// import axios from "axios";
import Feedback from "../Feedback/Feedback";
import {firebaseApp,analytics,logEvent} from '../../../firebaseEvents/firebase';

const CallConsultationDetails = () => {
  const isInternational = localStorage.getItem("isInternational") === "true";
  const navigate = useNavigate();
  const astroDetails = useSelector(
    (state) => state.selectedAstrologerDetails.astroDetails
  );
  const callEndedDetails = useSelector(
    (state) => state.callConsultationEndedDetails
  );
  callEndedDetails.purchaseId?logEvent(analytics,"call_success"):null
  // const redirectHome = () => {
  //   navigate("/");
  // };
const host = localStorage.getItem("host")
console.log(host);
  return (
    <>
      {astroDetails ? (
        <Container>
          <Box
            // h={"90vh"}
            margin="10%"
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Flex
              direction={"column"}
              borderRadius={"6px"}
              border={host === "myguruji" ? "1px solid #ff4c00" : "1px solid orange"}
              w={"500px"}
              py={2}
            >
              <Box mb={{ base: "8px", md: "16px" }}>
                <Center>
                  <Avatar
                    // mx={"auto"}
                    width="100px"
                    height="100px"
                    src={astroDetails.user.avatar.url}
                    alt="user"
                    // maxW={{ base: "25%", md: "100%" }}
                  />
                </Center>
                <Text
                  fontSize={{ base: "16px", md: "22px" }}
                  fontWeight={"500"}
                  textAlign={"center"}
                >
                  {astroDetails.user.firstName} {astroDetails.user.lastName}
                </Text>
              </Box>
              <Divider alignSelf={"center"} width={"90%"} color={"gray.300"} />

              <Flex
                justifyContent={"space-between"}
                my={{ base: "8px", md: "12px" }}
              >
                <VStack borderRight="1px" w={"50%"}>
                  <Text
                    fontSize={{ base: "14px", md: "18px" }}
                    fontWeight={500}
                    color={"#7B7B7B"}
                  >
                    Consultation Charge
                  </Text>
                  <Text
                    fontSize={{ base: "14px", md: "25px" }}
                    fontWeight={500}
                    color={"#252525"}
                  >
                    {isInternational ? '$' : '₹'}
                    {callEndedDetails.amount?parseFloat(callEndedDetails.amount).toFixed(2):"0"}
                  </Text>
                </VStack>
                <VStack w={"50%"}>
                  <Text
                    fontSize={{ base: "14px", md: "18px" }}
                    fontWeight={500}
                    color={"#7B7B7B"}
                  >
                    Call Duration
                  </Text>
                  <Text
                    fontSize={{ base: "14px", md: "25px" }}
                    fontWeight={500}
                    color={"#252525"}
                  >
                    {callEndedDetails.callDuration?parseFloat(callEndedDetails.callDuration).toFixed(2):"0"} min
                  </Text>
                </VStack>
              </Flex>
              <Divider alignSelf={"center"} width={"90%"} color={"gray.300"} />
              <Flex
                justifyContent={"center"}
                mt={{ base: "12px", md: "16px" }}
                mb={{ base: "14px", md: "21px" }}
              >
                <Text
                  fontSize={{ base: "12px", md: "17px" }}
                  fontWeight={500}
                  color={"green.400"}
                >
                  Consultation Successfully Completed !!
                </Text>
              </Flex>

              <Feedback purchaseId={callEndedDetails.purchaseId}/>

              {/* <Stack
                direction="row"
                spacing={4}
                justify={"space-between"}
                align="center"
                px={2}
              >
                <Button
                  colorScheme="orange"
                  w={"100%"}
                  variant="solid"
                  onClick={redirectHome}
                >
                  Redirect To Home
                </Button>
              </Stack> */}
            </Flex>
          </Box>
        </Container>
      ) : null}

      
    </>
  );
};

export default CallConsultationDetails;
