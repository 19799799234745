// import { Box, Container, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react'
// import React from 'react'
// import astrologericon from "../../../assets/newhomepage/liveastrologericon.svg"
// import { newpageapidata } from '../Apidatas/Apidata'
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

// const LiveAstrologer = () => {

//   const options = {
//     loop: false,
//     dots: false,
//     autoplay: false,
//     autoplayHoverPause: false,
//     margin: 3,
//     responsive: {
//       0: {
//         items: 3,
//         dots: false,
//       },
//       600: {
//         items: 4,
//       },
//       1000: {
//         items: 4,
//       },
//     },
//   };
//   return (
//     <Container border={"2px solid green"} my={2} bg={"#fff"} py={2}  w={{base:"auto"}} maxW={"10xl"} h={{base:"15rem", md:"25rem"}}  display={"flex"} justifyContent={"center"}>
//         <VStack border={"2px solid yellow"} display="flex" alignItems="flex-start" h={{base:"15rem", md:"15rem"}} maxW={"6xl"}>

//             <Box display={"flex"} justifyContent={{base:"flex-start",md:"center"}} w={"100%"} mt={{md:"1rem", base:"0rem"}}>

//                <Image src={astrologericon}/>
//                <Heading fontSize="md">Live Astrologers</Heading>

//             </Box>
//             <Box position={"relative"} top={{base:"0" , md:"2rem"}} left={{base:"0" , md:"3.75rem"}} w={{base:"90%"}}>
//              <OwlCarousel {...options}>
//                 {newpageapidata.map((item,id)=>{
//                   return  <Box  >
//                           <VStack>
//                             <Image src={item.images[0]} width="150px" h={{base:"130px", md:"230px"}} />
//                             <Text>{item.title}</Text>
//                           </VStack>
//                     </Box>
//                 })}
//              </OwlCarousel>
//             </Box>
//         </VStack>
//     </Container>
//   )
// }

// export default LiveAstrologer

import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase";

import "./Liveastrologer.css";
import { Box, Flex, Text, Image, Container, Img } from "@chakra-ui/react";
import img1 from "../../../assets/newhomepage/img1.jpg";
import img2 from "../../../assets/newhomepage/img2.jpg";
import img3 from "../../../assets/newhomepage/img3.jpg";
import img4 from "../../../assets/newhomepage/img4.jpg";
import img5 from "../../../assets/newhomepage/img5.jpg";
import lives from "../../../assets/newhomepage/lives.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { G_ALL_LIVE_ASTROLOGERS } from "../../../apiLinks";

const options = {
  // loop: true,
  // controlsClass: 'owl-controls',
  // center: true,
  nav: true,
  dots: false,
  // margin: 8,
  // dotsEach: false,
  slideBy: 3,
  responsive: {
    0: {
      items: 3,
      // margin: 8,
      // nav: false,
      // loop: true,
    },
    600: {
      items: 4,
      loop: true,
    },
    1000: {
      items: 4,
    },
  },
};

const live = [
  {
    id: 1,
    name: "Usha Singh",
    img: "https://res.cloudinary.com/dok5apdcq/image/upload/v1688715877/zfsddmrkl5wp6lcyybid.webp",
  },
  {
    id: 2,
    name: "Kaveshii Chauhan",
    img: "https://res.cloudinary.com/dok5apdcq/image/upload/v1688715770/dh9hk09kmyhg9zegt9qw.webp",
  },
  {
    id: 3,
    name: "Tarot Sunita",
    img: "https://res.cloudinary.com/dok5apdcq/image/upload/v1688715973/lit6gumkizij0wfsl5zy.webp",
  },
  {
    id: 4,
    name: "Vandana Bhutani",
    img: "https://res.cloudinary.com/dok5apdcq/image/upload/v1688715941/tzebg9kocxmvffgamm38.webp",
  },
  {
    id: 5,
    name: "Usha Singh",
    img: "https://res.cloudinary.com/dok5apdcq/image/upload/v1688715877/zfsddmrkl5wp6lcyybid.webp",
  },
  {
    id: 6,
    name: "Kaveshii Chauhan",
    img: "https://res.cloudinary.com/dok5apdcq/image/upload/v1688715770/dh9hk09kmyhg9zegt9qw.webp",
  },
  {
    id: 7,
    name: "Tarot Sunita",
    img: "https://res.cloudinary.com/dok5apdcq/image/upload/v1688715973/lit6gumkizij0wfsl5zy.webp",
  },
  {
    id: 8,
    name: "Vandana Bhutani",
    img: "https://res.cloudinary.com/dok5apdcq/image/upload/v1688715941/tzebg9kocxmvffgamm38.webp",
  },
];

const containerStyle = {
  mt: { md: "8px", base: "0" },

  w: { base: "auto", md: "86%" },
  // position: "relative",
  // left: { base: "0", md: "5rem" },
  overflowX: "auto",
  css: {
    "&::-webkit-scrollbar": {
      width: "0",
    },
    scrollbarWidth: "none",
  },
};

function LiveAstrologer() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay of 2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  const [astrologerLive, setAstrologerLive] = useState(false);
  const [liveGurus, setLiveGurus] = useState();
  // console.log(liveGurus);

  const getLiveAstrologers = async () => {
    try {
      const response = await axios.get(G_ALL_LIVE_ASTROLOGERS);
      //
      if (response.data.guru.length !== 0) {
        setLiveGurus(response.data.guru.filter((guru) => guru.gid !== 6));
        setAstrologerLive(true);
        console.log(response.data.guru);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLiveAstrologers();
  }, []);

  return (
    <>
      {/* <Container display={{md:"none" , base:"block"}} bgColor={"green"} w={{base:"100%"}} h={{base:"18rem"}}>
      <Box border={"2px solid yellow"} bgColor={"yellow"} w={"100%"} h={"17rem"} mt={"0.4rem"} mb={"0.4rem"}>
        <Box border={"2px solid red"} h={"15%"} w={"100%"}>Live Astrologer</Box>
        <Box border={"2px solid blue"} h={"85%"} w={"100%"}> 
          <Box className="overflowBox" overflowX={"auto"} display={"flex"} gap={"0.4rem"} w={"auto"}> 
              

              {
                live.map((el,id) => {
                  return(
                   <Box className="card" border={"2px solid orange"} w={"150px"} h={"170px"}>
                      <Image w={"150px"} h={"160px"} src={el.img} />
                      <Text>{el.name}</Text>
                   </Box>
                  )
                })
              }
              
          </Box>
         </Box>
      </Box>
    </Container> */}

      <>
        {astrologerLive ? (
          <Container
            display={{ md: "none", base: "block" }}
            bgColor={"white"}
            mb={"7px"}
            w={{ base: "100%" }}
            h={{ base: "16.8rem" }}
          >
            <Box
              pt={{ base: "7px" }}
              w={"100%"}
              h={"13rem"}
              mt={"0.4rem"}
              mb={"0.4rem"}
            >
              <Box h={"15%"} w={"100%"} display={"flex"}>
                <Image
                  src={lives}
                  alt="imgs"
                  w={"25px"}
                  h={"25px"}
                  mt={"0.5rem"}
                />
                <Text
                  as={"h2"}
                  mt={"0.5rem"}
                  fontSize={{ base: "18px", md: "23px" }}
                  fontWeight={"700"}
                  color={"var(--black-75, #414141);"}
                  mx={{ base: "12px", md: "7px" }}
                  position={"relative"}
                  right={"8px"}
                >
                  Live Astrologers
                </Text>
              </Box>

              <Box
                mt={"10px"}
                overflowX={"scroll"}
                h={"101%"}
                w={"100%"}
                borderRadius={{ md: "0.4rem" }}
                css={`
                  /* Hide the scrollbar on webkit-based browsers (Chrome, Safari) */
                  ::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                  }

                  /* Optional: Add styles for Firefox scrollbar (can be customized) */
                  scrollbar-width: none;
                `}
              >
                <Box
                  display={"flex"}
                  gap={"0.4rem"}
                  w={"300vw"}
                  pt={{ base: "8px" }}
                >
                  {liveGurus.map((curr) => (
                    <Box
                      key={curr.id}
                      w={"155px"}
                      h={"170px"}
                      onClick={() => {
                        window.location.href = `/live/${curr?.user?.firstName}${curr?.user?.lastName}?id=${curr?.user?.guru}`;
                        logEvent(analytics, "live_enter");
                      }}
                      cursor={"pointer"}
                    >
                      <Image
                        w={"155px"}
                        h={"170px"}
                        src={curr?.user?.avatar?.url}
                        borderRadius={"0.2rem"}
                      />
                      <Text
                        as={"h4"}
                        textAlign={"center"}
                        fontSize={"13px"}
                        fontWeight={"600"}
                        mt={"5px"}
                      >
                        {curr?.user?.firstName} {curr?.user?.lastName}
                      </Text>
                    </Box>
                  ))}
                  {/* add more */}
                  {/* <Box w={"155px"} h={"170px"} border={"2px solid red"} borderRadius={"0.3rem"}> 
                   see more
                </Box> */}
                  {/* add more */}
                </Box>
              </Box>
            </Box>
          </Container>
        ) : null}
      </>

      {/* For Owlcrousel & web*/}

      {astrologerLive ? (
        <Container
          id="liveastro"
          display={{ base: "none", md: "flex" }}
          bg={"#fff"}
          // mt={{md:"15px"}}
          // border={"2px solid green"}
          justifyContent={{ md: "center" }}
          flexDir={{ md: "column" }}
          alignItems={{ md: "center" }}
          my={{ md: "20px" }}
          maxW={{ md: "full" }}
          h={{ md: "520px" }}
        >
          <Box maxW={{ md: "6xl" }}>
            <Box
              maxW={{ md: "6xl" }}
              px={{ md: "0" }}
              pt={"1.5rem"}
              display={"flex"}
              w={{ base: "250px", md: "100%" }}
              h={"70px"}
              gap={"0.35rem"}
              alignItems={"center"}
              mt={"0.4rem"}
              justifyContent={{ md: "center", base: "flex-start" }}
            >
              <Image
                src={lives}
                alt="imgs"
                w={"48px"}
                h={"48px"}
                mb={"0.5rem"}
              />
              <Text
                mt={"0.5rem"}
                fontSize={{ md: "34px" }}
                fontWeight={"700"}
                color={"var(--black-75, #414141);"}
                mx={{ base: "12px", md: "7px" }}
              >
                Live Astrologers
              </Text>
            </Box>

            <Box
              {...containerStyle}
              w={"71rem"}
              h={{ md: "26.5rem" }}
              pt={"20px"}
              justifyContent={"center"}
            >
              <OwlCarousel {...options}>
                {liveGurus.map((curr) => (
                  <Box
                    h={"19.5rem"}
                    key={curr.id}
                    w={{ md: "270px" }}
                    onClick={() => {
                      window.location.href = `/live/${curr?.user?.firstName}${curr?.user?.lastName}?id=${curr?.user?.guru}`;
                      // logEvent(analytics, "live_enter");
                    }}
                    cursor={"pointer"}
                  >
                    <Box h={"86%"}>
                      <Image
                        src={curr?.user?.avatar?.url}
                        alt="img"
                        borderRadius={"0.3rem"}
                      />
                    </Box>
                    <Box h={"14%"} textAlign={"center"} mt={"10px"}>
                      <Text fontSize={"19px"} fontWeight={"600"} mt={"10px"}>
                        {curr?.user?.firstName} {curr?.user?.lastName}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </OwlCarousel>
            </Box>
          </Box>
        </Container>
      ) : null}
    </>
  );
}

export default LiveAstrologer;

// import {
//   Box,
//   Button,
//   Container,
//   Flex,
//   HStack,
//   // Heading,
//   // Icon,
//   // Image,
//   Img,
//   Skeleton,
//   Text,
//   // WrapItem,
// } from "@chakra-ui/react";
// import React, { useEffect, useMemo, useState } from "react";
// // import "./FreeLive.css";
// // import { GiBeveledStar } from "react-icons/gi";
// // import Astro_1 from "../../../assets/Astro_1.webp";
// // import Star from "../../../assets/Star.png";
// // import { Avatar } from "@chakra-ui/react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import { G_ALL_LIVE_ASTROLOGERS } from "../../../apiLinks";
// import axios from "axios";
// // import headerStar from "../../../assets/headerStar.png";
// import headerSun from "../../../assets/headerSun.png";
// import SkeletonCard from "../AstroCard/SkeletonCard";
// import {
//   firebaseApp,
//   analytics,
//   logEvent,
// } from "../../../firebaseEvents/firebase";

// const FreeLive = () => {
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     // Simulating a delay of 2 seconds
//     const timer = setTimeout(() => {
//       setIsLoading(false);
//     }, 2000);

//     return () => clearTimeout(timer);
//   }, []);
//   const [astrologerLive, setAstrologerLive] = useState(false);
//   const [liveGurus, setLiveGurus] = useState();

//   const getLiveAstrologers = async () => {
//     try {
//       const response = await axios.get(G_ALL_LIVE_ASTROLOGERS);
//       //
//       if (response.data.guru.length !== 0) {
//         setLiveGurus(response.data.guru.filter((guru) => guru.gid !== 6));
//         setAstrologerLive(true);
//         // console.log(response.data.guru);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     getLiveAstrologers();
//   }, []);

//   const options = useMemo(
//     () => ({
//       nav: true,
//       dots: false,
//       //   margin: 4,
//       // center: true,
//       // autoplay: true,
//       // autoplayTimeout: 3000,
//       // autoplayHoverPause: true,
//       responsive: {
//         0: {
//           items: 2.1,
//           margin: 8,
//           nav: false,
//           loop: false,
//         },
//         600: {
//           items: 2.8,
//           margin: 8,
//           loop: true,
//           nav: false,
//         },
//         1000: {
//           items: 3,
//         },
//       },
//     }),
//     []
//   );

//   return (
//     <>
//       {astrologerLive ? (
//         <Container
//           maxW={"6xl"}
//           id="free-live"
//           my={{ base: "30px" }}
//           className={"section-heading"}
//         >
//           {/* Heading */}
//           <Box
//             as="div"
//             display={"flex"}
//             flexDirection={"column"}
//             justifyContent={"center"}
//             alignItems={"center"}
//             mb={{ base: "1px", md: "1px" }}
//           >
//             <Img
//               src={headerSun}
//               alt="header-sun"
//               w={"140px"}
//               h={"auto"}
//               display={"none"}
//             />
//             {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
//             <Text
//               fontSize={{ base: "18px", md: "32px" }}
//               fontWeight={"700"}
//               color={"#D97122"}
//               mx={{ base: "12px", md: "19px" }}
//             >
//               Live Astrologers
//             </Text>
//             {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
//           </Box>
//           <Box
//             as="div"
//             display={"flex"}
//             justifyContent={"center"}
//             alignItems={"center"}
//             mb={{ md: "30px", base: "16px" }}
//             className={"section-heading"}
//           >
//             {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
//             <Text
//               fontSize={{ base: "12px", md: "20px" }}
//               fontWeight={"500"}
//               color={"#D97122"}
//               mx={{ base: "12px", md: "19px" }}
//               textAlign={"center"}
//             >
//               Unlock the expertise of renowned astrologers in their specialized
//               fields for premium insights
//             </Text>

//             {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
//           </Box>

//           {astrologerLive ? (
//             <Flex>
//               {isLoading ? (
//                 <Container maxW={"6xl"} mt={{ base: "18px", md: "24px" }}>

//                   <Flex

//                   >
//                     {liveGurus.map((curr, index) => {
//                       return (
//                         <Skeleton
//                           w={{ lg: "295px", md: "330px", base: "170px" }}
//                           h={{ md: "334px", base: "307px" }}
//                           borderRadius={"12px"}
//                           mx={"8px"}
//                           key={index}
//                         />
//                       );
//                     })}

//                     {/* <SkeletonCard/>
//        <SkeletonCard/>
//        <SkeletonCard/> */}
//                   </Flex>
//                 </Container>
//               ) : (
//                 <OwlCarousel className="owl-theme" {...options}>
//                   {liveGurus.map((curr, index) => {
//                     return (
//                       <Box
//                         //   id="astro-card"
//                         borderRadius={"7px"}
//                         w={{ lg: "295px", md: "330px", base: "170px" }}
//                         h={{ md: "210px", base: "307px" }}
//                         key={index}
//                         display={"flex"}
//                         flexDirection={"column"}
//                         justifyContent={"flex-end"}
//                         _hover={{
//                           backgroundColor: "orange.200",
//                         }}
//                         sx={{
//                           backgroundImage: `url(${curr?.user?.avatar?.url})`,
//                           backgroundSize: "cover",
//                           backgroundPosition: "center",
//                           height: { base: "210px", md: "334px" },
//                         }}
//                         onClick={() => {
//                           window.location.href = `/live/${curr?.user?.firstName}${curr?.user?.lastName}?id=${curr?.user?.guru}`;
//                           logEvent(analytics, "live_enter");
//                         }}
//                         cursor={"pointer"}
//                       >
//                         <Box p={{ md: "19px", base: "14px" }} pb={0}>
//                           <Text
//                             fontSize={{ md: "16px", base: "12px" }}
//                             fontWeight={"700"}
//                             color={"white"}
//                           >
//                             Venus and Art Venus and ArtVenus and Art
//                           </Text>
//                           <HStack
//                             spacing={{ md: "16px", base: "8px" }}
//                             mt={{ md: "14px", base: "8px" }}
//                           >
//                             {/* <Button colorScheme="orange" borderRadius={"0 0 7px 7px"} w={"100%"}>
//                         {curr.name}
//                       </Button> */}
//                           </HStack>
//                         </Box>
//                         <Button
//                           colorScheme="orange"
//                           borderRadius={"0 0 7px 7px"}
//                           w={"100%"}
//                           fontSize={{ md: "md", base: "sm" }}
//                         // onClick={() =>
//                         //   (window.location.href = `/live/${curr?.user?.firstName}${curr?.user?.lastName}?id=${curr?.user?.guru}`)
//                         // }
//                         >
//                           {curr?.user?.firstName} {curr?.user?.lastName}
//                         </Button>
//                       </Box>
//                     );
//                   })}
//                 </OwlCarousel>
//               )}
//             </Flex>
//           ) : (
//             <Flex
//               justifyContent={"center"}
//               alignItems={"center"}
//               h={{ lg: "332px", md: "332px" }}
//               bg={"#FFF7EF"}
//               borderRadius={"12px"}
//             >
//               <Text
//                 fontSize={{ md: "45px", base: "18px" }}
//                 textAlign={"center"}
//                 color={"orange.500"}
//                 fontWeight={"500"}
//                 py={{ base: "10px" }}
//               >
//                 Live Stream Start Soon
//               </Text>
//             </Flex>
//           )}
//         </Container>
//       ) : null}
//     </>
//   );
// };

// export default FreeLive;
