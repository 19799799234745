import { AvatarBadge, Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

const Following = ({ astroData }) => {
  return (
    <>
      {astroData?.data?.map((data) => {
        //
        let guruToken = data.user.guru
        return (
          <NavLink
            to={`/astrologer/${data.user.firstName}${data.user.lastName}?id=${guruToken}`}
          >
            <Flex direction={"column"} alignItems={"center"}>
              <Box
                boxSize={{
                  md: "148px",
                  base: "95px",
                }}
                h={{
                  md: "134px !important",
                  base: "80px",
                }}
                display={"block"}
                borderBottom={"none !important"}
                position={"relative"}
              >
                {/* {console.log(data.user.avatar)} */}
                <Image
                  minW={"90%"}
                  src={data.user.avatar.url}
                  alt="user"
                  boxSize={"100%"}
                  borderRadius={"50%"}
                  borderBottomWidth={"0px !important"}
                />
                <Box
                  boxSize={"10px"}
                  bg={"green"}
                  borderRadius={"full"}
                  position={"absolute"}
                  right={"15%"}
                  top={"12%"}
                ></Box>
              </Box>
              <Text
                borderBottomWidth={"0px !important"}
                fontSize={{
                  md: "22px",
                  base: "12px",
                }}
                fontWeight={"600"}
                // color={"#5B2800"}
                textAlign={"center"}
                py={"0 !important"}
                mb={0}
              >
                {data.user.firstName} {data.user.lastName}
              </Text>
            </Flex>
          </NavLink>
        );
      })}
    </>
  );
};

export default Following;
