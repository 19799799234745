import React, {
  // useCallback,
  useEffect,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  // FormErrorMessage,
  FormLabel,
  // HStack,
  // Icon,
  Input,
  InputGroup,
  Select,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  // useTheme,
} from "@chakra-ui/react";
// Datepicker
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { selectPeers, useHMSStore } from "@100mslive/react-sdk";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  P_LIVE_GET_CALL_TICKET_ID,
  // P_LIVE_GET_LIVE_TRIGGER,
  P_LIVE_REQUEST_START_CALL,
  P_LIVE_SAVE_PEER_ID,
  // P_LIVE_START_CALL,
} from "../../../apiLinks";
import { fetchAstrologerProfileData } from "../../../store/astrologerProfileSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseEvents/firebase";
// import { IoPhonePortrait } from "react-icons/io5";

const LiveCallFlow = (props) => {
  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );
  const [searchParams] = useSearchParams();
  const guruToken = searchParams.get("id");
  const isInternational = localStorage.getItem("isInternational") === "true";
  const astroDetails = props.astroDetail;
  const [step, setStep] = useState(1);
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthTime, setBirthTime] = useState("");
  const [birthID, setBirthID] = useState("");
  const [isBirthDetailsId, setIsBirthDetailsId] = useState(false);
  const [birthData, setBirthdata] = useState();
  const callFee = isInternational
    ? astroDetails.live.public.international.callfee
    : astroDetails.live.public.national.callfee;
  const walletBalance = isInternational
    ? loggedInUserDetails?.userDetails?.internationalWallet
    : loggedInUserDetails?.userDetails?.wallet;

  // const { onClose } = useDisclosure();
  // const { onCloseModal1 } = useDisclosure();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const navigate = useNavigate();
  const handleRecharge = () => {
    navigate("/wallet");
  };

  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // add 1 to make it 1-12 instead of 0-11
    const day = date.getDate();

    // do something with the year, month and day variables here
    return `${day}/${month}/${year}`;
    //
  };

  const handleTimeChange = (event) => {
    setBirthTime(event.target.value);
  };

  const localUser = props?.localUser;
  const [peer_id, setPeer_id] = useState();

  useEffect(() => {
    if (localUser) {
      setPeer_id(localUser[0]?.id);
    }
  }, [localUser]);

  const loginToken = localStorage.getItem("loginToken");
  // Get Birth Details Function when modal is render.
  useEffect(() => {
    const fetchBirthDetails = async () => {
      try {
        const response = await axios.get(
          "https://data.gurucool.life/api/v1/user/getBirthDetails",
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        );

        // Save the response data in the state
        setBirthdata(response);
        setIsBirthDetailsId(response.data.success);
        setBirthID(response.data.birthDetails._id);
        setFullName(response.data.birthDetails.fullName);
        setGender(response.data.birthDetails.gender);
        setBirthPlace(response.data.birthDetails.birthPlace);
        setBirthDate(response.data.birthDetails.birthDate);
        setBirthTime(response.data.birthDetails.birthTime);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchBirthDetails();
  }, []);

  async function handleSubmit() {
    logEvent(analytics, "live_Call_Start", {
      item_name:
        astroDetails?.user?.firstName + " " + astroDetails?.user?.lastName,
    });
    nextStep();
    const loginToken = localStorage.getItem("loginToken");

    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      await axios.post(
        P_LIVE_SAVE_PEER_ID,
        {
          peerId: peer_id,
        },
        config
      );
    } catch (error) {}
    try {
      let requestData;
      // if (isBirthDetailsId) {
      //   requestData = {
      //     astrologer: astroDetails.user.guru,
      //     roomId: astroDetails.roomId,
      //     birthID: birthID,
      //     peerId: peer_id,
      //   };
      // } else {
      requestData = {
        astrologer: astroDetails.user.guru,
        roomId: astroDetails.roomId,
        fullName: fullName,
        gender: gender,
        birthTime: birthTime,
        birthDate: birthDate,
        birthPlace: birthPlace,
        peerId: peer_id,
      };
      // }
      const response = await axios.post(
        P_LIVE_REQUEST_START_CALL,
        requestData,
        config
      );

      if (response.data.success) {
        localStorage.setItem("triggerId", response.data.triggerId);
        checkCallStatus();
      }
    } catch (error) {}
  }

  /**
   * The function checks the status of a call ticket and waits for it to either be rejected or
   * successful.
   */
  const [callStatus, setCallStatus] = useState("pending");

  function checkCallStatus() {
    const triggerId = localStorage.getItem("triggerId");
    const intervalId = setInterval(() => {
      axios
        .get(P_LIVE_GET_CALL_TICKET_ID(triggerId))
        .then((response) => {
          if (response.data.triggerStatus === "rejected") {
            // rejected
            setCallStatus("rejected");
            clearInterval(intervalId);
          } else if (response.data.triggerStatus === "accepted") {
            localStorage.setItem("callTicket", response.data.publicCallTicket);
            setCallStatus("accepted");
            clearInterval(intervalId);
          }
        })
        .catch((error) => {});
      // Wait for 10 seconds before making the next request
    }, 10000);
  }

  useEffect(() => {
    dispatch(fetchAstrologerProfileData({userName: guruToken, isToken: true}));
  }, []);

  const validateStep2 = () => {
    return fullName && gender && birthPlace && birthDate && birthTime;
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <Box maxWidth="400px" mx="auto" mt="6" id="live-call-modal">
      {step === 1 && (
        <>
          {astroDetails?.liveCallAvailability?.public ? (
            <Box mb="4" alignItems="center">
              <Box as="div" display={"flex"} alignItems={"center"}>
                <Avatar
                  size={{ base: "sm", md: "md", lg: "lg" }}
                  src={astroDetails.user.avatar.url}
                  mr="6"
                />
                <Box>
                  <Text
                    fontSize={{ base: "18px", md: "20px" }}
                    fontWeight={"600"}
                  >
                    {astroDetails.user.firstName}&nbsp;
                    {astroDetails.user.lastName}
                  </Text>
                  <Text fontSize={{ base: "16px" }} fontWeight={"500"}>
                    Astrologer fee:{" "}
                    <Text as={"span"} fontSize={"14px"} fontWeight={"400"}>
                      {isInternational
                        ? "$" + astroDetails.live.public.international.callfee
                        : "₹" + astroDetails.live.public.national.callfee}
                      /Min
                    </Text>{" "}
                  </Text>
                </Box>
              </Box>
              <Divider my={2} color={"gray.400"} />
              <Box
                as="div"
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  <Text fontSize={{ base: "16px" }} fontWeight={"500"}>
                    Your Details
                  </Text>
                  <Text fontSize={{ base: "14px" }} fontWeight={"400"}>
                    Wallet Balance:{" "}
                    {isInternational
                      ? "$ " +
                        parseFloat(
                          loggedInUserDetails?.userDetails?.internationalWallet
                        ).toFixed(2)
                      : "₹ " +
                        Math.round(loggedInUserDetails?.userDetails?.wallet)}
                  </Text>
                </Box>
                <Text color={"gray.500"} fontSize={"12px"}>
                  Min. Call Time 5 mins
                </Text>
              </Box>
              <Divider my={2} color={"gray.400"} />
              <Text fontSize={"8px"} fontWeight={"400"}>
                Once start, You will receive a call , Please prepare your detail
                for consultation: First and last name, Gender, Date and time of
                birth, Town/city of birth
              </Text>
            </Box>
          ) : (
            <Box>
              !!! Apologies, the astrologer is currently unavailable. Please try
              again later.
            </Box>
          )}
        </>
      )}
      {step === 2 && (
        <>
          <FormControl isInvalid={!validateStep2()}>
            <FormLabel htmlFor="fullName">Full name</FormLabel>
            <Input
              id="fullName"
              type="text"
              placeholder="Enter Your Full Name"
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
              bg={fullName ? "green.100" : ""}
              focusBorderColor="transparent"
              border={fullName ? "1px solid green" : ""}
              isRequired
            />
            {/* <FormErrorMessage fontSize={"14px"}>
              Please enter your full name.
            </FormErrorMessage> */}
          </FormControl>
          <Flex
            justifyContent={"space-between"}
            gap={2}
            mt={{ base: "12px", md: "16px" }}
          >
            <Box w={"50%"}>
              <FormControl isInvalid={!validateStep2()}>
                <FormLabel htmlFor="gender">Gender</FormLabel>
                <Select
                  id="gender"
                  value={gender}
                  onChange={(event) => setGender(event.target.value)}
                  bg={gender ? "green.100" : ""}
                  focusBorderColor="transparent"
                  border={gender ? "1px solid green" : ""}
                  isRequired
                >
                  <option value="">Select gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
                {/* <FormErrorMessage fontSize={"14px"}>
                  Please select your gender.
                </FormErrorMessage> */}
              </FormControl>
            </Box>

            <Box w={"50%"}>
              <FormControl isInvalid={!validateStep2()}>
                <FormLabel htmlFor="birthPlace">Place of birth</FormLabel>
                <Input
                  id="birthPlace"
                  type="text"
                  placeholder="Enter Birth Place"
                  value={birthPlace}
                  onChange={(event) => setBirthPlace(event.target.value)}
                  bg={birthPlace ? "green.100" : ""}
                  focusBorderColor="transparent"
                  border={birthPlace ? "1px solid green" : ""}
                  isRequired
                />
                {/* <FormErrorMessage fontSize={"14px"}>
                  Please enter your place of birth.
                </FormErrorMessage> */}
              </FormControl>
            </Box>
          </Flex>

          <Flex
            justifyContent={"space-between"}
            gap={2}
            mt={{ base: "12px", md: "16px" }}
          >
            <Box w={"50%"}>
              <FormControl isInvalid={!validateStep2()}>
                <FormLabel htmlFor="birthDate">Date of birth</FormLabel>
                {/* <DatePicker
                  id="birthDate"
                  selected={birthDate}
                  onChange={setBirthDate}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName={
                    birthDate ? "liveFormDatepickerFill" : "liveFormDatepicker"
                  }
                  focusBorderColor=""
                  placeholderText="Enter Your Birth Date"
                /> */}

                <InputGroup>
                  <Input
                    isRequired
                    type="date"
                    value={birthDate}
                    onChange={(event) => setBirthDate(event.target.value)}
                    placeholder="Select a time"
                    pl={"6px"}
                    // _focus={{
                    //   border: "1px solid orange",
                    //   outline: "none",
                    //   boxShadow: "none",
                    // }}
                    bg={birthDate ? "green.100" : ""}
                    focusBorderColor="transparent"
                    border={birthDate ? "1px solid green" : ""}
                    required
                  />
                </InputGroup>
                {/* <FormErrorMessage fontSize={"14px"}>
                  Please select your date of birth.
                </FormErrorMessage> */}
              </FormControl>
            </Box>
            <Box w={"50%"}>
              <FormControl isInvalid={!validateStep2()}>
                <FormLabel htmlFor="birthTime">Time of birth</FormLabel>

                <InputGroup>
                  <Input
                    isRequired
                    type="time"
                    value={birthTime}
                    onChange={handleTimeChange}
                    placeholder="Select a time"
                    pl={"6px"}
                    // _focus={{
                    //   border: "1px solid orange",
                    //   outline: "none",
                    //   boxShadow: "none",
                    // }}
                    bg={birthTime ? "green.100" : ""}
                    focusBorderColor="transparent"
                    border={birthTime ? "1px solid green" : ""}
                    required
                  />
                </InputGroup>
                {/* <FormErrorMessage fontSize={"14px"}>
                  Please enter your time of birth.
                </FormErrorMessage> */}
              </FormControl>
            </Box>
          </Flex>
        </>
      )}
      {step === 3 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {callStatus === "pending" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color={"black"}>
                  <Spinner size="lg" color="black" />
                  Connecting to Astrologer, Please wait...
                </Text>
                <Text fontSize={"12px"} color="red" fontWeight={"400"}>
                  Please do not refresh this page!!!.
                </Text>
              </Box>
            )}
            {callStatus === "rejected" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color={"red"}>
                  Connecting to Astrologer failed, Please try again after some
                  time...
                </Text>
              </Box>
            )}
          </Box>
        </>
      )}
      <Stack mt="4" spacing="4" direction="row" justifyContent="space-between">
        {step > 1 && step !== 3 && (
          <Button onClick={prevStep} variant="outline" colorScheme="blue.500">
            Back
          </Button>
        )}
        {step < 2 && astroDetails?.liveCallAvailability?.public && (
          <>
            <Button
              onClick={handleRecharge}
              variant="solid"
              colorScheme={walletBalance <= callFee * 5 ? "green" : "gray"}
              w={"100%"}
            >
              Recharge
            </Button>
            <Button
              onClick={nextStep}
              // onClick={handleSubmit}
              colorScheme={walletBalance <= callFee * 5 ? "gray" : "green"}
              w={"100%"}
              isDisabled={walletBalance <= callFee * 5}
            >
              Next
            </Button>
          </>
        )}
        {step === 2 && (
          <Button
            onClick={handleSubmit}
            colorScheme="orange"
            isDisabled={!validateStep2()}
            w={"100%"}
          >
            Consult
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default LiveCallFlow;
