import React, { useEffect, useRef } from "react";
import { Avatar, Flex, Text } from "@chakra-ui/react";

const Messages = ({ messages }) => {
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  return (
    <Flex w="100%" h="80%" overflowY="scroll" flexDirection="column" p="3">
      {messages.map((item, index) => {
        if (item.from === "me") {
          return (
            <Flex key={index} w="100%" justify="flex-end">
              <Flex
                bg="#FFDCCD"
                color="black"
                minW="100px"
                maxW="350px"
                my="1"
                p={{ md: "20px", base: "16px" }}
                borderRadius={"16px 16px 0px 16px"}
              >
                <Text fontSize={{ md: "20px", base: "16px" }} fontWeight={"400"} mb={0}>
                  {item.text}
                </Text>
              </Flex>
            </Flex>
          );
        } else {
          return (
            <Flex key={index} w="100%">
              {/* <Avatar
                name="Computer"
                src="https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
                bg="blue.300"
              ></Avatar> */}
              <Flex
                bg={"#F6F6F6"}
                color="black"
                minW="100px"
                maxW="350px"
                my="1"
                p={{ md: "20px", base: "16px" }}
                borderRadius={"16px 16px 16px 0px"}
              >
                <Text fontSize={{ md: "20px", base: "16px" }} fontWeight={"400"} mb={0}>{item.text}</Text>
              </Flex>
            </Flex>
          );
        }
      })}
      <AlwaysScrollToBottom />
    </Flex>
  );
};

export default Messages;
