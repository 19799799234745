import { Box, Container, Flex, HStack, Image } from '@chakra-ui/react';
import React from 'react';

const SearchBottomCard = () => {
  return (
    <Container p={0} justifyContent="center" bg="#fff">
      <Flex alignItems="center" justifyContent="center">
        <HStack bg="linear-gradient(145deg, #FF964F 0%, #FFF 100%);" w="20.5rem" h="7rem">
          <Box>
            <Image src="../../../../assets/Grp1.png" />
          </Box>
          <Box></Box>
        </HStack>
      </Flex>
    </Container>
  );
};

export default SearchBottomCard;
