import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { P_PUT_USER_DETAILS } from "../../apiLinks";
import { MDBCardBody, MDBBtn } from "mdb-react-ui-kit";
import {
  Image,
  Button,
  Text,
  Input,
  Flex,
  FormLabel,
  FormControl,
  Select,
  // Box,
  Avatar,
  HStack,
  IconButton,
  Icon,
  VStack,
  Divider,
  Heading,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import Edit from "../../assets/Edit.webp";
import { BsGenderMale, BsGenderFemale, BsTelephone } from "react-icons/bs";
import { EditIcon } from "@chakra-ui/icons";
import { CiMail } from "react-icons/ci";

const UserDetails = () => {
  const isInternational = localStorage.getItem("isInternational") === "true";
  const userDetails = useSelector((state) => state.loggedInUserDetails.data);
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState(userDetails.user.firstName);
  const [lastName, setLastName] = useState(userDetails.user.lastName);
  const [gender, setGender] = useState(userDetails.user.gender);
  const [email, setEmail] = useState(userDetails.user.email);

  // console.log("USER DETAILS FROM USER>>>>>>>>>>>>>", userDetails);

  const handleEditName = () => setEditMode(true);

  const handleSaveName = () => {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(P_PUT_USER_DETAILS(firstName, lastName, gender, email), {}, config)
      .then(() => setEditMode(false))
      .catch((error) => console.error(error));
  };

  const displayEditForm = () => (
    <FormControl>
      <FormLabel textAlign="center" mt="20px">
        Edit User Details
      </FormLabel>
      <Flex alignItems={"flex-start"} flexDirection="column">
        {createInputSection(
          "Full Name",
          [firstName, setFirstName],
          [lastName, setLastName]
        )}
        {createInputSection("Email", [email, setEmail])}
        {/* {createInputSection("Phone", null, null, userDetails.user.phone, true)} */}

        <Flex flexDirection={"column"} alignItems="center" my={"10px"} w="100%">
          <Text
            fontSize={{ md: "13px", base: "11px" }}
            textAlign={"left"}
            w={{ md: "370px", base: "270px" }}
            mb="1"
          >
            Gender
          </Text>
          <Select
            h={{ md: "40px", base: "35px" }}
            w={{ md: "370px", base: "270px" }}
            onChange={(e) => setGender(e.target.value)}
            value={gender}
            borderRadius="5px"
            variant="outline"
            borderColor="gray.400"
          >
            <option value="" disabled selected>
              Select
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
        </Flex>

        <Flex mx="auto">
          <Button
            colorScheme="orange"
            onClick={handleSaveName}
            size={"sm"}
            h={{ md: "35px", base: "30px" }}
            w={{ md: "180px", base: "70px" }}
            mb="20px"
            fontSize={"16px"}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </FormControl>
  );

  const createInputSection = (
    label,
    value1,
    value2 = null,
    initialValue = "",
    disabled = false
  ) => (
    <Flex w="100%" alignItems={"center"} flexDirection="column">
      <Text
        fontSize={{ md: "13px", base: "11px" }}
        w={{ md: "370px", base: "270px" }}
        textAlign="left"
        mb="1"
      >
        {label}
      </Text>
      <Flex w={{ md: "370px", base: "270px" }}>
        {value1 && createInput(value1[0], value1[1])}
        {value2 && createInput(value2[0], value2[1], initialValue, disabled)}
      </Flex>
    </Flex>
  );

  const createInput = (
    value,
    setValue,
    initialValue = "",
    disabled = false
  ) => (
    <Input
      type="text"
      onChange={(e) => setValue(e.target.value)}
      value={value || initialValue}
      mr="2"
      mb="4"
      size={"sm"}
      h={{ md: "40px", base: "35px" }}
      w={{ md: "100%", base: "100%" }}
      borderRadius="5px"
      variant="outline"
      borderColor="gray.400"
      isDisabled={disabled}
    />
  );

  const displayUserInfo = () => (
    <>
      <HStack
        borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
        justifyContent={"center"}
        alignItems={"center"}
        mt={{ md: "12px", base: "8px" }}
      >
        <Heading
          as="h2"
          fontSize={{ md: "24px", base: "16px" }}
          fontWeight={"500"}
          mb={0}
        >
          {firstName} {lastName}
        </Heading>
        <IconButton
          bg={"#87420C"}
          aria-label="Search database"
          icon={<EditIcon color={"white"} />}
          borderRadius={"50%"}
          w={{ md: "30px", sm: "30px",  base: "30px" }}
          h={{ md: "34px", sm: "35px",  base: "35px" }}
          // h={{ md: "32px" }}
          onClick={handleEditName}
          _hover={{
            bg: "#a35a20",
          }}
        />
      </HStack>
      <VStack
        mt={"32px"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={{ md: "24px", base: "16px" }}
      >
        <HStack
          justifyContent={"space-between"}
          w={{ md: "468px", base: "320px" }}
        >
          <Heading
            as="h5"
            fontSize={{ md: "16px", base: "14px" }}
            fontWeight={"500"}
            color={"#838383"}
            mb={0}
          >
            {gender === "male" ? (
              <Icon
                as={BsGenderMale}
                boxSize={{ md: "28px", base: "18px" }}
                mr={"12px"}
              />
            ) : (
              <Icon
                as={BsGenderFemale}
                boxSize={{ md: "28px", base: "18px" }}
                mr={"12px"}
              />
            )}
            Gender
          </Heading>
          <Heading
            as="h5"
            fontSize={{ md: "16px", base: "14px" }}
            fontWeight={"500"}
            color={"#3F3F3F"}
            mb={0}
          >
            {gender}
            {/* <Divider mt={0}/> */}
          </Heading>
        </HStack>
        {/* <Divider mt={0}/> */}
        <HStack
          justifyContent={"space-between"}
          w={{ md: "468px", base: "320px" }}
        >
          <Heading
            as="h5"
            fontSize={{ md: "16px", base: "14px" }}
            fontWeight={"500"}
            color={"#838383"}
            mb={0}
          >
            <Icon
              as={CiMail}
              boxSize={{ md: "28px", base: "18px" }}
              mr={"12px"}
            />
            Email ID
          </Heading>
          <Heading
            as="h5"
            fontSize={{ md: "16px", base: "14px" }}
            fontWeight={"500"}
            color={"#3F3F3F"}
            mb={0}
          >
            {email}
          </Heading>
        </HStack>
        <HStack
          justifyContent={"space-between"}
          w={{ md: "468px", base: "320px" }}
        >
          <Heading
            as="h5"
            fontSize={{ md: "16px", base: "14px" }}
            fontWeight={"500"}
            color={"#838383"}
            mb={0}
          >
            <Icon
              as={BsTelephone}
              boxSize={{ md: "28px", base: "18px" }}
              mr={"12px"}
            />
            Mobile No
          </Heading>
          <Heading
            as="h5"
            fontSize={{ md: "16px", base: "14px" }}
            fontWeight={"500"}
            color={"#3F3F3F"}
            mb={0}
          >
            {userDetails.user.phone}
          </Heading>
        </HStack>
      </VStack>
    </>
    // <Text
    //   display="flex"
    //   alignItems={"center"}
    //   flexDirection="column"
    //   mt={"30px"}
    // >
    //   Full Name : {firstName} {lastName}
    //   <Flex flexDirection={"row"} alignItems="center">
    //     Gender : {gender}
    //   </Flex>
    //   <Flex>Email : {email} </Flex>
    //   <Flex>Phone : {userDetails.user.phone} </Flex>
    //   <Button
    //     size="sm"
    //     colorScheme="orange"
    //     variant="ghost"
    //     onClick={handleEditName}
    //     mt="10px"
    //     mb="15px"
    //   >
    //     <Image
    //       src={Edit}
    //       h={{ md: "25px", base: "20px" }}
    //       w={{ md: "25px", base: "20px" }}
    //     />
    //   </Button>
    // </Text>
  );

  return (
    <MDBCardBody className="text-center">
      <div className="my-profile-img">
        {/* <Avatar
          src={userDetails.userImg}
          alt="avatar"
          className="rounded-circle"
          w={{ md: "160px", base: "100px" }}
          h={{ md: "160px", base: "100px" }}
          textAlign="center"
          fluid
        /> */}

        {userDetails?.user?.avatar ? (
          <Image
            // display={{ base: "none", md: "block" }}
            // w="36px"
            // borderRadius="50%"
            src={userDetails?.user?.avatar?.url}
            alt="avatar"
            className="rounded-circle"
            w={{ md: "160px", base: "100px" }}
            h={{ md: "160px", base: "100px" }}
            textAlign="center"
            fluid
          />
        ) : (
          <Avatar
            // display={{ base: "none", md: "block" }}
            name={`${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
            size={"2xl"}
            alt="avatar"
            className="rounded-circle"
            w={{ md: "160px", base: "100px" }}
            h={{ md: "160px", base: "100px" }}
            textAlign="center"
            fluid
          ></Avatar>
        )}
      </div>
      {editMode ? displayEditForm() : displayUserInfo()}
      {/* <div className="d-flex justify-content-center mb-2">
        <MDBBtn outline className="ms-1">
          <NavLink to="/wallet">
            Wallet Balance:{" "}
            {userDetails.user.role !== "astrologer" && (
              <>
                {isInternational
                  ? "$ " +
                    parseFloat(
                      userDetails.userDetails.internationalWallet
                    ).toFixed(2)
                  : "₹ " +
                    Math.round(userDetails.userDetails.wallet).toFixed(2)}
              </>
            )}
          </NavLink>
        </MDBBtn>
      </div> */}
    </MDBCardBody>
  );
};

export default UserDetails;
