import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  selectHMSMessages,
  selectHMSMessagesCount,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
// import Message from "@100mslive/hms-video/dist/sdk/models/HMSMessage";
import Message from "./Message";
// import { selectPeers } from "@100mslive/react-sdk";
import {
  Box,
  Container,
  // Flex,
  HStack,
  // Img,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  // VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import ReactScrollableFeed from "react-scrollable-feed";

import ShowGifts from "../GIfts/ShowGifts";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseEvents/firebase";

const ChatNdParticipants = (props) => {
  const localUserRole = props.localUserRole;
  const astroDetail = props.astroDetail;
  const [message, setMessage] = useState();

  // const peers = useHMSStore(selectPeers);
  const messages = useHMSStore(selectHMSMessages);
  const hmsActions = useHMSActions();

  const handleSubmit = (e) => {
    e.preventDefault();

    logEvent(analytics, "live_Public_chat_Send");
    
    hmsActions.sendBroadcastMessage(message);
    setMessage("");
  };

  const publicRef = useRef(null);
  const privateRef = useRef(null);

  const storeMessageSelector = selectHMSMessagesCount;

  const messagesCount = useHMSStore(storeMessageSelector) || 0;

  const scrollToBottom = useCallback(
    (unreadCount = 0) => {
      if (
        publicRef.current &&
        publicRef.current.scrollToItem &&
        unreadCount > 0
      ) {
        publicRef.current?.scrollToItem(messagesCount, "end");
        requestAnimationFrame(() => {
          publicRef.current?.scrollToItem(messagesCount, "end");
        });
        hmsActions.setMessageRead(true);
      }
    },
    [hmsActions, messagesCount]
  );

  const colorScheme = useBreakpointValue({ base: "whiteAlpha", lg: "orange" });

  function PublicChat() {
    return (
      <Box
        h={{ base: "80%", md: "100%" }}
        // display={localUserRole === "hls-viewer" ? "block" : "none"}
        // overflowY="scroll"
        // background={"pink"}
      >
        <ReactScrollableFeed>
          {messages.map((msg, index) => {
            const messageLength = messages.length;
            return (
              <Message
                key={msg.id}
                message={msg}
                index={index}
                messageLength={messageLength}
              />
            );
          })}
        </ReactScrollableFeed>
        <div ref={publicRef}></div>
      </Box>
    );
  }

  return (
    <>
      <Container
        maxW={"6xl"}
        position={{ base: "absolute", md: "unset" }}
        bottom={"0%"}
        h={{ base: "55%", lg: "60%" }}
        zIndex={"999"}
        // background={"gray"}
        // overflow={"hidden"}
        bgGradient={{
          base: "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(99, 99, 99, 0.48) 49.07%, rgba(28, 28, 28, 0.65) 87.50%, rgba(3, 3, 3, 0.70) 99.99%, rgba(0, 0, 0, 0.65) 100%)",
          md: "none",
        }}
      >
        <ShowGifts astroDetails={astroDetail} />
        <PublicChat />
      </Container>
      <form name="send-messge" onSubmit={handleSubmit} />
    </>
  );
};

export default ChatNdParticipants;

// useEffect(() => {
//   scrollToBottom(1);
//   publicRef.current.scrollIntoView({ behavior: "smooth" });
// }, [messages, messagesCount]);

// useEffect(() => {
//   scrollToBottom(1);
//   privateRef.current.scrollIntoView({ behavior: "smooth" });
// }, [messages, messagesCount]);
// useEffect(() => {
//   scrollToBottom(1);
//   publicRef.current.scrollIntoView({ behavior: "smooth" });
// }, [messages, messagesCount]);

// useEffect(() => {
//   scrollToBottom(1);
//   privateRef.current.scrollIntoView({ behavior: "smooth" });
// }, [messages, messagesCount]);
// function PrivateChat() {
//   return (
//     <Box
//       h="22vh"
//       overflowY="scroll"
//       // bgGradient="linear-gradient(360deg, rgba(57, 57, 57, 0.79) -7.32%, rgba(103, 103, 103, 0) 84.08%)"
//     >
//       <ReactScrollableFeed>
//         {messages.map((msg, index) => {
//           const messageLength = messages.length;
//           return (
//             <Message
//               scrollToBottom={scrollToBottom}
//               key={msg.id}
//               message={msg}
//               index={index}
//               messageLength={messageLength}
//             />
//           );
//         })}
//       </ReactScrollableFeed>
//       <div ref={privateRef}></div>
//     </Box>
//   );
// }

// function PublicChatForTab() {
//   return (
//     <Box
//       display={localUserRole === "hls-viewer" ? "block" : "none"}
//       h="20vh"
//       overflowY="scroll"
//       // bgGradient="linear-gradient(360deg, rgba(57, 57, 57, 0.79) -7.32%, rgba(103, 103, 103, 0) 84.08%)"
//     >
//       <ReactScrollableFeed>
//         {messages.map((msg, index) => {
//           const messageLength = messages.length;
//           //
//           return (
//             <Message
//               key={msg.id}
//               message={msg}
//               index={index}
//               messageLength={messageLength}
//             />
//           );
//         })}
//       </ReactScrollableFeed>

//       <div ref={publicRef}></div>
//     </Box>
//   );
// }
{
  /* <Tabs
  variant={"solid-rounded"}
  colorScheme={colorScheme}
  display={localUserRole === "hls-viewer" ? "none" : "block"}
>
  <HStack justify={"space-between"}>
  {/* <TabList
  mb="0.5em"
      bg={{ base: "rgba(23, 23, 23, 0.3)", lg: "#bd631d73" }}
      width={"fit-content"}
      p={2}
      borderRadius={"4px"}
      >
      <Tab borderRadius={"4px"} py={{ lg: 1 }}>
      Public
      </Tab>
      <Tab borderRadius={"4px"} py={{ lg: 1 }}>
        Private
        </Tab>
    </TabList> */
}
{
  /* <Box display={{ base: "block", md: "none" }}>
      <Text fontSize={"18px"} fontWeight={"600"} color={"#52FF36"}>
        ₹30/
        <Text as={"span"} fontSize={"14px"} fontWeight={"600"}>
          min
        </Text>
      </Text>
    </Box> 
  </HStack>

  <TabPanels>
    <TabPanel>
      {/* <PrivateChat /> 
      <PublicChatForTab />
    </TabPanel>
    <TabPanel>
      <PublicChatForTab />
    </TabPanel>
  </TabPanels>
</Tabs> */
}
{
  /* <Flex
h={"230px"}
direction={{ base: "column" }}
align={"start"}
justify={"end"}
overflowY={"scroll"}
>
{messages.map((msg, index) => {
  const messageLength = messages.length;
 
  return (
    <Message
      key={msg.id}
      message={msg}
      index={index}
      messageLength={messageLength}
    />
  );
})}
</Flex> */
}
{
  /* {peers.map((peer) => (
<div className="rightBox__participant">
  {peer.name}
  <p>{peer.roleName}</p>
</div>
))} */
}
