import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GET_CONSULTATION } from '../apiLinks';
const initialState = {
  data: [],
  total: 0,
  status: 'idle',
  error: null
};


export const fetchConsultations = createAsyncThunk('allConsultationsData/fetchConsultations', async ({pageNumber, perPage, uid = "", gid = ""}) => {
    const loginToken = localStorage.getItem("loginToken");
  const config = {
    headers: {
      Authorization: `Bearer ${loginToken}`,
    },
  };
  const response = await axios.get(GET_CONSULTATION(pageNumber, perPage, uid, gid) , config);

//  
  return response.data.consultations;
});

const getConsultationsSlice = createSlice({
  name: 'allConsultationsData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConsultations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.docs;
        state.total = action.payload.total;
      })
      .addCase(fetchConsultations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default getConsultationsSlice.reducer;
