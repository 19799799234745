import { Stack, Switch,Text,Box } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchLoggedInUserDetails } from '../../../store/LoggedInUserDetails';


export default function Callqueuetoggle({ astrologer }) {
    const [checked,setChecked]=useState(astrologer.guru.enableQueue);
    // console.log("Astrologer",astrologer);
    const dispatch=useDispatch();
    const handleChange=()=>{
        const token =localStorage.getItem("loginToken"); 
        const config = {
           headers: {
         'Authorization': `Bearer ${token}`
         }};
        axios.put(`https://data.gurucool.life/api/v1/guru/enableQueue?enableQueue=${!checked}&gid=${astrologer?.guru?.gid}`,{},config)
        .then(response => {
            setChecked(!checked);
            dispatch(fetchLoggedInUserDetails(localStorage.getItem("loginToken")));
        //
         })
        .catch(error => {
           toast.error("Call Queue Succesfully Not On!");
        });
    }


  return (
    <>
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} h={"60px"} w={"368px"} bg={checked?"#C6F9BF":"#C8C8C8"} borderRadius={"12px"}>
        <Text border={""} alignSelf={"center"} fontSize={"22px"} fontWeight={"500"} color={"#636363"} ml={"30px"} mt={"auto"} mb={"auto"}>Call Queue</Text>
        <Box>
        <Switch onChange={handleChange} colorScheme='teal' isChecked={checked} size='lg' mr={"10px"} />
        </Box>
    </Stack>
    </>
  )
}
