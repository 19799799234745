

// import React, {
//   useState,
//   useEffect,
//   // , useRef
// } from "react";
// import "../../../App.css";
// import {
//   // Heading,
//   // Avatar,
//   Box,
//   Container,
//   Text,
//   // Stack,
//   // Button,
//   // Link,
//   //   IconButton,
//   Image,
//   useDisclosure,
//   Modal,
//   Flex,
//   Skeleton,
//   // Icon,
//   // Divider,
//   //   useColorModeValue,
//   //   useBreakpointValue,
// } from "@chakra-ui/react";

// // import Slider from "react-slick";

// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import tarast from "../../../assets/newhomepage/tarast.svg"
// // import Chat_icon_gray from "../../../assets/chat-gray.svg";
// // import Chat_icon from "../../../assets/Chat_icon.png";
// // import Call_icon from "../../../assets/Call_icon.png";
// // import CallBusy from "../../../assets/callBusy.png";
// // import CallOffline from "../../../assets/callOffline.png";
// // import Star from "../../../assets/Star.png";
// import ChatFlowModal from "../../ChatFlow/ChatFlowModal";
// import CallFlowModal from "../../CallFlow/CallFlowModal";
// import Login from "../../Login&Signup/Login";
// import recastro from "../../../assets/newhomepage/recastro.svg"

// import { useDispatch, useSelector } from "react-redux";
// // import { fetchSelectedAstrologerData } from "../../../store/selectedAstrologerSlice";
// // import { fetchData } from "../../../store/TarotSlice";
// import { Center, Spinner } from "@chakra-ui/react";
// // import { NavLink } from "react-router-dom";
// import axios from "axios";
// import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";
// // import Call_icon_1 from "../../../assets/Call-icon-1.webp";
// // import rating_start from "../../../assets/rating_star.webp";

// // import LazyLoad from "react-lazy-load";
// // import AstroCard from "../AstroCard/AstroCard";
// import AstroProfileCard from "../AstroCard/AstroProfileCard";
// // import { GiBeveledStar } from "react-icons/gi";
// import { useMemo } from "react";
// // import { gsap } from "gsap";
// // import { ScrollTrigger } from "gsap/ScrollTrigger";
// import footerLine from "../../../assets/footerLine.png";
// import SkeletonCard from "../AstroCard/SkeletonCard";
// import homepageAstrologers from "../../../store/homepageAstrologers";
// // import Aos from "aos";
// // import "aos/dist/aos.css";
// import "./TarotAstrologer.css"
// export default function RecommendedAstrologer() {
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     // Simulating a delay of 2 seconds
//     const timer = setTimeout(() => {
//       setIsLoading(false);
//     }, 2000);

//     return () => clearTimeout(timer);
//   }, []);
//   const token = localStorage.getItem("guruToken");
//   //
//   useEffect(() => {
//     if (token) {
//       axios
//         .get(
//           `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${token}`
//         )
//         .then((response) => {
//           //
//           dispatch(
//             selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
//           );
//         });
//     }
//     // setIsLoading(false);
//   }, [token]);

//   const dispatch = useDispatch();

//   const data = useSelector((state) => state.homepageAstrologers.data);
//   const status = useSelector((state) => state.homepageAstrologers.status);
//   const error = useSelector((state) => state.homepageAstrologers.error);

//   // const filteredData = data.filter(
//   //   (item) => item.status === "active" && (item.callAvailability === "online" || item.callAvailability === "busy")
//   //   );
//   // useEffect(() => {
//   //   const interval = setInterval(homepageAstrologers(), 5000);
//   //   return () => clearInterval(interval);
//   // }, []);

//   const filteredData = data;

//   const options = {
//     //  loop: false,
//     // controlsClass: 'owl-controls',
//     // center: true,
//     nav: true,
//     dots: false,
//     // margin: 8,
//     // dotsEach: false,
//     slideBy: 3,
//     responsive: {
//       0: {
//         items: 1.7,
//         // margin: 8,
//         nav: false,
//         loop: false,
//       },
//       600: {
//         items: 3.2,
//         loop: true,
//       },
//       1000: {
//         items: 4,
//       },
//     },
//   };




//   const { isOpen, onOpen, onClose } = useDisclosure();

//   const [chatBtnClicked, setChatBtnClicked] = useState(false);
//   const [callBtnClicked, setCallBtnClicked] = useState(false);

//   const chatClicked = () => {
//     setChatBtnClicked(true);
//     onOpen();
//   };

//   const callClicked = () => {
//     setCallBtnClicked(true);
//     onOpen();
//   };

//   const loginToken = localStorage.getItem("loginToken");

//   if (status === "loading") {
//     return (
//       // <Box  w={'full'} bg={'white'}>
//       <Container  w={'100%'} maxW={"6xl"} mt={{ base: "18px", md: "24px" }}>
//         <Box
//           as="div"
//           display={"flex"}
//           justifyContent={"center"}
//           alignItems={"center"}
//           mb={{ md: "56px", base: "16px" }}
//         >
//           {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
//           <Text
//             fontSize={{ base: "18px", md: "32px" }}
//             fontWeight={"700"}
//             color={"#D97122"}
//             mx={{ base: "12px", md: "19px" }}
//           // fontFamily={'sans-serif'}
//           >
//             Premium Astrologers
//           </Text>
//           {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
//         </Box>
//         <Center>
//           <Spinner
//             thickness="4px"
//             speed="0.65s"
//             emptyColor="gray.200"
//             color="orange.500"
//             size="xl"
//           />
//         </Center>
//       </Container>
//       // </Box>
//     );
//   } else if (status === "succeeded") {
//     return (
//       //   <Center py={6}>
//       <>
//       <Box w={'100%'} bg={'white'}>
//         <Container h={{md:"36rem"}} id="recommended-id" bg={'white'} maxW={"6xl"} mb={{ base: "8px", md: "24px" }} mt={{ base: "8px", md: "24px" }}>
//           {!loginToken ? (
//             <Modal
//               isCentered
//               isOpen={isOpen}
//               onClose={onClose}
//               size={{ base: "xs", md: "4xl" }}
//             >
//               <Login />
//             </Modal>
//           ) : (
//             <Modal
//               isCentered
//               isOpen={isOpen}
//               onClose={onClose}
//               size={{ base: "xs", md: "4xl" }}
//             >
//               {chatBtnClicked && <ChatFlowModal />}
//               {callBtnClicked && <CallFlowModal />}
//             </Modal>
//           )}
//           <Box
//             as="div"
//             display={"flex"}
//             justifyContent={{md:"center", base:"flex-start"}}
//             alignItems={"center"}
//             mb={{ md: "1px", base: "1px" }}
//             className={"section-heading"}

//           >
//             {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
//             <Image src={tarast} alt="imgs" h={{ md: "45px", base: "30px" }} w={{ md: "45px", base: "30px" }} mb={{ md: "0.5rem" }} />

//             <Text
//               fontSize={{ base: "18px", md: "34px" }}
//               fontWeight={"700"}
//               color={"var(--black-75, #414141);"}
//               mx={{ base: "8px", md: "12px" }}
//               className="heading"
//               mt={"0.5rem"}
//               py={"20px"}
//               position={"relative"}
//               bottom={{ base: "2px", md: "2px" }}
//             >
//               Tarot Astrologers
//             </Text>

//             {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
//           </Box>
//           {/* <Box
//             as="div"
//             display={"flex"}
//             justifyContent={"center"}
//             alignItems={"center"}
//             mb={{ md: "30px", base: "16px" }}
//             className={"section-heading"}
//           >

//             <Text
//               fontSize={{ base: "12px", md: "20px" }}
//               fontWeight={"500"}
//               color={"#D97122"}
//               mx={{ base: "12px", md: "19px" }}
//               textAlign={"center"}
//             >
//               Unlock the expertise of renowned astrologers in their specialized
//               fields for premium insights.
//             </Text>
//           </Box> */}

//           {/* <Flex
//             gap={{ lg: 0, md: "24px", base: 0 }}
//             flexWrap={{ md: "wrap", base: "nowrap" }}
//             justifyContent={{ md: "center", base: "flex-start" }}
//             borderRadius={"7px"}
//             overflowX={{ md: "unset", base: "auto" }}
//             pb={"6px"}
//             sx={{
//               // Hide scrollbar in webkit-based browsers
//               "&::-webkit-scrollbar": {
//                 display: "none",
//               },
//             }}
//           > */}
//           <OwlCarousel {...options}>

//             {data.map((currElem, index) => {
//               return (
//                 <>
//                 <AstroProfileCard
//                   currElem={currElem}
//                   length={data.length}
//                   index={index}
//                   callClicked={callClicked}
//                 />
//                 </>
//               );
//             })}
//           </OwlCarousel>
//           {/* </Flex> */}
//           {/* <Image src={footerLine} alt="footer-line" /> */}
//         </Container>
//         </Box>
//       </>
//       // </Center>
//     );
//   } else if (status === "failed") {
//     return (
//       <Center>
//         <Text color="red">{error}</Text>
//       </Center>
//     );
//   }

//   return null;
// }

















import React, {
  useState,
  useEffect,
  // , useRef
} from "react";
import "../../../App.css";
import {
  // Heading,
  // Avatar,
  Box,
  Container,
  Text,
  // Stack,
  // Button,
  // Link,
  //   IconButton,
  Image,
  useDisclosure,
  Modal,
  Flex,
  Skeleton,
  // Icon,
  // Divider,
  //   useColorModeValue,
  //   useBreakpointValue,
} from "@chakra-ui/react";

// import Slider from "react-slick";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import tarast from "../../../assets/newhomepage/tarast.svg"
import ChatFlowModal from "../../ChatFlow/ChatFlowModal";
import CallFlowModal from "../../CallFlow/CallFlowModal";
import Login from "../../Login&Signup/Login";
import recastro from "../../../assets/newhomepage/recastro.svg"

import { useDispatch, useSelector } from "react-redux";

import { Center, Spinner } from "@chakra-ui/react";

import axios from "axios";
import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";

import AstroProfileCard from "../AstroCard/AstroProfileCard";

import { useMemo } from "react";

import footerLine from "../../../assets/footerLine.png";
import Marriage from "../../../assets/newhomepage/marriages.svg"
import SkeletonCard from "../AstroCard/SkeletonCard";
import homepageAstrologers from "../../../store/homepageAstrologers";

import "./MarriageAstrologer.css"
export default function RecommendedAstrologer() {


  const [isLoading, setIsLoading] = useState(true);
  const [astroData, setAstroData] = useState();

  useEffect(() => {

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  const token = localStorage.getItem("guruToken");

  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${token}`
        )
        .then((response) => {
          //
          dispatch(
            selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
          );
        });
    }

  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://data.gurucool.life/api/v1/guru/astrologersDetails?specialization=Marriage`
        );
       
        // console.log(response)
        setAstroData(response?.data?.guru?.docs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData()
  }, []);
  // console.log(astroData);
  // console.log(typeof astroData);

  const dispatch = useDispatch();

  const data = useSelector((state) => state.homepageAstrologers.data);
  const status = useSelector((state) => state.homepageAstrologers.status);
  const error = useSelector((state) => state.homepageAstrologers.error);

  // const filteredData = data.filter(
  //   (item) => item.status === "active" && (item.callAvailability === "online" || item.callAvailability === "busy")
  //   );
  // useEffect(() => {
  //   const interval = setInterval(homepageAstrologers(), 5000);
  //   return () => clearInterval(interval);
  // }, []);

  const filteredData = data;

  const options = {
    //  loop: false,
    // controlsClass: 'owl-controls',
    // center: true,
    nav: true,
    dots: false,
    // margin: 8,
    // dotsEach: false,
    slideBy: 3,
    responsive: {
      0: {
        items: 1.7,
        // margin: 8,
        nav: false,
        loop: false,
      },
      600: {
        items: 3.2,
        loop: true,
      },
      1000: {
        items: 4,
      },
    },
  };





  const { isOpen, onOpen, onClose } = useDisclosure();

  const [chatBtnClicked, setChatBtnClicked] = useState(false);
  const [callBtnClicked, setCallBtnClicked] = useState(false);

  const chatClicked = () => {
    setChatBtnClicked(true);
    onOpen();
  };

  const callClicked = () => {
    setCallBtnClicked(true);
    onOpen();
  };

  const loginToken = localStorage.getItem("loginToken");

  const tenMarriageAstrologer = astroData?.slice(7,17)

  if (status === "loading") {
    return (
      // <Box  w={'full'} bg={'white'}>
      <Container id="marriage" w={'100%'} maxW={"6xl"} mt={{ base: "18px", md: "24px" }}>
        <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ md: "56px", base: "16px" }}
        >
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          <Text
            fontSize={{ base: "18px", md: "32px" }}
            fontWeight={"700"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
          // fontFamily={'sans-serif'}
          >
            Premium Astrologers
          </Text>
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
        </Box>
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="orange.500"
            size="xl"
          />
        </Center>
      </Container>
      // </Box>
    );
  } else if (status === "succeeded") {
    return (
      //   <Center py={6}>
      <>
        <Box w={'100%'} bg={'white'}>
          <Container h={{ md: "36rem" }} id="recommended-id" bg={'white'} maxW={"6xl"} mb={{ base: "8px", md: "24px" }} mt={{ base: "8px", md: "24px" }}>
            {!loginToken ? (
              <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}
                size={{ base: "xs", md: "4xl" }}
              >
                <Login />
              </Modal>
            ) : (
              <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}
                size={{ base: "xs", md: "4xl" }}
              >
                {chatBtnClicked && <ChatFlowModal />}
                {callBtnClicked && <CallFlowModal />}
              </Modal>
            )}
            <Box
              as="div"
              display={"flex"}
              justifyContent={{ md: "center", base: "flex-start" }}
              alignItems={"center"}
              mb={{ md: "1px", base: "1px" }}
              className={"section-heading"}
              position={"relative"}
            >
              {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
              <Image src={Marriage} alt="imgs" h={{ md: "45px", base: "30px" }} w={{ md: "45px", base: "30px" }} mb={{ md: "-0.5rem" }} />

              <Text
                fontSize={{ base: "18px", md: "34px" }}
                fontWeight={"700"}
                color={"var(--black-75, #414141);"}
                mx={{ base: "8px", md: "12px" }}
                className="heading"
                mt={"0.5rem"}
                py={"20px"}
                
                bottom={{ base: "2px", md: "2px" }}
              >
                Marriage Astrologers
              </Text>

              <Text as={"a"} href="astrologers/Marriage" color={" #FF5710"} fontSize={{base:"16px",md:"22px"}} fontWeight={"700"} width={"auto"} position={"absolute"} top={{base:"27px"}} right={{base:"0px"}}>See all</Text>
              {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
            </Box>
            {/* <Box
            as="div"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mb={{ md: "30px", base: "16px" }}
            className={"section-heading"}
          >
         
            <Text
              fontSize={{ base: "12px", md: "20px" }}
              fontWeight={"500"}
              color={"#D97122"}
              mx={{ base: "12px", md: "19px" }}
              textAlign={"center"}
            >
              Unlock the expertise of renowned astrologers in their specialized
              fields for premium insights.
            </Text>
          </Box> */}

            {/* <Flex
            gap={{ lg: 0, md: "24px", base: 0 }}
            flexWrap={{ md: "wrap", base: "nowrap" }}
            justifyContent={{ md: "center", base: "flex-start" }}
            borderRadius={"7px"}
            overflowX={{ md: "unset", base: "auto" }}
            pb={"6px"}
            sx={{
              // Hide scrollbar in webkit-based browsers
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          > */}
            <Box display={{ md: "block", base: "none" }}>

              <OwlCarousel {...options}>
                   {/* {console.log(astroData)} */}
                {
                  astroData?.map((currElem, index) => (
                    <AstroProfileCard
                      key={index}
                      currElem={currElem}
                      length={astroData.length}
                      index={index}
                      callClicked={callClicked}
                    />
                  ))
                }
              </OwlCarousel>
            </Box>

            <Flex
              display={{ base: "flex", md: "none" }}
              gap={{ lg: 0, md: "24px", base: "8px" }}
              flexWrap={{ md: "wrap", base: "nowrap" }}
              justifyContent={{ md: "center", base: "flex-start" }}
              borderRadius={"7px"}
              overflowX={{ md: "unset", base: "auto" }}
              pb={"16px"}
              sx={{
                // Hide scrollbar in webkit-based browsers
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              w={"auto"}
            >
              {tenMarriageAstrologer?.map((currElem, index) => {
                return (
                  <>
                    <AstroProfileCard
                      currElem={currElem}
                      length={astroData.length}
                      index={index}
                      callClicked={callClicked}
                    />
                  </>
                );
              })}
            </Flex>
            {/* </Flex> */}
            {/* <Image src={footerLine} alt="footer-line" /> */}
          </Container>
        </Box>
      </>
      // </Center>
    );
  } else if (status === "failed") {
    return (
      <Center>
        <Text color="red">{error}</Text>
      </Center>
    );
  }

  return null;
}
