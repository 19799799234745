import {
  HMSNotificationTypes,
  selectHMSMessages,
  selectPeers,
  useHMSActions,
  useHMSNotifications,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useAutoplayError } from "@100mslive/react-sdk";
import React, { useEffect, useState } from "react";
import Peer from "../Peer/Peer";
import LiveFooter from "../LiveFooter";
import LiveHeader from "../LiveHeader";
import { Box, Text } from "@chakra-ui/layout";
import ChatNdParticipants from "../LiveChat/ChatNdParticipants";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchAstrologerProfileData } from "../../../store/astrologerProfileSlice";
import { useMediaQuery } from "@chakra-ui/react";
import ShowGifts from "../GIfts/ShowGifts";
import "./Conference.css";
import taphere from "../../../assets/live-stream/tap_to_unmute.png";
import liveEnded from "../../../assets/live-stream/ended_fallback.png";
import { G_GET_USER_PROFILE } from "../../../apiLinks";
import axios from "axios";
import liveFallback from "../../../assets/live-stream/live_fallback.png";
import Message from "../LiveChat/Message";

function Conference() {
  const notification = useHMSNotifications([HMSNotificationTypes.ROOM_ENDED]);
  const loginToken = localStorage.getItem("loginToken");
  const { error, resetError, unblockAudio } = useAutoplayError();
  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );
  const getRandomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
  const [searchParams] = useSearchParams();
  const guruToken = searchParams.get("id");
  useEffect(() => {
    dispatch(fetchAstrologerProfileData({userName: guruToken, isToken: true}));
  }, [guruToken, dispatch]);
  const astroDetails = useSelector((state) => state?.astrologerProfile?.data);
  const hmsActions = useHMSActions();
  const peers = useHMSStore(selectPeers);
  const localUser = peers.filter((peer) => peer.isLocal);
  const localUserRole = localUser[0]?.roleName;
  const astrologerPeer = peers.find((peer) => peer.roleName === "astrologer");
  const [isMediumScreen] = useMediaQuery("(min-width: 768px)");

  /* This code is using the `useEffect` hook to listen for a specific notification type
(`HMSNotificationTypes.ROOM_ENDED`) from the `useHMSNotifications` hook. If the notification is
received, the code logs a message to the console and redirects the user to the homepage
(`window.location.href = "/"`). The `notification` variable is included in the dependency array of
the `useEffect` hook to ensure that the effect is re-run whenever the notification changes. */
  useEffect(() => {
    if (notification?.type === HMSNotificationTypes.ROOM_ENDED) {
      window.location.href = "/";
    }
  }, [notification]);

  /* This code is using the `useEffect` hook to set an event listener for the `window.onunload` event.
When the user navigates away from the page or closes the tab/window, the `hmsActions.leave()`
function is called to leave the live video room. The `hmsActions` variable is included in the
dependency array of the `useEffect` hook to ensure that the effect is re-run whenever the
`hmsActions` variable changes. */
  useEffect(() => {
    window.onunload = () => {
      hmsActions.leave();
    };
  }, [hmsActions]);

  /**
   * The function handles the submission of a form and joins a live video room using the user's login
   * token and room code.
   */
  const handleSubmit = async () => {
    const roomCode = astroDetails?.liveGatewayIDs?.userLiveId;
    if (astroDetails?.liveStatus && roomCode) {
      const userName = loginToken
        ? `${
            (
              await axios.get(G_GET_USER_PROFILE, {
                headers: { Authorization: `Bearer ${loginToken}` },
              })
            ).data.user.firstName
          } ${
            (
              await axios.get(G_GET_USER_PROFILE, {
                headers: { Authorization: `Bearer ${loginToken}` },
              })
            ).data.user.lastName
          }`
        : `newUser${getRandomNumber(10, 500)}`;

      try {
        const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode });
        if (authToken) {
          await hmsActions.join({ userName, authToken });
          await hmsActions.sendBroadcastMessage(userName + " joined the live");
        }
      } catch (e) {
        console.error("Error joining:", e);
      }
    }
  };

  // Run handleSubmit on component mount (refresh)
  useEffect(() => {
    handleSubmit();
  }, [astroDetails]);

  const messages = useHMSStore(selectHMSMessages);
  return (
    <div style={{ height: "100svh", maxHeight: "100svh" }}>
      {error && (
        <Box
          className="conference-section"
          width={"100%"}
          height={"100svh"}
          position={"relative"}
          bg={"#884227"}
          onClick={() => {
            unblockAudio();
            resetError();
          }}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          overflow={"hidden"}
        >
          <img alt="taphere img" src={taphere}></img>
          <Text fontSize={"29.2835px"} color={"white"}>
            Tap Here
          </Text>
          <Text fontSize={"17px"} color={"white"}>
            Tap to start live session with astrologer
          </Text>
        </Box>
      )}

      {!error && astroDetails?.liveStatus && (
        <Box>
          {astrologerPeer ? (
            <Box
              className="conference-section"
              width={"100%"}
              height={"100svh"}
              position={"relative"}
              overflow={"hidden"}
              // bgGradient={{
              //   base: "linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(0, 0, 0, 0.00) 40.10%, rgba(0, 0, 0, 0.00) 52.08%, rgba(0, 0, 0, 0.59) 100%);",
              //   md: "none",
              // }}
            >
              {/* <Box
                className="contains-Gradient"
                width={"100%"}
                height={"100svh"}
                position={"absolute"}
                overflow={"hidden"}
                bgGradient={{
                  base: "linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(0, 0, 0, 0.00) 40.10%, rgba(0, 0, 0, 0.00) 52.08%, rgba(0, 0, 0, 0.59) 100%);",
                  md: "none",
                }}
              ></Box> */}
              <>
                {isMediumScreen ? null : (
                  <LiveHeader
                    peers={peers}
                    localUserRole={localUserRole}
                    astroDetail={astroDetails}
                  />
                )}
                {/* <ShowGifts astroDetail={astroDetails} /> */}
                {isMediumScreen ? null : (
                  <ChatNdParticipants
                    astroDetail={astroDetails}
                    localUserRole={localUserRole}
                  />
                )}

                <div className="peers-container">
                  {astrologerPeer ? (
                    <Peer
                      key={astrologerPeer.id}
                      peer={astrologerPeer}
                      localUserRole={localUserRole}
                    />
                  ) : (
                    <Box
                      backgroundColor={"pink"}
                      // backgroundImage={liveEnded}
                      className="conference-section"
                      width={"100%"}
                      height={"100%"}
                      position={"relative"}
                      backgroundImage={astroDetails?.user?.avatar?.url}
                      backgroundRepeat={"no-repeat"}
                    />
                  )}
                </div>
                {isMediumScreen ? null : (
                  <LiveFooter
                    localUserRole={localUserRole}
                    astroDetail={astroDetails}
                    localUser={localUser}
                  />
                )}
              </>
            </Box>
          ) : (
            <Box
              className="conference-section"
              width={"100%"}
              height={"100svh"}
              position={"relative"}
              backgroundRepeat={"no-repeat"}
              backgroundPosition={"center"}
              backgroundImage={
                astroDetails?.liveStatus
                  ? astroDetails?.user?.avatar?.url
                  : liveEnded
              }
            />
          )}
        </Box>
      )}
    </div>
  );
}

export default Conference;
