import { selectLocalPeer, useHMSStore } from "@100mslive/react-sdk";
import { Box, Text } from "@chakra-ui/react";

function Message({ message, index, messageLength }) {
  const localPeer = useHMSStore(selectLocalPeer);
  //
  return (
    <Box
    
      className={`message ${
        message.senderUserId === localPeer.customerUserId && "myMessage"
      }`}
    >
      <Text
        fontSize={{ base: "14px", md: "16px" }}
        color={{ base: "#FFFFFF", md: "#535353" }}
        // opacity={(index + 1) / messageLength}
        m={0}
        pt={"12px"}
        text-textShadow={"0px 2px 4px rgba(0, 0, 0, 0.2)"}
      >
        {message.senderName}
      </Text>
      <Text
        m={0}
        as={"span"}
        fontSize={{ base: "16px", md: "18px" }}
        fontWeight={"600"}
        color={{ md: "#535353", base: "#FFFFFF" }}
        // opacity={(index + 1) / messageLength}
        text-textShadow={"0px 2px 4px rgba(0, 0, 0, 0.2)"}
      >
        {message.message}
      </Text>
    </Box>
  );
}

export default Message;
