import React, { useState } from "react";
// import Navbar from "./Navbar";
import Toggle from "./Toggle";
import Earning from "./Earning";
import Consultation from "./Consultation";
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Text,
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  WrapItem,
  Avatar,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,  
  AccordionPanel,
} from "@chakra-ui/react";
import TimeTable from "./TimeTable";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Edit from "./Edit";
import { FaUserFriends } from "react-icons/fa";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { BsArrowDown } from "react-icons/bs";
// import {FiChevronDown} from "react-icons/fi"
import { FiChevronDown } from "react-icons/fi";
import ConsultationHistory from "../Consultation_History/ConsultationHistory";
import CallQueue from "./Callqueue";

const Dashboard = ({ astrologer }) => {
  // const name = astrologer?.user?.firstName;
  const astroData = astrologer;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = useState("inside");
 
  //

  const btnRef = React.useRef(null);
  return (
    <>
      <Modal
        onClose={onClose}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior={scrollBehavior}
        isCentered
        size={{ md: "sm", base: "xs" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Followers</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {astrologer.guru.followers.map((curr, index) => (
              <Flex my={{ md: 4, base: 2 }} alignItems={"center"}>
                <WrapItem>
                  <Avatar
                    name={`${curr.firstName} ${curr.lastName}`}
                    src={curr?.avatar?.url}
                  />
                </WrapItem>
                <Text mb={0} ml={{ md: "8px", base: "6px" }} key={index}>
                  {curr.firstName} {curr.lastName}
                </Text>
              </Flex>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <Container maxW={"6xl"}> */}
      <Flex
        maxW={"6xl"}
        mx={"auto"}
        px={4}
        justifyContent={{ md: "flex-start", base: "space-between" }}
        alignItems={"center"}
      >
        <Heading
          color={"#B5570F"}
          textAlign={"center"}
          pt={"20px"}
          astrologer={astrologer}
          fontSize={{ base: "21px", md: "42px" }}
        >
          Hi {astrologer?.user?.firstName} {astrologer?.user?.lastName}
        </Heading>
        <HStack
          ml={{ md: "50px" }}
          pt={"20px"}
          spacing={{ md: "15px", base: "8px" }}
          ref={btnRef}
          onClick={onOpen}
          cursor={"pointer"}
        >
          <Icon
            as={FaUserFriends}
            boxSize={{ md: "29px", base: "24px" }}
            color={"#FF4C00"}
          />
          <Text
            fontSize={{ md: "20px", base: "16px" }}
            fontWeight={"600"}
            color={"#525252"}
          >
            {astrologer.guru.followers.length}
          </Text>
          <Icon
            as={FiChevronDown}
            boxSize={{ md: "20px", base: "16px" }}
            ml={{ md: "8px !important", base: "4px !important" }}
            color={"#525252"}
          ></Icon>
        </HStack>
      </Flex>
      <Flex flexDirection="column" alignItems="center">
        <Toggle astrologer={astrologer} />
        <Earning astrologer={astrologer} />
        <TimeTable astroData={astroData} />

        <Accordion
                          allowMultiple
                          mt="30px"
                          mb="30px"
                          width={{md:"1120px", sm:"380px", base:"380px"}}
                          borderRadius={{md:"18px", sm:"8px", base:"8px"}}
                          border={"2px solid #FFC293"}
                          mb={"30px"}
                        >
                          <AccordionItem border={"none"}>
                            <h2 style={{ marginBottom: "0px" }}>
                              <AccordionButton
                                justifyContent={"center"}
                                borderRadius={{md:"18px", sm:"8px", base:"8px"}}
                              >
                                <Heading
                                  textAlign={"center"}
                                  fontSize={{ md: "24px", base: "18px" }}
                                  fontWeight={"500"}
                                  ml={{ md: 4, base: 2 }}
                                  mb={0}
                                  p={{md:"30px", base:"10px" ,sm:"10px"}}
                                >
                                  Consultation History
                                </Heading>
                                {/* <Flex justifyContent={"flex-end"}> */}
                                <AccordionIcon />
                                {/* </Flex> */}
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} px={0}>
                            <ConsultationHistory/>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
        
        {/* <Consultation astrologer={astrologer} /> */}
        {/* <CallQueue astrologer={astrologer} /> */}
      </Flex>
      {/* </Container> */}
    </>
  );
};

export default Dashboard;
