import { Container, Heading, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const RefundAndCancelation = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <title>Gurucool || Refund Policy</title>
        <meta
          name="description"
          content="At GuruCool, we strive for customer satisfaction. Familiarize yourself with our refund policy to understand the criteria and process for requesting a refund, if applicable."
        />
      </Helmet>
      <Container maxW={"6xl"} my={{ md: 6, base: 3 }}>
        <Heading textAlign={"center"} mb={3}>
          Refund Policy
        </Heading>
        <Text textAlign={"left"} mb={0}>
          The Company can issue refunds for purchased Credits to your dedicated
          account within one hour of the transaction, if the payment was made
          using Online/Source account. No other refunds for purchased Credits
          are available.
          <br></br>
          Transferring Credits to other users or Accounts is not permitted, and
          the Company will not transfer your in-app credits to any other user or
          Account.
          <br></br>
          <br></br>
          <Text textAlign={"left"} mb={0} fontWeight={"bold"}>
            REFUND OF USED CREDITS{" "}
          </Text>
          Medical consultations are subjective, and different medical
          professionals may provide varying results. Credits spent are intended
          to compensate MedicalPartners for their time and effort, and not for
          any specific output. No refunds will be issued for any inaccuracies,
          incorrectness, or fallacies in predictions, advice, or remedies
          provided during consultations.
          <br></br>
          <br></br>
          If you are dissatisfied with the Services, you may contact customer
          care within seven days, explaining the reasons for your
          dissatisfaction.
          <br></br>
          <br></br>
          Customer care representatives will verify your complaint, accessing
          necessary information, and may request further details. The Company
          will make efforts to gather this information within five business
          days.
          <br></br>
          <br></br>
          Customer care representatives will determine if your dissatisfaction
          is due to specific circumstances, such as call disturbances, language
          issues, delays, or irrelevant responses.
          <br></br>
          <br></br>
          If your dissatisfaction arises from the circumstances mentioned above,
          a refund of corresponding Credits will be initiated to your Account
          after deducting applicable charges. Refunds will be credited to your
          in-app credits.
          <br></br>
          <br></br>
          All other transactions related to the Services are final and
          non-refundable.
          <br></br>
          <br></br>
          Please note that this is a general template and should be reviewed and
          customized as per your specific refund policy and terms of service.
        </Text>
      </Container>
    </>
  );
};

export default RefundAndCancelation;
