import { Box, Container, Flex, Heading, Text } from "@chakra-ui/layout";
import React from "react";
import Specialities from "../Specialities";
import { FaHeart, FaHeartbeat, FaHandHoldingHeart } from "react-icons/fa";
import { Icon } from "@chakra-ui/icon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Image,
  Modal,
  useDisclosure,
} from "@chakra-ui/react";
import ConsultAstroCard from "./ConsultAstroCard/ConsultAstroCard";
import { useState } from "react";
import axios from "axios";
import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";
import Login from "../../Login&Signup/Login";
import CallFlowModal from "../../CallFlow/CallFlowModal";
import { useLocation, useNavigate } from "react-router-dom";
// import { FaHandHoldingHeart } from "react-icons/fa";
import free1 from "../../../assets/banners/free1.jpg";
import { CommonBanners } from "./CommonBanners";
import { ChevronRightIcon } from "@chakra-ui/icons";
import SearchBarTop from "../../Newhomepage/SearchBarTop/SearchBarTop";

const Life = () => {
  const token = localStorage.getItem("guruToken");
  const [astroData, setAstroData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${token}`
        )
        .then((response) => {
          //
          dispatch(
            selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
          );
        });
    }
  }, [token]);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const status = useSelector((state) => state.data.status);
  const error = useSelector((state) => state.data.error);
  const [skill, setSkill] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://data.gurucool.life/api/v1/guru/astrologersDetails?specialization=Life&skills=${skill}`
        );
        setAstroData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [skill]);

  // const filteredData = data.filter(
  //   (item) =>
  //     item.specialization.includes("Life") &&
  //     (skill ? item.skills.includes(skill) : true)
  // );

  const handleOptionChange = (selectedOption) => {
    // Do something with the selected option received from the child component
    //
    setSkill(selectedOption);
  };

  // const shuffledArray = useMemo(() => {
  //   const array = [...filteredData];
  //   for (let i = array.length - 1, j; i > 0; i--) {
  //     j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]];
  //   }
  //   return array;
  // }, [filteredData]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [callBtnClicked, setCallBtnClicked] = useState(false);
  const users = useSelector((state) => state.loggedInUserDetails.data);

  const callClicked = () => {
    setCallBtnClicked(true);
    onOpen();
  };

  const location = useLocation().pathname;
  const [displayCount, setDisplayCount] = useState(6);

  const navigate = useNavigate();
  const handleSeeMore = () => {
    navigate("/astrologers/Life");
  };

  const loginToken = localStorage.getItem("loginToken");

  const [isOpen1, setIsOpen1] = useState(false);
  const [oneTime, setOnetime] = useState();

  const handleAccordionClick = (id) => {
    setIsOpen1(!isOpen1);
    setOnetime(id);
  };

  const faq = [
    {
      id: 1,
      q: " What factors should I consider while filtering astrologers on Gurucool.life for Life-related consultations?",
      a: "When filtering astrologers for Life-related consultations, consider the following factors:- Life Specialization: Look for astrologers who have expertise in Life and corporate astrology. - Experience: Check the astrologers' track record and years of experience in providing Life-related guidance.- Reviews and Ratings: Read feedback from previous clients to assess the astrologers' credibility and accuracy in Life predictions. - Credentials: Verify their qualifications, certifications, and any affiliations related to Life astrology.",
    },
    {
      id: 2,
      q: " How can I find astrologers specialized in Life-related consultations on Gurucool.life?",
      a: "To find astrologers with expertise in Life-related consultations on Gurucool.life:- Search Filters: Utilize the website's search filters and select Life or Corporate Astrology as the preferr specialization. - Profile Information: Visit astrologers' profiles to explore their areas of expertise, which often include Life-related details.",
    },
    {
      id: 3,
      q: "Are there any testimonials or reviews available for astrologers on Gurucool.life related to Life consultations?",
      a: "Yes, Gurucool.life usually includes testimonials and reviews from clients who have consulted astrologers for Life-related matters. These reviews provide valuable insights into the astrologers' abilities and the effectiveness of their Life guidance..",
    },
    {
      id: 4,
      q: " Can I communicate with astrologers specialized in Life-related consultations before scheduling a session?",
      a: "Certainly! Gurucool.life allows users to interact with astrologers through various means, such as:- Messaging: Send direct messages to astrologers to discuss your Life-related queries and concerns.- Free Introductory Sessions: Some astrologers may offer free introductory sessions, providing an opportunity to get to know them better before booking a paid consultation..",
    },
    {
      id: 5,
      q: "How can I verify the credentials and authenticity of astrologers specialized in Life-related consultations on Gurucool.life??",
      a: "To verify the credentials and authenticity of astrologers:- Check Profiles: Thoroughly review their profiles for details on Life specialization and any relevant certifications they may have.- Online Research: Conduct online searches to see if they have a reputable presence beyond Gurucool.life as Life astrologers..",
    },
    {
      id: 6,
      q: "Can I compare multiple astrologers specializing in Life-related consultations before making a decision?",
      a: "Yes, Gurucool.life allows you to compare multiple astrologers based on their profiles, expertise, reviews, and ratings. This way, you can make an informed decision when selecting an astrologer for Life-related consultations.",
    },
    {
      id: 7,
      q: "What are some red flags to be aware of while filtering astrologers for Life-related consultations on Gurucool.life??",
      a: "Be cautious of the following red flags:- Lack of Life Specialization: Astrologers who do not have specific experience or qualifications in Life and corporate astrology.- Consistently Negative Feedback: Multiple negative reviews and feedback from previous clients regarding their Life-related services.",
    },
    {
      id: 8,
      q: "Is there customer support available if I need assistance while filtering astrologers for Life-related consultations?",
      a: "Yes, Gurucool.life typically provides customer support to assist users with any queries or concerns related to filtering astrologers or using the platform. Remember to prioritize your Life goals and objectives while engaging with astrologers on Gurucool.life or any other platform. Take your time to research and choose an astrologer whose expertise aligns with your Life-related interests.",
    },
  ];

  return (
    <>
    <SearchBarTop/>
      <Container
        maxW={"7xl"}
        mb={"50px"}
        px={location !== "/astrologers" ? 4 : 0}
      >
        {!loginToken ? (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "4xl" }}
          >
            <Login />
          </Modal>
        ) : (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "md" }}
          >
            {/* {chatBtnClicked && <ChatFlowModal />} */}
            {callBtnClicked && <CallFlowModal />}
          </Modal>
        )}
        {/* {location !== "/astrologers" && (
          <Box
            display={
              // users?.userDetails?.StripeInvoice.length !== 0 ||
              users?.userDetails?.paymentsDetails.length !== 0 ? "none" : "flex"
            }
          >
            <Image src={free1} alt="life-banner" />
          </Box>
        )} */}
        <CommonBanners users={users} />
        {location !== "/astrologers" && (
          <Specialities handleOptionChange={handleOptionChange} />
        )}
        <Box>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            mt={{ md: "56px", base: "24px" }}
            gap="10px"
          >
            <Icon
              as={FaHandHoldingHeart}
              // key={curr.id}
              boxSize={{ md: "42px", base: "30px", sm: "30px" }}
              color={"#BC148D"}
              // display={exactPath === `/${curr.name}` ? "none" : "block"}
              mr={{ md: "20px" }}
            />
            <Heading
              as="h2"
              textAlign={"center"}
              fontSize={{ md: "38px", base: "18px", sm: "18px" }}
              fontWeight={"700"}
              mb={0}
            >
              Life
            </Heading>
          </Flex>
          <Text
            fontSize={{ md: "16px" }}
            fontWeight={"400"}
            color={"#545454"}
            mt={{ md: "16px" }}
            textAlign={"center"}
            w={"90%"}
            mx={"auto"}
          >
            Love Experts: Discover experts who specialize in matters of the
            heart. Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey.
          </Text>
        </Box>

        <Flex justifyContent="center" flexWrap="wrap" gap={{ md: "20px" }}>
          {astroData?.guru?.docs?.length !== 0 ? (
            astroData?.guru?.docs?.map((currElem, index) => {
              if (location === "/astrologers") {
                if (index < displayCount) {
                  return (
                    <ConsultAstroCard
                      key={index}
                      currElem={currElem}
                      index={index}
                      callClicked={callClicked}
                    />
                  );
                }
              } else {
                return (
                  <ConsultAstroCard
                    key={index}
                    currElem={currElem}
                    index={index}
                    callClicked={callClicked}
                  />
                );
              }
            })
          ) : (
            <Box>
              <Heading>We are looking for Astrologers under this Skill</Heading>
            </Box>
          )}
        </Flex>

        {location === "/astrologers" && (
          <Flex justifyContent={"flex-end"}>
            <Button
              variant={"ghost"}
              colorScheme={"orange"}
              onClick={handleSeeMore}
              mt={4}
            >
              See More
            </Button>
          </Flex>
        )}
      </Container>

      <Container maxW={"6xl"}>
        <Box>
          <Heading
            fontSize={{ md: "38px", base: "18px" }}
            fontWeight={"600"}
            textAlign={"center"}
            mb={{ md: "26px", base: "26px" }}
          >
            FAQ's
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={{ md: "16px" }}
            fontWeight={"400"}
            color={"#545454"}
            display={{ md: "block", base: "none" }}
          >
            We are here to help you, with most frequent question asked by our
            users.
          </Text>
        </Box>
        <Box mb={{ md: "40px", base: "16px" }}>
          <Accordion
            allowToggle
            w={{ md: "896px", base: "328px" }}
            mx={"auto"}
            mb={{ base: "14px", md: "25px" }}
          >
            {faq.map((curr, index) => {
              return (
                <AccordionItem
                   border={
                    curr.id === oneTime
                      ? "2px solid #FF4C00"
                      : "none"
                  }
                  borderRadius={"18px"}
                  boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.26)"}
                  // h={{ md: "132px", base: "68px" }}
                >
                  <h2>
                    <AccordionButton
                      _hover={{ bg: "transparent" }}
                      // h={{ md: "132px", base: "68px" }}
                      // pt={{md: isOpen1 ? "50px" : "50px", base: "16px"}}
                      // pb={{md: isOpen1 ? "0px" : "50px", base: isOpen1 ? "0px" :"16px"}}
                      onClick={() => handleAccordionClick(curr.id)}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize={{ md: "18px", base: "12px" }}
                        fontWeight={"400"}
                        color={"#3F3F3F"}
                        ml={{ md: "38px", base: "16px" }}
                        mr={{ md: "100px", base: "36px" }}
                      >
                        {curr.q}
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        boxSize={{ md: "50px" }}
                        borderRadius={"full"}
                        bg={
                          isOpen1 && curr.id === oneTime ? "#FF4C00" : "white"
                        }
                        flexDirection={"row-reverse"}
                        transform={
                          isOpen1 && curr.id === oneTime ? "rotate(90deg)" : ""
                        }
                        transition="transform 0.2s ease-in-out"
                      >
                        <ChevronRightIcon
                          boxSize={{ md: 8, base: 4 }}
                          color={
                            isOpen1 && curr.id === oneTime ? "white" : "#FF4C00"
                          }
                        />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    pt={0}
                    fontSize={{ md: "18px", base: "12px" }}
                    fontWeight={"400"}
                    color={"#626262"}
                    ml={{ md: "38px", base: "16px" }}
                    mr={{ md: "100px", base: "36px" }}
                    // mt={{md: "-42px", base: "-24px"}}
                  >
                    {curr.a}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      </Container>
    </>
  );
};

export default Life;
