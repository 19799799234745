import { createSlice } from "@reduxjs/toolkit";

const callConsultationEndedDetailsSlice = createSlice({
  name: "callConsultationEndedDetailsSlice",
  initialState: {
    // todo to check if cookie exist to chheck the user is logged in or not
    callDuration: "",
    amount: "",
    purchaseId:""
  },
  reducers: {
    setCallDuration(state, action) {
      state.callDuration = action.payload;
    },
    setAmount(state, action) {
      state.amount = action.payload;
    },
    setPurchaseId(state, action) {
      state.purchaseId = action.payload;
    },
  },
});

export const callConsultationEndedDetailsAction =
  callConsultationEndedDetailsSlice.actions;
export default callConsultationEndedDetailsSlice.reducer;
