import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Modal,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./LiveastrologerBottom.css";
import consultimage from "../../../assets/newhomepage/consultnowimage.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Userdetails from "../../Popups/AstrologerCallPopup/Userdetails";
import CallFlowModal from "../../CallFlow/CallFlowModal";
import Login from "../../Login&Signup/Login";

const LiveastrologerBottom = () => {
  const [callBtnClicked, setCallBtnClicked] = useState(false);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loginToken = localStorage.getItem("loginToken");
  const userDetails = useSelector((state) => state.loggedInUserDetails);
  console.log(userDetails?.data);
  const userName = userDetails?.data?.user?.firstName;

  const [lastConsultedAstrologer, setLastConsultedAstrologer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  console.log(lastConsultedAstrologer);

  const uid = userDetails?.data?.user?.uid;
  console.log(uid);
  console.log(lastConsultedAstrologer);

  const getUserLastConsulation = async () => {
    console.log("CALLED");
    try {
      const response = await axios.get(
        `https://data.gurucool.life/api/v1/user/getUserLastFiveUniqueConsulation?uid=${uid}`,
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );

      // Handle the response data here (response.data)
      console.log("API Response:", response);
      setLastConsultedAstrologer(response?.data?.userLastConsultedAstrologer);
      setIsLoading(false);
      // You can return the response data or process it further as needed
    } catch (error) {
      // Handle errors here
      console.error("API Error:", error);

      // You can throw the error or return a custom error message
      // throw error;
      return { error: "Something went wrong. Please try again later." };
    }
  };

  const options = {
    // loop: true,
    // controlsClass: 'owl-controls',
    // center: true,
    // nav: true,
    dots: true,
    // margin: 18,
    // dotsEach: true,
    // autoplay: true,
    // autoplayTimeout: 10000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
        // margin: 8,
        // nav: false,
        dots: true,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    getUserLastConsulation();
  }, [userDetails]);

  return (
    <>
      {!loginToken ? (
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          size={{ base: "xs", md: "4xl" }}
        >
          <Login />
        </Modal>
      ) : (
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          size={{ base: "xs", md: "md" }}
        >
          {callBtnClicked && <CallFlowModal />}
        </Modal>
      )}

      {!loginToken ||
      userDetails?.data?.userDetails?.consultationCount === 0 ? null : (
        <Container
          id="liveAstrologerBottom"
          // maxW={{ md: "full", base: "full" }}
          minW={{ md: "full" }}
          w={"100%"}
          bg="#fff"
          display={{ md: "flex", base: "flex" }}
          justifyContent={{ md: "center", base: "center" }}
          // mb={{md:"20px"}}
          // py={{base:"5px"}}
          px={{ base: "30px" }}
          mt={{ base: "2px" }}
        >
          {isLoading ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={{ base: "9rem", md: "164px" }}
            >
              <Spinner></Spinner>
            </Box>
          ) : (
            <Box
              // my={{ md: "20px" }}
              h={{ base: "9rem", md: "164px" }}
              minW={{ md: "6xl", base: "115%" }}
              px={{ md: "0" }}
              bg="#fff"
              display={"flex"}
              alignItems="center"
              // w={{ base: "auto" }}
              p={{ base: "10px 10px" }}
            >
              <OwlCarousel
                style={{ height: "105px !important" }}
                className="owl-theme"
                loop
                {...options}
              >
                {lastConsultedAstrologer?.map((el, id) => {
                  return (
                    <Flex
                      as={"a"}
                      cursor={"pointer"}
                      href={`/astrologer/${el?.userName}`}
                      px={{ md: "0" }}
                      border={{ base: "2px solid #FF6726", md: "none" }}
                      ml={{ base: "0.2rem" }}
                      pl={{ base: "10px", md: "50px" }}
                      // gap={{ base: "-0.25rem", md: "0.75rem" }}
                      borderRadius={{ base: "4px", md: "none" }}
                      // w={{ base: "368px", md: "1000px" }}
                      w={"99%"}
                      // pt={{ base: "auto", md: "0rem" }}
                      // mb={{ base: "-1px" }}
                      alignItems={"center"}
                      mt={{
                        base: lastConsultedAstrologer.length > 1 ? "45px" : "0",
                      }}
                    >
                      <Box boxSize={{ base: "70px" }}>
                        <Image
                          src={el?.user?.avatar?.url}
                          boxSize={"100%"}
                          borderRadius="50%"
                        />
                      </Box>
                      <Box
                        position={"relative"}
                        bottom={"23px"}
                        right={"14px"}
                        boxSize={"11px"}
                        bgColor={"#14A400"}
                        borderRadius={"50%"}
                      ></Box>
                      <Box
                        mt={"1rem"}
                        lineHeight={{ base: "1rem", md: "1.55rem" }}
                        display={"flex"}
                        flexDirection={{ base: "column", md: "row" }}
                        w={{ md: "80%" }}
                        justifyContent={{ md: "space-between" }}
                      >
                        <VStack alignItems={"flex-start"}>
                          <Heading
                            as={"h2"}
                            color="var(--black-75, #414141)"
                            ml={{ md: "0.5rem" }}
                            fontWeight="600"
                            m={1}
                            position={"relative"}
                            bottom={{ md: "-0.2rem" }}
                            fontSize={{ base: "17px", md: "1.65rem" }}
                            my={"0 !important"}
                          >
                            {el?.user?.firstName} {el?.user?.lastName}
                          </Heading>
                          <Text
                            color="var(--black-50, #808080)"
                            fontSize={{ base: "12px", md: "17px" }}
                            m={0}
                            fontWeight="500"
                            ml={"0.5rem"}
                            mt={{ md: "0.45rem" }}
                            pl={{ base: "4px" }}
                          >
                            Hi {userName} I am online now you can
                          </Text>
                        </VStack>
                        <Text
                          color={{ base: "#FF4D00", md: "#fff" }}
                          fontSize={{ md: "17.5px", base: "12px" }}
                          fontWeight="600"
                          ml={"0.25rem"}
                          // position={"relative"}
                          // left={{ base: "0", md: "53.5rem" }}
                          // bottom={{ base: "-5px", md: "2.5rem" }}
                          w={{ base: "72px", md: "166px" }}
                          h={{ base: "auto", md: "42px" }}
                          bgColor={{ base: "none", md: "#FF4D00" }}
                          // pl={{base:"auto", md:"19px"}}
                          borderRadius={"0.4rem"}
                          // pb={{md:"0.25rem"}}
                          // textAlign={{md:"center"}}
                          display={{ md: "flex" }}
                          justifyContent={{ md: "center" }}
                          alignItems={{ md: "center" }}
                          mt={{ md: "25px", base: "10px !important" }}
                        >
                          Consult Now
                        </Text>
                      </Box>
                    </Flex>
                  );
                })}
              </OwlCarousel>
            </Box>
          )}
        </Container>
      )}
      {/* <Container
        w={{ base:"100vw", md:"1500px" }}
        h={{ base: "7rem", md: "8.5rem" }}
        bgColor={"white"}
        display={"flex"}
        alignItems={"center"}
        
      >
        <Flex border={"2px solid green"} w={{ base:"100vw", md:"1500px" }}>
          <Box>
            <Image
              src={consultimage}
              w={{ base: "100px", md: "100px" }}
              h="100px"
              borderRadius="50%"
            />
          </Box>
          <Box  m={"0.25rem 0 0 0.5rem"}>
            <Box >
              <Heading
                color="var(--black-75, #414141)"
                fontSize="22px"
                fontWeight="600"
                m={1}
              >
                Astro Kangana
              </Heading>
              <Text
                color="var(--black-50, #808080)"
                fontSize="14px"
                m={0}
                fontWeight="400"
                ml={"0.25rem"}
              >
                Hi Varun I am online now you can
              </Text>
            </Box>
            <Box>
              <Text
                color="var(--cskff-4-d-00500, #FF4D00)"
                fontSize="18px"
                fontWeight="600"
                ml={"0.25rem"}
              >
                consult now
              </Text>
            </Box>
          </Box>
        </Flex>
      </Container> */}

      {/* <Flex p={2} >
                <Box display="flex" alignItems="center" justifyContent="center" w="35%" border={"2px solid red"}>
                    <Image src={consultimage} w={{base:"100px",md:"100px"}} h="100px" borderRadius="50%" mr={{base:"auto", md:"14rem"}}/>
                    <Box position={"relative"}>
                        <Box  w={3} h={3} bg={"lightgreen"} borderRadius={"50%"} position={"absolute"} top={"-40px"} left={{base:"-20px", md:"-15rem"}}></Box>
                    </Box>
                </Box>
                <Box w="65%">
                    <Box display={"flex"} flexDirection={{base:"column", md:"row"}} gap={{base:"0rem", md:"14rem"}}>
                        <Box display={"relative"} left={{base:"auto", md:"-10rem"}}>
                          <Heading   color="var(--black-75, #414141)" fontSize="22px" fontWeight="600" m={1}>Astro Kangana</Heading>
                          <Text color="var(--black-50, #808080)" fontSize="14px" m={0} fontWeight="400">Hi Varun I am online now you can</Text>
                        </Box>
                        <Box>
                          <Text color="var(--cskff-4-d-00500, #FF4D00)" fontSize="18px" fontWeight="600">consult now</Text>
                        </Box>
                    </Box>
                    
                    
                </Box>
            </Flex> */}
    </>
  );
};

export default LiveastrologerBottom;
