// import { Box, Container, Image, Text, VStack } from '@chakra-ui/react'
// import React from 'react'
// import Customersupport from "../../../assets/newhomepage/customersupport.svg"
// import Refund from "../../../assets/newhomepage/refund.svg"
// import Private from "../../../assets/newhomepage/private.svg"
// import Verified from "../../../assets/newhomepage/agreement.svg"

// const Footerhome = () => {
//   return (
//     <Container display={"flex"} justifyContent={"space-between"}p={2} bg={"#fff"} my={2} py={3}>
        
//             <Box>
//                 <VStack width={"100%"}>
//                     <Box bg="#FFF5EF;" borderRadius="50%" w="40px" h="40px" display="flex" justifyContent={"center"} alignItems={"center"}>
//                       <Image src={Customersupport} w="28px" h="28px"/>
//                     </Box>
//                     <Text textAlign={"center"} fontSize={"10px"} fontWeight={"bold"} >24 X 7 Customer Support</Text>
//                 </VStack>
//             </Box>
//             <Box>
//                 <VStack width={"100%"}>
//                     <Box bg="#FFF5EF;" borderRadius="50%" w="40px" h="40px" display="flex" justifyContent={"center"} alignItems={"center"}>
//                       <Image src={Refund} w="50px" h="50px"/>
//                     </Box>
//                     <Text textAlign={"center"} fontSize={"10px"} fontWeight={"bold"} >100% Refund if Unsatisfied</Text>
//                 </VStack>
//             </Box>
//             <Box>
//                 <VStack width={"100%"}>
//                     <Box bg="#FFF5EF;" borderRadius="50%" w="40px" h="40px" display="flex" justifyContent={"center"} alignItems={"center"}>
//                       <Image src={Private} w="30px" h="30px"/>
//                     </Box>
//                     <Text textAlign={"center"} fontSize={"10px"} fontWeight={"bold"} >Private & Confidential</Text>
//                 </VStack>
//             </Box>
//             <Box>
//                 <VStack width={"100%"}>
//                     <Box bg="#FFF5EF;" borderRadius="50%" w="40px" h="40px" display="flex" justifyContent={"center"} alignItems={"center"}>
//                       <Image src={Verified} w="25px" h="25px"/>
//                     </Box>
//                     <Text textAlign={"center"} fontSize={"10px"} fontWeight={"bold"} >Verified Astrologer</Text>
//                 </VStack>
//             </Box>
            
            
    
//     </Container>
//   )
// }

// export default Footerhome


import { Box, Container, Flex, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import Customersupport from "../../../assets/newhomepage/customersupport.svg"
import Refund from "../../../assets/newhomepage/refund.svg"
import Private from "../../../assets/newhomepage/private.svg"
import Verified from "../../../assets/newhomepage/agreement.svg"

const Footerhome = () => {
  return (
    <Container px={0}  mt={"3px"} mb={"58px"} py={3} maxW="full" display={{base:"flex", md:"none"}}>
      <Container m={"auto"} maxW={"full"} display="flex" bg={"#fff"} mb={"8px"} px={2} py={3} mx={0} >

        <Box>
          <VStack width={"100%"}>
            <Box bg="#FFF5EF;" borderRadius="50%" w="40px" h="40px" display="flex" justifyContent={"center"} alignItems={"center"}>
              <Image src={Customersupport} w="28px" h="28px" />
            </Box>
            <Text textAlign={"center"} fontSize={"10px"} fontWeight={"bold"} >24 X 7 Customer Support</Text>
          </VStack>
        </Box>
        <Box>
          <VStack width={"100%"}>
            <Box bg="#FFF5EF;" borderRadius="50%" w="40px" h="40px" display="flex" justifyContent={"center"} alignItems={"center"}>
              <Image src={Refund} w="50px" h="50px" />
            </Box>
            <Text textAlign={"center"} fontSize={"10px"} fontWeight={"bold"} >100% Refund if Unsatisfied</Text>
          </VStack>
        </Box>
        <Box>
          <VStack width={"100%"}>
            <Box bg="#FFF5EF;" borderRadius="50%" w="40px" h="40px" display="flex" justifyContent={"center"} alignItems={"center"}>
              <Image src={Private} w="30px" h="30px" />
            </Box>
            <Text textAlign={"center"} fontSize={"10px"} fontWeight={"bold"} >Private & Confidential</Text>
          </VStack>
        </Box>
        <Box>
          <VStack width={"100%"}>
            <Box bg="#FFF5EF;" borderRadius="50%" w="40px" h="40px" display="flex" justifyContent={"center"} alignItems={"center"}>
              <Image src={Verified} w="25px" h="25px" />
            </Box>
            <Text textAlign={"center"} fontSize={"10px"} fontWeight={"bold"} >Verified Astrologer</Text>
          </VStack>
        </Box>
      </Container>


    </Container>
  )
}

export default Footerhome