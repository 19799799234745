import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import icon1 from "./image/Frame.svg";
import icon2 from "./image/Frame (1).svg";
import icon3 from "./image/Frame (2).svg";
import Horosscope from "./Components/Horosscope";
import icon4 from "./image/Group.svg";
import icon5 from "./image/Number Block.svg";
import icon6 from "./image/Color.svg";
import { AiFillStar } from "react-icons/ai";
import { GiHeartPlus } from "react-icons/gi";
import { HiFaceSmile } from "react-icons/hi2";
import { FaLeaf } from "react-icons/fa";
import Viewmoretext from "./Components/Viewmoretext";
import axios from "axios";
import {BsBrightnessHigh} from "react-icons/bs";
import Zodiacsign2 from "./Zodiasign2";

export default function Clickviewmore({name}) {
const [viewmore,setViewmore]=useState(false);
const [daily,setDaily]=useState(false);
const [monthly,setMonthly]=useState(false);
const [yearly,setYearly]=useState(false);
const [data,setData]=useState(null);

const [birthdetail,setBirthdetail]=useState(true);
const [zodiacsign,setZodiacsign]=useState("");
const token =localStorage.getItem("loginToken");
const config = {
      headers: {
         'Authorization': `Bearer ${token}`
  }};

const birthdetails=()=>{
    axios.get("https://data.gurucool.life/api/v1/user/getBirthDetails",config)
     .then(response => {
      
       setZodiacsign(response.data.birthDetails.zodiacSign);
       setBirthdetail(true);

     })
     .catch(error => {
       console.error('Error:', error);
       setBirthdetail(false);
});
}


const date = new Date(); 
let day= date.getDate();
let month=date.getMonth()+1;
let year=date.getFullYear();
const fetchZodiacDataDaily = async () => {
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign:name,
        type: "daily",
        day: day,
        month: month,
        year: year,
      },
    });
   
    setData(response.data.zodiac);
  } catch (error) {
    // Handle the error
   
    console.error(error);
    setData(null);
  }
};

const fetchZodiacDataMonthly= async ()=>{
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign:name,
        type: "monthly",
        month: month,
        year: year,
      },
    });
   
    setData(response.data.zodiac);
  } catch (error) {
    // Handle the error
   
    console.error(error);
    setData(null);
  }

};
const fetchZodiacDataYearly = async ()=>{
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign:name,
        type: "yearly",
        year: year,
      },
    });
   
    setData(response.data.zodiac);
  } catch (error) {
    // Handle the error
   
    console.error(error);
    setData(null);
  }

}

const handleDaily=()=>{
  fetchZodiacDataDaily();
  setDaily(true);
  setMonthly(false);
  setYearly(false);
 
 
}

const handleMonthly=()=>{
  fetchZodiacDataMonthly();
  setDaily(false);
  setMonthly(true);
  setYearly(false);
 
 
}

const handleYearly = ()=>{
  fetchZodiacDataYearly();
  setDaily(false);
  setMonthly(false);
  setYearly(true);
 
 
}

useEffect(()=>{
 
  fetchZodiacDataDaily();
  birthdetails();
  setDaily(true);
  setMonthly(false);
  setYearly(false);
},[]);


return (
  <>{birthdetail? <>
    <Heading textAlign={"center"} fontSize={"25px"} mt={"10px"}>Check Your Horoscopeeee</Heading>
    <Box display={"grid"} justifyContent={"center"} alignItems={"center"}>
      <Box display={"grid"} gridTemplateColumns={"repeat(3,1fr)}"}>
        <Button
          borderRadius={"48px"}
          margin={"10px"}
          onClick={handleDaily}
          leftIcon={daily?<Image src={icon1}></Image>:<BsBrightnessHigh  size={"23px"} />}
          color={daily?"white":"#737373"}
          backgroundColor={daily?"#FF4C00":""}
          borderColor={daily?"none":"#737373"}
          variant={daily?"solid":"outline"}
        >
          Today
        </Button>
        <Button
          leftIcon={<Image src={icon2}></Image>}
          borderRadius={"48px"}
          margin={"10px"}
          onClick={handleMonthly}
          color={monthly?"white":"#737373"}
          backgroundColor={monthly?"#FF4C00":""}
          borderColor={monthly?"none":"#737373"}
          variant={monthly?"solid":"outline"}
        >
          Monthly
        </Button>
        <Button
          leftIcon={<Image src={icon3}></Image>}
          borderRadius={"48px"}
          margin={"10px"}
          onClick={handleYearly}
          color={yearly?"white":"#737373"}
          backgroundColor={yearly?"#FF4C00":""}
          borderColor={yearly?"none":"#737373"}
          variant={yearly?"solid":"outline"}
        >
          2023
        </Button>
      </Box>
    </Box>
   {data===null?
   <>
   <Box textAlign={"center"} color={"red"} fontSize={"25px"}>Today Horoscope Data Not Updated</Box></>:

   <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      
    >
      <Box>
        <Box display={"grid"} justifyContent={"center"} alignItems={"center"}>
          <Box
            backgroundColor={"#FFDED0"}
            borderRadius={"50%"}
            height={"211px"}
            width={"211px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"5px"}
          >
            <Image src={data.images.url}></Image>
          </Box>
          <Heading
            fontSize={"25px"}
            textAlign={"center"}
            margin={{ base: "5px", sm: "10px", md: "10px", lg: "10px" }}
          >
            {name}
          </Heading>
        </Box>
      </Box>
  <Box
      display={"flex"}
      justifyContent={"center"}
      mt={{ base: "10px", sm: "12px", md: "15px", lg: "17px" }}
      ml={{base:"-30px",sm:"0px"}}
      mb={"-15px"}
      
    >
      <Box display={"flex"} flexDirection={{base:"column",sm:"row"}} alignItems={"center"}>
      <Image src={icon5} marginRight={"8px"} mt={"-10px"} height={{base:"50px"}}></Image>
      <Box marginRight={"25px"} >
        <Text color={"#656565"} fontWeight={"500"} bg={""}>
          Lucky Number
        </Text>
        <Text textAlign={"center"} mt={"-10px"} bg={""} color={"#FF4C00"} fontWeight={"650"} >{data.luckyNumber}</Text>
      </Box>
      </Box>
      <Box display={"flex"} flexDirection={{base:"column",sm:"row"}} alignItems={"center"}>
      <Image src={icon6} marginLeft={"25px"} marginRight={"8px"} mt={"-10px"}></Image>
      <Box>
        <Text color={"#656565"} fontWeight={"500"}>
          Lucky Color
        </Text>
        <Text color={"#FF4C00"} mt={"-10px"} fontWeight={"650"} textAlign={"center"} > <Text as={"span"} color={data.luckyColor}>{data.luckyColor}</Text> </Text>
      </Box>
      </Box>
  </Box>
  <Box
      backgroundColor={""}
      width={{ base: "100%", sm: "100%", md: "85%", lg: "80%" }}
      marginTop={"20px"}
      display={"grid"}
      columnGap={{base:"6px",md:"",lg:"",xl:""}}
      rowGap={{base:"6px",md:"",lg:"",xl:""}}
      gridTemplateColumns={{
        base: "repeat(2,1fr)",
        sm: "repeat(2,1fr)", // 480px
        md: "repeat(4,1fr)", // 768px
        lg: "repeat(4,1fr)", // 992px
        xl: "repeat(4,1fr)", // 1280px
      }}
    >
      <Box
        justifySelf={"center"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box color={"#656565"} fontWeight={"500"}>
          Overall
        </Box>
        <Box display={"flex"} backgroundColor={""}>
         {[...Array(data.rating.overall)].map(()=><Text
            as={"span"}
            margin={{ base: "1px", sm: "2px", md: "5px", lg: "5px" }}
          >
            <AiFillStar
              style={{ color: "#FF4C00", height: "24px", width: "24px" }}
            />
          </Text>)}
          {[...Array(5-(data.rating.overall))].map(()=> <Text
            as={"span"}
            margin={{ base: "1px", sm: "2px", md: "5px", lg: "5px" }}
          >
            <AiFillStar
              style={{ color: "#FFA179", height: "24px", width: "24px" }}
            />
          </Text>)}         
        </Box>
      </Box>
      <Box
  
        justifySelf={"center"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box color={"#656565"} fontWeight={"500"}>
          Physical
        </Box>
        <Box display={"flex"} backgroundColor={""}>
          {[...Array(data.rating.physical)].map(()=>  <Text
            as={"span"}
            margin={{ base: "1px", sm: "2px", md: "5px", lg: "5px" }}
          >
            <GiHeartPlus
              style={{ color: "#FF4C00", height: "24px", width: "24px" }}
            />
          </Text>)}
          {[...Array(5-(data.rating.physical))].map(()=> <Text
            as={"span"}
            margin={{ base: "1px", sm: "2px", md: "5px", lg: "5px" }}
          >
            <GiHeartPlus
              style={{ color: "#FFA179", height: "24px", width: "24px" }}
            />
          </Text>)} 
        </Box>
      </Box>
      <Box
        justifySelf={"center"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box color={"#656565"} fontWeight={"500"}>
          Emotional
        </Box>
        <Box display={"flex"} backgroundColor={""}>
          {[...Array(data.rating.emotional)].map(()=> <Text
            as={"span"}
            margin={{ base: "1px", sm: "2px", md: "5px", lg: "5px" }}
          >
            <HiFaceSmile
              style={{
                color: "#FF4C00",
                height: "26px",
                width: "26px",
              }}
            />
          </Text>)}
          {[...Array(5-(data.rating.emotional))].map(()=> <Text
            as={"span"}
            margin={{ base: "1px", sm: "2px", md: "5px", lg: "5px" }}
          >
            <HiFaceSmile
              style={{
                color: "#FFA179",
                height: "24px",
                width: "24px",
              }}
            />
          </Text>)}
         
        </Box>
      </Box>
      <Box
        justifySelf={"center"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box color={"#656565"} fontWeight={"500"}>
          Intellectual
        </Box>
        <Box display={"flex"} backgroundColor={""}>
        {[...Array(data.rating.intellectual)].map(()=> <Text
            as={"span"}
            margin={{ base: "1px", sm: "2px", md: "5px", lg: "5px" }}
          >
            <FaLeaf
              style={{
                color: "#FF4C00",
                height: "24px",
                width: "24px",
              }}
            />
          </Text>)}
          
        {[...Array(5-(data.rating.intellectual))].map(()=> <Text
            as={"span"}
            margin={{ base: "1px", sm: "2px", md: "5px", lg: "5px" }}
          >
            <FaLeaf
              style={{
                color: "#FFA179",
                height: "24px",
                width: "24px",
              }}
            />
          </Text>)}
        </Box>
      </Box>
  </Box>
    <Box backgroundColor={""} width={{base:"85%",sm:"75.7%"}} mt={"18px"} mb={"25px"} textAlign={"center"}>
        <Text as={"span"} textAlign={"center"} color={"#545454"}>
          {data.mainContent}
        </Text>
        {!viewmore&&<Box></Box>}
        {!viewmore&&<Button bg={"#FF4C00"} color={"#FFFFFF"} mt={"5px"} onClick={()=>{setViewmore(true)}}>View More</Button>}
        {viewmore&&<Viewmoretext data={data} />}
      </Box>
   </Box>}
  

  </>:<Zodiacsign2 name={name} />}
  </>
  );
}
