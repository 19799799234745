import { Box, Heading, Text, Link } from "@chakra-ui/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function ContactUsPage() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box maxW="800px" mx="auto" my="10">
      <Helmet>
        <title>Gurucool || Contact Us</title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact GuruCool's friendly support team. We are here to help you with any queries regarding our astrology services. Reach out to us today."
        />
      </Helmet>
      <Heading>Gurucool</Heading>
      <Text mb={0}>
        GuruCool is a platform that links users with proficient doctors,
        facilitating personalized consultation services. Our doctors go through
        a rigorous verification procedure for top-notch service. MediGuide
        charges a nominal operational fee from the consultancy fee. Users can
        effortlessly add funds to their MediGuide wallet via trusted payment
        gateways like Razorpay and use the balance to pay for consultation
        services.
        <br></br>
        {/* <Text fontWeight={"bold"} mb={0}>Ani and Kai Marketing (OPC) Private Limited</Text> */}
      </Text>
      <Heading>Contact Us</Heading>
      <Text mt="4" mb={0}>
        If you have any questions or concerns, please don't hesitate to reach
        out to us:
      </Text>
      <Box mt="6" p="6" borderWidth="1px" borderRadius="md">
        {/* <Text fontSize="lg" mb={0}>Phone:</Text>
        <Link href="tel:+91 63503 73601 " color="blue.500" fontSize="lg" mt="1">
          +91 63503 73601
        </Link> */}
        <Text fontSize="lg" mb={0}>
          Email:
        </Text>
        <Link
          href="mailto:contact@example.com"
          color="blue.500"
          fontSize="lg"
          mt="1"
        >
          hi@gurucool.life
        </Link>
        <Text fontSize="lg" mt="4" mb={0}>
          Address:
        </Text>
        <Link
          href="mailto:contact@example.com"
          color="blue.500"
          fontSize="lg"
          mt="1"
        >
          52, Padmawati Colony-II Kings Road Nirman Nagar, Jaipur, Rajasthan
          (302019)
        </Link>
      </Box>
      <Text mt="6">
        We're here to help and will get back to you as soon as possible!
      </Text>
    </Box>
  );
}

export default ContactUsPage;
