// useAddToHomeScreen.js
import { useState, useEffect } from 'react';
import { firebaseApp, analytics, logEvent } from '../firebaseEvents/firebase';

export const useAddToHomeScreen = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone === true
  );

  useEffect(() => {
    if (isInstalled) return;

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [isInstalled]);

  const addToHomeScreen = () => {
    logEvent(analytics, "getApp");
    if (!deferredPrompt) return;

    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
       
      } else {
       
      }
      setDeferredPrompt(null);
    });
  };

  return [deferredPrompt, addToHomeScreen, isInstalled];
};
