import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { G_GET_USER_PROFILE } from "../apiLinks";
import { loginSystemAction } from "./LoginSystemSlice";
import avatar from "../assets/Avatar 1.svg";
// Async thunk action
export const fetchLoggedInUserDetails = createAsyncThunk(
  "loggedInUserDetails/fetchDetails",
  async (loginToken, { dispatch }) => {
    if (loginToken) {
      dispatch(loginSystemAction.setUserLoggedIn(true));
      const response = await axios.get(G_GET_USER_PROFILE, {
        headers: { Authorization: `Bearer ${loginToken}` },
      });
      dispatch(logInUserDetailsAction.setData(response.data));
    }
  }
);

const loggedInUserDetailsSlice = createSlice({
  name: "loggedInUserDetails",
  initialState: {
    data: [],
    status: "idle",
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLoggedInUserDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchLoggedInUserDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(fetchLoggedInUserDetails.rejected, (state, action) => {
      state.status = "failed";
      localStorage.removeItem("loginToken");
    });
  },
});

export const logInUserDetailsAction = loggedInUserDetailsSlice.actions;
export default loggedInUserDetailsSlice.reducer;
