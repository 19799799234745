
import newusers from "../../../assets/newhomepage/newusers.svg";
import Astro_3 from "../../../assets/Astro_3.webp";
import userTestimonial1 from "../../../assets/userTestimonial1.jpeg";
import userTestimonial6 from "../../../assets/userTestimonial6.jpeg";
import userTestimonial3 from "../../../assets/userTestimonial3.jpeg";
import "./Testimonal.css";
import { Box, Flex, Text, Image, Container, Heading } from "@chakra-ui/react";
import img1 from "../../../assets/newhomepage/img1.jpg";
import img2 from "../../../assets/newhomepage/img2.jpg";
import img3 from "../../../assets/newhomepage/img3.jpg";
import img4 from "../../../assets/newhomepage/img4.jpg";
import img5 from "../../../assets/newhomepage/img5.jpg";
import testimonal from "../../../assets/newhomepage/testimonal.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options = {
  // loop: true,
  // controlsClass: 'owl-controls',
  // center: true,
  nav: true,
  dots: false,
  margin: 8,
  // dotsEach: false,
  // slideBy: 3,
  responsive: {
    0: {
      items: 3,
      // margin: 8,
      // nav: false,
      // loop: true,
    },
    600: {
      items: 2,
      loop: true,
    },
    1000: {
      items: 2,
    },
  },
};

const images = [
  {
    id: 1,
    name: "Krishna",
    date: "20/02/2023",
    img: newusers,
    url: "https://www.youtube.com/embed/-runrBBiy7k",
    about:
      "Gurucool Astro app is a knowledge powerhouse. It not only provides accurate astrological insights but also empowers users with educational content to deepen their understanding of astrology  . ",
  },
  {
    id: 2,
    name: "Rajveer",
    date: "27/02/2023",
    img: userTestimonial1,
    url: "https://www.youtube.com/embed/C2bSwYi74Po",
    about:
      "Gurucool Astro app is a gem! It seamlessly integrates astrology and education, providing accurate horoscopes and valuable astrological knowledge in one convenient platform ++ Gurucool Astro app is a knowledge powerhouse. It not only provides accurate astrological insights but also empowers users with educational content to deepen their understanding of astrology.",
  },
  {
    id: 3,
    name: "Rashmi",
    date: "08/03/2023",
    img: Astro_3,
    url: "https://www.youtube.com/embed/LdiVZm85tl0",
    about:
      "Gurucool Astro app is my go-to for astrology and learning. It offers in-depth horoscope analysis and engaging educational resources, making it a must-have for astrology enthusiasts and learners.",
  },
  {
    id: 4,
    name: "Shyam",
    date: "17/03/2023",
    img: userTestimonial6,
    url: "https://www.youtube.com/embed/ST5f39rSQMY",
    about:
      "Gurucool Astro app is a knowledge powerhouse. It not only provides accurate astrological insights but also empowers users with educational content to deepen their understanding of astrology.",
  },
  {
    id: 5,
    name: "Shubh",
    date: "22/03/2023",
    img: userTestimonial3,
    url: "https://www.youtube.com/embed/h3ZAY9b9URo",
    about:
      "Gurucool Astro app is a game-changer. It combines the magic of astrology with comprehensive educational resources, helping users unlock their potential and expand their astrological wisdom.",
  },
];

const containerStyle = {
  mt: { md: "8px", base: "0" },

  w: { base: "auto", md: "86%" },
  position: "relative",
  left: { base: "0", md: "7rem" },
  overflowX: "auto",
  css: {
    "&::-webkit-scrollbar": {
      width: "0",
    },
    scrollbarWidth: "none",
  },
};

function Testimonal() {
  return (
    <>
      <Container
        display={{ base: "block", md: "none" }}
        bg={"#fff"}
        my={2}
        p={0}
        maxW={"6xl"}
        h={{ md: "21.5rem", base: "auto" }}
        overflowX="auto"
      >
        <Box
          display={"flex"}
          w={{ base: "250px", md: "100%" }}
          h={"45px"}
          gap={"0.35rem"}
          alignItems={"center"}
          pl={"1rem"}
          mt={"0.4rem"}
          // border={"2px solid pink"}
          justifyContent={{ md: "center", base: "flex-start" }}
        >
          <Image src={testimonal} alt="imgs" w={"25px"} h={"25px"} />
          <Text mt={"1.25rem"} fontSize={"1.15rem"} fontWeight={"700"}>
            User Testimonal
          </Text>
        </Box>

        <Box {...containerStyle} h={{ base: "260px" }}>
          <Flex>
            {images.map((image) => (
              <Box
                key={image.id}
                // minWidth="550px"
                p={4}
              // borderLeft="1px solid"
              // borderRight="1px solid"
              // borderColor="gray.200"
              >
                <Flex h={"200px"} gap={"0.75rem"}>
                  <Box
                    h={"220px"}
                    display={"flex"}
                    flexDirection={"column"}
                    border={"2px solid lightgray"}
                    w={"300px"}
                    borderRadius={"0.3rem"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      m={"0.95rem 0 0 0.45rem"}
                      gap={"0.5rem"}
                    >
                      <Box w={"40px"} h={"40px"}>
                        <Image
                          src={image.img}
                          alt="imgs"
                          w={"45px"}
                          h={"40px"}
                          borderRadius={"50%"}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        h={"40px"}
                        lineHeight={"0.55rem"}
                        pt={"0.26rem"}
                      >
                        <Text fontWeight={"700"} fontSize={"18px"}>
                          {image.name}
                        </Text>
                        <Text fontWeight={"500"} fontSize={"13px"}>
                          {image.date}
                        </Text>
                      </Box>
                    </Box>
                    <Box w={"290px"} h={"140px"} p={"1.25rem 0 0 .2rem"}>
                      <Text
                        as="p"
                        w={"100%"}
                        h={"100%"}
                        m={"0.45rem 0 0 0.45rem"}
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: "vertical",
                          overflow: "scroll",
                          textOverflow: "ellipsis",
                          color: "gray.400",
                          fontSize: "0.92rem",
                        }}
                      >
                        {image.about}
                      </Text>
                    </Box>
                  </Box>
                  <Box w={"170px"} h={"220px"} borderRadius="0.3rem">
                    {/* Right Video */}
                    <iframe
                      width="100%"
                      height="220px"
                      src={image.url}
                      title="Video"
                      allowFullScreen
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "0.4rem",
                      }}
                    ></iframe>
                  </Box>
                </Flex>
              </Box>
            ))}
          </Flex>
        </Box>
      </Container>

      {/* for web */}

      <Container
        id="testimonials"
        maxW={"full"}
        px={0}
        bg={"#fff"}
        display={{ md: "block", base: "none" }}
      >
        <Container maxW={"6xl"} pt={{ md: "1px" }}>
          <Box display={"flex"} justifyContent={"center"} mt={{ md: "20px" }}>
            <Image w={"40px"} src={testimonal} alt="testimonials-icns" />

            <Heading
              fontSize={{ base: "18px", md: "34px" }}
              fontWeight={"700"}
              color={"#414141"}
              textAlign={"center"}
              ml={2}
            >
              User Testimonial
            </Heading>
          </Box>
          <OwlCarousel {...options}>
            {images.map((image) => (
              <Box
                key={image.id}
                // p={4}
                // borderLeft="1px solid"
                // borderRight="1px solid"
                // borderColor="gray.200"
                // borderRadius="md"
                // w={{ md: "475px" }}
                h={{ md: "250px" }}
                // border={"2px solid green"}
                mt={{ md: "25px" }}
              >
                <Flex h="240px" gap="0.5rem">
                  <Box
                    display="flex"
                    flexDirection="column"
                    border="2px solid #E1E1E1"
                    w="355px"
                    borderRadius="4px"
                    bgColor={"FBFBFB"}
                  >
                    <Box display="flex" flexDirection="row" gap="2" p="2">
                      <Image
                        src={image.img}
                        alt="profile-pic"
                        w="3.75rem"
                        h="3.75rem"
                        borderRadius="50%"
                      />
                      <Flex flexDirection="column">
                        <Text
                          fontWeight="bold"
                          position={"relative"}
                          top={"4px"}
                        >
                          {image.name}
                        </Text>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          position={"relative"}
                          bottom={"4px"}
                        >
                          {image.date}
                        </Text>
                      </Flex>
                    </Box>
                    <Box p="2" overflow="hidden" h="150px">
                      <Text
                        as="p"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#808080",
                        }}
                      >
                        {image.about}
                      </Text>
                    </Box>
                  </Box>

                  <Box w="188px" height={"220px"} borderRadius={"0.4rem"}>
                    <iframe
                      width="100%"
                      height="240px"
                      src={image.url}
                      title="Video"
                      allowFullScreen
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "0.4rem",
                      }}
                    ></iframe>
                  </Box>
                </Flex>
              </Box>
            ))}
          </OwlCarousel>
        </Container>
      </Container>
    </>
  );
}

export default Testimonal;
