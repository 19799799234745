import {
  Box,
  Heading,
  Image,
  Text,
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  Stack,
  CardFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import img1 from "../assets/imageplaceholder.svg";
import img2 from "../assets/Image placeholder (1).svg";
import img3 from "../assets/Image placeholder (2).svg";
import img4 from "../assets/Image placeholder (3).svg";
import img5 from "../assets/Image placeholder (5).svg";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const BlogMain = () => {
  const [blog, setBlog] = useState();
  const [mainblog, setMainblog] = useState();
  const [err, setErr] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://data.gurucool.life/api/v1/blogs/getAllBlogs"
        );
        console.log(response);
        const updateObj = response?.data?.blogs?.docs.slice(0, 7);
        console.log(updateObj);
        const mainB = response?.data?.blogs?.docs.filter(
          (obj) => obj.mainBlog === true
        );
        console.log(mainB[0]);
        setMainblog(mainB[0]);
        setBlog(updateObj);
      } catch (error) {
        setErr(true);
        console.error("Error fetching data:", error.message);
      }
    }
    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleClick = (id) => {
    // console.log(id);
    // localStorage.setItem("blogId", id);
    navigate(`/blog/${id}`);
  };

  console.log(mainblog);

  const [readmore, setReadmore] = useState(false);
  return (
    <>
      {err ? (
        <Box textAlign={"center"} verticalAlign={"center"}>
          Error
        </Box>
      ) : (
        <Container maxW="8xl">
                <Box m={"0px"} mt={"20px"} p={"0px"} width={"95%"}>
                  <Heading fontWeight={"600"} fontSize={"36px"}>
                    {mainblog?.title}
                  </Heading>
                  <Image
                    width={"100%"}
                    height={"350px"}
                    display={{ base: "none", sm: "block" }}
                    src={mainblog?.titleImage?.url}
                    mb={"17px"}
                  />
                  {/* <Image
              width={"100%"}
              display={{ base: "block", sm: "none" }}
              src={img5}
              mb={"17px"}
            /> */}
                  <Button
                    borderColor={"#EF5406"}
                    color={"#EF5406"}
                    mr={"3%"}
                    variant={"outline"}
                    h={"32px"}
                    w={"95px"}
                    borderRadius={"20px"}
                    display={{ base: "none", sm: "none", md: "inline-flex" }}
                    fontWeight={"350"}
                  >
                    Tarot Card
                  </Button>
                  <Button
                    borderColor={"#EF5406"}
                    color={"#EF5406"}
                    mr={"3%"}
                    variant={"outline"}
                    h={"32px"}
                    w={"95px"}
                    borderRadius={"20px"}
                    fontWeight={"350"}
                  >
                    Tarot Card
                  </Button>
                  <Button
                    borderColor={"#EF5406"}
                    color={"#EF5406"}
                    mr={"3%"}
                    variant={"outline"}
                    h={"32px"}
                    w={"95px"}
                    borderRadius={"20px"}
                    display={{ base: "none", sm: "none", md: "inline-flex" }}
                  >
                    Tarot Card
                  </Button>
                  <Text
                    mb={"7px"}
                    fontSize={"20px"}
                    fontWeight={"600"}
                    color={"blackAlpha.600"}
                    mt={"15px"}
                  >
                    ASTRO NAME | TAG NAME
                  </Text>
                  <Text
                    fontSize={"16px"}
                    fontWeight={"500"}
                    color={"blackAlpha.600"}
                  >
                    March 13,2022
                  </Text>
                </Box>
                <Box>
                  <Heading
                    fontSize={"24px"}
                    fontWeight={"500"}
                    pl={"3px"}
                    pr={"3px"}
                  >
                    Anima Introduces: Hotspots Hints
                  </Heading>
                  <Text
                    pl={"3px"}
                    pr={"3px"}
                    m={"auto"}
                    fontSize={"18px"}
                    lineHeight={"28px"}
                    fontWeight={"400"}
                    color={"#6B6B6B"}
                    letterSpacing={""}
                  >
                    Hotspot Hints Help Guide Users Navigate Through a Prototype,
                    Anima allows designers to create high-fidelity prototypes
                    inside Sketch, Adobe XD, and Figma, and export HTML & CSS in
                    a single click.
                    <Text display={!readmore && "none"} as={"span"}>
                      {" "}
                      Hotspot Hints Help Guide Users Navigate Through a
                      Prototype, Anima allows designers to create high-fidelity
                      prototypes inside Sketch, Adobe XD, and Figma, and export
                      HTML & CSS in a single click. Hotspot Hints Help Guide
                      Users Navigate Through a Prototype, Anima allows designers
                      to create high-fidelity prototypes inside Sketch, Adobe
                      XD, and Figma, and export HTML & CSS in a single click.
                      Hotspot Hints Help Guide Users Navigate Through a
                      Prototype, Anima allows designers to create high-fidelity
                      prototypes inside Sketch, Adobe XD, and Figma, and export
                      HTML & CSS in a single click. Hotspot Hints Help Guide
                      Users Navigate Through a Prototype, Anima allows designers
                      to create high-fidelity prototypes inside Sketch, Adobe
                      XD, and Figma, and export HTML & CSS in a single click.
                      Hotspot Hints Help Guide Users Navigate Through a
                      Prototype, Anima allows designers to create high-fidelity
                      prototypes inside Sketch, Adobe XD, and Figma, and export
                      HTML & CSS in a single click. Hotspot Hints Help Guide
                      Users Navigate Through a Prototype, Anima allows designers
                      to create high-fidelity prototypes inside Sketch, Adobe
                      XD, and Figma, and export HTML & CSS in a single click.
                    </Text>
                  </Text>
                  <Text
                    textDecoration={"underline"}
                    color={"#F3520C"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    // display={readmore?"none":"block"}
                    onClick={() => {
                      setReadmore(!readmore);
                    }}
                    mb={"30px"}
                    textAlign={"center"}
                  >
                    {readmore ? "Readless" : "Readmore"}
                  </Text>
                  <hr
                    style={{
                      color: "#FF4C00",
                      width: "80%",
                      border: "",
                      margin: "auto",
                    }}
                  />
                </Box>

          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={{ base: "center", sm: "center" }}
              mt={"15px"}
              mb={"40px"}
            >
              <Button
                borderColor={"#EF5406"}
                color={"white"}
                mr={"4%"}
                variant={"solid"}
                h={"32px"}
                w={"95px"}
                borderRadius={"20px"}
                bg={"#FF4C00"}
                fontWeight={"350"}
              >
                All
              </Button>
              <Button
                borderColor={"#EF5406"}
                color={"#EF5406"}
                mr={"4%"}
                variant={"outline"}
                h={"32px"}
                w={"95px"}
                borderRadius={"20px"}
                display={{ base: "none", sm: "none", md: "inline-flex" }}
                fontWeight={"350"}
              >
                Tarot Card
              </Button>
              <Button
                borderColor={"#EF5406"}
                color={"#EF5406"}
                mr={"4%"}
                variant={"outline"}
                h={"32px"}
                w={"95px"}
                borderRadius={"20px"}
                display={{ base: "none", sm: "none", md: "inline-flex" }}
                fontWeight={"350"}
              >
                Vedic
              </Button>
              <Button
                borderColor={"#EF5406"}
                color={"#EF5406"}
                mr={"4%"}
                variant={"outline"}
                h={"32px"}
                w={"95px"}
                borderRadius={"20px"}
                display={{ base: "none", sm: "none", md: "inline-flex" }}
                fontWeight={"350"}
              >
                Kundli
              </Button>
              <Button
                borderColor={"#EF5406"}
                color={"#EF5406"}
                mr={"4%"}
                variant={"outline"}
                h={"32px"}
                w={"95px"}
                borderRadius={"20px"}
                display={{ base: "none", sm: "none", md: "inline-flex" }}
                fontWeight={"350"}
              >
                Tarot Card
              </Button>
              <Button
                borderColor={"#EF5406"}
                color={"#EF5406"}
                mr={"4%"}
                variant={"outline"}
                h={"32px"}
                w={"95px"}
                borderRadius={"20px"}
                fontWeight={"350"}
              >
                Tarot Card
              </Button>
              <Button
                borderColor={"#EF5406"}
                color={"#EF5406"}
                mr={"4%"}
                variant={"outline"}
                h={"32px"}
                w={"95px"}
                borderRadius={"20px"}
                fontWeight={"350"}
              >
                Tarot Card
              </Button>
            </Box>
            <Box
              mt={"20px"}
              mb={"20px"}
              display={"flex"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              rowGap={"2.65rem"}
            >
              {blog?.map((obj) => (
                // <Link to={`/blog/${obj?._id}`}>
                <Card
                  w={{ base: "97%", sm: "97%", md: "48.5%" }}
                  boxShadow={"1.5px 3.5px #eee"}
                  onClick={() => {
                    handleClick(obj?._id);
                  }}
                  cursor={"pointer"}
                >
                  <CardBody>
                    <Image
                      src={obj?.titleImage?.url}
                      alt="Green double couch with wooden legs"
                      borderRadius="lg"
                      height={"300px"}
                      width={"587px"}
                    />
                    <Stack mt="6" spacing="2">
                      <Box>
                        {obj?.tags?.map((tag) => (
                          <Button
                            borderColor={"#EF5406"}
                            color={"#EF5406"}
                            mr={"4%"}
                            variant={"outline"}
                            // h={"32px"}
                            // w={"95px"}
                            borderRadius={"20px"}
                            fontWeight={"350"}
                          >
                            {tag}
                          </Button>
                        ))}
                      </Box>
                      <Text
                        fontSize={{ base: "15px", sm: "18px" }}
                        fontWeight={"400"}
                        opacity={"1"}
                        color={"#6B6B6B"}
                      >
                        {obj?.author?.firstName + " " + obj?.author?.lastName} |{" "}
                        {obj?.author?.role}
                      </Text>
                      <Text
                        fontSize={{ base: "15px", sm: "18px" }}
                        fontWeight={"400"}
                        opacity={"1"}
                        color={"#6B6B6B"}
                      >
                        {obj?.createdAt.slice(0, 10)}
                      </Text>
                      <Text
                        fontSize={"24px"}
                        fontWeight={"500"}
                        lineHeight={"24px"}
                      >
                        {obj?.content[0]?.heading}
                      </Text>
                      <Text
                        fontSize={"16px"}
                        fontWeight={"400"}
                        opacity={"1"}
                        color={"#6B6B6B"}
                      >
                        {obj?.content[0]?.body.slice(0, 150)}...
                      </Text>
                    </Stack>
                  </CardBody>
                </Card>
                // </Link>
              ))}

              <Card
                w={{ base: "97%", sm: "97%", md: "48.5%" }}
                boxShadow={"1.5px 3.5px #eee"}
              >
                <CardBody>
                  <Image
                    src={img3}
                    alt="Green double couch with wooden legs"
                    borderRadius="lg"
                  />
                  <Stack mt="6" spacing="2">
                    <Box>
                      <Button
                        borderColor={"#EF5406"}
                        color={"#EF5406"}
                        mr={"4%"}
                        variant={"outline"}
                        h={"32px"}
                        w={"95px"}
                        borderRadius={"20px"}
                        fontWeight={"350"}
                      >
                        Tarot Card
                      </Button>
                    </Box>
                    <Text
                      fontSize={{ base: "15px", sm: "18px" }}
                      fontWeight={"400"}
                      opacity={"1"}
                      color={"#6B6B6B"}
                    >
                      Stephen Leonardi | Software Engineer
                    </Text>
                    <Text
                      fontSize={{ base: "15px", sm: "18px" }}
                      fontWeight={"400"}
                      opacity={"1"}
                      color={"#6B6B6B"}
                    >
                      Sepetember 8,2022
                    </Text>
                    <Text
                      fontSize={"24px"}
                      fontWeight={"500"}
                      lineHeight={"24px"}
                    >
                      Learn how to organize Your Anima Project with Sections
                    </Text>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"400"}
                      opacity={"1"}
                      color={"#6B6B6B"}
                    >
                      Create dividers and drag screens to arrange your Anima
                      projects.
                    </Text>
                  </Stack>
                </CardBody>
              </Card>
              {/* <Card w={{base:"97%",sm:"97%",md:"48.5%"}} boxShadow={"1.5px 3.5px #eee"}>
              <CardBody>
                <Image
                  src={img4}
                  alt="Green double couch with wooden legs"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="2">
                  <Box>
                    <Button
                      borderColor={"#EF5406"}
                      color={"#EF5406"}
                      mr={"4%"}
                      variant={"outline"}
                      h={"32px"}
                      w={"95px"}
                      borderRadius={"20px"}
                      fontWeight={"350"}
                    >
                      Tarot Card
                    </Button>
                  </Box>
                  <Text fontSize={{base:"15px",sm:"18px"}} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                   Stephen Leonardi | Software Engineer
                  </Text>
                  <Text fontSize={{base:"15px",sm:"18px"}} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                    Sepetember 8,2022
                  </Text>
                  <Text fontSize={"24px"} fontWeight={"500"} lineHeight={"24px"}>
                     Learn how to organize Your Anima Project with Sections
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                    Create dividers and drag screens to arrange your Anima projects.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
            <Card w={{base:"97%",sm:"97%",md:"48.5%"}} boxShadow={"1.5px 3.5px #eee"}>
              <CardBody>
                <Image
                  src={img2}
                  alt="Green double couch with wooden legs"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="2">
                  <Box>
                    <Button
                      borderColor={"#EF5406"}
                      color={"#EF5406"}
                      mr={"4%"}
                      variant={"outline"}
                      h={"32px"}
                      w={"95px"}
                      borderRadius={"20px"}
                      fontWeight={"350"}
                    >
                      Tarot Card
                    </Button>
                  </Box>
                  <Text fontSize={{base:"15px",sm:"18px"}} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                   Stephen Leonardi | Software Engineer
                  </Text>
                  <Text fontSize={{base:"15px",sm:"18px"}} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                    Sepetember 8,2022
                  </Text>
                  <Text fontSize={"24px"} fontWeight={"500"} lineHeight={"24px"}>
                     Learn how to organize Your Anima Project with Sections
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                    Create dividers and drag screens to arrange your Anima projects.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
            <Card w={{base:"97%",sm:"97%",md:"48.5%"}} boxShadow={"1.5px 3.5px #eee"}>
              <CardBody>
                <Image
                  src={img2}
                  alt="Green double couch with wooden legs"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="2">
                  <Box>
                    <Button
                      borderColor={"#EF5406"}
                      color={"#EF5406"}
                      mr={"4%"}
                      variant={"outline"}
                      h={"32px"}
                      w={"95px"}
                      borderRadius={"20px"}
                      fontWeight={"350"}
                    >
                      Tarot Card
                    </Button>
                  </Box>
                  <Text fontSize={{base:"15px",sm:"18px"}} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                   Stephen Leonardi | Software Engineer
                  </Text>
                  <Text fontSize={{base:"15px",sm:"18px"}} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                    Sepetember 8,2022
                  </Text>
                  <Text fontSize={"24px"} fontWeight={"500"} lineHeight={"24px"}>
                     Learn how to organize Your Anima Project with Sections
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                    Create dividers and drag screens to arrange your Anima projects.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
            <Card w={{base:"97%",sm:"97%",md:"48.5%"}} boxShadow={"1.5px 3.5px #eee"}>
              <CardBody>
                <Image
                  src={img2}
                  alt="Green double couch with wooden legs"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="2">
                  <Box>
                    <Button
                      borderColor={"#EF5406"}
                      color={"#EF5406"}
                      mr={"4%"}
                      variant={"outline"}
                      h={"32px"}
                      w={"95px"}
                      borderRadius={"20px"}
                      fontWeight={"350"}
                    >
                      Tarot Card
                    </Button>
                  </Box>
                  <Text fontSize={{base:"15px",sm:"18px"}} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                   Stephen Leonardi | Software Engineer
                  </Text>
                  <Text fontSize={{base:"15px",sm:"18px"}} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                    Sepetember 8,2022
                  </Text>
                  <Text fontSize={"24px"} fontWeight={"500"} lineHeight={"24px"}>
                     Learn how to organize Your Anima Project with Sections
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"400"} opacity={"1"} color={"#6B6B6B"}>
                    Create dividers and drag screens to arrange your Anima projects.
                  </Text>
                </Stack>
              </CardBody>
            </Card> */}
            </Box>
          </Box>
          <Box
            h={"280px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button h={"54px"} w={"156px"} bg={"#FF4C00"} color={"white"}>
              View More
            </Button>
          </Box>
        </Container>
      )}
    </>
  );
};

export default BlogMain;
