import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  Textarea,
} from "@chakra-ui/react";
import whygurucool from "../../Assests/whygurucool.png";
import customersupport from "../../Assests/customersupport.png";
import PrivateNetwork from "../../Assests/PrivateNetwork.png";
import Wallet from "../../Assests/Wallet.png";
import agreement from "../../Assests/agreement.png";
{
  /* <div styleName="" />; */
}
// import React from 'react'
// import backgroundImage from '../../Assests/BackgroundImage.png';
const Gurucool = () => {
  return (
    <>
      <Container maxW={"full"} px={0} bg={"#F9F9F9"} mt={{ md: "60px" }}>
        <Container maxW={"6xl"}>
          <Flex
            flexDirection={{ md: "row", base: "column" }}
            justifyContent={{ md: "space-around", base: "center" }}
            alignItems={"center"}
            py={{ md: "60px", base: "24px" }}
          >
            <Box boxSize={{ md: "300px", base: "149px" }}>
              <Image src={whygurucool} alt="Why-guruji" />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={{ base: "center", md: "flex-start" }}
              mt={{ base: "24px", md: "unset" }}
            >
              <Text
                mb={0}
                fontSize={{ md: "38px", base: "22px" }}
                fontWeight={"700"}
              >
                Why Gurucool?
              </Text>
              <Text
                mt={{ md: "26px", base: "12px" }}
                mb={0}
                fontSize={{ md: "16px", base: "12px" }}
                fontWeight={"400"}
                color={"#232323"}
                w={{ md: "615px", base: "326px" }}
                textAlign={{ md: "left", base: "center" }}
              >
                a distinguished healthcare platform where trust, privacy, and
                expertise unite to offer you unparalleled medical guidance. Our
                team consists of dedicated and experienced doctors who have
                committed years to their field.
              </Text>
              <Text
                mt={{ md: "26px", base: "12px" }}
                mb={0}
                fontSize={{ md: "16px", base: "12px" }}
                fontWeight={"400"}
                color={"#232323"}
                w={{ md: "615px", base: "326px" }}
                textAlign={{ md: "left", base: "center" }}
              >
                Rest assured, your confidentiality is paramount as we assist you
                in navigating your health journey. Explore insightful guidance
                and personalized advice with full assurance in our services at
                Gurucool.
              </Text>

              <Box mt={{ md: "50px", base: "24px" }}>
                <HStack spacing={{ md: "37px", base: "22px" }}>
                  <HStack spacing={{ md: "18px", base: "10px" }}>
                    <Box boxSize={{ md: "32px", base: "18.5px" }}>
                      <Image src={customersupport} alt="constumer-support" />
                    </Box>
                    <Text
                      fontSize={{ md: "17px", base: "10px" }}
                      fontWeight={"500"}
                    >
                      24 X 7 Customer Support
                    </Text>
                  </HStack>
                  <HStack spacing={{ md: "18px", base: "10px" }}>
                    <Box boxSize={{ md: "32px", base: "18.5px" }}>
                      <Image src={PrivateNetwork} alt="PrivateNetworkt" />
                    </Box>
                    <Text
                      fontSize={{ md: "17px", base: "10px" }}
                      fontWeight={"500"}
                    >
                      Private & Confidential
                    </Text>
                  </HStack>
                </HStack>

                <HStack
                  spacing={{ md: "26px", base: "17px" }}
                  mt={{ md: "26px", base: "11px" }}
                >
                  <HStack spacing={{ md: "18px", base: "10px" }}>
                    <Box boxSize={{ md: "32px", base: "18.5px" }}>
                      <Image src={Wallet} alt="constumer-support" />
                    </Box>
                    <Text
                      fontSize={{ md: "17px", base: "10px" }}
                      fontWeight={"500"}
                    >
                      100% Refund if Unsatisfied
                    </Text>
                  </HStack>
                  <HStack spacing={{ md: "18px", base: "10px" }}>
                    <Box boxSize={{ md: "32px", base: "18.5px" }}>
                      <Image src={agreement} alt="PrivateNetworkt" />
                    </Box>
                    <Text
                      fontSize={{ md: "17px", base: "10px" }}
                      fontWeight={"500"}
                    >
                      Verified Doctors
                    </Text>
                  </HStack>
                </HStack>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Container>
    </>
  );
};

export default Gurucool;
