import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Horoscopecard(props) {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const loggedIn = useSelector((state) => state.logIn.userLoggedIn);
  
  
  return (
    <>
      <Link
        to={`/horoscope/${props.name.toLowerCase()}`}
      >
        <Card
          onClick={handleClick}
          height={{ base: "140px", sm: "210px", md: "220px", lg: "220px" }}
          border={"1px solid #FF8E5E"}
          style={{
            backgroundColor: "#FFE9DF",
            alignSelf: "center",
            justifySelf: "center",
            width: "100%",
          }}
        >
          <CardHeader>
            <Image
              backgroundColor={""}
              width={{
                base: "75px",
                sm: "131.81px",
                md: "131.81px",
                lg: "131.81px",
                xl: "131.81px",
              }}
              height={{
                base: "60px",
                sm: "105px",
                md: "105px",
                lg: "105px",
                xl: "105px",
              }}
              style={{ margin: "auto" }}
              src={props.icon}
              alt="Dan Abramov"
            />
          </CardHeader>
          <CardBody
            mt={{ base: "-18px", sm: "-10px", md: "-8px", lg: "-8px" }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Heading
              as="h6"
              height={{
                base: "13px",
                sm: "20px",
                md: "27px",
                lg: "27px",
                xl: "27px",
              }}
              fontSize={{
                base: "12px",
                sm: "16px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              }}
            >
              {props.name}
            </Heading>
            <Text
              color={"#626262"}
              fontSize={{
                base: "9px",
                sm: "16px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              }}
            >
              {props.date}
            </Text>
          </CardBody>
        </Card>
      </Link>
    </>
  );
}
