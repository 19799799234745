import { useEffect } from "react";
import {
  selectPeers,
  // useHMSActions,
  useHMSStore,
  useVideo,
} from "@100mslive/react-sdk";
import {
  // Avatar,
  Box,
  Flex,
  // Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import LiveHeader from "../LiveHeader";
import { useSelector } from "react-redux";
import LiveFooter from "../LiveFooter";
import ChatNdParticipants from "../LiveChat/ChatNdParticipants";
import { useState } from "react";
import liveFallback from "../../../assets/live-stream/live_fallback.png";
import liveEnded from "../../../assets/live-stream/ended_fallback.png";
function Peer({ peer }) {
  // const hmsActions = useHMSActions();
  const astroDetails = useSelector((state) => state.astrologerProfile.data);
  const peers = useHMSStore(selectPeers);
  const localUser = peers.filter((peer) => peer.isLocal);
  const localUserRole = localUser[0]?.roleName;
  // const localUserRole = "astrologer";
  const { videoRef } = useVideo({
    trackId: peer.videoTrack,
  });

  const [isLargerThanMedium] = useMediaQuery(
    "(min-width: 600px) and (max-width: 992px)"
  );

  const [isLargerThanMobile] = useMediaQuery("(min-width: 992px)");

  // useEffect(() => {
  //   const unblockAudio = async () => {
  //     try {
  //       await hmsActions.unblockAudio();
  //     } catch (error) {
  //       console.error("Error unblocking audio:", error);
  //     }
  //   };

  //   unblockAudio();
  // }, [hmsActions]);

  //
  const [loading, setLoading] = useState(false);
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimedOut(true);
    }, 5000); // set the timeout to 5 seconds

    return () => clearTimeout(timeout);
  }, []);

  const handleLoadedData = () => {
    setLoading(false);
  };

  return (
    <>
      {peer.roleName === "astrologer" && (
        <Flex h={"100svh"}>
          <Flex
            justify={"center"}
            p={{ md: "30px" }}
            borderRadius={"6px"}
            bg={{ md: "#FFEFDF", base: "unset" }}
            w={"fit-content"}
            margin={"auto"}
          >
            <Box
              className="peer-container"
              width={{ base: "100%", md: "100%", lg: "auto" }}
              display={{ md: "flex" }}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundImage={liveFallback}
            >
              <video
                style={{
                  width: "100%",
                  height: isLargerThanMedium
                    ? "33vh"
                    : isLargerThanMobile
                    ? "70vh"
                    : "100svh",
                  objectFit: "cover",
                }}
                ref={videoRef}
                className={`peer-video ${peer.isLocal ? "local" : ""}`}
                autoPlay
                muted
                playsInline
                onLoadedData={handleLoadedData}
              />
            </Box>
            <Box
              display={{ md: "flex", base: "none" }}
              flexDirection={"column"}
              justifyContent={"space-between"}
              width={"500px"}
              height={{ lg: "70vh", md: "33vh" }}
              bg={"white"}
            >
              <LiveHeader
                peers={peers}
                localUserRole={localUserRole}
                astroDetail={astroDetails}
              />
              <ChatNdParticipants
                astroDetail={astroDetails}
                localUserRole={localUserRole}
              />
              <LiveFooter
                localUserRole={localUserRole}
                astroDetail={astroDetails}
                localUser={localUser}
              />
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default Peer;
