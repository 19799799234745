import {
  selectPeers,
  useAVToggle,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
// import { selectPeers, useHMSStore } from "@100mslive/react-sdk";
import {
  Box,
  Button,
  Container,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  InputRightElement,
  Modal,
  useMediaQuery,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/react";
import { IoCall, IoVideocamOff } from "react-icons/io5";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FiMic, FiMicOff } from "react-icons/fi";
// import { RiShareForwardLine } from "react-icons/ri";
import {
  IoSend,
  IoVideocamOutline,
  // IoVideocamOffOutline,
  IoCameraReverseOutline,
  // IoMicOffOutline,
  IoMicOutline,
} from "react-icons/io5";

import gifts from "../../assets/live-stream/gifts.png";
import { useEffect, useState } from "react";
import Login from "../Login&Signup/Login";
import LiveCallFlow from "./LiveCallFlow/LiveCallFlow";
import Gifts from "./GIfts/Gifts";
import { useDispatch } from "react-redux";
import { fetchAstrologerProfileData } from "../../store/astrologerProfileSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseEvents/firebase";

function LiveFooter(props) {
  const localUserRole = props.localUserRole;
  //
  const sessionId = props.sessionId;
  const astroDetails = props.astroDetail;
  const localUser = props.localUser;
  const isInternational = localStorage.getItem("isInternational") === "true";
  const peers = useHMSStore(selectPeers);

  //
  const hasPaidViewer = peers.some((item) =>
    item.roleName.includes("paid-viewer")
  );

  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  useEffect(() => {
    if (localUserRole === "paid-viewer" && !isLocalAudioEnabled) {
      toggleAudio;
    }
  }, [peers]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();

  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();
  const [inputValue, setInputValue] = useState("");
  const hmsActions = useHMSActions();

  const loginToken = localStorage.getItem("loginToken");

  /**
   * The function checks if a login token exists and either sends a broadcast message or opens a modal
   * accordingly.
   */
  const handleSubmit = () => {
    if (loginToken) {
      hmsActions.sendBroadcastMessage(inputValue);
      setInputValue("");
    } else {
      onOpen();
    }
  };
  const callClickHandler = () => {
    logEvent(analytics, "live_Public_Call_Click", {
      item_name:
        astroDetails?.user?.firstName + " " + astroDetails?.user?.lastName,
    });
    if (loginToken) {
      onOpenModal2();
    } else {
      onOpen();
    }
  };

  /**
   * The function updates the value of an input field based on user input.
   */
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  /**
   * The function checks if the browser supports sharing and shares the current page's title and URL if
   * it does.
   */
  // const handleShare = async () => {
  //   try {
  //     if (navigator.share) {
  //       await navigator.share({
  //         title: document.title,
  //         url: window.location.href,
  //       });
  //     } else {
  //       alert("Sharing is not supported in this browser.");
  //     }
  //   } catch (error) {
  //     console.error("Error sharing:", error);
  //   }
  // };

  const [isLargerThanMedium] = useMediaQuery("(min-width: 768px)");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchAstrologerProfileData(astroDetails?.user?.guru));
  // }, []);

  const [dataFromChild, setDataFromChild] = useState("");

  // Maintain the variable for send Icon in the chat Input.
  const isInputEmpty = inputValue === "";

  const [astroBusy, setAstroBusy] = useState(false);
  return (
    <>
      {/* Modal For Login */}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "xs", md: "4xl" }}
      >
        <Login />
      </Modal>

      {/* Drawer for Gift start */}
      <Drawer
        isOpen={isOpenModal1}
        placement="bottom"
        onClose={onCloseModal1}
        size="sm"
      >
        <DrawerOverlay>
          <DrawerContent
            height="fit-content"
            borderRadius={{ base: "6px 6px 0px 0px" }}
            background="rgba(0, 0, 0, 0.3)"
            py={2}
          >
            <DrawerCloseButton color={"white"} />
            <Box p="4">
              <Gifts
                sessionId={sessionId}
                astroDetail={astroDetails}
                onClose1={onCloseModal1}
              />
            </Box>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      {/* Drawer for Gift End */}

      {isLargerThanMedium ? (
        <Modal
          isOpen={isOpenModal2}
          onClose={onCloseModal2}
          size="lg"
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          {localUserRole === "paidviewer" ||
          localUserRole === "astrologer" ? null : (
            <ModalContent>
              <ModalHeader pb={0}>Private Call</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box p="4">
                  <LiveCallFlow
                    astroDetail={astroDetails}
                    localUser={localUser}
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          )}
        </Modal>
      ) : (
        localUserRole === "hls-viewer" && (
          <Drawer
            isOpen={isOpenModal2}
            placement="bottom"
            onClose={onCloseModal2}
            size="sm"
          >
            <DrawerOverlay />
            <DrawerContent
              borderRadius={{ base: "6px", md: "none" }}
              bg="gray.200"
            >
              <DrawerCloseButton color="black" />
              <Box p="4">
                <LiveCallFlow
                  astroDetail={astroDetails}
                  localUser={localUser}
                  onDataFromChild={setDataFromChild}
                />
              </Box>
            </DrawerContent>
          </Drawer>
        )
      )}

      <Container
        maxW={"6xl"}
        position={{ base: "absolute", md: "unset" }}
        bottom={0}
        zIndex={"1000"}
        // backgroundColor={"#393939C9"}
      >
        {localUserRole === "hls-viewer" ? null : (
          <Box
            width={"70%"}
            borderRadius={"6px"}
            background={"gray.600"}
            display={!isLocalAudioEnabled ? "flex" : "none"}
            flexDirection={"column"}
            alignItems={"center"}
            p={2}
          >
            <Text color={"white"} m={0}>
              You are muted
              <Icon
                as={FiMicOff}
                boxSize={21}
                color={{ base: "white", lg: "gray.600" }}
              />
            </Text>
            <Text color={"white"} m={0}>
              {" "}
              Press mic button to unmute
            </Text>
          </Box>
        )}
        <Box display={"flex"} flexDirection={"column"} pb={{ base: 4 }}>
          {isMobile && (
            <>
              {!hasPaidViewer ? (
                /* Call Button */
                <VStack
                  zIndex={"8888"}
                  float={"right"}
                  alignSelf={"flex-end"}
                  display={
                    localUserRole === "paid-viewer" ||
                    localUserRole === "astrologer"
                      ? "none"
                      : "flex"
                  }
                  position={{ md: "absolute", base: "unset" }}
                  left={{ lg: "5%", md: "35%" }}
                  top={{ lg: "65%", md: "58%" }}
                >
                  <Button
                    colorScheme="whiteAlpha"
                    variant="outline"
                    boxSize={"46px"}
                    // border={"1px solid white"}
                    borderRadius={"50%"}
                    // onClick={onOpenModal2}
                    onClick={callClickHandler}
                  >
                    <Icon as={IoCall} boxSize={21} color={"white"} />
                  </Button>
                  <Text
                    color={"white"}
                    fontSize={{ base: "16px" }}
                    fontWeight={{ base: "600" }}
                  >
                    {isInternational
                      ? "$" + astroDetails?.live?.public?.international?.callfee
                      : "₹" + astroDetails?.live?.public?.national?.callfee}
                    <Text
                      as={"span"}
                      color={"white"}
                      fontSize={{ base: "14px" }}
                      fontWeight={{ base: "500" }}
                    >
                      /min
                    </Text>
                  </Text>
                </VStack>
              ) : (
                <VStack
                  zIndex={"8888"}
                  float={"right"}
                  alignSelf={"flex-end"}
                  display={
                    localUserRole === "paid-viewer" ||
                    localUserRole === "astrologer"
                      ? "none"
                      : "flex"
                  }
                  position={{ md: "absolute", base: "unset" }}
                  left={{ lg: "5%", md: "35%" }}
                  top={{ lg: "65%", md: "58%" }}
                >
                  <Button
                    colorScheme="whiteAlpha"
                    variant="outline"
                    boxSize={"46px"}
                    border={"1px solid red"}
                    borderRadius={"50%"}
                  >
                    <Icon as={IoCall} boxSize={21} color={"red"} />
                  </Button>
                  <Text
                    as={"span"}
                    color={"red"}
                    fontSize={{ base: "16px" }}
                    fontWeight={{ base: "500" }}
                  >
                    Busy
                  </Text>
                </VStack>
              )}
            </>
          )}
          {/* Chat Button */}

          {/* Chat Input, gift and share button */}
          <HStack justify={"space-between"} mt={{ base: 8 }}>
            <InputGroup
              w={
                localUserRole === "hls-viewer" ||
                localUserRole === "paid-viewer"
                  ? { base: "280px", md: "256px", lg: "320px" }
                  : { base: "156px", lg: "310px" }
              }
            >
              <InputLeftElement
                pointerEvents="none"
                children={
                  <BsFillChatDotsFill color={{ base: "white", md: "gray" }} />
                }
              />
              <Input
                variant="filled"
                type="text"
                placeholder="Chat Here"
                _placeholder={{ color: "black" }}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }}
                color={{ base: "black", md: "black" }}
                rounded="full"
                focusBorderColor="transparent"
                border={"1px solid gray"}
                bg={{ base: "white", md: "#F0F0F0" }}
                // opacity={0.5}
                _focus={{
                  outline: "none",
                  bg: "white",
                  opacity: "0.7",
                  border: "2px solid gray",
                }}
              />
              <InputRightElement
                children={<IoSend />}
                onClick={handleSubmit}
                color={isInputEmpty ? "orange.100" : "orange.600"}
                cursor={"pointer"}
              />
            </InputGroup>
            {isMobile ? null : (
              <>
                {!hasPaidViewer ? (
                  <VStack
                    zIndex={"8888"}
                    float={"right"}
                    alignSelf={"flex-end"}
                    display={
                      localUserRole === "paid-viewer" ||
                      localUserRole === "astrologer"
                        ? "none"
                        : "flex"
                    }
                    alignItems={"center"}
                    justifyContent={"center"}
                    // position={{ md: "absolute", base: "unset" }}
                    left={{ lg: "5%", md: "35%" }}
                    top={{ lg: "65%", md: "58%" }}
                  >
                    <Button
                      colorScheme="blackAlpha"
                      variant="outline"
                      boxSize={"40px"}
                      // border={"1px solid white"}
                      borderRadius={"50%"}
                      // onClick={onOpenModal2}
                      onClick={() => {
                        callClickHandler();
                      }}
                    >
                      <Icon as={IoCall} boxSize={21} color={"black"} />
                    </Button>
                    <Text
                      color={"black"}
                      fontSize={{ base: "16px" }}
                      fontWeight={{ base: "600" }}
                    >
                      {isInternational
                        ? "$" + astroDetails.live.public.international.callfee
                        : "₹" + astroDetails.live.public.national.callfee}
                      <Text
                        as={"span"}
                        color={"black"}
                        fontSize={{ base: "14px" }}
                        fontWeight={{ base: "500" }}
                      >
                        /min
                      </Text>
                    </Text>
                  </VStack>
                ) : (
                  <VStack
                    zIndex={"8888"}
                    float={"right"}
                    alignSelf={"flex-end"}
                    display={
                      localUserRole === "paid-viewer" ||
                      localUserRole === "astrologer"
                        ? "none"
                        : "flex"
                    }
                    alignItems={"center"}
                    justifyContent={"center"}
                    // position={{ md: "absolute", base: "unset" }}
                    left={{ lg: "5%", md: "35%" }}
                    top={{ lg: "65%", md: "58%" }}
                  >
                    <Button
                      colorScheme="red"
                      variant="outline"
                      boxSize={"40px"}
                      // border={"1px solid white"}
                      borderRadius={"50%"}
                      // onClick={onOpenModal2}
                    >
                      <Icon as={IoCall} boxSize={21} color={"red"} />
                    </Button>
                    <Text
                      color={"red"}
                      fontSize={{ base: "16px" }}
                      fontWeight={{ base: "600" }}
                    >
                      Busy
                    </Text>
                  </VStack>
                )}
              </>
            )}
            <Box
              boxSize={{ base: "36px" }}
              bg={{ base: "unset", md: "#868686" }}
              borderRadius={"50%"}
              display={localUserRole === "hls-viewer" ? "block" : "none"}
              onClick={onOpenModal1}
            >
              <Image
                src={gifts}
                alt={"gifts"}
                objectFit="cover"
                boxSize={"100%"}
                // borderRadius={"6px"}
              />
            </Box>
            {/* Share Button */}
            {/* <Box
              as={"div"}
              display={
                localUserRole === "hls-viewer" 
                  ? "flex"
                  : "none"
              }
              alignItems={"center"}
              justifyContent={"center"}
              boxSize={{ base: "36px" }}
              borderRadius={{ base: "50%" }}
              bg={{ base: "rgba(255, 255, 255, 0.3)", md: "#868686" }}
              onClick={handleShare}
            >
              <Icon
                as={RiShareForwardLine}
                boxSize={21}
                color={{ base: "white", md: "gray.600" }}
              />
            </Box> */}

            {/* video turn on / off button */}
            <Box
              as={"div"}
              display={localUserRole === "astrologer" ? "flex" : "none"}
              alignItems={"center"}
              justifyContent={"center"}
              boxSize={{ base: "36px" }}
              borderRadius={{ base: "50%" }}
              bg={"rgba(255, 255, 255, 0.3)"}
              onClick={toggleVideo}
            >
              {isLocalVideoEnabled ? (
                <Icon
                  as={IoVideocamOutline}
                  boxSize={21}
                  color={{ base: "white", lg: "gray.600" }}
                />
              ) : (
                <Icon
                  as={IoVideocamOff}
                  boxSize={21}
                  color={{ base: "white", lg: "gray.600" }}
                />
              )}
            </Box>

            {/* Mic on/off button */}
            <Box
              as={"div"}
              display={
                localUserRole === "astrologer" ||
                localUserRole === "paid-viewer"
                  ? "flex"
                  : "none"
              }
              alignItems={"center"}
              justifyContent={"center"}
              boxSize={{ base: "36px" }}
              borderRadius={{ base: "50%" }}
              bg={"rgba(255, 255, 255, 0.3)"}
              onClick={toggleAudio}
            >
              {isLocalAudioEnabled ? (
                <Icon
                  as={FiMic}
                  boxSize={21}
                  color={{ base: "white", lg: "gray.600" }}
                />
              ) : (
                <Icon
                  as={FiMicOff}
                  boxSize={21}
                  color={{ base: "white", lg: "gray.600" }}
                />
              )}
            </Box>

            {/* Camera Rotate Button */}
            <Box
              as={"div"}
              display={localUserRole === "astrologer" ? "flex" : "none"}
              alignItems={"center"}
              justifyContent={"center"}
              boxSize={{ base: "36px" }}
              borderRadius={{ base: "50%" }}
              bg={"rgba(255, 255, 255, 0.3)"}
              onClick={() => {
                hmsActions.switchCamera();
              }}
            >
              <Icon
                as={IoCameraReverseOutline}
                boxSize={21}
                color={{ base: "white", lg: "gray.600" }}
              />
            </Box>
          </HStack>
        </Box>
      </Container>
    </>
  );
}

export default LiveFooter;
