import {
  Box,
  Container,
  Flex,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, {
  useEffect,
  //  useEffect, 
  useMemo, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
// import { EffectFade, Navigation, Pagination } from "swiper";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import { GiBeveledStar } from "react-icons/gi";
import "./UserReview.css";
import User1 from "../../../assets/UserReview/User1.png";
// import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { BsFillStarFill, BsStarHalf } from "react-icons/bs";
// BsFillStarFill

import user1 from "../../../assets/userTestimonial1.jpeg";
import user2 from "../../../assets/userTestimonial2.jpeg";
import user3 from "../../../assets/userTestimonial3.jpeg";
import user4 from "../../../assets/userTestimonial4.jpeg";
import user5 from "../../../assets/userTestimonial5.jpeg";
import user6 from "../../../assets/user6.webp";
import user7 from "../../../assets/user7.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Aos from "aos";
import "aos/dist/aos.css";

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  // useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);

  return (
    <Flex gap={{ md: "16px" }} alignItems="center">
      {[...Array(fullStars)].map((_, index) => (
        <Icon
          key={index}
          as={BsFillStarFill}
          boxSize={{ md: "30px" }}
          color={"#984100"}
        />
        // <FaStar key={index} color="yellow.500" fontSize={{md:"42px"}} />
      ))}
      {hasHalfStar && (
        <Icon as={BsStarHalf} boxSize={{ md: "30px" }} color={"#984100"} />
      )}
      {/* {[...Array(5 - Math.ceil(rating))].map((_, index) => (
        <Icon  key={fullStars + index} as={BsFillStarFill} boxSize={{md: "42px"}} color={"#984100"}/>
        // <FaStar key={fullStars + index} color="gray.300" />
      ))} */}
    </Flex>
  );
};

const UserReview = () => {
  const images = [
    User1,
    User1,
    User1,
    User1,
    User1,
    User1,
    User1,
    User1,
    User1,
    User1,
  ];

  const reviews = [
    {
      img: user1,
      name: "Rahul Satyawali",
      review:
        " Gurucool provides the best and most affordable online astrology services. I have used so many different platforms but always ended up wasting my time and money, but with Gurucool, I've always had a wonderful experience.",
      date: "18/03/2023",
    },

    {
      img: user2,
      name: "Sanveen Kohli",
      review:
        " I simply don't have enough words to express how grateful I am to the Gurucool platform. My astrologer at Gurucool helped me come out of the darkest phase of my life. She patiently listened to all my issues, read my birth chart, and also gave me some remedies that helped calm my mind. Gradually, my life came back on track and I started attracting prosperity, peace, and love. She's truly an angel.",
      date: "12/02/2023",
    },
    {
      img: user3,
      name: "Lalit Negi",
      review:
        " My business had been experiencing losses for 6 consecutive months but I had no clue as to why it was happening. Then my wife who is a firm believer in astrology convinced me to consult an astrologer on Gurucool. To my surprise, he was able to tell me things that nobody except my family knew and then gave me some remedies. I started seeing results within 3-4 weeks, my financial situation started improving significantly and now my business is booming with god's grace and Gurucool's help.",
      date: "14/02/2023",
    },
    {
      img: user4,
      name: "Shivani Kutiyal",
      review:
        " Gurucool helped me find the love of my life😍. A year ago I was in an extremely toxic relationship. I had come to a point of hating myself and my life. Then one day I decided to consult a tarot reader on Gurucool and I also took healing sessions from her. Within a year, I manifested a guy who truly loves, respects, and takes care of me.",
      date: "17/02/2023",
    },
    {
      img: user5,
      name: "Prashant Rawat",
      review:
        " I come from a family who consult astrologers before doing any important thing but nowadays it's hard to find a good astrologer. I was skeptical when I first heard about the concept of online astrology, but because of the free live sessions on the Gurucool platform, I found a good astrologer. Now I consult him especially for muhurtas every time before doing anything important.",
      date: "19/03/2023",
    },
    {
      img: user6,
      name: "Jhalak Chaturvedi",
      review:
        " My entire family uses the Gurucool platform because it's so convenient and pocket friendly. I love attending the free live sessions and consulting on video calls . My husband is more comfortable with the chat consultation and my super busy daughter gets her consultation mostly through video reports so that she can watch the video report whenever she has time.",
      date: "29/03/2023",
    },
    {
      img: user7,
      name: "Yash Agarwal",
      review:
        " I have been using the Gurucool platform since the beginning because of the good quality service at a really affordable price. I was facing issues in my marital life and thanks to the Gurucool experts and the highly effective remedies, now my wife and I have a beautiful life together.",
      date: "09/03/2023",
    },
  ];

  // const [info, setinfo] = useState([]);

  const options = useMemo(
    () => ({
      nav: true,
      dots: false,
      //   margin: 4,
      // center: true,
      // autoplay: true,
      // autoplayTimeout: 3000,
      // autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
          margin: 8,
          //   nav: false,
          loop: true,
        },
        600: {
          items: 1,
          loop: true,
        },
        1000: {
          items: 1,
        },
      },
    }),
    []
  );

  const [startIndex, setStartIndex] = useState(0);

  // const handlePrevClick = () => {
  //   setStartIndex(Math.max(startIndex - 7, 0));
  // };

  // const handleNextClick = () => {
  //   setStartIndex(Math.min(startIndex + 7, images.length - 1));
  // };

  return (
    <>
      <Container
        maxW={"6xl"}
        pt={{ md: "34px", base: "51px" }}
        pb={{ md: "34px", base: "18px" }}
        bg={{ md: "#FFEEE1", base: "#F2D2BA" }}
        // mb={{ md: "19px" }}
        id="text-reviews"
        my={{ md: "60px", base: "30px" }}
        // data-aos="fade-up"
      >
        {/* Heading */}
        <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ base: "18px", md: "36px" }}
        >
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          <Text
            fontSize={{ base: "18px", md: "32px" }}
            fontWeight={"700"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
          >
            User Review
          </Text>
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
        </Box>

        {/* User Reviews */}
        <OwlCarousel className="owl-theme" {...options}>
          {reviews.map((curr, index) => {
            return (
              <VStack
                spacing={{ md: "20px", base: "10px" }}
                alignItems={"center"}
                p={4}
                // pt={4}
                bg={"#FFF5EE"}
                mx={"auto"}
                position={"relative"}
                // w={'82%'}
                // pb={{ md: "120px", base: "61px"}}
              >
                <StarRating rating={4.1 } size='sm' />
                <Box w={{ md: "715px", base: "293px" }} p={'10px'}>
                  <Text
                    fontSize={{ md: "20px", base: "12px" }}
                    fontWeight={"400"}
                    color={"#5B2800"}
                    textAlign={"center"}
                  >
                    {curr.review}
                  </Text>
                </Box>
                <Flex
                  direction={"column"}
                  justify={"center"}
                  alignItems={"center"}
                  // position={"absolute"}
                  // bottom={"-50%"}
                  // transform={"translate(0, -40%)"}
                >
                  <Box boxSize={{ md: "120px", base: "61px" }}>
                    <Image
                      src={curr.img}
                      alt="user"
                      boxSize={"100%"}
                      borderRadius={"50%"}
                    />
                  </Box>

                  <Text
                    fontSize={{ md: "22px", base: "12px" }}
                    fontWeight={"600"}
                    color={"#5B2800"}
                    textAlign={"center"}
                    // mb={"24px"}
                  >
                    {curr.name}
                  </Text>
                </Flex>
              </VStack>
            );
          })}
        </OwlCarousel>
      </Container>
    </>
  );
};

export default UserReview;
