import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Text, 
  // Button, 
  Flex, Center, Spinner } from "@chakra-ui/react";
import PaymentFailure from "./PaymentFailure/PaymentFailure";
import PaymentSuccess from "./PaymentSuccess/PaymentSuccess";
import axios from "axios";
import { 
  // G_GET_PAYMENT_INFO, 
  G_GET_PAYMENT_INFO_URL, G_GET_PAYMENT_INFO_URL_V2 } from "../../apiLinks";

const PaymentResult = () => {
  const { id } = useParams(); // Get the result parameter from the URL

  const paymentDetailsId = id.slice(4);

  const navigate = useNavigate();

  const [remainingTime, setRemainingTime] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((time) => time - 1);
    }, 1000);

    if (remainingTime === 0) {
      clearInterval(timer);
      navigate("/wallet");
    }

    return () => clearInterval(timer);
  }, [navigate, remainingTime]);

  const [captured, setCaptured] = useState(false);
  const [response, setResponse] = useState(false);
  // const [trxID, setTrxID] = useState(false);

  useEffect(() => {
    axios
      .get(
        // `https://data.gurucool.life/api/v1/payments/get_payment_info?paymentID=${paymentDetailsId}`
        G_GET_PAYMENT_INFO_URL(paymentDetailsId)
      )
      .then((response) => {
        //
        setCaptured(response.data.status === "captured" || "authorized");
        setResponse(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [paymentDetailsId]);

  return (
    <Flex
      height={{ md: "80vh", base: "56vh" }}
      textAlign="center"
      alignItems="center"
      justifyContent={"center"}
    >
      <Box w="6xl">
        {!captured ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : (
          <Text>
            {captured ? (
              <PaymentSuccess
                remainingTime={remainingTime}
                response={response}
              />
            ) : (
              <PaymentFailure
                remainingTime={remainingTime}
                response={response}
                setRemainingTime={setRemainingTime}
              />
            )}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default PaymentResult;
