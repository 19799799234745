import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";
import paymentFailure from "../../../assets/payment fail.gif";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {firebaseApp,analytics,logEvent} from '../../../firebaseEvents/firebase';
const PaymentFailure = ({
  amount,
  response,
  remainingTime,
  setRemainingTime,
  randomValue,
}) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setRemainingTime((time) => time - 1);
  //   }, 1000);

  //   if (remainingTime === 0) {
  //     clearInterval(timer);
  //     navigate("/wallet");
  //   }

  //   return () => clearInterval(timer);
  // }, [navigate, remainingTime]);

  useEffect(() => {
    logEvent(analytics,'fail_payment');
  }, []);
  return (
    <Box
      bg="white"
      w={{ base: "90%", sm: "80%", md: "50%" }}
      mx="auto"
      my={10}
      p={8}
      borderRadius="lg"
      boxShadow="0 0 20px rgba(0, 0, 0, 0.2)"
      textAlign="center"
    >
      <Flex color="red.500" fontSize="5xl" mb={5} justifyContent="center">
        <motion.img src={paymentFailure} alt="Your GIF" />
      </Flex>
      <Heading size="md" mb={5}>
        Payment Failed
      </Heading>
      <Text fontSize={{ md: "24px", base: "20px" }} fontWeight={700} mb={5}>
        {response.userAmount} /-
      </Text>

      <Text mb={5}>
        <span
          style={{ fontWeight: "normal" }}
          fontSize={{ md: "18px", base: "14px" }}
        >
          Transaction ID :
        </span>{" "}
        <span
          style={{ fontWeight: "bold" }}
          fontSize={{ md: "18px", base: "14px" }}
        >
          {response.orderId}
        </span>
      </Text>

      <Text mb={5} color="red" fontWeight={600}>
        Unfortunately, your payment could not be processed.
      </Text>

      <Button colorScheme="red" onClick={() => navigate("/wallet")}>
        Try Again
      </Button>
      <Text my={6}>
        You will be redirected to the wallet page in {remainingTime} seconds...
      </Text>
    </Box>
  );
};

export default PaymentFailure;
