import React from "react";
import Select from "react-select";
import "./Login.css";
// import { v4 as uuidv4 } from "uuid";
import options from "./options";

// const options = [
//   { value: "+1", label: () => <><FlagIcon code="us" size={16} /> USA (+1)</> },
//   { value: "+44", label: () => <><FlagIcon code="gb" size={16} /> UK (+44)</> },
//   { value: "+91", label: () => <><FlagIcon code="in" size={16} /> India (+91)</> },
//   { value: "+81", label: () => <><FlagIcon code="jp" size={16} /> Japan (+81)</> },
//   { value: "+86", label: () => <><FlagIcon code="cn" size={16} /> China (+86)</> },
//   { value: "+61", label: () => <><FlagIcon code="au" size={16} /> Australia (+61)</> },
//   // add more options as needed
// ];

// const options = [
//   {
//     value: "+1-ca",
//     label: "Canada (+1)",
//     code: "ca",
//     flag: require("../../assets/Canada.jpg"),
//   },
//   {
//     value: "+91",
//     label: "India (+91)",
//     code: "in",
//     flag: require("../../assets/India.jpg"),
//   },
//   {
//     value: "+971",
//     label: "UAE (+971)",
//     code: "ae",
//     flag: require("../../assets/United_Arab_Emirates.jpg"),
//   },

//   {
//     value: "+44",
//     label: "UK (+44)",
//     code: "gb",
//     flag: require("../../assets/United_Kingdom.jpg"),
//   },
//   {
//     value: "+1-us",
//     label: "USA (+1)",
//     code: "us",
//     flag: require("../../assets/United_States.jpg"),
//   },

//   {
//     value: "+65",
//     label: "Singapore (+65)",
//     code: "sg",
//     flag: require("../../assets/Singapore.jpg"),
//   },
// ];

// const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       backgroundColor: "#f2f2f2", // change the background color of the control
//       borderRadius: 8,
//       borderColor: state.isFocused ? 'grey' : 'red',
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isFocused ? "#f0ac8b" : "white", // change the background color of the selected option
//     //   backgroundColor: state.isFocused ? "blue" : "white", // change the background color of the selected option
//       color: state.isSelected ? "#DD7340" : "black", // change the color of the selected option #f0ac8b
//       display: "flex",
//           alignItems: "center",
//     }),
//     singleValue: (styles) => ({
//         ...styles,
//         display: "flex",
//         alignItems: "center",
//       }),
//   };

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#DD7340" : "#fff",
    color: state.isSelected ? "#fff" : "#495057",
    "&:hover": {
      backgroundColor: "#fad8c8",
      color: "black",
    },
    display: "flex",
    alignItems: "center",
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#848484",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#DD7340",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "#495057",
    borderColor: "#DD7340",
    display: "flex",
    alignItems: "center",
  }),
};

const PhoneCodeSelect = ({ value, onChange }) => {
  //
  // const phoneCode = value.split("-")[0];
  //
  return (
    <Select
      className="countrycode"
      options={options}
      defaultValue={options.find((option) => option.code === "in")}
      value={options.find((option) => option.value === value)}
      onChange={(selectedOption) =>
        onChange(selectedOption.value.split("-")[0])
      }
      isSearchable={false}
      getOptionLabel={(option) => (
        <>
          <img
            src={option.flag}
            alt={`${option.code}-flag`}
            style={{ width: 22, height: 14, marginRight: 12 }}
          />
          {option.label}
        </>
      )}
      styles={customStyles}
    />
  );
};

export default PhoneCodeSelect;
