import { Box, Button, Heading, Image, Text} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import icon1 from "./image/Frame.svg";
import icon2 from "./image/Frame (1).svg";
import icon3 from "./image/Frame (2).svg";
//Zodiac Sign Icon
import {BsBrightnessHigh} from "react-icons/bs";
import axios from "axios";

export default function Zodiacsign2({name}) {
const [content,setContent]=useState(null);

const [daily,setDaily]=useState(false);
const [monthly,setMonthly]=useState(false);
const [yearly,setYearly]=useState(false);

const date = new Date(); 
let day= date.getDate();
let month=date.getMonth()+1;
let year=date.getFullYear();
const fetchZodiacDataDaily = async () => {
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign:name,
        type: "daily",
        day: day,
        month: month,
        year: year,
      },
    });
   
    setContent(response.data.zodiac);
  } catch (error) {
    // Handle the error
   
    console.error(error);
    setContent(null);
  }
};

const fetchZodiacDataMonthly= async ()=>{
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign:name,
        type: "monthly",
        month: month,
        year: year,
      },
    });
   
    setContent(response.data.zodiac);
  } catch (error) {
    // Handle the error
   
    console.error(error);
    setContent(null);
  }

};
const fetchZodiacDataYearly = async ()=>{
  try {
    const response = await axios.get('https://data.gurucool.life/api/v1/admin/getZodiac', {
      params: {
        zodiacSign:name,
        type: "yearly",
        year: year,
      },
    });
   
    setContent(response.data.zodiac);
  } catch (error) {
    // Handle the error
   
    console.error(error);
    setContent(null);
  }

}
const handleDaily=()=>{
    fetchZodiacDataDaily();
    setDaily(true);
    setMonthly(false);
    setYearly(false);
}

const handleMonthly=()=>{
    fetchZodiacDataMonthly();
    setDaily(false);
    setMonthly(true);
    setYearly(false);
}

const handleYearly=()=>{
    fetchZodiacDataYearly();
    setDaily(false);
    setMonthly(false);
    setYearly(true);
}

useEffect(()=>{
    fetchZodiacDataDaily();
    setDaily(true);
},[])
  
  

  return (
    <>
      <Box display={"grid"} justifyContent={"center"} alignItems={"center"}>
        <Box display={"grid"} gridTemplateColumns={"repeat(3,1fr)}"}>
          <Button
            borderRadius={"48px"}
            leftIcon={daily?<Image src={icon1}></Image>:<BsBrightnessHigh  size={"23px"} />}
            variant={daily?"solid":"outline"}
            color={daily?"white":"#737373"}
            bg={daily?"#FF4C00":""}
            borderColor={daily?"none":"#737373"}
            margin={"10px"}
            onClick={handleDaily}
            
          >
            Today
          </Button>
          <Button
            leftIcon={<Image src={icon2}></Image>}
            bg={monthly?"#FF4C00":""}
            borderColor={monthly?"none":"#737373"}
            color={monthly?"white":"#737373"}
            borderRadius={"48px"}
            variant={monthly?"solid":"outline"}
            margin={"10px"}
            onClick={handleMonthly}
          >
            Monthly
          </Button>
          <Button
            leftIcon={<Image src={icon3}></Image>}
            variant={yearly?"solid":"outline"}
            color={yearly?"white":"#737373"}
            bg={yearly?"#FF4C00":""}
            borderColor={yearly?"none":"#737373"}
            borderRadius={"48px"}
            margin={"10px"}
            onClick={handleYearly}
          >
            2023
          </Button>
        </Box>
        <Box display={"grid"} justifyContent={"center"} alignItems={"center"}>
          <Box
            backgroundColor={"#FFDED0"}
            borderRadius={"50%"}
            height={"211px"}
            width={"211px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"10px"}
          >
            <Image src={content&&content.images.url}></Image>
          </Box>
        </Box>
      </Box>
      <Box width={{base:"85%",sm:"75%"}} margin={"auto"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        
          <Heading fontSize={"25px"} textAlign={"center"} margin={"10px"}>
            {name}
          </Heading>
          <Text textAlign={"center"} p={"0px "} >
           {content?content.mainContent:<Box textAlign={"center"} fontSize={"20px"} color={"red.500"}>Today Horoscope Data Not Updated</Box>}
          </Text>
          <Button backgroundColor={"#FF4C00"} color={"white"} margin={"15px"} onClick={()=>{alert("Please Update Your BirthDetails");window.scrollTo(0, 1000);}} >View More</Button>
      </Box>
      
    </>
  );
}
