import {
  Box,
  chakra,
  Container,
  // Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  Image,
  // position,
  HStack,
  Flex,
  VStack,
  Textarea,
  Button,
  Divider,
  InputGroup,
  InputRightElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import axios from "axios";

import FloatingButton from "../FloatingButton/FloatingButton";
import {
  Link as RouterLink,
  useLocation,
  useMatch,
  useParams,
} from "react-router-dom";

import { Link } from "react-router-dom";
// import { ReactNode } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
// import { BiMailSend } from "react-icons/bi";
// import logo from "../../assets/logo.png";
import Gurukool_logo from "../../assets/gurucoolLogo/gurucoolLogo.png";
import razorpay from "../../assets/razorpay.png";
import Paytm from "../../assets/Paytm.png";
import stripe from "../../assets/stripe.png";
import { useState } from "react";
import validator from "validator";
import { toast } from "react-toastify";
import Customersupport from "./../../assets/newhomepage/customersupport.svg";
import Refund from "./../../assets/newhomepage/refund.svg";
import Private from "./../../assets/newhomepage/private.svg";
import Verified from "./../../assets/newhomepage/agreement.svg";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "red.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={0}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState("");
  const handleChange = (e) => {
    setEmail(e.target.value);
    //
  };
  const postEmail = async () => {
    try {
      const response = await axios.post(
        "https://data.gurucool.life/api/v1/user/saveEmailToGS",
        {
          email: email,
        }
      );

      setNotification(true);
      setMessage(response.data.message);
    } catch (error) {
      console.error(error);
      //
      //
      setNotification(false);
      setMessage(error.response.data.message);
    }
  };
  const handleClick = () => {
    if (validator.isEmail(email)) {
      postEmail();
      setEmail("");
    } else {
      setNotification(false);
      setMessage("Please enter valid mail!");
      setEmail("");
    }
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  const isHomePage = window.location.pathname === "/";

  // Get the display value based on the device (mobile or desktop)
  const displayValue = useBreakpointValue({ base: "none", md: "block" });

  // const location = useLocation();
  const isAstrologerRoute = useMatch("/astrologer/:astro");

  // Check if the current route is a dynamic path appended to "/astrologer"
  const shouldHideFloatingButton = isAstrologerRoute !== null;

  return (
    <>
      <Box
        // my={"20px"}
        // border={"2px solid red"}
        as="footer"
        // position="absolute"
        // top="100%"
        width="100%"
        display={{ base: "none", sm: "none", md: "block" }}
        // mt="100px"
        // backgroundColor="#f5f5f5"
        // padding="20px"
        // textAlign="center"
        // bg={useColorModeValue('#BC5D14')}
        // bg={useColorModeValue("#ffffff")}
        color={useColorModeValue("white")}
        // pos={location.pathname === "/wallet" ? "absolute" : "static"}
        bottom={location.pathname === "/wallet" ? "0" : "auto"}
        sx={{
          "@media screen and (max-width: 767px)": {
            // position: "static",
            // bottom: "auto",
          },
        }}
        // mt={{md: "3rem"}}
      >
        <Container
          // border={"2px solid green"}
          as={Stack}
          maxW={"6xl"}
          pt={10}
          pb={{ md: 10, base: 20 }}
          mt={{ md: "0px", base: "0px" }}
          bg={"#ffffff"}
        >
          <SimpleGrid
            templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
            spacing={{ md: 8, base: 5 }}
            pb={{ md: "26px", base: 0 }}
          >
            <Stack spacing={2}>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <Image
                  src={Gurukool_logo}
                  // w={{ base: "150px", sm: "200px" }}
                  // h={{ base: "42px", md: "56px" }}
                  // w="90%"
                  w={{ md: "70%", base: "60%" }}
                  h="auto"
                  color={useColorModeValue("gray.700", "white")}
                  margin={{ base: "auto", md: 0 }}
                  mb={"10px"}
                  alt="Gurukool_logo"
                />
              </Box>
              <Text
                fontSize={{ md: "16px", base: "12px" }}
                fontWeight={"400"}
                color={"black"}
                textAlign={{ base: "center", md: "left" }}
              >
                GuruCool.life connects individuals with seasoned and specialized
                doctors within a secure and trusted platform. Prioritizing
                excellence, users receive personalized and reliable healthcare
                guidance, spanning various medical domains, to enhance their
                well-being.
              </Text>
              <Box
                display={{ base: "flex", md: "none" }}
                justifyContent={"center"}
                w={"100%"}
                pb={{ base: "24px", md: 0 }}
              >
                <Accordion
                  allowToggle
                  colorScheme="black"
                  // w={"fit-content"}
                  mx={"auto"}
                >
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        colorScheme="black"
                        pb={0}
                        pt={6}
                        _focus={{ bg: "unset" }}
                      >
                        <Box
                          flex="1"
                          textAlign="left"
                          color="black"
                          mr={2}
                          fontSize={"15px"}
                          fontWeight={"500"}
                        >
                          <Heading as="h4" fontSize={"20px"}>
                            Company / Support
                          </Heading>
                        </Box>
                        <AccordionIcon color={"black"} />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      pb={4}
                      pt={0}
                      textAlign={"center"}
                      textColor={"black"}
                    >
                      <Link
                        to="/aboutus"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        {" "}
                        About us
                      </Link>
                      <br></br>
                      <Link
                        to="/privacy"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        Privacy Policy
                      </Link>
                      <br></br>
                      <Link
                        to="/termsandconditions"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        Terms and Conditions
                      </Link>
                      <br></br>
                      <Link
                        to="/contact-us"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        Contact us
                      </Link>
                      <br></br>
                      <Link
                        to="/refund-policy"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        Refund Policy
                      </Link>
                      <br></br>
                      <Link
                        to="/desclaimer"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        Disclaimer
                      </Link>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
              {/* <Text
                color={"black"}
                textAlign={"center"}
                fontSize={"15px"}
                fontWeight={"500"}
                display={{ md: "none" }}
              >
                Connecting With Us
              </Text> */}
              {/* <HStack
                spacing="4"
                pt={{ md: "30px", base: "15px" }}
                py={{ md: "48px", base: "4px" }}
                justify={{ base: "center", md: "flex-start" }}
              >
                <Box bg={"white"} borderRadius="full" boxSize={"40px"}>
                  <IconButton
                    as="a"
                    href="https://www.facebook.com/GuruCool.life/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                    icon={<FaFacebook />}
                    variant="ghost"
  
                  />
                </Box>
                <Box bg={"white"} borderRadius="full">
                  <IconButton
                    as="a"
                    href="https://www.instagram.com/gurucool_life/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                    icon={<FaInstagram />}
                    variant="ghost"
                    colorScheme="pink"
                  />
                </Box>
                <Box bg={"white"} borderRadius="full">
                  <IconButton
                    as="a"
                    href="https://www.youtube.com/channel/UCBU2CDJ1OSh4n2uvycg_N5A"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="YouTube"
                    icon={<FaYoutube />}
                    variant="ghost"
                    colorScheme="red"
                  />
                </Box> */}
              {/* <Box bg={"white"} borderRadius="full">
                  <IconButton
                    as="a"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter"
                    icon={<FaTwitter />}
                    variant="ghost"
                    colorScheme="blue"
                  />
                </Box> */}
              {/* </HStack> */}

              {/* <Box mx={"auto"} pt={{ base: "24px", md: 0 }}>
                <Text
                  fontSize={{ md: "22px", base: "15px" }}
                  fontWeight={"500"}
                  color={"black"}
                  textAlign={{ base: "center", md: "left" }}
                >
                  Trusted & Seals
                </Text>
                <Flex
                  gap={{ md: "17px", base: "9px" }}
                  flexWrap={{ md: "wrap" }}
                  justify={{ base: "center", md: "flex-start" }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={{ md: "131px" }}
                    h={{ md: "51px" }}
                    p={1}
                    bg={"white"}
                    borderRadius={"8px"}
                  >
                    <Image src={razorpay} />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={{ md: "131px" }}
                    h={{ md: "51px" }}
                    p={1}
                    bg={"white"}
                    borderRadius={"8px"}
                  >
                    <Image src={Paytm} />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={{ md: "131px" }}
                    h={{ md: "51px" }}
                    p={1}
                    bg={"white"}
                    borderRadius={"8px"}
                  >
                    <Image src={stripe} />
                  </Box>
                </Flex>
              </Box> */}
            </Stack>
            <Stack
              direction={{ md: "column", base: "row" }}
              align={"flex-start"}
              justify={{ base: "space-between", md: "flex-start" }}
              spacing={{ md: "48px" }}
            >
              <VStack
                align={"flex-start"}
                color={"black"}
                display={{ md: "flex", base: "none" }}
              >
                <ListHeader>
                  {" "}
                  <Heading as="h4" fontSize={"20px"}>
                    Company
                  </Heading>
                </ListHeader>
                <Link to="/aboutus">About us</Link>
                <Link to="/privacy">Privacy Policy</Link>

                {/* <Link href={'#'}>Pricing</Link> */}
                {/* <Link as={RouterLink} to="/#customer-testimonial">Testimonials</Link> */}
                <Link
                  to="/termsandconditions
                "
                  // color={'#A95210'}
                >
                  Terms And Conditions
                </Link>
              </VStack>

              {/* <VStack
                align={"flex-start"}
                display={{ md: "none", base: "flex" }}
                color={"black"}
              >
                <ListHeader>Support</ListHeader>
                <Link to="/contact-us">Contact us</Link>
                <Link to="/refund-policy">Refund Policy</Link>S
                <Link to="/desclaimer">Desclaimer</Link> */}
              {/* <Link href={"#"}>Help Center</Link> */}

              {/* <Link href={"#"}>Legal</Link> */}
              {/* </VStack> */}
              {/* <VStack align={"flex-start"}>
                <ListHeader>Collaborate</ListHeader>
                <Link>Clever Top</Link>
                <Link>Exotel</Link>
  
                <Link>Facebook</Link>
                <Link>Quora</Link>
                <Link>Google</Link>
                <Link>Youtube</Link>
              </VStack> */}
            </Stack>
            <Stack
              direction={{ md: "column", base: "row" }}
              align={"flex-start"}
              spacing={{ md: "48px" }}
              justify={{ base: "flex-start", md: "flex-start" }}
              display={{ md: "flex", base: "none" }}
              color={"black"}
            >
              <VStack align={"flex-start"}>
                <ListHeader>
                  <Heading as="h4" fontSize={"20px"}>
                    Support
                  </Heading>
                </ListHeader>
                <Link to="/contact-us">Contact us</Link>
                <Link to="/refund-policy">Refund Policy</Link>
                <Link to="/desclaimer">Disclaimer</Link>
                {/* <Link href={"#"}>Help Center</Link>
  
                <Link href={"#"}>Legal</Link> */}
              </VStack>

              {/* <VStack align={"flex-start"}>
                <ListHeader>Important Links</ListHeader>
                <Link>Tarot Reader</Link>
                <Link href={"#"}>Vedic Astrology </Link>
  
                <Link href={"#"}>Palmistry </Link>
                <Link href={"#"}>Gemology </Link>
                <Link href={"#"}>Vastu </Link>
                <Link href={"#"}>Numerology </Link>
              </VStack> */}
            </Stack>
            <Stack
              display={{ md: "flex", base: "none" }}
              // border={"2px solid red"}
              align={{ md: "flex-start", base: "center" }}
              w={"100%"}
              color={"black"}
              pb={{ base: "26px", md: 0 }}
            >
              {/* <Text textAlign={"center"} mb={0}> */}
              <ListHeader>
                {" "}
                <Heading as="h4" fontSize={"20px"}>
                  Secure
                </Heading>
              </ListHeader>
              <Flex
                mt={"12px"}
                h={"70%"}
                w={"100%"}
                gap={2}
                flexDirection={"row"}
              >
                <Flex
                  gap={5}
                  w={"50%"}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <Box>
                    <HStack width={"100%"}>
                      <Box
                        bg="#FFF5EF;"
                        borderRadius="50%"
                        w="40px"
                        h="40px"
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Image src={Customersupport} w="28px" h="28px" />
                      </Box>
                      <Text
                        textAlign={"center"}
                        fontWeight={400}
                        fontsize={{ md: "24px" }}
                      >
                        24 X 7 Customer Support
                      </Text>
                    </HStack>
                  </Box>

                  <Box>
                    <HStack width={"100%"}>
                      <Box
                        bg="#FFF5EF;"
                        borderRadius="50%"
                        w="40px"
                        h="40px"
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Image src={Refund} w="50px" h="50px" />
                      </Box>
                      <Text
                        textAlign={"center"}
                        fontWeight={400}
                        fontsize={{ md: "32px" }}
                      >
                        100% Refund if Unsatisfied
                      </Text>
                    </HStack>
                  </Box>
                </Flex>
                <Flex gap={5} w={"50%"} flexDirection={"column"}>
                  <Box>
                    <HStack width={"100%"}>
                      <Box
                        bg="#FFF5EF;"
                        borderRadius="50%"
                        w="40px"
                        h="40px"
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Image src={Private} w="30px" h="30px" />
                      </Box>
                      <Text
                        textAlign={"center"}
                        fontWeight={400}
                        fontsize={{ md: "32px" }}
                      >
                        Private & Confidential
                      </Text>
                    </HStack>
                  </Box>

                  <HStack width={"100%"}>
                    <Box
                      bg="#FFF5EF;"
                      borderRadius="50%"
                      w="40px"
                      h="40px"
                      display="flex"
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Image src={Verified} w="25px" h="25px" />
                    </Box>
                    <Text
                      textAlign={"center"}
                      fontWeight={400}
                      fontsize={"1rem"}
                    >
                      Verified Doctors
                    </Text>
                  </HStack>
                </Flex>
              </Flex>

              {/* </Text> */}
              {/* <Stack direction={"column"} w={"100%"} gap={{ md: "50px" }}> */}
              {/* <Input
                  placeholder={"Name"}
                  bg={useColorModeValue("#fff")}
                  border={0}
                  _focus={{
                    bg: "whiteAlpha.300",
                    border: "none",
                    outline: "1px solid #BC5D14",
                    boxShadow: "none",
                  }}
                />
                <Input
                  type="email"
                  placeholder={"Mail ID"}
                  bg={useColorModeValue("#fff")}
                  border={0}
                  _focus={{
                    bg: "whiteAlpha.300",
                    border: "none",
                    outline: "1px solid #BC5D14",
                    boxShadow: "none",
                  }}
                />
                <Textarea
                  placeholder={"Leave message"}
                  bg={useColorModeValue("#fff")}
                  border={0}
                  _focus={{
                    bg: "whiteAlpha.300",
                    border: "none",
                    outline: "1px solid #BC5D14",
                    boxShadow: "none",
                  }}
                />
                <Button
                  bg={useColorModeValue("green.400", "green.800")}
                  color={useColorModeValue("white", "gray.800")}
                  _hover={{
                    bg: "green.600",
                  }}
                  // aria-label="Subscribe"
                  // icon={<BiMailSend />}
                >
                  Submit
                </Button> */}
              {/* <VStack >
                  <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={"email"}
                    placeholder={"Your Mail ID"}
                    bg={useColorModeValue("#fff")}
                    border={0}
                    _focus={{
                      bg: "whiteAlpha.300",
                      border: "none",
                      outline: "1px solid #BC5D14",
                      boxShadow: "none",
                    }}
                    value={email}
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="100%"
                      w="100%"
                      size="sm"
                      colorScheme="green"
                      borderRadius={"0 6px 6px 0"}
                      onClick={handleClick}
                    >
                      Submit
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Box
                  alignSelf={"baseline"}
                  fontSize={"14px"}
                  color={notification ? "green" : "red"}
                >
                  {message}
                </Box>
              </VStack> */}
              {/* <Box mx={"auto"} pt={{ base: "24px", md: 0 }}>
                <Heading
                  as="h4"
                  fontSize={{ md: "20px", base: "15px" }}
                  // fontWeight={"500"}
                  color={"black"}
                  textAlign={{ base: "center", md: "left" }}
                >
                  Trusted & Seals
                </Heading>
                <Flex
                  gap={{ md: "17px", base: "9px" }}
                  flexWrap={{ md: "wrap" }}
                  justify={{ base: "center", md: "flex-start" }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={{ md: "131px" }}
                    h={{ md: "51px" }}
                    p={1}
                    bg={"white"}
                    borderRadius={"8px"}
                  >
                    <Image src={razorpay} alt="razorpay" />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={{ md: "131px" }}
                    h={{ md: "51px" }}
                    p={1}
                    bg={"white"}
                    borderRadius={"8px"}
                  >
                    <Image src={Paytm} alt="paytm" />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w={{ md: "131px" }}
                    h={{ md: "51px" }}
                    p={1}
                    bg={"white"}
                    borderRadius={"8px"}
                  >
                    <Image src={stripe} alt="stripe" />
                  </Box>
                </Flex>
              </Box> */}
              {/* </Stack> */}
            </Stack>
          </SimpleGrid>
          <Text
            color={"black"}
            textAlign={"center"}
            fontSize={"16px"}
            fontWeight={"500"}
            // display={{ md: "none" }}
          >
            Connecting With Us
          </Text>

          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            // border={"2px solid red"}
            spacing="4"
            pt={{ md: "30px", base: "15px" }}
            py={{ md: "0", base: "4px" }}
            justify={{ base: "center", md: "flex-start" }}
          >
            <Box bg={"white"} borderRadius="full" boxSize={"40px"}>
              <IconButton
                as="a"
                href="https://www.facebook.com/GuruCool.life/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
                icon={<FaFacebook />}
                variant="ghost"
                colorScheme="blue"
                // size={24}
                // boxSize={'100%'}
              />
            </Box>
            <Box bg={"white"} borderRadius="full">
              <IconButton
                as="a"
                href="https://www.instagram.com/gurucool_life/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                icon={<FaInstagram />}
                variant="ghost"
                colorScheme="pink"
              />
            </Box>
            <Box bg={"white"} borderRadius="full">
              <IconButton
                as="a"
                href="https://www.youtube.com/channel/UCBU2CDJ1OSh4n2uvycg_N5A"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
                icon={<FaYoutube />}
                variant="ghost"
                colorScheme="red"
              />
            </Box>
          </HStack>

          <Divider orientation="horizontal" color={"orange"} my={3} />
          <Text
            py={"10px"}
            bg="#FF4D00"
            fontSize={{ base: "11px", md: "16px" }}
            fontWeight={"600"}
            textAlign={"center"}
            color={"white"}
            // pt={"26px"}
          >
            {/* © 2023 Ani and Kai Marketing (OPC) Private Limited */}© 2023
            Nohata and Shiv
          </Text>
        </Container>
      </Box>
      {shouldHideFloatingButton ? null : <FloatingButton />}
      {/* <FloatingButton /> */}
    </>
  );
}
