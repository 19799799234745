import { Box, Container, Flex, Heading, Text } from "@chakra-ui/layout";
import Specialities from "../Specialities";
import {
  FaHeart,
  FaHeartbeat,
  FaHandHoldingHeart,
  FaArrowUp,
} from "react-icons/fa";
import { Icon } from "@chakra-ui/icon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Button,
  ButtonGroup,
  Image,
  MenuButton,
  Modal,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import ConsultAstroCard from "./ConsultAstroCard/ConsultAstroCard";
import { useState } from "react";
import axios from "axios";
import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";
import Login from "../../Login&Signup/Login";
import CallFlowModal from "../../CallFlow/CallFlowModal";
import { useLocation, useNavigate } from "react-router-dom";
import free1 from "../../../assets/banners/free1.jpg";
import {
  consultAstrologers,
  fetchConsultAstrologers,
} from "../../../store/consultAstrologerSlice";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { fetchAllUsers } from "../../../store/getAllUsers";
import PrimaryLoading from "../../Loaders/PrimaryLoading";
import Pagination from "react-js-pagination";
import "./Shimmer.css";
import { CommonBanners } from "./CommonBanners";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { TfiAngleDown } from "react-icons/tfi";
import { AccordionItem } from "@chakra-ui/react";
import SearchBarTop from "../../Newhomepage/SearchBarTop/SearchBarTop";
// import data from "../../Utils/data";

const Love = ({ DocData }) => {
  const token = localStorage.getItem("guruToken");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${token}`
        )
        .then((response) => {
          //
          dispatch(
            selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
          );
        });
    }
  }, [token]);

  console.log("DOCDATA", DocData);

  const dispatch = useDispatch();
  // const data = useSelector((state) => state.data.data);
  const status = useSelector((state) => state.consultAstrologers.status);
  const total = useSelector((state) => state.consultAstrologers.total);
  const totalPages = useSelector(
    (state) => state.consultAstrologers.totalPages
  );

  const currentPages = useSelector(
    (state) => state.consultAstrologers.currentPage
  );
  // const data = useSelector((state) => state.consultAstrologers.status);
  const error = useSelector((state) => state.data.error);
  const data = useSelector((state) => state.consultAstrologers.data);
  //
  const [skill, setSkill] = useState("");
  const location = useLocation().pathname;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [callBtnClicked, setCallBtnClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const callClicked = () => {
    setCallBtnClicked(true);
    onOpen();
  };
  const [displayCount, setDisplayCount] = useState(data.length);
  const navigate = useNavigate();
  const handleSeeMore = () => {
    navigate("/doctor/Love");
  };
  const loginToken = localStorage.getItem("loginToken");
  const isMobileDevice = window.innerWidth <= 768;
  const options = {
    loop: true,

    responsive: {
      0: {
        items: 1.1,
        // margin: 8,
        // nav: false,
        // dots: true,
        dotsEach: true,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const handleOptionChange = (selectedOption) => {
    setSkill(selectedOption);
  };

  const itemsPerPage = 12;
  const [displayedItems, setDisplayedItems] = useState(itemsPerPage);
  const filteredData = data.filter((item) =>
    skill ? item.skills.includes(skill) : true
  );

  // useEffect(() => {
  //   dispatch(fetchConsultAstrologers({ pageNumber: currentPage, perPage: 12 }));
  // }, [currentPage]);

  useEffect(() => {
    dispatch(
      fetchConsultAstrologers({ pageNumber: 1, perPage: displayedItems })
    );
  }, [dispatch, displayedItems]);

  const handleShowMore = () => {
    setDisplayedItems((prevCount) => prevCount + itemsPerPage);
  };

  const handleShowLess = () => {
    setDisplayedItems((prevCount) => prevCount - itemsPerPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //
  // const itemsPerPage = 12;
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  //

  // const totalPages = Math.ceil(total / itemsPerPage);

  // const handlePageChange = (action) => {
  //   if (action === "prev" && currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   } else if (action === "next" && currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // // };
  //
  // const handlePageChange = (action) => {
  //   if (action === "prev" && currentPage > 0) {
  //     setCurrentPage(currentPage - 1);
  //   } else if (action === "next" && currentPage < totalPages - 1) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };
  const [showScrollButton, setShowScrollButton] = useState(false);
  const users = useSelector((state) => state.loggedInUserDetails.data);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [FaArrowUp]);

  const handleScroll = () => {
    const yOffset = window.pageYOffset;
    if (yOffset > 200) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const [oneTime, setOnetime] = useState();

  const handleAccordionClick = (id) => {
    setIsOpen1(!isOpen1);
    setOnetime(id);
  };

  const faq = [
    {
      id: 1,
      q: "What is GuruCool.life ?",
      a: "GuruCool.life  is an online platform that offers users the chance to consult skilled doctors for personalized healthcare advice, diagnoses, and insights via calls, chats, or video calls.",
    },
    {
      id: 2,
      q: "How can GuruCool.life benefit me?",
      a: "GuruCool.life enables you to connect with skilled doctors who offer valuable insights into various health aspects. From diagnoses and treatment plans to preventive measures and wellness guidance, our medical consultations aim to provide clarity and support based on medical expertise.",
    },
    {
      id: 3,
      q: " How do I book a consultation with a doctor on GuruCool.life?",
      a: "To schedule a medical consultation on GuruCool.life, just visit our website and explore our roster of experienced doctors. Select a doctor based on their specialization, ratings, and availability. Once chosen, you can book a session and pick your preferred communication method (call, chat, or video call).",
    },
    {
      id: 4,
      q: " Are the doctors on GuruCool.life qualified and experienced?",
      a: "Absolutely, GuruCool.life ensures that all doctors on our platform are qualified and experienced professionals. We implement a rigorous vetting process to onboard doctors with proven expertise. You can review their profiles, read customer feedback, and assess ratings to make an informed decision.",
    },
    {
      id: 5,
      q: "Is my consultation with the Doctors confidential?",
      a: "Certainly! GuruCool.life highly values your privacy and guarantees complete confidentiality for all interactions between users and doctors. Stringent privacy policies are enforced to protect your personal data and maintain the confidentiality of your consultations.",
    },
    {
      id: 6,
      q: "How much does a consultation on GuruCool.life Medical Services cost?",
      a: "The cost of consultations may vary based on the doctor you select and the duration of the session. Each doctor sets their own fees, which are clearly stated on their profiles. You can opt for a doctor whose charges align with your budget.",
    },
    {
      id: 7,
      q: "Can I ask specific questions during the consultation?",
      a: "Certainly, you can ask specific questions during your consultation with the doctor. In fact, sharing pertinent details and posing precise queries can aid the doctor in offering you more accurate and personalized guidance.",
    },
    {
      id: 8,
      q: "Are the medical diagnoses on GuruCool.life guaranteed to be accurate?",
      a: "Medicine is a complex field, and diagnoses can differ based on individual medical professionals. While doctors on GuruCool.life aim for accurate and well-informed diagnoses, it's essential to recognize that medical interpretations can vary. The guidance offered should be viewed as one aspect of healthcare decision-making.",
    },
  ];

  return (
    <>
      {/* {status === "loading" ? (
        <Spinner />
      ) : ( */}
      <SearchBarTop />
      <Container
        maxW={"7xl"}
        mb={2}
        px={location !== "/astrologers" ? 4 : 0}
        style={{
          filter: isOpen ? "blur(3px)" : "blur(0px)",
        }}
      >
        {!loginToken ? (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "md" }}
          >
            <Login />
          </Modal>
        ) : (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "md" }}
          >
            {callBtnClicked && <CallFlowModal />}
          </Modal>
        )}
        <CommonBanners users={users} />
        <Specialities handleOptionChange={handleOptionChange} />
        {/* {location !== "/astrologers" && (
            <Specialities handleOptionChange={handleOptionChange} />
          )} */}
        <Box>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            mt={{ md: "56px", base: "24px" }}
          >
            {/* <Icon
              // as={FaHeart}
              // key={curr.id}
              boxSize={{ md: "42px", base: "20px" }}
              color={"#FF1C1C"}
              mr={{ md: "20px", base: "12px" }}
            /> */}
            <Heading
              as="h2"
              textAlign={"center"}
              fontSize={{ md: "38px", base: "18px" }}
              fontWeight={"700"}
              mb={0}
            >
              All Doctors
            </Heading>
          </Flex>
          <Text
            fontSize={{ md: "16px", base: "12px" }}
            fontWeight={"400"}
            color={"#545454"}
            mt={{ md: "16px" }}
            textAlign={"center"}
            w={"90%"}
            mx={"auto"}
          >
            Address health concerns with precision. Access specialized doctors
            for personalized guidance, accurate diagnoses, and effective
            solutions. Your well-being journey starts here.
          </Text>
        </Box>

        <Flex
          justifyContent={"center"}
          flexWrap={"wrap"}
          gap={{ md: "20px", base: "15px" }}
          // display={isMobileDevice ? "none" : "flex"}
        >
          {/* {data.map((currElem, index) => { */}

          {DocData.map((currElem, index) => {
            // console.log(currElem)
            if (location === "/doctor") {
              return (
                <>
                  <ConsultAstroCard
                    key={index} // Add a unique key for each rendered element
                    currElem={currElem}
                    index={index}
                    callClicked={callClicked}
                  />
                </>
              );
              // }
              <Button variant={"ghost"}>See More</Button>;
            } else {
              return (
                // <AstroCard currElem={currElem} callClicked={callClicked} />
                <ConsultAstroCard
                  currElem={currElem}
                  // length={shuffledArray.length}
                  index={index}
                  callClicked={callClicked}
                />
              );
            }
          })}
        </Flex>
        {/* <Box ml={{ md: "100px", base: "50px", sm: "50px" }} mt="20px">
          {" "}
          {status === "loading" ? (
            // <Spinner h="50px" w="50px" />
            <div className="shimmer-card">
              <div className="shimmer-img stroke animate"></div>
              <div className="shimmer-title stroke animate"></div>
              <div className="shimmer-tags stroke animate "></div>
              <div className="shimmer-details stroke animate "></div>
            </div>
          ) : null}
        </Box> */}
        <Box ml={{ md: "100px", base: "50px", sm: "50px" }} mt="20px">
          {" "}
          {status === "loading" ? (
            <Spinner
              h="50px"
              w="50px"
              ml={{ md: "520px", sm: "90px", base: "90px" }}
              mb={{ md: "20px", base: "10px", sm: "10px" }}
            />
          ) : null}
        </Box>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          m="70px 70px 0px 70px"
          gap={{ md: "40px", base: "20px", sm: "20px" }}
        >
          {filteredData.length === 0 ? (
            <Box>
              <Heading>We are looking for Astrologers under this Skill</Heading>
            </Box>
          ) : (
            <>
              {displayedItems > itemsPerPage && (
                <Button
                  p={{ md: "10px", sm: "10px", base: "10px" }}
                  onClick={handleShowLess}
                  bgColor="#FF4F05"
                  color="#FFF"
                  // w="fit-content"
                  fontSize={"14px"}
                  variant="unstyled"
                >
                  Show Less
                </Button>
              )}
              <Button
                p={{ md: "10px", sm: "10px", base: "10px" }}
                onClick={handleShowMore}
                bgColor="#FF4F05"
                color="#FFF"
                fontSize={"14px"}
                // w="fit-content"
                variant="unstyled"
              >
                Show More
              </Button>
            </>
          )}
        </Flex>

        {showScrollButton && (
          <Box
            bgColor="#FF4F05"
            color="#FFF"
            position="relative"
            bottom="105px"
            width="30px"
            height="30px"
            left={{ md: "-40px", sm: "10px", base: "10px" }}
            borderRadius="full"
            zIndex="5"
            p="3px"
            pl="7px"
            pt="7px"
            cursor="pointer"
            onClick={scrollToTop}
          >
            <FaArrowUp />
          </Box>
        )}
        {/* <Flex justifyContent={"center"} alignItems={"center"} m="70px">
            <Pagination
              activePage={currentPages}
              itemsCountPerPage={12}
              totalItemsCount={total}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              prevPageText="Previous"
              nextPageText="Next"
            />
          </Flex> */}
        {/* <Flex gap="30px" justifyContent="center" p="50px">
            <Button
              colorScheme="gray"
              onClick={() => handlePageChange("prev")}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              colorScheme="gray"
              onClick={() => handlePageChange("next")}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Flex> */}
      </Container>
      {/* )} */}

      <Container maxW={"6xl"}>
        <Box>
          <Heading
            fontSize={{ md: "38px", base: "18px" }}
            fontWeight={"600"}
            textAlign={"center"}
            mb={{ md: "26px", base: "26px" }}
          >
            FAQ's
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={{ md: "16px" }}
            fontWeight={"400"}
            color={"#545454"}
            display={{ md: "block", base: "none" }}
          >
            We are here to help you, with most frequent question asked by our
            users.
          </Text>
        </Box>
        <Box mb={{ md: "40px", base: "16px" }}>
          <Accordion
            allowToggle
            w={{ md: "896px", base: "auto" }}
            mx={"auto"}
            mb={{ base: "14px", md: "25px" }}
          >
            {faq.map((curr, index) => {
              return (
                <AccordionItem
                  // border={"none"}
                  borderRadius={"18px"}
                  boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.26)"}
                  border={curr.id === oneTime ? "2px solid #FF4C00" : "none"}
                  // h={{ md: "132px", base: "68px" }}
                >
                  <h2>
                    <AccordionButton
                      _hover={{ bg: "transparent" }}
                      // h={{ md: "132px", base: "68px" }}
                      // pt={{md: isOpen1 ? "50px" : "50px", base: "16px"}}
                      // pb={{md: isOpen1 ? "0px" : "50px", base: isOpen1 ? "0px" :"16px"}}

                      onClick={() => handleAccordionClick(curr.id)}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize={{ md: "18px", base: "12px" }}
                        fontWeight={"400"}
                        color={"#3F3F3F"}
                        ml={{ md: "38px", base: "16px" }}
                        mr={{ md: "100px", base: "36px" }}
                      >
                        {curr.q}
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        boxSize={{ md: "50px" }}
                        borderRadius={"full"}
                        bg={
                          isOpen1 && curr.id === oneTime ? "#FF4C00" : "white"
                        }
                        flexDirection={"row-reverse"}
                        transform={
                          isOpen1 && curr.id === oneTime ? "rotate(90deg)" : ""
                        }
                        transition="transform 0.2s ease-in-out"
                      >
                        <ChevronRightIcon
                          boxSize={{ md: 8, base: 4 }}
                          color={
                            isOpen1 && curr.id === oneTime ? "white" : "#FF4C00"
                          }
                        />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    pt={0}
                    fontSize={{ md: "18px", base: "12px" }}
                    fontWeight={"400"}
                    color={"#626262"}
                    ml={{ md: "38px", base: "16px" }}
                    mr={{ md: "100px", base: "36px" }}
                    // mt={{md: "-42px", base: "-24px"}}
                  >
                    {curr.a}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      </Container>
    </>
  );
};

export default Love;
