import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { P_PUT_USER_DETAILS } from "../../apiLinks";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
} from "mdb-react-ui-kit";

import { useSelector } from "react-redux";
import {
  Center,
  IconButton,
  Spinner,
  // Tooltip,
  useDisclosure,
  Icon,
  Text,
  Box,
  Image,
  Flex,
  Divider,
  ModalHeader,
  Container,
  Heading,
  Stack,
  Grid,
  Input,
  Select,
  HStack,
  VStack,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  // ModalHeader,
  // ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import UserDetails from "./UserDetails";
import { MdOutlineRateReview } from "react-icons/md";
// import Feedback from "../CallFlow/Feedback/Feedback";
import FeedbackModal from "../CallFlow/Feedback/FeedbackModal";
import { FiPlay } from "react-icons/fi";
import { CgUnavailable } from "react-icons/cg";
import { Link as ChakraLink } from "@chakra-ui/react";
import AstrologerProfile from "./AstrologerProfile";
import axios from "axios";
import user1 from "../../assets/userTestimonial1.jpeg";
import user2 from "../../assets/userTestimonial2.jpeg";
import user3 from "../../assets/userTestimonial3.jpeg";
import user4 from "../../assets/userTestimonial4.jpeg";
import user5 from "../../assets/userTestimonial5.jpeg";
import user6 from "../../assets/user6.webp";
import user7 from "../../assets/user7.webp";
import Slider from "react-slick";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Profile.css";
import { Helmet } from "react-helmet";

import ReactPaginate from "react-paginate";
import Following from "./Following/Following";
import RechargeHistory from "./RechargeHistory/RechargeHistory";
import PrimaryLoading from "../Loaders/PrimaryLoading";
import BirthChart from "./BirthChart/BirthChart";
// import 'react-paginate/dist/react-paginate.css';
import iconnotFollwing from "./Follwing.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";
import { useDispatch } from "react-redux";
import ConsultationHistory from "./Consultation_History/ConsultationHistory";
import SearchBarTop from "../Newhomepage/SearchBarTop/SearchBarTop";

export default function ProfilePage() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showComponent, setShowComponent] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.loggedInUserDetails.data);

  useEffect(() => {
    dispatch(fetchLoggedInUserDetails(localStorage.getItem("loginToken")));
    // setChecked();
  }, []);

  const [checked, setChecked] = useState(true);
  useEffect(() => {
    setChecked(userDetails?.userDetails?.smsPermission);
  }, [userDetails]);

  function convertIst(date) {
    const utcDate = new Date(date);
    const istDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDate.toLocaleString();
  }

  const [astroData, setAstroData] = useState(null);
  const loginToken = localStorage.getItem("loginToken");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          "https://data.gurucool.life/api/v1/user/getUserFollowing",
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        );

        // Save the response data in the state
        console.log("Data..............", response);
        setAstroData(response.data);
        // console.log(userDetails.userDetails.smsPermission);
        //
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  // const [astroFirstName, setAstrofirstName] = useState([])
  // const [astroLastName, setAstroLastName] = useState([])
  // const [avatar, setAvatarName] = useState([])

  useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 800);
  });

  const status = useSelector((state) => state.loggedInUserDetails.status);
  //
  // console.log(userDetails);

  const token = localStorage.getItem("loginToken"); // Replace 'your_auth_token' with your actual token
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleSms = () => {
    const token = localStorage.getItem("loginToken");
    console.log(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(
        `https://data.gurucool.life/api/v1/user/smsPermission?smsPermission=${!checked}`,
        {},
        config
      )
      .then((response) => {
        // console.log("Api call......")
        // console.log(response);
        setChecked(response?.data?.userDetails?.smsPermission);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [userId, setUserId] = useState("");
  const [astroId, setAstroId] = useState("");
  const [purchaseId, setPurchaseId] = useState("");

  const handleIds = (item) => {
    setAstroId(item.guru);
    setUserId(item.user);
    setPurchaseId(item._id);
  };

  const reviews = [
    {
      img: user1,
      name: "Rahul Satyawali",
      review:
        " Gurucool provides the best and most affordable online astrology services. I have used so many different platforms but always ended up wasting my time and money, but with Gurucool, I've always had a wonderful experience.",
      date: "18/03/2023",
    },

    {
      img: user2,
      name: "Sanveen Kohli",
      review:
        " I simply don't have enough words to express how grateful I am to the Gurucool platform. My astrologer at Gurucool helped me come out of the darkest phase of my life. She patiently listened to all my issues, read my birth chart, and also gave me some remedies that helped calm my mind. Gradually, my life came back on track and I started attracting prosperity, peace, and love. She's truly an angel.",
      date: "12/02/2023",
    },
    {
      img: user3,
      name: "Lalit Negi",
      review:
        " My business had been experiencing losses for 6 consecutive months but I had no clue as to why it was happening. Then my wife who is a firm believer in astrology convinced me to consult an astrologer on Gurucool. To my surprise, he was able to tell me things that nobody except my family knew and then gave me some remedies. I started seeing results within 3-4 weeks, my financial situation started improving significantly and now my business is booming with god's grace and Gurucool's help.",
      date: "14/02/2023",
    },
    {
      img: user4,
      name: "Shivani Kutiyal",
      review:
        " Gurucool helped me find the love of my life😍. A year ago I was in an extremely toxic relationship. I had come to a point of hating myself and my life. Then one day I decided to consult a tarot reader on Gurucool and I also took healing sessions from her. Within a year, I manifested a guy who truly loves, respects, and takes care of me.",
      date: "17/02/2023",
    },
    {
      img: user5,
      name: "Prashant Rawat",
      review:
        " I come from a family who consult astrologers before doing any important thing but nowadays it's hard to find a good astrologer. I was skeptical when I first heard about the concept of online astrology, but because of the free live sessions on the Gurucool platform, I found a good astrologer. Now I consult him especially for muhurtas every time before doing anything important.",
      date: "19/03/2023",
    },
    {
      img: user6,
      name: "Jhalak Chaturvedi",
      review:
        " My entire family uses the Gurucool platform because it's so convenient and pocket friendly. I love attending the free live sessions and consulting on video calls . My husband is more comfortable with the chat consultation and my super busy daughter gets her consultation mostly through video reports so that she can watch the video report whenever she has time.",
      date: "29/03/2023",
    },
    {
      img: user7,
      name: "Yash Agarwal",
      review:
        " I have been using the Gurucool platform since the beginning because of the good quality service at a really affordable price. I was facing issues in my marital life and thanks to the Gurucool experts and the highly effective remedies, now my wife and I have a beautiful life together.",
      date: "09/03/2023",
    },
  ];
  {
    astroData?.data.map((data) => {
      const astroFirstName = data.user?.firstName;
      const astroLastName = data.user?.lastName;
      const avatar = data.user?.avatar;
    });
  }
  const options = {
    loop: false,
    // controlsClass: 'owl-controls',
    // center: true,
    nav: true,
    dots: false,
    rewind: false,
    slideBy: 4,
    // margin: 18,
    // dotsEach: true,
    // autoplay: true,
    // autoplayTimeout: 10000,
    // autoplayHoverPause: false,
    responsive: {
      0: {
        items: 3,
        // margin: 8,
        // nav: false,
        // dots: true,
        // dotsEach: true,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const [getRemedies, setGetRemedies] = useState("");

  const handleRemedies = (remedy) => {
    setGetRemedies(remedy);
  };

  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();

  const [visibleItems, setVisibleItems] = useState(3);

  const recharge = [
    {
      id: 1,
      status: "Completed",
      txn: "A19102711000032185",
      time: "15:30  12/05/2023",
      method: "Paytm",
      subTotal: "200",
      tax: "20",
      total: "230.66",
    },
    {
      id: 2,
      status: "Completed",
      txn: "A19102711000032185",
      time: "15:30  12/05/2023",
      method: "Paytm",
      subTotal: "200",
      tax: "20",
      total: "230.66",
    },
    {
      id: 3,
      status: "Completed",
      txn: "A19102711000032185",
      time: "15:30  12/05/2023",
      method: "Paytm",
      subTotal: "200",
      tax: "20",
      total: "230.66",
    },
    {
      id: 4,
      status: "Completed",
      txn: "A19102711000032185",
      time: "15:30  12/05/2023",
      method: "Paytm",
      subTotal: "200",
      tax: "20",
      total: "230.66",
    },
  ];

  // const calculateGST = (amount) => {
  //   const subtotal = amount / 1.18;
  //   const gst = amount - subtotal;
  //   return { subtotal, gst };
  // };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const itemsToDisplay = userDetails?.userDetails?.purchases.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // console.log("Items to Display>>>>>>>>", itemsToDisplay)
  //

  // const rechargeUpiItemsToDisplay =
  //   userDetails?.userDetails?.paymentsDetails.slice(
  //     indexOfFirstItem,
  //     indexOfLastItem
  //   );

  const filterConsultationType = (type) => {
    return userDetails?.userDetails?.purchases.filter(
      (item) => item.consultationType === type
    );
  };

  const callItemsToDisplay = filterConsultationType("Call")?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleNavigate = () => {
    navigate("/astrologers");
  };
  //
  //
  console.log("UserDetauils?>>>>>>>>>.", userDetails);

  return (
    <>
      <Helmet>
        <title>Gurucool || Profile</title>
      </Helmet>
      {status === "succeeded" ? (
        userDetails.user && userDetails.user.role === "astrologer" ? (
          <AstrologerProfile userDetails={userDetails} />
        ) : (
          <>
            <Modal isOpen={isOpen} onClose={onClose} size={"md"} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  <FeedbackModal
                    userId={userId}
                    astroId={astroId}
                    purchaseId={purchaseId}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>

            {/* Modal For Remedies */}
            <Modal
              isOpen={isOpenModal1}
              onClose={onCloseModal1}
              size={{ base: "xs", md: "sm" }}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign={"center"}>Your Remedies</ModalHeader>
                <ModalCloseButton
                  _hover={{ color: "orange.500", background: "orange.100" }}
                />
                <Divider mt={0} w={"90%"} color={"gray.300"} mx={"auto"} />
                <ModalBody>
                  <Text>
                    {getRemedies
                      ? getRemedies
                      : "Astrologer haven't submited remedy yet..."}
                  </Text>
                </ModalBody>
              </ModalContent>
            </Modal>
            <SearchBarTop/>
            <section
            // style={{ backgroundColor: "#eee" }}
            >
              <MDBContainer className="py-5">
                <MDBRow>
                  <Container maxW={"6xl"}>
                    <MDBCol lg="12">
                      <MDBCard
                        className="mb-4"
                        style={{
                          border: "2px solid #FFC293",
                          borderRadius: "18px",
                        }}
                      >
                        <UserDetails />
                      </MDBCard>
                    </MDBCol>
                  </Container>
                  {/* <Container maxW={"6xl"}>
                    <Box
                      h={{md: "381px"}}
                      w={"100%"}
                      borderRadius={"23px"}
                      border={"1px solid orange"}
                    ></Box>
                  </Container> */}

                  <Container maxW={"6xl"} mb={{ md: 6, base: 8 }}>
                    <Accordion
                      allowMultiple
                      w={"100%"}
                      borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                      border={"2px solid #FFC293"}
                    >
                      <AccordionItem border={"none"}>
                        <h2 style={{ marginBottom: "0px" }}>
                          <AccordionButton
                            justifyContent={"center"}
                            borderRadius={{
                              md: "18px",
                              sm: "8px",
                              base: "8px",
                            }}
                          >
                            <Heading
                              as="h3"
                              textAlign={"center"}
                              fontSize={{ md: "24px", base: "18px" }}
                              fontWeight={"500"}
                              ml={{ md: 4, base: 2 }}
                              mb={0}
                            >
                              Birth Chart
                            </Heading>
                            {/* <Flex justifyContent={"flex-end"}> */}
                            <AccordionIcon />
                            {/* </Flex> */}
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} px={0}>
                          <BirthChart userDetails={userDetails} />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Container>

                  {/* astrologers list */}

                  {/* ............................... */}
                  <MDBCol lg="12">
                    <MDBRow>
                      <Container
                        maxW={"6xl"}
                        mb={{ md: 6, base: 8 }}
                        borderRadius={{ md: "23px", sm: "8px", base: "8px" }}
                      >
                        <Accordion
                          allowMultiple
                          w={"100%"}
                          borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                          border={"2px solid #FFC293"}
                        >
                          <AccordionItem border={"none"}>
                            <h2 style={{ marginBottom: "0px" }}>
                              <AccordionButton
                                justifyContent={"center"}
                                borderRadius={{
                                  md: "18px",
                                  sm: "8px",
                                  base: "8px",
                                }}
                                // border={"2px solid #FFC293"}
                              >
                                <Heading
                                  as="h3"
                                  textAlign={"center"}
                                  fontSize={{ md: "24px", base: "18px" }}
                                  fontWeight={"500"}
                                  ml={{ md: 4, base: 2 }}
                                  mb={0}
                                >
                                  Following Astrologers
                                </Heading>
                                {/* <Flex justifyContent={"flex-end"}> */}
                                <AccordionIcon />
                                {/* </Flex> */}
                              </AccordionButton>
                            </h2>
                            <AccordionPanel
                              pb={4}
                              px={0}
                              h={{ base: "276px", sm: "330px" }}
                            >
                              <MDBCol md="12" className="pb-3">
                                <MDBCard
                                  className="mb-4 mb-md-0"
                                  style={{
                                    // border: "2px solid #FFC293",
                                    borderRadius: "18px",
                                    // padding:'24px',
                                    fontWeight: "500",
                                  }}
                                >
                                  <MDBCardBody>
                                    {/* astroData.data.length */}
                                    <MDBCardText
                                      style={{
                                        // border: "2px solid red",
                                        textAlign: "center",
                                        // fontFamily: "Poppins",
                                        fontWeight: "500",
                                        fontSize: "24px",
                                      }}
                                    >
                                      {" "}
                                      {/* Following Astrologers */}
                                    </MDBCardText>
                                    {/* <MDBTable responsive style={{marginBottom:'0 !important', display:"flex", justifyContent:'center'}}> */}
                                    {astroData?.data?.length === 0 ? (
                                      <Box>
                                        <Image
                                          margin={"auto"}
                                          src={iconnotFollwing}
                                          alt={"icon"}
                                        />
                                        <Box
                                          color={"#A0A0A0"}
                                          textAlign={"center"}
                                          width={{
                                            base: "100%",
                                            sm: "80%",
                                            md: "50%",
                                          }}
                                          m={"auto"}
                                          // border={"2px solid red"}
                                        >
                                          {" "}
                                          You are not following any astrologers
                                          at the moment.You will be notified
                                          once the following astrologer online
                                        </Box>
                                        <Box
                                          width={"100%"}
                                          display={"flex"}
                                          justifyContent={"center"}
                                          alignItems={"center"}
                                        >
                                          <Button
                                            color={"white"}
                                            bg={"#FF4C00"}
                                            borderRadius={"6px"}
                                            fontFamily={"Poppins"}
                                            width={"244px"}
                                            height={"52px"}
                                            mt={"15px"}
                                            onClick={handleNavigate}
                                          >
                                            Follow Now
                                          </Button>
                                        </Box>
                                      </Box>
                                    ) : (
                                      <>
                                        <Flex
                                          id="following-list"
                                          direction={{ md: "row", base: "row" }}
                                          justifyContent={"center"}
                                          h={{ base: "130px", md: "auto" }}
                                        >
                                          <OwlCarousel
                                            className="owl-theme"
                                            loop
                                            {...options}
                                          >
                                            <Following astroData={astroData} />
                                          </OwlCarousel>
                                        </Flex>
                                      </>
                                    )}
                                    {/* </MDBTable> */}
                                    <Box
                                      bg={""}
                                      display={"flex"}
                                      justifyContent={{
                                        base: "space-between",
                                        sm: "center",
                                      }}
                                      border={""}
                                      alignItems={{
                                        base: "center",
                                        sm: "baseline",
                                      }}
                                      pt={"5px"}
                                      mb={{ base: "-35px", sm: "-12px" }}
                                      pb={{ base: "14px", sm: "0px" }}
                                    >
                                      <Checkbox
                                        colorScheme={"orange"}
                                        isChecked={checked}
                                        onChange={handleSms}
                                        mr={"10px"}
                                        mt={{ base: "-22px", sm: "10px" }}
                                        size="lg"
                                        border={""}
                                      ></Checkbox>
                                      <Text
                                        fontWeight={"500"}
                                        fontSize={{ base: "12px", sm: "17px" }}
                                        color={"#8F8F8F"}
                                      >
                                        {" "}
                                        Get Instant SMS Alerts When Your
                                        Favorite Astrologer Goes Online!
                                      </Text>
                                    </Box>
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Container>

                      {/* Recharge History Card New UI */}
                      <Container
                        maxW={"6xl"}
                        mb={{ md: 6, base: 8 }}
                        borderRadius={{ md: "23px", sm: "8px", base: "8px" }}
                      >
                        <Accordion
                          allowMultiple
                          w={"100%"}
                          borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                          border={"2px solid #FFC293"}
                        >
                          <AccordionItem border={"none"}>
                            <h2 style={{ marginBottom: "0px" }}>
                              <AccordionButton
                                justifyContent={"center"}
                                borderRadius={{
                                  md: "18px",
                                  sm: "8px",
                                  base: "8px",
                                }}
                              >
                                <Heading
                                  as="h3"
                                  textAlign={"center"}
                                  fontSize={{ md: "24px", base: "18px" }}
                                  fontWeight={"500"}
                                  ml={{ md: 4, base: 2 }}
                                  mb={0}
                                >
                                  Recharge History
                                </Heading>
                                {/* <Flex justifyContent={"flex-end"}> */}
                                <AccordionIcon />
                                {/* </Flex> */}
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} px={0}>
                              <RechargeHistory userDetails={userDetails} />
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Container>

                      {/* New Consultation History Card UI */}
                      <ConsultationHistory userDetails={userDetails} />
                      <Container maxW={"6xl"} mb={{base: "70px", md: "unset"}}>
                        <Accordion
                          allowMultiple
                          w={"100%"}
                          borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                          border={"2px solid #FFC293"}
                        >
                          <AccordionItem border={"none"}>
                            <h2 style={{ marginBottom: "0px" }}>
                              <AccordionButton
                                justifyContent={"center"}
                                borderRadius={{
                                  md: "23px",
                                  sm: "8px",
                                  base: "8px",
                                }}
                              >
                                <Heading
                                  as="h3"
                                  textAlign={"center"}
                                  fontSize={{ md: "24px", base: "18px" }}
                                  fontWeight={"500"}
                                  mb={0}
                                >
                                  Consultation History
                                </Heading>
                                {/* <Flex justifyContent={"flex-end"}> */}
                                <AccordionIcon />
                                {/* </Flex> */}
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} px={0}>
                              <Box
                                // h={{ md: "381px" }}
                                w={"100%"}
                                // borderRadius={"23px"}
                                // border={"2px solid #FFC293"}
                                p={{ md: "34px", base: "8px" }}
                                bg={"#FAFAFA"}
                              >
                                <Tabs
                                  variant={"solid-rounded"}
                                  colorScheme="green"
                                  mx={"auto"}
                                  mt={{ md: "32px", base: "19px" }}
                                >
                                  <TabList
                                    justifyContent={"center"}
                                    border={"none"}
                                  >
                                    <Tab
                                      _selected={{
                                        bg: "#FF5E1A",
                                        color: "white",
                                      }}
                                      bg={"#FFE1CA"}
                                      borderRadius={"6px 0 0 6px"}
                                      w={{ md: "92px", base: "92px" }}
                                      fontSize={{ md: "md", base: "sm" }}
                                    >
                                      All
                                    </Tab>
                                    <Tab
                                      _selected={{
                                        bg: "#FF5E1A",
                                        color: "white",
                                      }}
                                      bg={"#FFE1CA"}
                                      borderRadius={"0 6px 6px 0"}
                                      w={{ md: "92px", base: "92px" }}
                                      fontSize={{ md: "md", base: "sm" }}
                                    >
                                      Call
                                    </Tab>
                                    {/* <Tab
                                _selected={{ bg: "#FF5E1A", color: "white" }}
                                bg={"#FFE1CA"}
                                borderRadius={"0 6px 6px 0"}
                                w={{ md: "92px", base: "92px" }}
                                fontSize={{ md: "md", base: "sm" }}
                              >
                                Pvt. Live
                              </Tab> */}
                                  </TabList>
                                  <TabPanels>
                                    <TabPanel px={0}>
                                      {/* {userDetails?.userDetails?.purchases ? ( */}

                                      {itemsToDisplay.map((item) => {
                                        return (
                                          <Stack
                                            direction={{
                                              md: "row",
                                              base: "column",
                                            }}
                                            p={{ md: "16px" }}
                                            spacing={{ md: "119px" }}
                                            bg={{ md: "white", base: "unset" }}
                                            borderRadius={"6px"}
                                            // justifyContent={{md: "center"}}
                                            // mb={2}
                                            mt={{ base: "28px", md: "32px" }}
                                          >
                                            {/* Consultation status, astrologer name & Remedies */}
                                            <VStack
                                              spacing={{ md: "18px" }}
                                              alignItems={"flex-start"}
                                              w={{ md: "272px" }}
                                            >
                                              {item.callStatus ===
                                              "completed" ? (
                                                <Text
                                                  fontSize={{ md: "19px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                  color={"#38CC24"}
                                                  display={{
                                                    md: "block",
                                                    base: "none",
                                                  }}
                                                >
                                                  Completed
                                                </Text>
                                              ) : (
                                                <Text
                                                  fontSize={{ md: "19px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                  color={"#FF2D2D"}
                                                  display={{
                                                    md: "block",
                                                    base: "none",
                                                  }}
                                                >
                                                  Failed
                                                </Text>
                                              )}

                                              {/* For mobile Responsive */}
                                              <HStack
                                                display={{
                                                  md: "none",
                                                  base: "flex",
                                                }}
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                                w={"100%"}
                                              >
                                                {item.callStatus ===
                                                "completed" ? (
                                                  <Text
                                                    fontSize={{
                                                      md: "19px",
                                                      base: "18px",
                                                    }}
                                                    fontWeight={"600"}
                                                    mb={0}
                                                    color={"#38CC24"}
                                                  >
                                                    Completed
                                                  </Text>
                                                ) : (
                                                  <Text
                                                    fontSize={{
                                                      md: "19px",
                                                      base: "18px",
                                                    }}
                                                    fontWeight={"600"}
                                                    mb={0}
                                                    color={"#FF2D2D"}
                                                  >
                                                    Failed
                                                  </Text>
                                                )}

                                                <Text
                                                  fontSize={"10px"}
                                                  fontWeight={"500"}
                                                  mb={0}
                                                  // color={"#38CC24"}
                                                >
                                                  ID：{item._id}
                                                </Text>
                                              </HStack>
                                              {/* Divider For mobile Responsive */}
                                              <Divider
                                                my={"4px"}
                                                display={{
                                                  base: "block",
                                                  md: "none",
                                                }}
                                                color={"orange.200"}
                                              />
                                              <HStack
                                                alignItems={"center"}
                                                spacing={{ md: "23px" }}
                                                justifyContent={{
                                                  base: "space-between",
                                                  md: "flex-start",
                                                }}
                                                w={{ base: "100%", md: "auto" }}
                                                mt={{
                                                  base: "12px",
                                                  md: "unset",
                                                }}
                                              >
                                                <Text
                                                  fontSize={{ md: "20px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                >
                                                  {item.guruName}
                                                </Text>
                                                {/* <NavLink to={`/astrologer/${item.user.firstName}${item.user.lastName}?id=${props.currElem.user.guru}`}> */}
                                                <Button
                                                  variant={"outline"}
                                                  fontSize={{ md: "14px" }}
                                                  onClick={() => {
                                                    props.callClicked();
                                                    localStorage.setItem(
                                                      "guruToken",
                                                      props.currElem.user.guru
                                                    );
                                                  }}
                                                >
                                                  Consult
                                                </Button>
                                                {/* </NavLink> */}
                                              </HStack>
                                              <Button
                                                variant={
                                                  item.callStatus ===
                                                    "completed" && item.Remedies
                                                    ? "solid"
                                                    : "outline"
                                                }
                                                fontSize={{ md: "14px" }}
                                                display={{
                                                  base: "none",
                                                  md: "inline-flex",
                                                }}
                                                colorScheme={
                                                  item.callStatus ===
                                                    "completed" && item.Remedies
                                                    ? "orange"
                                                    : "gray"
                                                }
                                                onClick={() => {
                                                  handleRemedies(item.Remedies);
                                                  onOpenModal1();
                                                }}
                                              >
                                                Remedies
                                              </Button>
                                            </VStack>
                                            {/* Consultation Time, Duration, type and Money spent */}
                                            <VStack
                                              spacing={{
                                                md: "6px",
                                                base: "3px",
                                              }}
                                              alignItems={"flex-start"}
                                              w={{ md: "361px" }}
                                              justifyContent={"center"}
                                            >
                                              <HStack
                                                justifyContent={"space-between"}
                                                w={"100%"}
                                              >
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  Date & Time
                                                </Text>
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  {convertIst(item.createdAt)}
                                                </Text>
                                              </HStack>
                                              <HStack
                                                justifyContent={"space-between"}
                                                w={"100%"}
                                              >
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  Duration
                                                </Text>
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  {item.timeDuration
                                                    ? parseFloat(
                                                        item.timeDuration
                                                      ).toFixed(2)
                                                    : 0}{" "}
                                                  min
                                                </Text>
                                              </HStack>
                                              <HStack
                                                justifyContent={"space-between"}
                                                w={"100%"}
                                              >
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  Consultation Type
                                                </Text>
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  {item.consultationType}
                                                </Text>
                                              </HStack>
                                              {/* Divider for Mobile Responsive */}
                                              <Divider
                                                my={"4px"}
                                                display={{
                                                  base: "block",
                                                  md: "none",
                                                }}
                                                color={"orange.200"}
                                              />
                                              <HStack
                                                justifyContent={"space-between"}
                                                w={"100%"}
                                                mt={{
                                                  base: "8px",
                                                  md: "unset",
                                                }}
                                              >
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                >
                                                  Money Spent
                                                </Text>
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                >
                                                  ₹
                                                  {item.netAmount
                                                    ? Math.round(item.netAmount)
                                                    : 0}
                                                </Text>
                                              </HStack>
                                            </VStack>
                                            {/* Consultation ID, Recording and Share Feedback */}
                                            <VStack
                                              spacing={{ md: "6px" }}
                                              alignItems={"flex-start"}
                                              // w={{ md: "361px" }}
                                              justifyContent={"space-between"}
                                            >
                                              <Text
                                                fontSize={{ md: "12px" }}
                                                fontWeight={"400"}
                                                mb={0}
                                                display={{
                                                  base: "none",
                                                  md: "block",
                                                }}
                                              >
                                                ID：{item._id}
                                              </Text>
                                              <Stack
                                                w={{ base: "100%", md: "auto" }}
                                                direction={{
                                                  base: "row",
                                                  md: "column",
                                                }}
                                                justifyContent={{
                                                  base: "space-between",
                                                  md: "unset",
                                                }}
                                                spacing={{ md: "11px" }}
                                                mt={{
                                                  base: "16px",
                                                  md: "unset",
                                                }}
                                              >
                                                {item.callStatus ===
                                                "completed" ? (
                                                  <Button
                                                    variant={"outline"}
                                                    fontSize={{ md: "14px" }}
                                                    w={{
                                                      md: "188px",
                                                      base: "105px",
                                                    }}
                                                    onClick={() =>
                                                      window.open(
                                                        item.RecordingUrl,
                                                        "_blank"
                                                      )
                                                    }
                                                  >
                                                    Recording
                                                  </Button>
                                                ) : (
                                                  <Button
                                                    variant={"outline"}
                                                    fontSize={{ md: "14px" }}
                                                    w={{
                                                      md: "188px",
                                                      base: "105px",
                                                    }}
                                                  >
                                                    No Recording
                                                  </Button>
                                                )}
                                                <Button
                                                  variant={
                                                    item.callStatus ===
                                                      "completed" &&
                                                    item.Remedies
                                                      ? "solid"
                                                      : "outline"
                                                  }
                                                  fontSize={{ md: "14px" }}
                                                  w={{
                                                    md: "188px",
                                                    base: "105px",
                                                  }}
                                                  display={{
                                                    base: "inline-flex",
                                                    md: "none",
                                                  }}
                                                  colorScheme={
                                                    item.callStatus ===
                                                      "completed" &&
                                                    item.Remedies
                                                      ? "orange"
                                                      : "gray"
                                                  }
                                                  onClick={() => {
                                                    handleRemedies(
                                                      item.Remedies
                                                    );
                                                    onOpenModal1();
                                                  }}
                                                >
                                                  Remedies
                                                </Button>
                                                {item.feedback ? (
                                                  <Text
                                                    color={"orange.500"}
                                                    mb={0}
                                                    display={{
                                                      base: "none",
                                                      md: "block",
                                                    }}
                                                  >
                                                    Submitted
                                                  </Text>
                                                ) : (
                                                  <Button
                                                    variant={"outline"}
                                                    fontSize={{ md: "14px" }}
                                                    w={{ md: "188px" }}
                                                    display={{
                                                      base: "none",
                                                      md: "inline-flex",
                                                    }}
                                                    onClick={() => {
                                                      handleIds(item);
                                                      onOpen();
                                                    }}
                                                  >
                                                    Share Feedback
                                                  </Button>
                                                )}
                                              </Stack>
                                              <Flex
                                                display={{
                                                  base: "flex",
                                                  md: "none",
                                                }}
                                                justifyContent={"center"}
                                                w={"100%"}
                                                mt={"20px"}
                                              >
                                                {item.feedback ? (
                                                  <Text
                                                    color={"orange.500"}
                                                    mb={0}
                                                  >
                                                    Submitted
                                                  </Text>
                                                ) : (
                                                  <Button
                                                    variant={"outline"}
                                                    fontSize={{ md: "14px" }}
                                                    w={{
                                                      md: "188px",
                                                      base: "251px",
                                                    }}
                                                    mx={"auto"}
                                                    // display={{
                                                    //   base: "none",
                                                    //   md: "inline-flex",
                                                    // }}
                                                    onClick={() => {
                                                      handleIds(item);
                                                      onOpen();
                                                    }}
                                                  >
                                                    Share Feedback
                                                  </Button>
                                                )}
                                              </Flex>
                                            </VStack>
                                            <Divider
                                              display={{
                                                base: "block",
                                                md: "none",
                                              }}
                                              color={"orange.300"}
                                              style={{ margin: "16px 0px" }}
                                            />
                                          </Stack>
                                        );
                                      })}
                                      <ReactPaginate
                                        previousLabel="<< Prev"
                                        nextLabel="Next >>"
                                        pageCount={Math.ceil(
                                          userDetails?.userDetails?.purchases
                                            .length / itemsPerPage
                                        )}
                                        onPageChange={handlePageChange}
                                        containerClassName="pagination"
                                        previousLinkClassName="pagination__link"
                                        nextLinkClassName="pagination__link"
                                        disabledClassName="pagination__link--disabled"
                                        activeClassName="pagination__link--active"
                                        // style={paginationStyles}
                                      />
                                      {/* ) : (
                                  <Center>No previous history</Center>
                                )} */}
                                      {/* <Divider
                                        my={"32px"}
                                        display={{ base: "block", md: "none" }}
                                        color={"orange.200"}
                                      /> */}
                                    </TabPanel>
                                    <TabPanel px={0}>
                                      {callItemsToDisplay.map((item) => {
                                        return (
                                          <Stack
                                            direction={{
                                              md: "row",
                                              base: "column",
                                            }}
                                            p={{ md: "16px" }}
                                            spacing={{ md: "119px" }}
                                            bg={{ md: "white", base: "unset" }}
                                            borderRadius={"6px"}
                                            // justifyContent={{md: "center"}}
                                            // mb={2}
                                            mt={{ base: "28px", md: "32px" }}
                                          >
                                            {/* Consultation status, astrologer name & Remedies */}
                                            <VStack
                                              spacing={{ md: "18px" }}
                                              alignItems={"flex-start"}
                                              w={{ md: "272px" }}
                                            >
                                              {item.callStatus ===
                                              "completed" ? (
                                                <Text
                                                  fontSize={{ md: "19px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                  color={"#38CC24"}
                                                  display={{
                                                    md: "block",
                                                    base: "none",
                                                  }}
                                                >
                                                  Completed
                                                </Text>
                                              ) : (
                                                <Text
                                                  fontSize={{ md: "19px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                  color={"#FF2D2D"}
                                                  display={{
                                                    md: "block",
                                                    base: "none",
                                                  }}
                                                >
                                                  Failed
                                                </Text>
                                              )}

                                              {/* For mobile Responsive */}
                                              <HStack
                                                display={{
                                                  md: "none",
                                                  base: "flex",
                                                }}
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                                w={"100%"}
                                              >
                                                {item.callStatus ===
                                                "completed" ? (
                                                  <Text
                                                    fontSize={{
                                                      md: "19px",
                                                      base: "18px",
                                                    }}
                                                    fontWeight={"600"}
                                                    mb={0}
                                                    color={"#38CC24"}
                                                  >
                                                    Completed
                                                  </Text>
                                                ) : (
                                                  <Text
                                                    fontSize={{
                                                      md: "19px",
                                                      base: "18px",
                                                    }}
                                                    fontWeight={"600"}
                                                    mb={0}
                                                    color={"#FF2D2D"}
                                                  >
                                                    Failed
                                                  </Text>
                                                )}

                                                <Text
                                                  fontSize={"10px"}
                                                  fontWeight={"500"}
                                                  mb={0}
                                                  // color={"#38CC24"}
                                                >
                                                  ID：{item._id}
                                                </Text>
                                              </HStack>
                                              {/* Divider For mobile Responsive */}
                                              <Divider
                                                my={"4px"}
                                                display={{
                                                  base: "block",
                                                  md: "none",
                                                }}
                                                color={"orange.200"}
                                              />
                                              <HStack
                                                alignItems={"center"}
                                                spacing={{ md: "23px" }}
                                                justifyContent={{
                                                  base: "space-between",
                                                  md: "flex-start",
                                                }}
                                                w={{ base: "100%", md: "auto" }}
                                                mt={{
                                                  base: "12px",
                                                  md: "unset",
                                                }}
                                              >
                                                <Text
                                                  fontSize={{ md: "20px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                >
                                                  {item.guruName}
                                                </Text>
                                                <Button
                                                  variant={"outline"}
                                                  fontSize={{ md: "14px" }}
                                                >
                                                  Consult
                                                </Button>
                                              </HStack>
                                              <Button
                                                variant={
                                                  item.callStatus ===
                                                    "completed" && item.Remedies
                                                    ? "solid"
                                                    : "outline"
                                                }
                                                fontSize={{ md: "14px" }}
                                                display={{
                                                  base: "none",
                                                  md: "inline-flex",
                                                }}
                                                colorScheme={
                                                  item.callStatus ===
                                                    "completed" && item.Remedies
                                                    ? "orange"
                                                    : "gray"
                                                }
                                                onClick={() => {
                                                  handleRemedies(item.Remedies);
                                                  onOpenModal1();
                                                }}
                                              >
                                                Remedies
                                              </Button>
                                            </VStack>
                                            {/* Consultation Time, Duration, type and Money spent */}
                                            <VStack
                                              spacing={{
                                                md: "6px",
                                                base: "3px",
                                              }}
                                              alignItems={"flex-start"}
                                              w={{ md: "361px" }}
                                              justifyContent={"center"}
                                            >
                                              <HStack
                                                justifyContent={"space-between"}
                                                w={"100%"}
                                              >
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  Date & Time
                                                </Text>
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  {convertIst(item.createdAt)}
                                                </Text>
                                              </HStack>
                                              <HStack
                                                justifyContent={"space-between"}
                                                w={"100%"}
                                              >
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  Duration
                                                </Text>
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  {item.timeDuration
                                                    ? parseFloat(
                                                        item.timeDuration
                                                      ).toFixed(2)
                                                    : 0}{" "}
                                                  min
                                                </Text>
                                              </HStack>
                                              <HStack
                                                justifyContent={"space-between"}
                                                w={"100%"}
                                              >
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  Consultation Type
                                                </Text>
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"400"}
                                                  mb={0}
                                                >
                                                  {item.consultationType}
                                                </Text>
                                              </HStack>
                                              {/* Divider for Mobile Responsive */}
                                              <Divider
                                                my={"4px"}
                                                display={{
                                                  base: "block",
                                                  md: "none",
                                                }}
                                                color={"orange.200"}
                                              />
                                              <HStack
                                                justifyContent={"space-between"}
                                                w={"100%"}
                                                mt={{
                                                  base: "8px",
                                                  md: "unset",
                                                }}
                                              >
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                >
                                                  Money Spent
                                                </Text>
                                                <Text
                                                  fontSize={{ md: "16px" }}
                                                  fontWeight={"600"}
                                                  mb={0}
                                                >
                                                  ₹{" "}
                                                  {item.netAmount
                                                    ? Math.round(item.netAmount)
                                                    : 0}
                                                </Text>
                                              </HStack>
                                            </VStack>
                                            {/* Consultation ID, Recording and Share Feedback */}
                                            <VStack
                                              spacing={{ md: "6px" }}
                                              alignItems={"flex-start"}
                                              // w={{ md: "361px" }}
                                              justifyContent={"space-between"}
                                            >
                                              <Text
                                                fontSize={{ md: "12px" }}
                                                fontWeight={"400"}
                                                mb={0}
                                                display={{
                                                  base: "none",
                                                  md: "block",
                                                }}
                                              >
                                                ID：{item._id}
                                              </Text>
                                              <Stack
                                                w={{ base: "100%", md: "auto" }}
                                                direction={{
                                                  base: "row",
                                                  md: "column",
                                                }}
                                                justifyContent={{
                                                  base: "space-between",
                                                  md: "unset",
                                                }}
                                                spacing={{ md: "11px" }}
                                                mt={{
                                                  base: "16px",
                                                  md: "unset",
                                                }}
                                              >
                                                {item.callStatus ===
                                                "completed" ? (
                                                  <Button
                                                    variant={"outline"}
                                                    fontSize={{ md: "14px" }}
                                                    w={{
                                                      md: "188px",
                                                      base: "105px",
                                                    }}
                                                    onClick={() =>
                                                      window.open(
                                                        item.RecordingUrl,
                                                        "_blank"
                                                      )
                                                    }
                                                  >
                                                    Recording
                                                  </Button>
                                                ) : (
                                                  <Button
                                                    variant={"outline"}
                                                    fontSize={{ md: "14px" }}
                                                    w={{
                                                      md: "188px",
                                                      base: "105px",
                                                    }}
                                                  >
                                                    No Recording
                                                  </Button>
                                                )}
                                                <Button
                                                  variant={
                                                    item.callStatus ===
                                                      "completed" &&
                                                    item.Remedies
                                                      ? "solid"
                                                      : "outline"
                                                  }
                                                  fontSize={{ md: "14px" }}
                                                  w={{
                                                    md: "188px",
                                                    base: "105px",
                                                  }}
                                                  display={{
                                                    base: "inline-flex",
                                                    md: "none",
                                                  }}
                                                  colorScheme={
                                                    item.callStatus ===
                                                      "completed" &&
                                                    item.Remedies
                                                      ? "orange"
                                                      : "gray"
                                                  }
                                                  onClick={() => {
                                                    handleRemedies(
                                                      item.Remedies
                                                    );
                                                    onOpenModal1();
                                                  }}
                                                >
                                                  Remedies
                                                </Button>
                                                {item.feedback ? (
                                                  <Text
                                                    color={"orange.500"}
                                                    mb={0}
                                                    display={{
                                                      base: "none",
                                                      md: "block",
                                                    }}
                                                  >
                                                    Submitted
                                                  </Text>
                                                ) : (
                                                  <Button
                                                    variant={"outline"}
                                                    fontSize={{ md: "14px" }}
                                                    w={{ md: "188px" }}
                                                    display={{
                                                      base: "none",
                                                      md: "inline-flex",
                                                    }}
                                                    onClick={() => {
                                                      handleIds(item);
                                                      onOpen();
                                                    }}
                                                  >
                                                    Share Feedback
                                                  </Button>
                                                )}
                                              </Stack>
                                              <Flex
                                                display={{
                                                  base: "flex",
                                                  md: "none",
                                                }}
                                                justifyContent={"center"}
                                                w={"100%"}
                                                mt={"20px"}
                                              >
                                                {item.feedback ? (
                                                  <Text
                                                    color={"orange.500"}
                                                    mb={0}
                                                  >
                                                    Submitted
                                                  </Text>
                                                ) : (
                                                  <Button
                                                    variant={"outline"}
                                                    fontSize={{ md: "14px" }}
                                                    w={{
                                                      md: "188px",
                                                      base: "251px",
                                                    }}
                                                    mx={"auto"}
                                                    // display={{
                                                    //   base: "none",
                                                    //   md: "inline-flex",
                                                    // }}
                                                    onClick={() => {
                                                      handleIds(item);
                                                      onOpen();
                                                    }}
                                                  >
                                                    Share Feedback
                                                  </Button>
                                                )}
                                              </Flex>
                                            </VStack>
                                            <Divider
                                              display={{
                                                base: "block",
                                                md: "none",
                                              }}
                                              color={"orange.300"}
                                              style={{ margin: "16px 0px" }}
                                            />
                                          </Stack>
                                        );
                                      })}
                                      <ReactPaginate
                                        previousLabel="<< Prev"
                                        nextLabel="Next >>"
                                        pageCount={Math.ceil(
                                          filterConsultationType("Call")
                                            .length / itemsPerPage
                                        )}
                                        onPageChange={handlePageChange}
                                        containerClassName="pagination"
                                        previousLinkClassName="pagination__link"
                                        nextLinkClassName="pagination__link"
                                        disabledClassName="pagination__link--disabled"
                                        activeClassName="pagination__link--active"
                                        // style={paginationStyles}
                                      />
                                    </TabPanel>
                                    {/* <TabPanel>
                                <p>Private Call Coming Soon!</p>
                              </TabPanel> */}
                                  </TabPanels>
                                </Tabs>
                              </Box>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Container>
                      {/* <MDBCol md="12">
                        <MDBCard
                          className="mb-4 mb-md-0"
                          style={{
                            border: "2px solid #FFC293",
                            borderRadius: "18px",
                            // padding:'24px',
                            fontWeight: "500",
                          }}
                        >
                          <MDBCardBody>
                            <MDBCardText className="mb-4">
                              {" "}
                              Previous Consultations
                            </MDBCardText>
                            <MDBTable responsive>
                              <MDBTableHead>
                                <tr>
                                  <th scope="col">Call Date</th>
                                  <th scope="col">Money Spent</th>
                                  <th scope="col">Call Duration</th>
                                  <th scope="col">Consultation Type</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Recording</th>
                                  <th scope="col">Review</th>
                                  <th scope="col">Remedies</th>
                                </tr>
                              </MDBTableHead>
                              {userDetails?.userDetails?.purchases ? (
                                <MDBTableBody>
                                  {userDetails.userDetails.purchases.map(
                                    (item) => (
                                      <tr>
                                        <td>{convertIst(item.createdAt)}</td>
                                        <th scope="row">
                                          ₹ {parseFloat(item.amount).toFixed(2)}
                                        </th>
                                        <th scope="row">
                                          {" "}
                                          {parseFloat(
                                            item.timeDuration
                                          ).toFixed(2)}{" "}
                                          min
                                        </th>
                                        <th scope="row">
                                          {item.consultationType}
                                        </th>
                                        {item.callStatus === "success" ? (
                                          <td
                                            style={{
                                              color: "green",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Completed
                                          </td>
                                        ) : (
                                          <td
                                            style={{
                                              color: "red",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Failed
                                          </td>
                                        )}
                                        {item.RecordingUrl ? (
                                          <ChakraLink
                                            href={item.RecordingUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <Icon as={FiPlay} />
                                          </ChakraLink>
                                        ) : (
                                          <Text>
                                            <Icon as={CgUnavailable} />{" "}
                                          </Text>
                                        )}
                                        {item.feedback ? (
                                          <td style={{ color: "orange" }}>
                                            Submitted
                                          </td>
                                        ) : (
                                          <td style={{ color: "green" }}>
                                            <IconButton
                                              icon={<MdOutlineRateReview />}
                                              aria-label="add-review"
                                              onClick={() => {
                                                handleIds(item);
                                                onOpen();
                                              }}
                                              size="md"
                                              variant="ghost"
                                              colorScheme="orange"
                                            />
                                          </td>
                                        )}
                                        {item.callStatus === "success" &&
                                        item.Remedies ? (
                                          <td
                                            style={{
                                              color: "orange",
                                            }}
                                          >
                                            <Button
                                              variant={"outline"}
                                              colorScheme="orange"
                                              fontSize="11px"
                                              fontWeight={"600"}
                                              // onClick={onOpenModal1}
                                              onClick={() => {
                                                handleRemedies(item.Remedies);
                                                onOpenModal1();
                                              }}
                                            >
                                              Get Remedies
                                            </Button>
                                          </td>
                                        ) : (
                                          <td
                                            style={{
                                              color: "orange",
                                              fontSize: "11px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            ---
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  )}
                                </MDBTableBody>
                              ) : (
                                <Center>No previous history</Center>
                              )}
                            </MDBTable>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol> */}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              {/* <ConsultationHistory /> */}
            </section>
          </>
        )
      ) : (
        <PrimaryLoading />
      )}
    </>
  );
}
