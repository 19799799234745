import React, { useState } from "react";
import "../../../App.css";
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  // GridItem,
  FormLabel,
  Input,
  Select,
  // SimpleGrid,
  // InputLeftAddon,
  InputGroup,
  // Textarea,
  // FormHelperText,
  // InputRightElement,
  Image,
  Text,
  Divider,
  // HStack,
  VStack,
  Stack,
} from "@chakra-ui/react";
// import {  } from "@chakra-ui/react";
// import { TimeIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import _user from "../../../assets/_user.png";
import Insufficient_balance from "../../../assets/Insufficient_balance.png";

import { useToast } from "@chakra-ui/react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Form1 = () => {
  const userWalletBalance = useSelector(
    (state) => state.loggedInUserDetails.walletBalance
  );
  // const [show, setShow] = React.useState(false);
  const [balance, setBalance] = useState(true);
  // const handleClick = () => setShow(!show);
  return (
    <>
      {balance ? (
        <Flex direction={"column"}>
          <Box mb={{ base: "8px", md: "16px" }}>
            <Image
              mx={"auto"}
              src={_user}
              alt="user"
              maxW={{ base: "25%", md: "100%" }}
            />
            <Text
              fontSize={{ base: "16px", md: "22px" }}
              fontWeight={"500"}
              textAlign={"center"}
            >
              Astrologer Name
            </Text>
          </Box>
          <Divider alignSelf={"center"} width={"90%"} color={"gray.300"} />

          <Flex
            justifyContent={"space-between"}
            my={{ base: "8px", md: "12px" }}
          >
            <VStack borderRight="1px" w={"50%"}>
              <Text
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight={500}
                color={"#7B7B7B"}
              >
                Consultation Charges
              </Text>
              <Text
                fontSize={{ base: "14px", md: "25px" }}
                fontWeight={500}
                color={"#252525"}
              >
                ₹40/min
              </Text>
            </VStack>
            <VStack w={"50%"}>
              <Text
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight={500}
                color={"#7B7B7B"}
              >
                Your Balance
              </Text>
              <Text
                fontSize={{ base: "14px", md: "25px" }}
                fontWeight={500}
                color={"#252525"}
              >
                ₹ {userWalletBalance}
              </Text>
              <Text
                fontSize={{ base: "10px", md: "14px" }}
                fontWeight={400}
                color={"gray.500"}
              >
                Max call duration: 3mins
              </Text>
            </VStack>
          </Flex>
          <Divider alignSelf={"center"} width={"90%"} color={"gray.300"} />
          <Flex mt={{ md: "16px" }} mb={{ md: "21px" }}>
            <Text
              fontSize={{ md: "14px" }}
              fontWeight={400}
              color={"gray.700"}
              textAlign={"center"}
            >
              {/* Once start, You will receive a call , Please prepare your detail
              for consultation: First and last name, Gender, Date and time of
              birth, Town/city of birth */}
            </Text>
          </Flex>

          {/* <Stack
          direction="row"
          spacing={4}
          justify={"space-between"}
          align="center"
        >
          <Button colorScheme="green" w={"50%"} variant="solid">
            Recharge
          </Button>
          <Button colorScheme="orange" w={"50%"} variant="solid">
            Next
          </Button>
        </Stack> */}
        </Flex>
      ) : (
        <Flex direction={"column"}>
          <Heading
            fontSize={{ base: "14px", md: "20px" }}
            fontWeight={500}
            textAlign={"center"}
          >
            Birth Details
          </Heading>
          <Box mb={{ base: "8px", md: "16px" }}>
            <Image
              mx={"auto"}
              src={Insufficient_balance}
              alt="no-Balance"
              maxW={{ base: "25%", md: "100%" }}
            />
            <Text
              fontSize={{ base: "16px", md: "22px" }}
              fontWeight={"500"}
              textAlign={"center"}
            >
              Insufficient Balance !!..
            </Text>
            <Text
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight={"400"}
              textAlign={"center"}
              color={"#FF6644"}
              mt={"26px"}
            >
              Minimum wallet balance required to talk please recharge your
              wallet
            </Text>
          </Box>
          <Divider alignSelf={"center"} width={"90%"} color={"gray.300"} />
          <Flex
            justifyContent={"space-between"}
            my={{ base: "8px", md: "12px" }}
          >
            <VStack borderRight="1px" w={"50%"}>
              <Text
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight={500}
                color={"#7B7B7B"}
              >
                Consultation Charges
              </Text>
              <Text
                fontSize={{ base: "14px", md: "25px" }}
                fontWeight={500}
                color={"#252525"}
              >
                ₹40/min
              </Text>
            </VStack>
            <VStack w={"50%"}>
              <Text
                fontSize={{ base: "14px", md: "18px" }}
                fontWeight={500}
                color={"#7B7B7B"}
              >
                Your Balance
              </Text>
              <Text
                fontSize={{ base: "14px", md: "25px" }}
                fontWeight={500}
                color={"#252525"}
              >
                ₹ {userWalletBalance}
              </Text>
            </VStack>
          </Flex>
          <Divider
            alignSelf={"center"}
            m={0}
            width={"90%"}
            color={"gray.300"}
          />
        </Flex>
      )}
    </>
  );
};

const Form2 = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState("");

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
 
  return (
    <>
      {/* <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        User Details
      </Heading> */}
      <FormControl>
        <FormLabel
          htmlFor="full-name"
          fontSize={{ base: "14px", md: "16px" }}
          fontWeight={"normal"}
          color={"#E1843E"}
        >
          Full Name
        </FormLabel>
        <Input
          id="last-name"
          placeholder="Full name"
          _focus={{
            border: "1px solid orange",
            outline: "none",
            boxShadow: "none",
          }}
        />
      </FormControl>
      <Flex
        justifyContent={"space-between"}
        gap={2}
        mt={{ base: "12px", md: "16px" }}
      >
        <Box w={"50%"}>
          <FormControl mt={{ md: "16px" }}>
            <FormLabel
              htmlFor="last-name"
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight={"normal"}
              color={"#E1843E"}
            >
              Gender
            </FormLabel>
            <Select
              placeholder="Select Gender"
              colorScheme="orange"
              _focus={{
                border: "1px solid orange",
                outline: "none",
                boxShadow: "none",
              }}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Select>
          </FormControl>
        </Box>
        <Box w={"50%"}>
          <FormControl>
            <FormLabel
              htmlFor="birth-place"
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight={"normal"}
              color={"#E1843E"}
              mt={{ md: "16px" }}
            >
              Birth Place
            </FormLabel>
            <Input
              id="birth-place"
              placeholder="Your Birth Place!!"
              _focus={{
                border: "1px solid orange",
                outline: "none",
                boxShadow: "none",
              }}
            />
          </FormControl>
        </Box>
      </Flex>

      <FormControl>
        <Flex
          justifyContent={"space-between"}
          gap={2}
          mt={{ base: "12px", md: "16px" }}
        >
          <Box w={"50%"}>
            <FormLabel
              htmlFor="birth-place"
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight={"normal"}
              color={"#E1843E"}
            >
              Birth Date
            </FormLabel>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              wrapperClassName="formDatepicker"
              border={"1px solid red"}
            />
          </Box>
          <Box w={"50%"}>
            <FormLabel
              htmlFor="birth-place"
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight={"normal"}
              color={"#E1843E"}
              // mt={{ md: "16px" }}
            >
              Birth Time
            </FormLabel>
            <InputGroup>
              <Input
                type="time"
                value={time}
                onChange={handleTimeChange}
                placeholder="Select a time"
                pl={"6px"}
                _focus={{
                  border: "1px solid orange",
                  outline: "none",
                  boxShadow: "none",
                }}
              />
            </InputGroup>
          </Box>
        </Flex>
      </FormControl>
    </>
  );
};

// const Form3 = () => {
//   return (
//     <>
//       <Heading w="100%" textAlign={"center"} fontWeight="normal">
//         Social Handles
//       </Heading>
//       <SimpleGrid columns={1} spacing={6}>
//         <FormControl as={GridItem} colSpan={[3, 2]}>
//           <FormLabel
//             fontSize="sm"
//             fontWeight="md"
//             color="gray.700"
//             _dark={{
//               color: "gray.50",
//             }}
//           >
//             Website
//           </FormLabel>
//           <InputGroup size="sm">
//             <InputLeftAddon
//               bg="gray.500"
//               _dark={{
//                 bg: "gray.800",
//               }}
//               color="gray.500"
//               rounded="md"
//             >
//               http://
//             </InputLeftAddon>
//             <Input
//               type="tel"
//               placeholder="www.example.com"
//               focusBorderColor="brand.400"
//               rounded="md"
//             />
//           </InputGroup>
//         </FormControl>

//         <FormControl id="email" mt={1}>
//           <FormLabel
//             fontSize="sm"
//             fontWeight="md"
//             color="gray.700"
//             _dark={{
//               color: "gray.50",
//             }}
//           >
//             About
//           </FormLabel>
//           <Textarea
//             placeholder="you@example.com"
//             rows={3}
//             shadow="sm"
//             focusBorderColor="brand.400"
//             fontSize={{
//               sm: "sm",
//             }}
//           />
//           <FormHelperText>
//             Brief description for your profile. URLs are hyperlinked.
//           </FormHelperText>
//         </FormControl>
//       </SimpleGrid>
//     </>
//   );
// };

export default function ChatMultistepForm() {
  const toast = useToast();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(50);
  const [noBalance, setNoBalance] = useState(false);
  const navigate = useNavigate();

  // const goToConsultationStarted = () => {
  //   navigate("/chatconsultationstarted");
  // };
  return (
    <>
      <Box
        // borderWidth="1px"
        rounded="lg"
        // shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        px={3}
        m="10px auto"
        as="form"
      >
        {noBalance ? null : (
          <Progress
            hasStripe
            value={progress}
            mb="5%"
            mx="5%"
            isAnimated
            colorScheme={"orange"}
          ></Progress>
        )}

        {step === 1 ? <Form1 /> : step === 2 ? <Form2 /> : null}
        <ButtonGroup mt="3%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            {noBalance ? (
              <Stack
                direction={"row"}
                w={"100%"}
                spacing={4}
                justifyContent={"center"}
              >
                <Button colorScheme="orange" w={"100%"} variant="solid">
                  Recharge Now
                </Button>
              </Stack>
            ) : (
              <>
                {step === 2 ? (
                  <Stack
                    direction={"row"}
                    w={"100%"}
                    spacing={4}
                    justifyContent={"center"}
                  >
                    <Link to="/chatconsultationstarted">
                      <Button
                        // w="7rem"
                        isDisabled={step === 3}
                        onClick={() => {
                          setStep(step + 1);
                          // goToConsultationStarted();
                          if (step === 3) {
                            setProgress(100);
                          } else {
                            setProgress(progress + 33.33);
                          }
                        }}
                        colorScheme="orange"
                        variant="solid"
                        w={"100%"}
                      >
                        Start Consultation
                      </Button>
                    </Link>
                  </Stack>
                ) : (
                  <Stack
                    direction={"row"}
                    w={"100%"}
                    spacing={4}
                    justifyContent={"space-between"}
                  >
                    <Button
                      // onClick={() => {
                      //   setStep(step - 1);
                      //   setProgress(progress - 33.33);
                      // }}
                      // isDisabled={step === 1}
                      colorScheme="green"
                      variant="solid"
                      w={"50%"}
                    >
                      Recharge
                    </Button>
                    <Button
                      // w="7rem"
                      isDisabled={step === 3}
                      onClick={() => {
                        setStep(step + 1);
                        if (step === 3) {
                          setProgress(100);
                        } else {
                          setProgress(progress + 50);
                        }
                      }}
                      colorScheme="orange"
                      variant="solid"
                      w={"50%"}
                    >
                      Next
                    </Button>
                  </Stack>
                )}
              </>
            )}
            {step === 3 ? (
              <Button
                w="7rem"
                colorScheme="red"
                variant="solid"
                onClick={() => {
                  toast({
                    title: "Account created.",
                    description: "We've created your account for you.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              >
                Submit
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
      </Box>
    </>
  );
}
