import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {  G_GET_ALL_GURUS } from '../apiLinks';

const initialState = {
  data: [],
  total: 0,
  status: 'idle',
  error: null
};

export const fetchAllGurus = createAsyncThunk('allGurusData/fetchAllGurus', async ({pageNumber, perPage, uid = ""}) => {
  const response = await axios.get(G_GET_ALL_GURUS(pageNumber, perPage, uid));
  return response.data.astrologers;
});

const getAllGurusSlice = createSlice({
  name: 'allGurusData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGurus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllGurus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.docs;
        state.total = action.payload.total;
      })
      .addCase(fetchAllGurus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default getAllGurusSlice.reducer;
