import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    Heading,
    Input,
    Select,
} from "@chakra-ui/react";
import { toast } from 'react-toastify';
import axios from 'axios';
export default function Formpersonalized2() {
const [birthdetail,setBirthdetail]=useState(true);
const [name,setName]=useState("");
const [gender,setGender]=useState("");
const [birthtime,setBirthtime]=useState("");
const [birthdate,setBirthdate]=useState("");
const [birthplace,setBirthplace]=useState("");
const token =localStorage.getItem("loginToken"); // Replace 'your_auth_token' with your actual token
const config = {
      headers: {
         'Authorization': `Bearer ${token}`
}};

const resetData=()=>{
    setName("");
    setGender("");
    setBirthtime("");
    setBirthdate("");
    setBirthplace("");
}
const birthdetails=()=>{
    axios.get("https://data.gurucool.life/api/v1/user/getBirthDetails",config)
     .then(response => {
      
       setBirthdetail(true);
     })
     .catch(error => {
       console.error('Error:', error);
       setBirthdetail(false);
});
}

const handleSubmit=()=>{
    if(name===""||gender===""||birthtime===""||birthdate===""||birthplace===""){
        toast("Please Fill All Requeired Field!");
        return;
    }
    let text =birthdate;
    const myArray = text.split("-");
    const updateBirthdate=myArray[2]+'/'+myArray[1]+'/'+myArray[0];
    axios.post(`https://data.gurucool.life/api/v1/user/createBirthDetails`,
    {
        fullName:name,
        gender:gender,
        birthTime:birthtime,
        birthDate:updateBirthdate,
        birthPlace:birthplace
    },config).then((response)=>{
       
       
    }).catch((error)=>{
       
       
    })
    resetData();
    window.location.reload(true);
}

useEffect(()=>{
    birthdetails();
},[])

return (
        <>{birthdetail?"":
        <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "40px",
          width: "100%",
          backgroundColor: "#FFE9DF",
        }}
        mb={"25px"}
      >
        <Heading
          style={{ margin: "auto", textAlign: "center", padding: "15px" }}
        >
          Get Personalized Horoscope
        </Heading>
        {/* <Container></Container> */}
        <Box
          width={{base:"100vw",sm:"85vw",md:"85vw",lg:"85vw",xl:"85vw"}}
          maxW={"1100px"}
          bg={""}
          flexDirection={{ base: "column", sm: "column", md: "row", lg: "row" }}
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "15px",
            paddingBottom: "16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "4px auto", sm: "10px", md: "14px", lg: "14px" }}
            
          >
            <Input type="text" placeholder="Name" backgroundColor={"white"} value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "4px auto", sm: "10px", md: "14px", lg: "14px" }}
            
          >
            <Input
              type="text"
              placeholder="Birth Place"
              backgroundColor={"white"}
              value={birthplace}
              onChange={(e) => setBirthplace(e.target.value)}
            />
          </FormControl>
          <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "4px auto", sm: "10px", md: "14px", lg: "14px" }}
            
          >
            <Select placeholder="Gender" backgroundColor={"white"}  onChange={(e) => setGender(e.target.value)}>
              <option value={"Male"}>Male</option>
              <option value={"Female"}>Female</option>
            </Select>
          </FormControl>
          <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "4px auto", sm: "10px", md: "14px", lg: "14px" }}
           
          >
            <Input
              placeholder="Birth Date"
              type="date"
              backgroundColor={"white"}
              value={birthdate}
              onChange={(e) => setBirthdate(e.target.value)}
            />
          </FormControl>
          {/* <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "7px auto", sm: "10px", md: "14px", lg: "14px" }}
            
          >
            <Input type="time" placeholder="Time" backgroundColor={"white"} value={birthtime} onChange={(e) => setBirthtime(e.target.value)} />
          </FormControl> */}
          <FormControl
            width={{ base: "20vw", sm: "20vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "9px", sm: "10px", md: "14px", lg: "14px" }}
            
          >
            <Button
              width={{ base: "20vw", sm: "20vw", md: "20vw", lg: "20vw",xl:"15vw" }}
              type="submit"
              backgroundColor={"#FF4C00"}
              color={"white"}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </FormControl>
        </Box>
        </Box>}
          
        </>
      );
}
